import styled from "styled-components";
import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";
import { DecorIcon } from "ui/components/Icons/DecorIcon";

const IContainer = styled.div<{ color: string }>`
  display: flex;
  padding: 16px 20px;
  background-color: ${(props) => `rgba(${hexToRgba(props.color, 0.15)})`};
  color: ${(props) => props.color};
  align-items: center;
  border-radius: 12px;
  column-gap: 16px;
`;

const IIcon = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
`;

const IContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  min-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-align: left;
  color: ${_variables.blackColor};
`;

const icons = {
  alert: <DecorIcon name={"alert"} variant={"info"} width={24} height={24} />,
};

const colors = {
  primary: _variables.primaryColor,
};

export const StaticAlert: CT<{ icon?: "alert"; color?: "primary" }> = ({
  color = "primary",
  icon = "alert",
  children,
}) => {
  return (
    <IContainer color={colors[color]}>
      <IIcon>{icons[icon]}</IIcon>
      <IContent>{children}</IContent>
    </IContainer>
  );
};
