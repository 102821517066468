import styled from "styled-components";

import { useAppSelector } from "services/hooks";
import { selectImportState } from "services/store/importSlice";
import { _variables } from "ui/styles/_variables";

import { ImportEmailChooseUserList } from "../../containers/specialFormElements/ImportEmailChooseUserList";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 8px;
  min-height: 104px;
  height: 104px;
  width: 400px;
`;
export const ChooseList: CT<{
  onChangeList: (id: number, name: string, count: number) => void;
}> = ({ onChangeList }) => {
  const userSetting = useAppSelector(selectImportState);

  return (
    <Content>
      <ImportEmailChooseUserList
        initialData={
          userSetting.list
            ? {
                name: userSetting.list.name,
                id: userSetting.list.id,
                count: 111,
              }
            : { count: 0, id: 0, name: "" }
        }
        onChange={(data) => {
          onChangeList(Number(data.id), data.name, data?.data?.count ?? 0);
        }}
        onBlur={() => {}}
        label={"Список"}
        fixedElement={<div>Добавить список</div>}
      />
    </Content>
  );
};
