import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import useUrlState from "@ahooksjs/use-url-state";
import styled from "styled-components";

import { offExitConfirm, onExitConfirm } from "app/applicationExitHandler";
import { useCreateMailingStore } from "app/createMailing/useCreateMailingStore";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import {
  selectCreateMailingData,
  setChooseMailing,
  setSecondStepData,
  setTemplateMailingData,
} from "services/store/createMailingSlice";
import { mailingService } from "services/mailing";
import {
  clearUserCJMMailingData,
  selectCJMSavedMailingUserData,
} from "services/store/cjm";

import { CreateMailingMainForm } from "./CreateMailingMainForm";
import { LoadDots } from "../../components/LoadDots";

const LoadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const CreateMailingMainFormPage = () => {
  const [params] = useUrlState<{ id: string }>({
    id: "",
  });
  const userData = useAppSelector(selectUserData, shallowEqual);
  const mailingData = useAppSelector(selectCreateMailingData);
  const [isLoad, setLoad] = useState(true);
  const { hasMailingDataInStorage } = useCreateMailingStore();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const cjmData = useAppSelector(selectCJMSavedMailingUserData);

  useEffect(() => {
    onExitConfirm();
    return () => {
      offExitConfirm();
    };
  }, []);

  const load = async () => {
    try {
      const response = await mailingService.viewMailing(params.id);
      const data = response.data.data.mailing;
      dispatch(
        setTemplateMailingData({
          templateStep: data.template_id,
          firstStep: cjmData?.firstStep ?? {
            name: data.from ?? userData?.name ?? "",
            theme: data.name,
            address: data.email ?? userData?.email ?? "",
          },
          secondStep: cjmData?.secondStep ?? {
            name: data.book_name,
            count: data.book_count,
            listId: data.book_id,
          },
        })
      );
      setLoad(false);
    } catch (e) {
      console.log(e);
      toast("Ошибка загрузки рассылки", { autoClose: 3000, type: "warning" });
      history.push("/");
    }
  };

  //Если пользователь совершал путь создания
  //и ушел на импорт контактов -> вернувшись
  //заполнить данные
  useEffect(() => {
    if (!!cjmData?.secondStep) {
      dispatch(
        setSecondStepData({
          listId: cjmData.secondStep.listId,
          name: cjmData.secondStep.name,
          count: cjmData.secondStep.count,
        })
      );
      dispatch(setChooseMailing(false));
      // заполнение выполнено, очистить промежуточный стейт между страницами
      dispatch(clearUserCJMMailingData());
      sessionStorage.removeItem("goToChooseList");
    }
  }, []);

  useEffect(() => {
    if (!params.id || hasMailingDataInStorage()) {
      setLoad(false);
      return;
    }
    if (
      mailingData.data &&
      mailingData.data.firstStep &&
      mailingData.data.secondStep &&
      mailingData.data.templateStep
    ) {
      setLoad(false);
      return;
    }
    load();
  }, []);

  if (isLoad)
    return (
      <LoadContent>
        <LoadDots />
      </LoadContent>
    );
  return <CreateMailingMainForm />;
};
