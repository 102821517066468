import styled from "styled-components";

import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { createDateForBuyPackage } from "lib/date";

import img from "ui/assets/media/images/preview-payment-success.svg";
import { Button } from "../Buttons/Button";
import { Link } from "../Link";
import { useEffect } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 432px;
`;
const Preview = styled.div`
  width: 432px;
  height: 211px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const TextContent = styled.div`
  row-gap: 24px;
  display: flex;
  flex-direction: column;
`;
const Heading = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #898d9a;
  letter-spacing: -0.25px;
  text-align: left;
  & b {
    font-weight: 600;
  }
  & a {
    font-size: inherit;
  }
`;

const Buttons = styled.div`
  display: flex;

  column-gap: 8px;
`;

export const PaymentSuccessAlert: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const data = useAppSelector(selectModalData);

  useEffect(() => {
    window.dataLayer.push({ event: "cp_click_pay_success" });
  }, []);

  return (
    <Container>
      <Preview>
        <img src={img} alt="" />
      </Preview>
      <TextContent>
        <Heading>Платеж успешно совершен!</Heading>
        <Text>
          Вам зачислено {data!.limit} писем. Пакет действителен до{" "}
          {createDateForBuyPackage(data!.date_end)}. Информация о пакетах
          доступа в{" "}
          <Link onClick={onClose} link={"/main/history-pay"} color={"primary"}>
            “История покупок”
          </Link>
          .
        </Text>
      </TextContent>
      <Buttons>
        <Link onClick={() => onClose()} link={"/"}>
          <Button>На главную</Button>
        </Link>
      </Buttons>
    </Container>
  );
};
