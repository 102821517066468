import { useCallback, useEffect } from "react";

import { serviceUser } from "services/user";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";
import { createToastResponseError } from "lib/toastify";
import { selectUserData } from "services/store/userSlice";
import { deepEqual } from "lib/other";

export const useUserNotify = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserData, deepEqual);

  const load = useCallback(async () => {
    try {
      const response = await serviceUser.notifyNewRegistration();
      const data = response.data.data.notification;

      if (serviceUser.getToken()) {
        if (!data) return;
        if (document.location.pathname.includes("logout")) return;

        // Если просто бековское уведомление
        if (data.params?.scenario === "" || !Boolean(data.params.scenario)) {
          dispatch(
            setModalData({
              title: data.params.title,
              message: data.params.message,
              id: data.id,
              isNotShowClose: true,
            })
          );
          dispatch(openModal("user-notify"));
          return;
        }

        if (data.params?.scenario === "error") {
          createToastResponseError(
            {},
            data.params.message
          )({
            autoClose: false,
            toastId: "notifyError_" + data?.id ?? "",
          });
        }

        // Eсли сценарное уведомление
        if (data.params?.scenario === "registration") {
          dispatch(
            setModalData({
              title: data.params.title,
              message: data.params.message,
              id: data.id,
              isNotShowClose: true,
            })
          );
          dispatch(openModal("registration-scenario-notify-modal"));
        }
      }
    } catch (e) {
      console.log(e);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user) return;
    if (!serviceUser.isAuth()) return;
    const request = () => {
      load();
      setTimeout(request, 30000);
    };
    setTimeout(request, 0);
    //eslint-disable-next-line
  }, []);
};
