import type { IconPropsType } from "./types";

import { IIconWrapper } from "./styles";

import Sprite from "../../assets/media/icons/sprites/action-icon-sprite.svg";

export type ActionIconComponentPropsType = IconPropsType<{
  variant: "download" | "edit";
  name: "arrow-bottom" | "pen" | "pen-tiny" | "delete";
}>;

/**
 * Иконки действия: скачивания, открытия, загрузки и пр
 */

export const ActionIcon: CT<ActionIconComponentPropsType> = ({
  variant,
  wrapper,
  className,
  style,
  height = 24,
  width = 24,
  name = "arrow-bottom",
}) => {
  return (
    <IIconWrapper
      style={{
        width: wrapper ? wrapper.width : "auto",
        height: wrapper ? wrapper.height : "auto",
        ...(wrapper?.style ?? ""),
      }}
    >
      <svg
        className={className}
        style={{ width: width, height: height, ...style }}
      >
        <use href={`${Sprite}#${name}-${variant}-icon`} />
      </svg>
    </IIconWrapper>
  );
};
