import { useEffect, useState } from "react";

import type { Idea, Status } from "domain/idea";
import { statuses } from "domain/idea";
import type { SortType, Tag } from "services/idea";
import { serviceIdea } from "services/idea";

type useIdeaListFetcherProps = {
  sort: SortType;
  theme: string;
  statusId?: number;
  tagId?: number;
};

type useIdeaListFetcherResult = {
  isLoading: boolean;
  isPageLoading: boolean;
  ideaList: Idea[];
  tagList: Tag[];
  statusList: Status[];
  pageCount: number;
  setCurrentPage: (page: number) => void;
};

const useIdeaListFetcher = ({
  sort,
  theme,
  statusId,
  tagId,
}: useIdeaListFetcherProps): useIdeaListFetcherResult => {
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [pageCount, setPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [ideaList, setIdeaList] = useState<Idea[]>([]);
  const [tagList, setTagList] = useState<Tag[]>([]);
  const [statusList, setStatusList] = useState<Status[]>(
    Object.values(statuses)
  );

  useEffect(() => {
    setIsPageLoading(true);
    Promise.all([
      serviceIdea.getTags().then((response) => {
        setTagList(response.data.data.tags);
      }),
      serviceIdea.getStatuses().then((response) => {
        const statuses = response.data.data.statuses;
        const sumCount = statuses.reduce(
          (acum: number, status: Status) => acum + status.count,
          0
        );
        setStatusList(
          statusList.map((status: Status, index: number) => ({
            ...status,
            count: status.id === 0 ? sumCount : statuses[index]?.count,
          }))
        );
      }),
    ]).then(() => {
      setIsPageLoading(false);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    serviceIdea
      .getAll({ sort, theme, statusId, tagId, currentPage })
      .then((response) => {
        setPageCount(response.headers["x-pagination-page-count"]);
        setIdeaList(response.data.data.ideas);
        setIsLoading(false);
      });
  }, [sort, theme, statusId, tagId, currentPage]);

  return {
    isLoading,
    isPageLoading,
    ideaList,
    tagList,
    statusList,
    pageCount,
    setCurrentPage,
  };
};

export default useIdeaListFetcher;
