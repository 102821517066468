import { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { usePayValidationRedirect } from "app/usePayValidationRedirect";
import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";

import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";
import { ModalTextContainer } from "../../components/Modals/ModalTextContainer";
import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";
import { Button } from "../../components/Buttons/Button";

const IContainer = styled(ModalDefaultFormContainer)`
  width: 505px;
`;

export const RedirectAfterValidateContacts: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const modalData = useAppSelector(selectModalData);
  const [isLoad] = useState(false);
  const { clearInfo } = usePayValidationRedirect();
  const history = useHistory();

  const clickRedirectHandler = () => {
    if (!modalData?.id) history.push(`/`);
    onClose();
    history.push(`/contacts/${modalData!.id}`);
  };

  useLayoutEffect(() => {
    return () => {
      clearInfo();
    };
  }, []);

  return (
    <IContainer>
      <ModalTextContainer>
        <p>
          Как только пройдет оплата, запустится проверка контактов. Проверка
          займет некоторое время. Статусы отображаются в прогресс баре в списке
          контактов.
        </p>
      </ModalTextContainer>
      <ModalButtonsContainer>
        <Button
          disabled={isLoad}
          isLoad={isLoad}
          onClick={clickRedirectHandler}
          type={"submit"}
        >
          Перейти в список
        </Button>
      </ModalButtonsContainer>
    </IContainer>
  );
};
