import { createGlobalStyle } from "styled-components";

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src:url('/media/fonts/Inter-Regular.woff') format('woff'),
    url('/media/fonts/Inter-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src:url('/media/fonts/Inter-Medium.woff') format('woff'),
    url('/media/fonts/Inter-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src:url('/media/fonts/Inter-SemiBold.woff') format('woff'),
    url('/media/fonts/Inter-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src:url('/media/fonts/Inter-Bold.woff') format('woff'),
    url('/media/fonts/Inter-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
  }
`;
