import styled from "styled-components";
import { useEffect, useState } from "react";

import { useAppDispatch } from "services/hooks";
import { useCreateClientFormPageState } from "app/createClientForm/useCreateClientFormPageState";
import { createFormClientActions } from "services/store/createFormClientSlice";

import { _variables } from "ui/styles/_variables";
import { InputUserContactList } from "ui/containers/specialFormElements/InputUserList";
import { Button } from "ui/components/Buttons/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const Box = styled.div`
  display: flex;
  min-width: 284px;
  flex-direction: column;
  height: fit-content;
  row-gap: 8px;
  padding: 16px;
  background-color: ${_variables.backgroundColor};
  border-radius: 12px;
`;
const TitleForm = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const SelectBooks = styled(InputUserContactList)`
  width: 400px;
`;

const ButtonRow = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const Btn = styled(Button)`
  width: 166px;
`;

export const ChooseContactStep = () => {
  const [currentList, setCurrentList] = useState(0);
  const { data: dataForm } = useCreateClientFormPageState();
  const [isLoad] = useState(false);
  const dispatch = useAppDispatch();

  const clickBackHandler = () => {
    dispatch(createFormClientActions.goToSetting());
  };

  const clickNextHandler = () => {
    dispatch(createFormClientActions.goToFinalStep());
  };

  // const clickNextHandler = async () => {
  //   setLoad(true);
  //   try {
  //     const data = userFormCreateAdapter(dataForm);
  //
  //     let response;
  //
  //     if (!!Number(id)) {
  //       response = await userFormService.update(Number(id), data);
  //     } else {
  //       response = await userFormService.create(data);
  //     }
  //
  //     createStandardToast(response.data.message)();
  //     setTimeout(() => {
  //       history.push("/forms/");
  //     }, 500);
  //   } catch (e) {
  //     createToastResponseError(e)();
  //   } finally {
  //     setLoad(false);
  //   }
  // };

  useEffect(() => {
    dispatch(createFormClientActions.setBookId(currentList));
  }, [currentList]);

  return (
    <Container>
      <Box>
        <TitleForm>Выберите список</TitleForm>
        {/*<Select background={"white"} values={[]} />*/}
        <SelectBooks
          isShowButtonAdd={true}
          placeholder={"Выберите список"}
          initialData={{
            name: dataForm.bookName,
            id: dataForm.bookId,
            count: 0,
          }}
          onChange={(data) => {
            setCurrentList(Number(data.id));
          }}
          onBlur={() => {}}
        />
      </Box>

      <ButtonRow>
        <Btn disabled={isLoad} onClick={clickBackHandler} variant={"grey"}>
          Назад
        </Btn>
        <Btn
          disabled={currentList === 0 || isLoad}
          onClick={clickNextHandler}
          variant={"primary"}
        >
          Далее
        </Btn>
      </ButtonRow>
    </Container>
  );
};
