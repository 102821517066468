import { Link } from "react-router-dom";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";
import createSendersContent from "ui/assets/media/images/create-senders-content.svg";
import importContactsContent from "ui/assets/media/images/import-contacts-content.svg";

import { TableContainerScroll } from "../containers/TableContainerScroll";
import { clearUserCJMMailingData } from "services/store/cjm";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { AlertEmailConfirm } from "ui/components/Alerts/AlertEmailConfirm";
import { selectUserData } from "services/store/userSlice";
import { deepEqual } from "lib/other";

const Row = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  column-gap: 32px;
  row-gap: 32px;
`;

const CreateSendersBox = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${_variables.backgroundColor};
  border: 1px solid ${_variables.strokeColor};
  border-radius: 12px;
  width: 100%;
  height: 188px;
`;
const ImportContactsBox = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(${hexToRgba(_variables.additionalColors.blue, 0.07)});
  border: 1px dashed ${_variables.additionalColors.blue};
  border-radius: 12px;
  width: 100%;
  height: 188px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 5px 12px 40px;
  width: 100%;
`;

const InnerContent = styled.div`
  display: flex;
  padding: 30px 0px 0 0px;
  row-gap: 24px;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const MainPage = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserData, deepEqual);

  return (
    <>
      <Content>
        <TableContainerScroll showScroll={true} height={70}>
          {user!.confirm_needed && <AlertEmailConfirm />}

          <InnerContent>
            <Row>
              <CreateSendersBox
                onClick={() => {
                  dispatch(clearUserCJMMailingData());
                }}
                to={"/templates/cards"}
              >
                <img src={createSendersContent} alt="" />
              </CreateSendersBox>

              <ImportContactsBox to={"/contacts/import"}>
                <img src={importContactsContent} alt="" />
              </ImportContactsBox>
            </Row>
            {/*<GraphicPreview />*/}
          </InnerContent>
        </TableContainerScroll>
      </Content>
    </>
  );
};
