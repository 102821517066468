import styled from "styled-components";

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 24px;
`;

export const NotContentDefaultTemplatePage: CT<unknown> = ({ className }) => {
  return (
    <Content className={className}>
      <Text>Нет публичных шаблонов</Text>
    </Content>
  );
};
