import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import styled from "styled-components";
import { addDays } from "date-fns";

import type { TemplateType } from "domain/templates";
import { useInnerPromptRedirect } from "app/useInnerPromptRedirect";
import { useCreateMailingStore } from "app/createMailing/useCreateMailingStore";
import { useCreateMailig } from "app/createMailing/useCreateMailig";
import { setModalData } from "services/store/modalDataSlice";
import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  clearReadyPushMail,
  clearTemplateMailingData,
  selectIsOpenAutoMailing,
  selectIsOpenDefferedMailing,
  setChooseMailing,
  setFirstStepData,
  setMailingParams,
  setOpenAutoMailing,
  setOpenDeferredMailing,
  setSecondStepData,
} from "services/store/createMailingSlice";
import { openModal } from "services/store/modal";
import { portCreateMailing } from "services/mailing";
import { selectUserData } from "services/store/userSlice";
import { templatesService } from "services/templates";
import { deepDateSerialize } from "lib/travisals";
import { dateDeSerialize } from "lib/date";

import { _variables } from "ui/styles/_variables";
import { scroll } from "ui/styles/elements/scroll";
import { CreateMailingInfoForm } from "ui/components/CreateMailingForms/CreateMailingInfoForm";
import { ChooseListForm } from "ui/components/CreateMailingForms/ChooseListForm";
import { ParametersForm } from "ui/components/CreateMailingForms/ParametersForm";
import { TemplateCardBigPreview } from "ui/components/TemplateCardBigPreview";
import { Button } from "ui/components/Buttons/Button";
import { Link } from "ui/components/Link";
import { useNotification } from "ui/containers/common/useNotification";
import { AutoMailingPreview } from "ui/components/CreateMailingForms/AutoMailingPreview";
import { userEmailsService } from "services/userEmailsService";
import { getDomainByEmail } from "lib/email";

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  padding: 20px 10px 20px 40px;
`;
const ScrollContent = styled.div`
  max-height: calc(100vh);
  overflow-y: scroll;
  padding-bottom: 380px;
  ${scroll};
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: calc(100% - 25px);
`;
const Heading = styled.h1`
  color: ${_variables.blackColor};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;
const SubText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const SubTextLine = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
`;
const Row = styled.div`
  display: flex;
  column-gap: 20px;
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  min-width: 676px;
  width: calc(100% - 468px);
`;
const Buttons = styled.div`
  display: flex;
  width: 426px;
  column-gap: 20px;

  & button {
    max-width: 50%;
  }
`;
const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const acceptRoutes = [
  "/main?by=import",
  "/templates/create-mailing",
  "/templates/choose-list",
  "/templates/cards?by=import",
  "/packages/payment",
  "/templates/choose-list",
];

export const CreateMailingMainForm = () => {
  const { mailingData, save, clear } = useCreateMailingStore();
  const { submitMailing, isLoad, saveMailing, isSuccessSend } =
    useCreateMailig();
  const userData = useAppSelector(selectUserData, shallowEqual);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const isOpenDeferredMailing = useAppSelector(selectIsOpenDefferedMailing);
  const isOpenAutoMailing = useAppSelector(selectIsOpenAutoMailing);
  const [hasBeenShowForceAutoMailing, setHasForceAutoMailing] = useState(false);

  const [templateData, setTemplateData] = useState<TemplateType | null>(null);
  const [clickSetTimeout, setClickTimout] = useState(false);
  const { isShowConfirmRedirect } = useInnerPromptRedirect(acceptRoutes);
  const { removeAllNotifications } = useNotification();
  const mainThemeHandler: React.ComponentProps<
    typeof CreateMailingInfoForm
  >["onChangeData"] = (data) => {
    dispatch(setFirstStepData(data));
  };

  const isForceShowAutoMailing = async () => {
    if (hasBeenShowForceAutoMailing) return false;
    try {
      const response = await userEmailsService.isNewDomain(
        getDomainByEmail(mailingData.data!.firstStep!.address)
      );
      return (
        response.data.data.result &&
        (mailingData.data?.secondStep?.count ?? 1000) > 500
      );
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const listIdHandler = (id: number, name: string, count: number) => {
    // Сброс проверки нового домена
    setHasForceAutoMailing(false);
    dispatch(setSecondStepData({ name, listId: id, count }));
  };

  const parametersHandler: React.ComponentProps<
    typeof ParametersForm
  >["changeHandler"] = (data) => {
    const prepareData = deepDateSerialize(data);
    dispatch(setMailingParams(prepareData));
  };

  const loadTemplate = async () => {
    if (!mailingData.data?.templateStep) return;
    try {
      const response = await templatesService.viewWithPreview(
        mailingData.data.templateStep
      );
      setTemplateData(response.data.data.template);
    } catch (e) {
      console.log(e);
    }
  };

  const clickTimeoutHandler = () => {
    setClickTimout(true);
    setTimeout(() => setClickTimout(false), 5000);
  };

  const submitHandler = async () => {
    clickTimeoutHandler();
    removeAllNotifications();
    const data = portCreateMailing(
      mailingData,
      mailingData.data!.params!.isNow ? 1 : 2
    );
    await submitMailing(data);
  };

  const saveHandler = async () => {
    const data = portCreateMailing(
      mailingData,
      mailingData.data!.params!.isNow ? 1 : 2
    );
    await saveMailing(data);
  };

  const exitHandler = useCallback(() => {
    if (sessionStorage.getItem("goToChooseList")) {
      removeAllNotifications();
      return;
    }
    clear();
    dispatch(clearTemplateMailingData());
    removeAllNotifications();
  }, [mailingData]);

  const clickAutoMailingHandler = () => {
    // Костыль закрытия отложенной отправки
    dispatch(setOpenAutoMailing(true));
    dispatch(setModalData({ mailingData: mailingData!.data! }));
    dispatch(openModal("auto-mailing-form"));
  };

  const openModalMail = async () => {
    // Если домен новый вызвать прогрев
    const isShowAutoMailing = await isForceShowAutoMailing();

    if (isShowAutoMailing) {
      setHasForceAutoMailing(true);
      clickAutoMailingHandler();
      return;
    }

    dispatch(setModalData({ count: mailingData!.data!.secondStep!.count }));
    if (mailingData.data!.params!.isNow) {
      dispatch(openModal("final-mailing-send"));
      return;
    }
    dispatch(openModal("final-mailing-paling"));
  };

  useEffect(() => {
    if (templateData) return;
    if (!isLoad && !mailingData.data) {
      history.push("/templates/cards");
      return;
    }
    loadTemplate();
  }, [mailingData, isLoad]);

  useEffect(() => {
    if (mailingData.data && !mailingData.data.params) {
      dispatch(
        setMailingParams({
          time: deepDateSerialize(new Date()),
          date: deepDateSerialize(new Date()),
          timeZone: userData!.timezone!,
          isNow: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    dispatch(clearReadyPushMail());
    dispatch(setChooseMailing(false));
    sessionStorage.removeItem("goToChooseList");
    clear();
    return () => exitHandler();
  }, []);

  useEffect(() => {
    if (!!mailingData?.data?.isChooseMailing) {
      sessionStorage.setItem("goToChooseList", "true");
      setTimeout(() => {
        history.push("/templates/choose-list");
      }, 150);
    }
  }, [mailingData?.data?.isChooseMailing]);

  useEffect(() => {
    dispatch(setOpenAutoMailing(false));
    dispatch(setOpenDeferredMailing(false));
    if (!mailingData?.data) {
      history.push("/templates/cards");
    }
  }, []);

  useEffect(() => {
    if (mailingData?.data?.isReadyPushMail) {
      submitHandler();
    }
  }, [mailingData?.data?.isReadyPushMail]);

  const disabledButton =
    isLoad ||
    clickSetTimeout ||
    ((mailingData.data?.isReadyPushMail ||
      !mailingData?.data?.firstStep?.isValid) ??
      true);
  if (!mailingData.data) return <></>;
  return (
    <Content>
      <Prompt
        message={(location) => {
          return isSuccessSend ||
            isShowConfirmRedirect(location as unknown as Location)
            ? true
            : `Вы покидаете окно создания рассылки. Внесенные изменения не сохранятся`;
        }}
      />
      <ScrollContent>
        <Top>
          <Heading>Отправьте рассылку</Heading>
          <SubTextLine>
            <SubText>
              Вы почти на финише! Осталось только проверить всё ли в порядке и
              отправить рассылку.
            </SubText>
            <Link
              onClick={(e) => {
                e.preventDefault();

                saveHandler();
              }}
              link={"/"}
              color={"gray"}
            >
              Сохранить и выйти
            </Link>
          </SubTextLine>
        </Top>
        <Row>
          <Main>
            <CreateMailingInfoForm
              initialData={mailingData.data.firstStep!}
              onChangeData={mainThemeHandler}
            />
            <ChooseListForm
              changeValue={listIdHandler}
              defaultValue={{
                count: mailingData?.data?.secondStep?.count ?? 0,
                name: mailingData.data!.secondStep!.name,
                id: mailingData.data!.secondStep!.listId,
              }}
            />
            {!isOpenAutoMailing && (
              <ParametersForm
                initialData={{
                  isNow: mailingData.data?.params?.isNow ?? true,
                  timeZone:
                    mailingData.data?.params?.timeZone ??
                    userData!.timezone ??
                    "Europe/Moscow",
                  time: mailingData.data?.params?.time
                    ? dateDeSerialize(mailingData.data.params.time)
                    : new Date(),
                  date: mailingData.data?.params?.date
                    ? dateDeSerialize(mailingData.data.params.date)
                    : addDays(new Date(), 1),
                }}
                changeHandler={parametersHandler}
              />
            )}
            {!isOpenDeferredMailing && (
              <>
                <AutoMailingPreview
                  isActiveSwitch={isOpenAutoMailing}
                  onCallAutoMailing={clickAutoMailingHandler}
                />
              </>
            )}
          </Main>
          {templateData && (
            <RightBlock>
              <TemplateCardBigPreview
                onSave={save}
                data={templateData}
                src={templateData.preview}
              />
              <Buttons>
                <Button
                  variant={"secondary"}
                  widthMode={"full"}
                  disabled={!mailingData.data.firstStep?.isValid ?? true}
                  onClick={() => {
                    dispatch(openModal("create-test-mailing"));
                  }}
                >
                  Отправить тест
                </Button>
                <Button
                  widthMode={"full"}
                  onClick={openModalMail}
                  disabled={disabledButton}
                >
                  Отправить рассылку
                </Button>
              </Buttons>
            </RightBlock>
          )}
        </Row>
      </ScrollContent>
    </Content>
  );
};
