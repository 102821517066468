import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import useUrlState from "@ahooksjs/use-url-state";

import type { ProjectDomainType } from "domain/project";
import { projectService } from "services/project";
import { useTableStateLoad } from "ui/hooks/useTableStateLoad";

export const useShowApiKeysCollection = () => {
  const [values, setValues] = useState<ProjectDomainType[]>([]);
  const [countPage, setCountPage] = useState(0);
  const [query, setQuery] = useUrlState({
    "per-page": "",
    page: "",
  });
  const [queryState, setQueryState] = useState({});
  const { setOffLoad, setOnLoad, isLoad, isNotFound } =
    useTableStateLoad(values);

  const loadData = async () => {
    setOnLoad();
    try {
      const list = await projectService.index({
        ...query,
        perPage: query["per-page"],
      });

      setCountPage(list.headers["x-pagination-page-count"]);
      setValues(list.data.data.projects);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  useEffect(() => {
    setQuery({
      ...query,
    });
    //@eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shallowEqual(queryState, query)) return;
    setValues([]);
    loadData();
    setQueryState(query);
    //@eslint-disable-next-line
  }, [query]);

  return {
    data: values,
    countPage,
    isLoad,
    loadData,
    isNotFound,
    query,
  };
};
