import plural from "plural-ru";

export const mailPlural = (val: number) => {
  return plural(val, "%d письмо", "%d письма", "%d писем");
};

export const contactsPlural = (val: number) => {
  return plural(val, "контакт", "контакта", "контактов");
};

export const pricePlural = (val: number) => {
  return plural(val, "%d рубль", "%d рубля", "%d рублей");
};
