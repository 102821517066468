import { useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import type { categoryMailingType } from "domain/mailing";
import { useAnalyticMailing } from "app/useAnalyticMailing";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";
import { useAppDispatch } from "services/hooks";

import { _variables } from "ui/styles/_variables";

import { StatisticBlockCount } from "../components/StatisticBlocks/StatisticBlockCount";
import { StatisticErrorCount } from "../components/StatisticBlocks/StatisticErrorCount";
import { BreadcrumbsTitle } from "../components/ BreadcrumbsTitle";
import { LoadContent } from "../components/LoadContent";

const IContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 40px 25px 12px 40px;
`;

const ITitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 32px;
`;

const IMainStatRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 24px;
`;

const ISeparator = styled.div`
  width: 100%;
  height: 1px;
  margin: 32px 0;
  background-color: ${_variables.strokeColor};
`;

const IErrorTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 32px;

  & span {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  & .positive {
    color: ${_variables.supportColors.green};
  }
  & .negative {
    color: ${_variables.supportColors.red};
  }
`;

const IErrorRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
`;

const Breadcrumbs = styled(BreadcrumbsTitle)`
  margin-bottom: 32px;
`;

export const AnalyticMailingPage = () => {
  const { id } = useParams<{ id: string }>();
  const { loadData, dataMailing: data } = useAnalyticMailing();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!id) return;
    loadData(id);
  }, []);

  const clickStatusMailingHandler = (
    category: keyof typeof categoryMailingType | null
  ) => {
    if (!data?.id) return;
    dispatch(setModalData({ category, noPaddingContent: true, id: data.id }));
    dispatch(openModal("list-status-mailing"));
  };

  const countErrors = (data?.total ?? 0) - (data?.delivered ?? 0);

  const ErrorTitle = () => {
    if (countErrors > 0)
      return (
        <IErrorTitle>
          Статистика ошибок -{" "}
          <span className={"negative"}>{countErrors} недоставленных</span>
        </IErrorTitle>
      );
    return (
      <IErrorTitle>
        Статистика ошибок - <span className={"positive"}>Ошибок нет</span>
      </IErrorTitle>
    );
  };

  if (!data) return <LoadContent />;
  return (
    <IContainer>
      <Breadcrumbs
        data={[
          { title: "История рассылок", link: "/mailing" },
          { title: "Подробная аналитика" },
        ]}
      />
      <ITitle>{data.name}</ITitle>
      <IMainStatRow>
        <StatisticBlockCount
          invertedCount={true}
          type={3}
          category={1}
          variant={"success"}
          percent={data.total_percent}
          count={data.total}
          onClick={clickStatusMailingHandler}
        />
        {/*Доставленно*/}
        <StatisticBlockCount
          invertedCount={true}
          type={2}
          category={2}
          onClick={clickStatusMailingHandler}
          variant={"mail"}
          percent={data.delivered_percent}
          count={data.delivered}
        />
        {/*Открыто*/}
        <StatisticBlockCount
          invertedCount={true}
          variant={"eye"}
          type={1}
          category={3}
          percent={data.open_percent}
          count={data.open}
          onClick={clickStatusMailingHandler}
        />
        {/*Переходы*/}
        <StatisticBlockCount
          invertedCount={true}
          variant={"click"}
          type={4}
          category={4}
          percent={data.click_percent}
          count={data.click}
          onClick={clickStatusMailingHandler}
        />
        <StatisticBlockCount
          invertedCount={true}
          variant={"unsubscribe"}
          type={6}
          category={6}
          percent={data.unsubscribe_percent}
          count={data.unsubscribe}
          onClick={clickStatusMailingHandler}
        />
        {/*Отписки*/}
        <StatisticBlockCount
          invertedCount={true}
          variant={"spam"}
          type={5}
          category={5}
          percent={data.abuse_percent}
          count={data.abuse}
          onClick={clickStatusMailingHandler}
        />
      </IMainStatRow>
      <ISeparator />
      <ErrorTitle />
      <IErrorRow>
        <StatisticErrorCount
          invertedCount={true}
          variant={"other"}
          count={data.error_other}
          percent={data.error_other_percent}
          onClick={clickStatusMailingHandler}
          category={10}
        />
        <StatisticErrorCount
          invertedCount={true}
          variant={"not exist"}
          count={data.error_not_exists}
          percent={data.error_not_exists_percent}
          onClick={clickStatusMailingHandler}
          category={8}
        />
        <StatisticErrorCount
          invertedCount={true}
          variant={"spam"}
          count={data.error_spam}
          percent={data.error_spam_percent}
          onClick={clickStatusMailingHandler}
          category={9}
        />
        <StatisticErrorCount
          invertedCount={true}
          variant={"full"}
          count={data.error_full}
          percent={data.error_full_percent}
          onClick={clickStatusMailingHandler}
          category={7}
        />
      </IErrorRow>
    </IContainer>
  );
};
