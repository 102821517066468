import { useEffect, useState } from "react";
import styled from "styled-components";
import cn from "classnames";

import type { importInterfaceStatusTypes } from "domain/fileContacts";

import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";
import preview from "ui/assets/media/images/file-load-preview.svg";

import { LoadDots } from "../LoadDots";

const isBadStatus = (status: importInterfaceStatusTypes) => {
  return status === 4 || status === 5 || status === 6;
};

const Filed = styled.div<{ status: importInterfaceStatusTypes }>`
  width: 100%;
  height: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  position: relative;
  z-index: 2;
  transition: all ${_variables.transition};

  border: 1.5px solid
    ${(props) => {
      if (isBadStatus(props.status)) return _variables.supportColors.red;
      if (props.status === 3) return _variables.primaryColor;
      return "transparent";
    }};
  background-color: ${(props) => {
    if (isBadStatus(props.status))
      return `rgba(${hexToRgba(_variables.supportColors.red, 0.05)})`;
    return "#f5f6f8";
  }};
  & input {
    display: none;
  }

  &.enter {
    background-color: rgba(${hexToRgba(_variables.primaryColor, 0.05)});
  }

  &:before {
    position: absolute;
    top: 0;
    display: ${(props) =>
      props.status === 1 || props.status === 2 ? "block" : "none"};
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";

    background-image: repeating-linear-gradient(
        0deg,
        #e4e5e8,
        #e4e5e8 17px,
        transparent 17px,
        transparent 24px,
        #e4e5e8 24px
      ),
      repeating-linear-gradient(
        90deg,
        #e4e5e8,
        #e4e5e8 17px,
        transparent 17px,
        transparent 24px,
        #e4e5e8 24px
      ),
      repeating-linear-gradient(
        180deg,
        #e4e5e8,
        #e4e5e8 17px,
        transparent 17px,
        transparent 24px,
        #e4e5e8 24px
      ),
      repeating-linear-gradient(
        270deg,
        #e4e5e8,
        #e4e5e8 17px,
        transparent 17px,
        transparent 24px,
        #e4e5e8 24px
      );
    background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    border-radius: 10px;

    & svg {
      min-width: 100%;
      height: 100%;
    }
  }
`;

const TextContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  row-gap: 12px;
`;

const Preview = styled.div`
  display: flex;
  width: 48px;
  height: 48px;

  & img {
    width: 100%;
    height: 100%;
  }
`;
const TextLine = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  justify-content: center;
  align-items: center;
  min-height: 38px;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
`;
const Subtext = styled(Text)`
  color: ${_variables.grayTextColor};
`;
const Label = styled.label`
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
  color: ${_variables.primaryColor};
  transition: color ${_variables.transition};

  &:hover {
    color: ${_variables.blackColor};
  }
`;
const DropMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  &.active {
    z-index: 11;
  }
`;

const statusText: (file: string) => { [key: number]: JSX.Element } = (
  file
) => ({
  1: (
    <TextLine>
      <Text>
        <Label htmlFor="load">Выберите файл</Label>
        &nbsp;со списком контактов или перетащите сюда
      </Text>
      <Subtext>Форматы: CSV, TXT, XLSX </Subtext>
    </TextLine>
  ),
  2: (
    <TextLine>
      <LoadDots />
    </TextLine>
  ),
  3: (
    <TextLine>
      <Text>
        Загрузка завершена <br />
        {file}
      </Text>
      <Subtext>Форматы: CSV, TXT, XLSX </Subtext>
    </TextLine>
  ),
  4: (
    <TextLine>
      <Text>Ошибка парсинга файла</Text>
      <Subtext>Форматы: CSV, TXT, XLSX </Subtext>
    </TextLine>
  ),
  5: (
    <TextLine>
      <Text>Неверный формат файла</Text>
      <Subtext>Форматы: CSV, TXT, XLSX </Subtext>
    </TextLine>
  ),
  6: (
    <TextLine>
      <Text>Не более 20 мегабайт</Text>
      <Subtext>Форматы: CSV, TXT, XLSX </Subtext>
    </TextLine>
  ),
});

export const LoadFile: CT<{
  onChangeFile: (file: File | undefined, content?: string) => void;
  file: string;
  status: importInterfaceStatusTypes;
}> = ({ file, onChangeFile, status, className }) => {
  const [enterMode, setEnterMode] = useState(false);

  useEffect(() => {
    window.addEventListener("drop", (e) => {
      e.preventDefault();
    });
    window.addEventListener("dragover", (e) => {
      e.preventDefault();
    });
  }, []);

  return (
    <div
      className={className}
      id={"field"}
      onDragEnter={() => {
        if (isBadStatus(status)) return;
        setEnterMode(true);
      }}
    >
      <Filed status={status} className={cn(enterMode && "enter")}>
        <DropMask
          className={cn(enterMode && "active")}
          onDragLeave={() => {
            if (isBadStatus(status)) return;
            setEnterMode(false);
          }}
          onDrop={(e) => {
            if (isBadStatus(status)) return;
            e.preventDefault();
            setEnterMode(false);
            onChangeFile(e.dataTransfer.files?.[0]);
          }}
        />
        <TextContentBox>
          <Preview>
            <img src={preview} alt="" />
          </Preview>
          {statusText(file ?? "")[status]}
        </TextContentBox>
        <input
          id="load"
          type="file"
          value={file}
          onChange={(e) => {
            onChangeFile(e.target.files?.[0]);
          }}
        />
      </Filed>
    </div>
  );
};
