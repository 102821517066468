import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectModalData, setModalData } from "services/store/modalDataSlice";
import type { TemplateViewStateType } from "services/store/createMailingSlice";

import { AutoMailingForm } from "ui/containers/forms/AutoMailing/AutoMailingForm";
import { AutoMailingProForm } from "ui/containers/forms/AutoMailing/AutoMailingProForm";

export const AutoMailingManagerForm: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  // Важно прокинуть данные с рассылки
  const modalData = useAppSelector(selectModalData) as {
    mailingData: NonNullable<TemplateViewStateType["data"]>;
  };

  //setProMode
  const [isProMode, setProMode] = useState(false);

  useEffect(() => {
    if (!isProMode) {
      dispatch(setModalData({ ...modalData, title: "Автоматический прогрев" }));
      return;
    }
    dispatch(
      setModalData({ ...modalData, title: "Настройка прогрева вручную" })
    );
  }, [isProMode]);

  if (!modalData.mailingData) return <></>;

  return (
    <>
      {isProMode && (
        <AutoMailingProForm
          onOpenLightMode={() => {
            setProMode(false);
          }}
          mailingData={modalData.mailingData}
          onClose={onClose}
        />
      )}
      {!isProMode && (
        <AutoMailingForm
          onOpenProMode={() => {
            setProMode(true);
          }}
          mailingData={modalData.mailingData}
          onClose={onClose}
        />
      )}
    </>
  );
};
