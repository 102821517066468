import { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";

import type { CreateIdea } from "services/idea";
import { serviceIdea } from "services/idea";
import { _variables } from "ui/styles/_variables";

import { Icon } from "../../components/Icon";
import { Input } from "../../components/FormsElements/Input";
import { TextArea } from "../../components/FormsElements/TextArea";
import { Button } from "../../components/Buttons/Button";

const Container = styled.div`
  width: 100%;
  padding: 40px 24px;
  overflow: auto;
`;

const Back = styled(Link)`
  display: flex;
  align-items: center;
  color: ${_variables.grayColor};
  & svg {
    margin-right: 4px;
  }
  &:hover {
    color: ${_variables.primaryColor};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 500;
  padding: 32px 0;
`;

const Label = styled.label`
  color: ${_variables.grayColor};
  font-size: 13px;
  margin-bottom: 16px;
  & input {
    padding: 13px 16px;
  }
`;

const NewIdeasPage = () => {
  const history = useHistory();

  const onSubmit = useCallback((data: CreateIdea) => {
    serviceIdea.createIdea(data).then(() => {
      history.push("/ideas");
    });
  }, []);

  const validationSchema = Yup.object().shape({
    theme: Yup.string()
      .required("Поле обязательно для заполнения")
      .min(10, `Минимально кол-во символов "10"`),
    content: Yup.string()
      .required("Поле обязательно для заполнения")
      .min(10, `Минимально кол-во символов "10"`),
  });

  const formik = useFormik({
    initialValues: {
      theme: "",
      content: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit,
  });

  return (
    <Container>
      <Back to="/ideas">
        <Icon id="arrow-left-back" height={16} />
        Назад
      </Back>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Heading>Предложить идею</Heading>
        <Label>
          Тема
          <Input
            name="theme"
            value={formik.values.theme}
            error={formik.errors.theme}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Label>
        <Label>
          Описание
          <TextArea
            name="content"
            value={formik.values.content}
            error={formik.errors.content}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Label>
        <Button>Отправить</Button>
      </Form>
    </Container>
  );
};

export default NewIdeasPage;
