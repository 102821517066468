import { createGlobalStyle, css } from "styled-components";

import { _reset } from "./_reset";
import _variables from "./_variables";
import { toastsCustomStyles } from "./customs/toasts";
import { popover } from "./customs/popover";
import { sidePanel } from "./customs/sidePanel";
import { notifications } from "./customs/notifications";
import { pagination } from "./customs/pagination";
import { getButtonCss } from "./customs/getButton";
import { getCarrot } from "./customs/carrot";

const includes = css`
  ${_reset}
`;

export const GlobalStyle = createGlobalStyle`
  ${includes}
  html {
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  body {
    font-size: 16px;
    line-height: 24px;
    position: relative;
    background-color: ${_variables.backgroundColor};
    min-width: 1240px;
    min-height: 100vh;
    font-family: ${_variables.fontFamily}, Arial;
    font-weight: 400;
    position: relative;
    max-width: 100vw;
    overflow: hidden;

    &.responsive {
      min-width: auto;
    }
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }


  ${toastsCustomStyles}
  ${popover}
  ${sidePanel}
  ${notifications}
  ${pagination}
  ${getButtonCss}
  ${getCarrot}

  
  
  *{
    &:focus{
        outline: none;
      
   
    }
  }
`;
