import { useEffect } from "react";
import { matchPath, useHistory } from "react-router-dom";

import type { RouteType } from "ui/routes/routes";

import { appConfig } from "../appConfig";

export const usePageTitle = (routes: RouteType[]) => {
  const history = useHistory();

  useEffect(() => {
    const find = routes.find((item) =>
      matchPath(history.location.pathname, item.path)
    );
    if (find) {
      document.title = find.title
        ? `${find.title}${appConfig.titleApp}`
        : appConfig.titleApp;
    }
  }, [history.location.pathname]);
};
