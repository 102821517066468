import styled from "styled-components";

import { ShadowButton } from "../Buttons/ShadowButton";
import { Icon } from "../Icon";
import { Link } from "../Link";

const Content = styled.div<{ marginTop?: string }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? "300px"};
`;

const Text = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 24px;
`;

export const NotContentListsPage = ({ marginTop }: { marginTop?: string }) => {
  return (
    <Content marginTop={marginTop}>
      <Text>У вас нет списков контактов</Text>
      <Link link={"/contacts/import"}>
        <ShadowButton>
          <Icon
            id={"plus-icon"}
            height={16}
            width={16}
            style={{ marginRight: 8 }}
          />
          Добавить контакты
        </ShadowButton>
      </Link>
    </Content>
  );
};
