import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { shallowEqual } from "react-redux";
import { debounce } from "lodash";
import styled from "styled-components";

import type { MailingContactsList } from "domain/mailing";
import { createToastResponseError } from "lib/toastify";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectModalData, setModalData } from "services/store/modalDataSlice";
import { projectService } from "services/project";
import { scroll } from "ui/styles/elements/scroll";
import { _variables } from "ui/styles/_variables";

import { Button } from "../../components/Buttons/Button";
import { LoadDots } from "../../components/LoadDots";
import { LoadCenterAbsolute } from "../../components/LoadCenterAbsolute";

const Container = styled.div`
  width: 800px;
  min-height: 380px;
  padding-bottom: 36px;
`;

const ListWrapper = styled.div`
  padding: 0 10px;
`;

const ButtonWrapper = styled.div`
  padding: 0 36px;
  margin-top: 32px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
`;

const List = styled.ul`
  overflow-y: scroll;
  padding: 0 30px;
  ${scroll};
  & li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${_variables.backgroundColor};
  }
`;

const Data = styled.div`
  display: flex;
  position: relative;
  height: 52px;
  align-items: center;
`;
const LoadData = styled.div`
  display: flex;
  justify-content: center;
  height: 52px;
  width: 100%;
  align-items: center;
`;

const Email = styled.p`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  width: 487px;
`;
const Status = styled.p`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
`;

export const ProjectStatusList: CT<{ onClose: VoidFunction }> = React.memo(
  ({ onClose }) => {
    const perPageLoad = 100;
    const modalData = useAppSelector(selectModalData);
    const dispatch = useAppDispatch();
    const [isLoad, setLoad] = useState(true);
    const [moreLoad, setMoreLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [data, setData] = useState<MailingContactsList>({
      totalCount: 0,
      mailingContacts: [],
    });

    const [haveDownloadCSV] = useState(false);

    const scrollRef = useRef<HTMLUListElement | null>(null);
    const [maxScroll, setMaxScroll] = useState(0);
    const scrollContainerHeight = 320;

    const scrollHandler = useCallback(
      debounce(() => {
        if (scrollRef.current!.scrollTop >= maxScroll - 200) {
          if (currentPage + 1 > totalPage) return;
          loadData(perPageLoad, Number(currentPage) + 1);
        }
      }, 500),
      [maxScroll]
    );

    const setFnMaxScroll = () => {
      setMaxScroll(
        (scrollRef.current as unknown as HTMLDivElement).scrollHeight -
          scrollContainerHeight
      );
    };

    const loadData = async (perPage: number, page: number) => {
      try {
        if (page > 1) {
          setMoreLoad(true);
        }
        const responseData = await projectService.getProjectMails({
          perPage,
          page,
          category: modalData!.category,
          id: modalData!.id,
        });
        dispatch(
          setModalData({
            ...modalData,
            count: responseData.headers["x-pagination-total-count"],
          })
        );
        setCurrentPage(
          Number(responseData.headers["x-pagination-current-page"])
        );
        setTotalPage(Number(responseData.headers["x-pagination-page-count"]));
        setData({
          totalCount: responseData.data.data.totalCount,
          mailingContacts: [
            ...data.mailingContacts,
            ...responseData.data.data.projectMails,
          ],
        });
        setLoad(false);
      } catch (e) {
        createToastResponseError(e)();
        onClose();
      } finally {
        setMoreLoad(false);
      }
    };

    const loadCSVHandler = async () => {
      const responseData = await projectService.getMailingCSVContacts({
        id: modalData!.id,
        category: modalData!.category,
      });
      const blob = new Blob([responseData.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("Mailing", "Mailing.csv");
      a.setAttribute("download", "Mailing.csv");
      a.click();
    };

    useLayoutEffect(() => {
      if (!scrollRef.current) return;
      setFnMaxScroll();
    }, [scrollRef, isLoad, data.mailingContacts.length]);

    useEffect(() => {
      loadData(perPageLoad, 1);
    }, []);

    if (isLoad)
      return (
        <Container>
          <LoadCenterAbsolute />
        </Container>
      );
    return (
      <Container>
        <ListWrapper>
          <List
            onScroll={scrollHandler}
            style={{ height: scrollContainerHeight }}
            ref={scrollRef}
          >
            {data.mailingContacts.map((item) => (
              <li>
                <Data>
                  <Email>{item.email}</Email>
                  <Status>{item.statusName}</Status>
                </Data>
              </li>
            ))}
            {moreLoad && (
              <li>
                <LoadData>
                  <LoadDots />
                </LoadData>
              </li>
            )}
          </List>
        </ListWrapper>
        <ButtonWrapper>
          <Button disabled={haveDownloadCSV} onClick={loadCSVHandler}>
            Скачать контакты
          </Button>
        </ButtonWrapper>
      </Container>
    );
  },
  shallowEqual
);
