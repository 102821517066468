import { useEffect, useState } from "react";

import { timezonesService } from "services/timezones";

import { SelectCustom } from "../../components/FormsElements/SelectCustom";
import { Input } from "../../components/FormsElements/Input";

export const SelectTimeZone: CT<{
  onChange: (val: string) => void;
  initialTimezone: string;
  label?: string;
  background?: string;
}> = ({ initialTimezone, onChange, label, background }) => {
  const [values, setValues] = useState<
    React.ComponentProps<typeof SelectCustom>["values"]
  >([]);

  const loadData = async () => {
    try {
      const response = await timezonesService.list();
      setValues(
        Array.from(Object.entries(response.data.data.timezones)).map(
          ([val, name]) => ({
            name: name,
            value: val,
            isDefaultChecked: initialTimezone === val,
          })
        )
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  useEffect(() => {
    loadData();
  }, []);
  if (values.length === 0) return <Input value={"..."} />;
  return (
    <SelectCustom
      background={background}
      label={label}
      onChange={(val) => {
        onChange && onChange(val.value as string);
      }}
      values={values}
    />
  );
};
