export const canvasToBlob = (file: HTMLCanvasElement): Promise<File> =>
  new Promise((resolve, reject) => {
    file.toBlob((blob) => {
      try {
        resolve(new File([blob!], "fileName.png", { type: "image/png" }));
      } catch (e) {
        reject(e);
      }
    });
  });

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
