export const getFormatNum = (val: number) => {
  if (val.toString().length <= 3) return val;
  const value = val.toString().split("");
  return value
    .reverse()
    .map((item, i) => {
      if (i % 3 === 0) return item + " ";
      return item;
    })
    .reverse()
    .join("");
};
