import styled from "styled-components";

import { LoadDots } from "./LoadDots";

const Load = styled.div`
  width: 100%;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadContent: CT<{
  style?: React.CSSProperties;
  top?: number;
  height?: number;
}> = ({ className, top = 120, height = 20, style }) => {
  return (
    <Load
      className={className}
      style={{ height: height, marginTop: top, ...style }}
    >
      <LoadDots color={"black"} />
    </Load>
  );
};
