import styled from "styled-components";

import { hexToRgba } from "ui/styles/_mixin";
import { _variables } from "ui/styles/_variables";

const bgcColor = {
  green: `rgba(${hexToRgba(_variables.statusColors.success, 0.15)})`,
  red: `rgba(${hexToRgba(_variables.supportColors.red, 0.15)})`,
  orange: `rgba(${hexToRgba("#FBBC05", 0.15)})`,
};

const color = {
  green: _variables.statusColors.success,
  red: _variables.supportColors.red,
  orange: _variables.secondaryColor,
};

const Content = styled.div<{ color: "green" | "red" | "orange" }>`
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  border-radius: 8px;
  background-color: ${(props) => bgcColor[props.color]};
  color: ${(props) => color[props.color]};
`;
export const Chip: CT<{ color: "green" | "red" | "orange" }> = ({
  color,
  children,
}) => {
  return <Content color={color}>{children}</Content>;
};
