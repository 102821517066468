import styled from "styled-components";

import { hexToRgba } from "ui/styles/_mixin";

const Container = styled.div`
  display: flex;
  background-color: rgba(${hexToRgba("#005ECE", 0.07)});
  position: relative;
  height: fit-content;
  border-radius: 8px;
  overflow: hidden;
  &:before {
    position: relative;
    left: 0;
    top: 0;
    content: "";
    width: 4px;
    background-color: #00629c;
  }
`;
const InnerContainer = styled.div`
  padding: 24px;
  width: 100%;
`;

export const AlertTable: CT<{
  maxWidth?: string;
}> = ({ children, className, maxWidth }) => {
  return (
    <Container style={{ maxWidth: maxWidth ?? "auto" }} className={className}>
      <InnerContainer>{children}</InnerContainer>
    </Container>
  );
};
