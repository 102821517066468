import { css } from "styled-components";

import { _variables } from "../_variables";

export const formWrapper = css`
  & .label {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: ${_variables.blackColor};
    margin-bottom: 8px;
  }
  & .error {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.25px;
    text-align: left;
    color: ${_variables.supportColors.red};
  }
  & .description {
    color: ${_variables.grayColor};
    margin-bottom: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.5px;
    text-align: left;
  }
  & .form {
    position: relative;
    width: 100%;
  }
  & .verify {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
  }
  & .value {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;
  }
`;

export const formElement = css<{ isError: boolean; haveIcon: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${_variables.borderRadius};
  color: ${_variables.blackColor};
  letter-spacing: -0.25px;
  font-size: 13px;
  line-height: 18px;
  background-color: ${(props) =>
    props.isError ? _variables.whiteColor : _variables.backgroundColor};
  height: 44px;
  width: 100%;
  outline: none;
  border: 2px solid transparent;
  font-weight: 600;
  padding: 13px 16px;
  padding-right: ${(props) => (props.haveIcon ? "32px" : "16px")};
  transition: all ${_variables.transition};
  &:hover {
    background-color: ${_variables.strokeColor};
  }
  &:focus {
    background-color: ${_variables.whiteColor};
    border: 2px solid ${_variables.blackColor};
  }
  &::placeholder {
    color: ${_variables.grayColor};
  }
  &:disabled {
    background-color: ${_variables.backgroundColor};
    color: ${_variables.grayTextColor};
    &:hover {
      background-color: ${_variables.backgroundColor};
    }
  }
  ${(props) => {
    if (props.isError) {
      return css`
        border: 2px solid ${_variables.supportColors.red};
        &:focus {
          border: 2px solid ${_variables.supportColors.red};
          background: ${_variables.whiteColor};
        }
        &:hover {
          background-color: ${_variables.whiteColor};
        }
      `;
    }
  }}
`;

export const formCheckbox = css``;
