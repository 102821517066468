const isDevelopment = process.env["NODE_ENV"];

export const isDev = () => isDevelopment === "development";

const SERVER_DEV_DOMAIN = "localhost:8080";

export const appConfig = {
  apiUrl: isDev()
    ? `http://${SERVER_DEV_DOMAIN}/api/`
    : `${process.env.REACT_APP_API_PATH}`,
  staticUrl: isDev()
    ? "http://localhost:3000/"
    : "https://cp.cheapsender.email/",
  proxyImageUrl: isDev()
    ? "http://localhost:5000"
    : "https://cp.cheapsender.email/proxy/",
  adminPanelUrl: `${process.env.REACT_APP_API_PATH}`,
  titleApp: " | Cheapsender",
  appName: "",
  telegramLink: "https://t.me/cheapsender",
  domainName: "cheapsender.email",
  yandexMetrikaId: 85824862,
  staticCKEditorPath: isDev()
    ? `http://${window.location.host}/plugin/ckeditor4/plugin/ckeditor.js`
    : `https://${window.location.host}/plugin/ckeditor4/plugin/ckeditor.js`,
  cdnCKEditorPath: isDev()
    ? `http://${SERVER_DEV_DOMAIN}/api/s3/`
    : `https://${window.location.host}/api/s3/`,
};
