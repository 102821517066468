import styled from "styled-components";

import { useSettingFields } from "app/importContacts/useSettingFields";
import type { PreviewFileType } from "domain/fileContacts";
import { _variables } from "ui/styles/_variables";

import { SettingField } from "./SettingField";
import { LoadDots } from "../LoadDots";

const Fields = styled.div`
  display: flex;
  column-gap: 12px;
  flex-wrap: wrap;
  row-gap: 12px;
  width: 100%;
  position: relative;
`;

const LoadWrapper = styled.div`
  width: 100%;
  height: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotFound = styled.div`
  border: 1px solid ${_variables.supportColors.red};
  border-radius: 8px;
  width: 250px;
  height: 117px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  align-items: center;
  justify-content: center;
`;

export const SettingFields: CT<{ data: PreviewFileType; isLoad: boolean }> = ({
  data,
  isLoad,
}) => {
  const { fields, onChangeStatusCard } = useSettingFields(data);
  return (
    <Fields>
      {isLoad && (
        <LoadWrapper>
          <LoadDots />
        </LoadWrapper>
      )}
      {!isLoad && fields.length === 0 && (
        <NotFound>Не удалось обработать поля</NotFound>
      )}
      {!isLoad &&
        fields.map((item, i) => (
          <SettingField
            column={i}
            style={{ zIndex: 50 - Math.trunc(i / 1) }}
            onChangeStatusField={onChangeStatusCard}
            status={item.status}
            content={item.content}
          />
        ))}
    </Fields>
  );
};
