import { Fragment, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import type { CommentType } from "domain/idea";
import { serviceIdea } from "services/idea";
import { _variables } from "ui/styles/_variables";

import { Comment } from "../../../components/Idea/Comment";
import { CommentField } from "../../../components/Idea/CommentField";
import { LoadContent } from "../../../components/LoadContent";
import { ContentPagination } from "../../../components/ContentPagination";

type IdeaCommentsProps = {
  ideaId: number | string;
};

const Content = styled.div`
  width: 100%;
`;

const Hr = styled.hr`
  color: ${_variables.grayColor};
`;

const CommentFieldWrapper = styled.div`
  width: 100%;
  margin: 32px 0;
`;

const PaginationWrapper = styled.div`
  margin: 20px auto;
`;

export const IdeaComments = ({ ideaId }: IdeaCommentsProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [comments, setComments] = useState<CommentType[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    serviceIdea.getComments(ideaId, currentPage).then((response) => {
      setComments(response.data.data.comments);
      setPageCount(response.headers["x-pagination-page-count"]);
      setLoading(false);
    });
  }, [ideaId, currentPage]);

  const addComment = useCallback(
    (comment: string, replyTo?: number) =>
      serviceIdea.addComment(ideaId, comment, replyTo).then(() => {
        serviceIdea.getComments(ideaId, currentPage).then((response) => {
          setComments(response.data.data.comments);
        });
      }),
    [ideaId, currentPage]
  );

  return (
    <Content>
      <CommentFieldWrapper>
        <CommentField addComment={addComment} />
      </CommentFieldWrapper>

      {loading ? (
        <LoadContent />
      ) : (
        <>
          {comments.map((comment, index) => {
            const isLast = comments.length - 1 === index;
            return (
              <Fragment key={comment.id}>
                <Comment
                  {...comment}
                  addComment={addComment}
                  key={comment.id}
                />
                {comment.answers.length ? <Hr /> : null}

                {comment.answers.map((answer, index, answers) => {
                  const isLastAnswer = answers.length - 1 === index;
                  return (
                    <Fragment key={answer.id}>
                      <Comment {...answer} />
                      {isLastAnswer ? null : <Hr />}
                    </Fragment>
                  );
                })}

                {isLast ? null : <Hr />}
              </Fragment>
            );
          })}
        </>
      )}
      {pageCount > 1 ? (
        <PaginationWrapper>
          <ContentPagination pageCount={pageCount} onClick={setCurrentPage} />
        </PaginationWrapper>
      ) : null}
    </Content>
  );
};
