import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

const Container = styled.div`
  display: flex;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 16px);
    height: 100%;
    background: rgba(232, 110, 77, 0.4);
    filter: blur(20px);
    border-radius: 12px;
    z-index: 8;
  }
`;
const Button = styled.button`
  position: relative;
  z-index: 9;
  padding: 0 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
  color: white;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: ${_variables.primaryColor};
  transition: background-color ${_variables.transition};
  &:hover {
    background-color: ${_variables.secondaryColor};
  }
`;

export const ShadowButton: CT<{ onClick?: VoidFunction }> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <Container className={className}>
      <Button onClick={onClick}>{children}</Button>
    </Container>
  );
};
