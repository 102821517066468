import { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { useAppDispatch } from "services/hooks";
import { openModal } from "services/store/modal";
import { setTemplateMailing } from "services/store/createMailingSlice";

import { _variables } from "ui/styles/_variables";
import { Button } from "../Buttons/Button";
import { Icon } from "../Icon";

const Header = styled.header`
  height: 68px;
  width: 100%;
  //position: sticky;
  //top: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 12px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const NameFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  position: relative;
  line-height: 12px;
`;
const NameInput = styled.input`
  width: 420px;
  border: none;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &::placeholder {
    font-size: 18px;
  }
  padding: 10px;
  position: relative;
  border-radius: 8px;
  outline: none;
`;
const ValidNameField = styled.span`
  font-size: 16px;
  cursor: text;
  width: 420px;
  display: block;
  padding: 15px 5px;
  border: ${_variables.primaryColor} solid 2px;
  border-radius: 8px;
`;
const Back = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 8px;
  transition: background-color ${_variables.transition};

  &:hover {
    background-color: ${_variables.strokeColor};
  }

  & img {
    width: 100%;
    position: relative;
    top: -2px;
    height: 100%;
  }
`;

const IconDevice = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  font-weight: 600;
  transition: background-color ${_variables.transition};

  &.active {
    background-color: ${_variables.backgroundColor};
  }

  &:hover {
    background-color: ${_variables.backgroundColor};
  }

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    margin: 0 4px;
  }
`;

const TestMailButton = styled.button`
  border: none;
  background: none;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  &:hover {
    color: ${_variables.primaryColor};
  }
`;

export const HeaderEditor: CT<{
  isNewTemplate: boolean;
  onClickSave: (withExit: boolean) => void;
  onCreate: (withExit: boolean) => void;
  onClickBack?: VoidFunction;
  templateName: string;
  onChangeTemplateName: (str: string) => void;
  isLoad?: boolean;
  clickViewHandler?: () => void;
  isMailingCreationWay?: boolean;
  templateId?: number;
}> = ({
  onClickSave,
  isLoad = false,
  onCreate,
  templateName,
  onChangeTemplateName,
  isNewTemplate,
  onClickBack = () => {},
  clickViewHandler = () => {},
  isMailingCreationWay,
  templateId,
}) => {
  const [isEmptyName, setIsEmptyName] = useState(
    !Boolean(templateName.trim().length)
  );

  const dispatch = useAppDispatch();
  const history = useHistory();

  const inputRef = useRef<HTMLInputElement>(null);

  const ActionButtons = useMemo(() => {
    if (isMailingCreationWay) {
      return (
        <>
          <Button disabled={isLoad} onClick={() => onClickSave(true)}>
            Сохранить и выйти
          </Button>
          <Button
            disabled={isLoad}
            onClick={() => {
              onClickSave(false);
              dispatch(setTemplateMailing(templateId!));
              history.push("/templates/create-mailing");
            }}
          >
            Продолжить
          </Button>
        </>
      );
    }
    if (isNewTemplate) {
      return (
        <>
          <Button disabled={isLoad} onClick={() => onCreate(false)}>
            Сохранить
          </Button>
          <Button disabled={isLoad} onClick={() => onCreate(true)}>
            Сохранить и выйти
          </Button>
        </>
      );
    }

    return (
      <>
        <Button onClick={() => onClickSave(false)}>Сохранить</Button>
        <Button onClick={() => onClickSave(true)}>Сохранить и выйти</Button>
      </>
    );
  }, [isMailingCreationWay, onCreate, onClickSave]);

  return (
    <Header
      onMouseOver={(e) => {
        const target = e.target as HTMLButtonElement;
        target.focus();
      }}
    >
      <LeftContainer>
        <Back onClick={onClickBack}>
          <Icon id={"back-icon"} width={24} height={24} />
        </Back>
        <NameFieldWrapper>
          {!isEmptyName ? (
            <NameInput
              ref={inputRef}
              value={templateName}
              maxLength={200}
              onBlur={(e) => {
                if (e.target.value.trim() === "") {
                  setIsEmptyName(true);
                }
              }}
              onChange={(e) => {
                onChangeTemplateName(e.target.value);
              }}
            />
          ) : (
            <ValidNameField
              onClick={() => {
                setIsEmptyName(false);
                setTimeout(() => {
                  inputRef.current?.focus();
                }, 150);
                inputRef.current?.focus();
              }}
            >
              Введите название шаблона
            </ValidNameField>
          )}
        </NameFieldWrapper>
      </LeftContainer>
      <IconDevice onClick={() => clickViewHandler()}>
        <Icon id="eye-icon" />
        Просмотр
      </IconDevice>
      <Buttons>
        <TestMailButton
          onClick={() => {
            if (isNewTemplate) {
              toast(
                "Для отправки тестового письма введите название шаблона и сохраните его",
                { autoClose: 3000 }
              );
              return;
            }
            dispatch(openModal("test-mailing-form-template"));
          }}
        >
          <Icon width={20} height={20} id="test-document" />
          Тестовое письмо
        </TestMailButton>

        {ActionButtons}
      </Buttons>
    </Header>
  );
};
