import React, { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import FileSaver from "file-saver";
import styled, { css } from "styled-components";

import type { categoryProjectType, ProjectDomainType } from "domain/project";
import { useShowApiKeysCollection } from "app/tables/useShowApiKeysCollection";
import { useAppDispatch } from "services/hooks";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";
import { projectService } from "services/project";
import { createToastResponseError } from "lib/toastify";

import { _variables } from "ui/styles/_variables";
import { Icon } from "ui/components/Icon";
import { mxm } from "ui/styles/_mixin";
import { LoadContent } from "ui/components/LoadContent";
import { Button } from "ui/components/Buttons/Button";
import { StatisticCircle } from "ui/components/StatisticBlocks/StatisticCircle";
import { StatisticApiCount } from "ui/components/StatisticBlocks/StatisticApiCount";

const MainHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
`;

const MainHeading = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const ListStatistic = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px 0;
  border-bottom: 1px solid ${_variables.strokeColor};
`;

const RowHeading = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;
const RowContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StatisticRow = styled.div`
  display: flex;
  column-gap: 40px;
`;

const ButtonDownload = styled(Button)`
  display: flex;
  column-gap: 10px;
  ${mxm(
    1400,
    css`
      margin-left: 16px;
    `
  )}
`;
export const Nothing = styled.p`
  margin-top: 275px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: ${_variables.grayTextColor};
`;
export const StatisticalHeading = React.memo(() => {
  return (
    <MainHeadingWrapper>
      <MainHeading>API рассылки</MainHeading>
    </MainHeadingWrapper>
  );
}, shallowEqual);

const RowContent: CT<{ data: ProjectDomainType }> = ({ data }) => {
  const dispatch = useAppDispatch();

  const [timeout, setTimeoutVal] = useState(0);

  const clickStatusMailingHandler = (
    category: keyof typeof categoryProjectType | null
  ) => {
    dispatch(setModalData({ category, noPaddingContent: true, id: data.id }));
    dispatch(openModal("list-status-project"));
  };

  useEffect(() => {
    if (timeout > 0) {
      setTimeout(() => {
        setTimeoutVal(timeout - 1);
      }, 1000);
    }
  }, [timeout]);

  const loadFile = async () => {
    try {
      setTimeoutVal(3);
      const response = await projectService.getMailsCSV(data.id);
      let blob = new Blob([response.data]);
      FileSaver.saveAs(blob, `${data.name}.csv`);
    } catch (e) {
      createToastResponseError(null, "Ошибка создания отчета")();
    }
  };

  return (
    <ListItem>
      <RowHeading>{data.name}</RowHeading>
      <RowContentDiv>
        <StatisticRow>
          <StatisticApiCount count={data.api_calls} variant={"api"} />
          <StatisticCircle
            onClick={clickStatusMailingHandler}
            category={1}
            variant={"success"}
            count={data.total}
            percent={data.total_percent}
          />
          <StatisticCircle
            onClick={clickStatusMailingHandler}
            category={2}
            variant={"mail"}
            count={data.delivered}
            percent={data.delivered_percent}
          />
          <StatisticCircle
            onClick={clickStatusMailingHandler}
            category={3}
            variant={"eye"}
            count={data.open}
            percent={data.open_percent}
          />
          <StatisticCircle
            onClick={clickStatusMailingHandler}
            category={4}
            variant={"click"}
            count={data.click}
            percent={data.click_percent}
          />
        </StatisticRow>
        <ButtonDownload
          disabled={timeout > 0}
          variant={"secondary"}
          onClick={loadFile}
        >
          <Icon width={20} height={20} id={"document-statistic-icon"} />
          Скачать отчет
        </ButtonDownload>
      </RowContentDiv>
    </ListItem>
  );
};

export const StatisticalContent: CT<{
  query: {
    perPage?: any;
    page?: any;
    name?: any;
    sortBy?: any;
  };
  onSetCountPage: (val: number) => void;
}> = ({ onSetCountPage }) => {
  const { data, countPage, isLoad } = useShowApiKeysCollection();

  useEffect(() => {
    onSetCountPage(countPage);
  }, [countPage]);

  if (isLoad) return <LoadContent top={120} />;
  if (!isLoad && data.length === 0) {
    return <Nothing>Статистика появится после использования API</Nothing>;
  }
  return (
    <ListStatistic>
      {data.map((item) => (
        <RowContent data={item} />
      ))}
    </ListStatistic>
  );
};
