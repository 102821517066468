import { useCallback, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";

import { serviceUser } from "services/user";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectUserData, setUserData } from "services/store/userSlice";
import { createToastResponseError } from "lib/toastify";

export const UploadUser: CT<{ JWT: string | null }> = ({ JWT, children }) => {
  const [isLoad, setLoad] = useState(true);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserData, shallowEqual);

  const load = useCallback(async () => {
    try {
      const data = await serviceUser.profile();
      dispatch(
        setUserData({
          ideaCount: data.data.data.ideaCount,
          newsCount: data.data.data.newsCount,
          ...data.data.data.profile,
          ...data.data.data.user,
        })
      );
      setLoad(false);
    } catch (e) {
      createToastResponseError(e)();
      // В случае ошибки, она обработается ан уровне запросов и произойдет редирект
      setLoad(false);
    }
  }, []);

  useEffect(() => {
    if (!JWT || JWT.length === 0) return;
    load();
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const request = () => {
      if (!JWT || JWT.length === 0) return;
      load();
      setTimeout(request, 15000);
    };
    setTimeout(request, 0);
    //eslint-disable-next-line
  }, []);

  if (!JWT || JWT.length === 0) return <>{children}</>;
  if (isLoad) return <></>;

  return <>{children}</>;
};
