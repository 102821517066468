export const apiTimeFormat = "dd.LL.yyyy HH:mm:ss";
export const apiDateFormat = "dd.LL.yyyy";

export const tableTimeFormat1 = "yyyy-LL-dd  HH:mm";
export const tableTimeFormat2 = "dd.LL.yyyy HH:mm:ss";
export const mailFormat1 = "dd MMMM,  HH:mm";
export const ideaFormat = "dd MMMM yyyy";

export const ideaFormatComment = "HH:mm, dd MMMM yyyy";
export const newsFormat = "dd MMM yyyy";

export const datePickerFormat = "dd.LL.yyyy";
export const timePickerFormat = "HH:mm";

export const buyPackageFormat = "dd.LL.yyyy";
