import styled from "styled-components";

import { Link } from "./Link";
import { Icon } from "./Icon";

type BreadcrumbsItem = {
  title: string;
  link?: string;
};

const IContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  & a {
    font-weight: inherit;
    font-size: inherit;
  }
`;

const Item = (data: BreadcrumbsItem) => {
  if (!data?.link) return <span>{data.title}</span>;
  return <Link link={`${data.link}`}>{data.title}</Link>;
};

export const BreadcrumbsTitle: CT<{
  data: BreadcrumbsItem[];
}> = ({ data, className }) => {
  return (
    <IContainer className={className}>
      {data.map((item, i) => {
        if (i === data.length - 1)
          return <Item title={item.title} link={item.link} />;
        return (
          <>
            <Item title={item.title} link={item.link} />
            &nbsp;
            <Icon id={"arrow-right-icon"} width={24} height={24} />
          </>
        );
      })}
    </IContainer>
  );
};
