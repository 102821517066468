import { css } from "styled-components";

export const popover = css`
  .popover-arrow {
    display: none !important;
  }
  .popover {
    background-color: transparent !important;
    border: none !important;
  }
  .popover[data-popper-placement*="left-start"] {
    inset: -8px -45px auto auto !important;
  }
`;
