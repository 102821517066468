import { StaticAlert } from "ui/components/Alerts/StaticAlert";
import styled from "styled-components";
import { useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import { deepEqual } from "lib/other";
import { Button } from "ui/components/Buttons/Button";
import {
  createStandardResponseToast,
  createToastResponseError,
  onlyOne,
} from "lib/toastify";
import useInterval from "app/useInterval";
import { useState } from "react";
import { serviceUser } from "services/user";

const IContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 24px;
  & b {
    font-weight: 500;
  }
`;

export const AlertEmailConfirm: CT<unknown> = () => {
  const userData = useAppSelector(selectUserData, deepEqual);

  const [isDisable, setDisable] = useState(false);
  const { startInterval } = useInterval(async () => {
    setDisable(false);
  }, 60000);

  const resendEmailConfirmHandler = async () => {
    setDisable(true);
    try {
      startInterval();
      const response = await serviceUser.resendConfirm(userData!.email);
      createStandardResponseToast(response.data)();
    } catch (e) {
      onlyOne(createToastResponseError(e));
    }
  };

  return (
    <StaticAlert>
      <IContainer>
        <div>
          Для проведения рассылок подтвердите email регистрации. Письмо было
          отправлено на email: <b>{userData?.email ?? ""}</b>
        </div>
        <Button onClick={resendEmailConfirmHandler} disabled={isDisable}>
          {isDisable ? "Отправлено" : "Отправить повторно"}
        </Button>
      </IContainer>
    </StaticAlert>
  );
};
