import "react-toastify/dist/ReactToastify.css";
import "react-notifications-component/dist/theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "animate.css";

import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ReactNotifications } from "react-notifications-component";

import { useExitApplicationHandler } from "app/useExitApplicationHandler";
import { serviceUser } from "services/user";
import { GlobalStyle } from "ui/styles/_global";
import { GlobalFonts } from "ui/styles/_fonts";

import { ToastExitButton } from "../../components/ToastExitButton";
import { RequestInterceptor } from "./RequestInterceptor";
import { ErrorBoundaryApi } from "./ErrorBoundaryApi";
import { useGlobalRequestConfiguration } from "./useGlobalRequestConfiguration";
import { UploadUser } from "./useUploadUser";
import { ModalLayer } from "./ModalLayer";
import { TemplateViewLayer } from "./TemplateViewLayer";
import { ErrorBoundary } from "./ErrorBoundary";
import { useSetAppStorageData } from "ui/containers/common/useSetAppStorageData";

export const AppLayout: CT<unknown> = ({ children }) => {
  useGlobalRequestConfiguration();
  useExitApplicationHandler();
  useSetAppStorageData();

  return (
    <>
      <GlobalFonts />
      <GlobalStyle />

      <Router>
        <ErrorBoundary>
          <ErrorBoundaryApi>
            <RequestInterceptor>
              <ReactNotifications />
              <UploadUser JWT={serviceUser.getToken()}>
                <Switch>
                  <ModalLayer>
                    <TemplateViewLayer>{children}</TemplateViewLayer>
                  </ModalLayer>
                </Switch>
              </UploadUser>

              <ToastContainer
                position={"bottom-center"}
                hideProgressBar={true}
                closeOnClick={false}
                autoClose={false}
                icon={<ToastExitButton />}
              />
            </RequestInterceptor>
          </ErrorBoundaryApi>
        </ErrorBoundary>
      </Router>
    </>
  );
};
