import type { CSSProperties } from "react";
import { useRef, useState } from "react";
import { useFocusWithin } from "ahooks";
import styled from "styled-components";

import type { FilePreviewCardStatus } from "domain/fileContacts";
import { _variables } from "ui/styles/_variables";
import IconSelectSVG from "ui/assets/media/icons/arrow-select-icon.svg";

import { Icon } from "../Icon";

const Content = styled.div`
  display: flex;
  height: 117px;
  width: 100%;
  border-radius: 8px;
  flex-direction: column;
  z-index: 0;
  position: relative;
`;

const Header = styled.div<{ status: FilePreviewCardStatus }>`
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${_variables.strokeColor};

  background-color: ${_variables.backgroundColor};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &:focus-within {
    border: 1px solid ${_variables.blackColor};
  }
`;

const Show = styled.div`
  display: flex;
  padding: 16px;
  padding-bottom: 0;
  height: 75px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  row-gap: 4px;
  position: relative;
  flex-direction: column;
  font-size: 14px;
  border: 1px solid ${_variables.strokeColor};
  border-top: none;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  z-index: 12;
  & div {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: inline-block;
    height: 17px;
    min-height: 17px;
    width: 100%;
  }
  &:before {
    z-index: 12;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    content: "";
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    height: 40px;
  }
`;

const StatusName = styled.span`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const AnswerName = styled(StatusName)`
  & svg {
    color: ${_variables.additionalColors.yellow};
  }
`;

const headerStatusFileds = {
  1: (
    <StatusName>
      <Icon id={"success-import-icon"} width={24} height={24} />
      Имя
    </StatusName>
  ),
  2: (
    <StatusName>
      <Icon id={"success-import-icon"} width={24} height={24} />
      Email
    </StatusName>
  ),
  3: (
    <StatusName>
      <Icon id={"not-import-big-icon"} width={24} height={24} />
      Не импортировать
    </StatusName>
  ),
  4: (
    <AnswerName>
      <Icon id={"answer-import-big-icon"} width={24} height={24} />
      Выберите поле
    </AnswerName>
  ),
};

const statusFields = {
  1: <StatusName>Имя</StatusName>,
  2: <StatusName>Email</StatusName>,
  3: (
    <StatusName>
      <Icon id={"not-import-icon"} width={24} height={24} />
      Не импортировать
    </StatusName>
  ),
  4: (
    <StatusName>
      <Icon id={"answer-circle"} width={24} height={24} />
      Выберите поле
    </StatusName>
  ),
};

const SelectContent = styled.div`
  width: 100%;
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 16px;
  cursor: pointer;
  z-index: 30;
`;
const Selected = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
`;
const ContentSelected = styled.ul`
  column-gap: 8px;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  border-radius: 8px;
  top: calc(100% + 5px);
  box-shadow: 4px 4px 24px rgba(14, 16, 59, 0.07);
  border: 1px solid ${_variables.strokeColor};
  width: calc(100% - 15px);
  left: 15px;

  & li {
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    column-gap: 6px;

    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }

    &:hover {
      background-color: ${_variables.backgroundColor};
    }

    &.withIcon {
      padding-left: 12px;
    }
  }
`;
const IconSelect = styled.div`
  background-image: url(${IconSelectSVG});
  background-position: center center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
`;

export const SelectHeader: CT<{
  status: FilePreviewCardStatus;
  onChangeStatusField: (status: FilePreviewCardStatus) => void;
}> = ({ status, onChangeStatusField }) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  const onOpen = () => {
    setOpen(true);
  };

  useFocusWithin(ref, {
    onFocus: () => onOpen(),
    onBlur: () => setOpen(false),
  });

  const changeHandler = (status: FilePreviewCardStatus) => {
    onChangeStatusField(status);
    setOpen(false);
    //@ts-ignore
    ref.current.blur();
  };

  // useClickAway(() => {
  //   setOpen(false);
  // }, ref);

  return (
    <>
      <SelectContent tabIndex={0} ref={ref}>
        <Selected>
          <span>{headerStatusFileds[status]}</span> <IconSelect />
        </Selected>
        {isOpen && (
          <ContentSelected>
            <li
              onClick={() => {
                changeHandler(3);
              }}
              className={"withIcon"}
            >
              {statusFields[3]}
            </li>
            <li
              onClick={() => {
                changeHandler(1);
              }}
            >
              {statusFields[1]}
            </li>
            <li
              onClick={() => {
                changeHandler(2);
              }}
            >
              {statusFields[2]}
            </li>
          </ContentSelected>
        )}
      </SelectContent>
    </>
  );
};

export const SettingField: CT<{
  style?: CSSProperties;
  status: FilePreviewCardStatus;
  content: string[];
  column: number;
  onChangeStatusField?: (status: FilePreviewCardStatus, column: number) => void;
}> = ({ status, style, onChangeStatusField, content, column }) => {
  const changeStatusHandler = (status: FilePreviewCardStatus) => {
    onChangeStatusField && onChangeStatusField(status, column);
  };

  return (
    <Content style={style}>
      <Header status={status}>
        <SelectHeader
          onChangeStatusField={changeStatusHandler}
          status={status}
        />
      </Header>
      <Show>
        {content
          .filter((item) => !!item)
          //eslint-disable-next-line
          .map((item) => {
            if (item) {
              let newLine = item
                .replaceAll(
                  /^\s+|\s$/g,
                  "<span style='color:#EA4335;font-weight: 600'>_</span>"
                )
                .replaceAll(
                  /^\t+|\t$/g,
                  "<span style=color:#EA4335;font-weight: 600>_</span>"
                );

              return <div dangerouslySetInnerHTML={{ __html: newLine }} />;
            }
          })}
      </Show>
    </Content>
  );
};
