import type { DomainType } from "domain/domain";
import {
  userDomainInError,
  userDomainInProgress,
  userDomainIsSuccess,
} from "domain/domain";

import { TooltipHover } from ".././TooltipHover";
import { BlackNotify } from ".././BlackNotify";
import { Chip } from "./Chip";

export const ChipDomainStatus: CT<{
  status: DomainType["status"];
}> = ({ status }) => {
  if (userDomainIsSuccess(status)) {
    return (
      <TooltipHover
        offset={[-85, -60]}
        content={
          <BlackNotify right={145}>Домен используется при отправке</BlackNotify>
        }
      >
        <Chip color="green">Включен в отправку</Chip>
      </TooltipHover>
    );
  }

  if (userDomainInProgress(status)) {
    return (
      <TooltipHover
        offset={[-160, -65]}
        content={
          <BlackNotify right={220}>
            <p>
              Домен либо только создан, либо изменен. <br /> Интервал проверки
              каждые ХХ минут в период Х дней.
            </p>
          </BlackNotify>
        }
      >
        <Chip color="orange">Создан для отправки</Chip>
      </TooltipHover>
    );
  }

  if (userDomainInError(status)) {
    return <Chip color="red">Настроить DKIM & SPF</Chip>;
  }
  return <></>;
};
