import styled, { css } from "styled-components";

import { useAppDispatch, useAppSelector } from "services/hooks";
import type { CreateFormClientType } from "services/store/createFormClientSlice";
import {
  createFormClientActions,
  selectFormClientState,
} from "services/store/createFormClientSlice";

import { hexToRgba } from "ui/styles/_mixin";
import { Button } from "ui/components/Buttons/Button";
import { SelectCustom } from "ui/components/FormsElements/SelectCustom";
import { _variables } from "ui/styles/_variables";
import { Icon } from "ui/components/Icon";
import { Switch } from "ui/components/FormsElements/Switch";
import firstFormImg from "../../../../assets/media/images/fist-form-user.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const ChooseBox = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  row-gap: 12px;
  border-radius: 12px;
  background-color: ${_variables.backgroundColor};
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const Items = styled.div`
  display: flex;
  column-gap: 18px;
`;

const Item = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 11px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  border-radius: 12px;
  & span {
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
  }
  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: auto;
        & span {
          opacity: 0.5;
        }
        &:before {
          width: 100%;
          content: "Находится в разработке";
          display: flex;
          padding-top: 58px;
          border-radius: 12px;
          font-size: 12px;
          font-weight: 600;
          line-height: 17px;
          letter-spacing: 0px;
          text-align: left;
          justify-content: center;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background-color: rgba(${hexToRgba(_variables.primaryColor, 0.15)});
        }
      `;
    }
  }}
`;

const ItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 225px;
  height: 98px;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 12px;
`;

const Checked = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  background-color: ${_variables.primaryColor};
  border-radius: 50%;
  position: relative;
  &:before {
    background-color: white;
    border-radius: 50%;
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const Unchecked = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${_variables.strokeColor};
  position: relative;
`;

const Info = styled.div`
  display: flex;
  column-gap: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  align-items: center;
  color: #777991;
`;

const SwitchBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const SwitchItem = styled(Switch)`
  width: fit-content;
`;

const ButtonRow = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const Btn = styled(Button)`
  width: 166px;
`;

export const ChooseFormatStep: CT<unknown> = () => {
  const {
    format,
    setting: { displayCondition, isHideMobile },
  } = useAppSelector(selectFormClientState);
  const dispatch = useAppDispatch();

  const clickNextHandler = () => {
    dispatch(createFormClientActions.goToSetting());
  };

  const clickBackHandler = () => {
    dispatch(createFormClientActions.goToConstructor());
  };

  const clickChooseFormatHandler = (format: CreateFormClientType["format"]) => {
    dispatch(createFormClientActions.setFormat(format));
  };

  const selectDisplayConditionHandler = (
    val: CreateFormClientType["setting"]["displayCondition"]
  ) => {
    dispatch(createFormClientActions.setSettingDisplayCondition(val));
  };

  const changeHideMobileHandler = (val: boolean) => {
    dispatch(createFormClientActions.setMobileHide(val));
  };

  return (
    <Container>
      <ChooseBox>
        <Title>Выберите формат</Title>
        <Items>
          <Item
            onClick={() => {
              clickChooseFormatHandler(1);
            }}
            disabled={false}
          >
            <ImageWrapper>
              <img src={firstFormImg} alt="" />
            </ImageWrapper>
            <ItemRow>
              <span>Всплывающая</span>
              {format === 1 ? <Checked /> : <Unchecked />}
            </ItemRow>
          </Item>
          <Item
            onClick={() => {
              return null;
              clickChooseFormatHandler(2);
            }}
            disabled={true}
          >
            <ImageWrapper></ImageWrapper>
            <ItemRow>
              <span>Статичная</span>
              {format === 2 ? <Checked /> : <Unchecked />}
            </ItemRow>
          </Item>
        </Items>
      </ChooseBox>
      <ChooseBox>
        <Title>Условие показа</Title>
        <SelectCustom
          background={"white"}
          onChange={(val) => {
            selectDisplayConditionHandler(
              Number(
                val.value
              ) as CreateFormClientType["setting"]["displayCondition"]
            );
          }}
          values={[
            {
              value: "1",
              name: "После загрузки страницы",
              isDefaultChecked: displayCondition == 1,
            },
            {
              value: "2",
              name: "При клике на кнопку",
              isDefaultChecked: displayCondition == 2,
            },
          ]}
        />
        {displayCondition == 2 && (
          <Info>
            <Icon id={"info-icon"} height={24} width={24} />
            Код кнопки будет сгенерирован вместе с кодом формы
          </Info>
        )}
      </ChooseBox>
      <ChooseBox>
        <SwitchBox>
          <span>Скрывать на мобильном</span>
          <SwitchItem
            onChange={changeHideMobileHandler}
            value={isHideMobile}
            size={{ width: 32, height: 20, radius: 16 }}
            isShowTextPreview={false}
          />
        </SwitchBox>
      </ChooseBox>
      <ButtonRow>
        <Btn onClick={clickBackHandler} variant={"grey"}>
          Назад
        </Btn>
        <Btn onClick={clickNextHandler} variant={"primary"} disabled={!format}>
          Далее
        </Btn>
      </ButtonRow>
    </Container>
  );
};
