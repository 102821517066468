import React, { useLayoutEffect, useState } from "react";
import { matchPath, useHistory } from "react-router-dom";
import styled from "styled-components";

import { usePageTitle } from "app/usePageTitle";

import { ColumnMenu } from "../components/ColumnMenu";
import { Header } from "./Header";
import type { RouteType } from "../routes/routes";

const headerHeight = 68;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: white;
`;

const HeaderComponent = styled(Header)`
  height: ${headerHeight}px;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
`;

const ContentRow = styled.div`
  display: flex;
  height: calc(100vh - ${headerHeight + 1}px);
`;

const Content = styled.main`
  display: flex;
  //width: 100%;
  width: calc(100% - 238px);
  height: 100%;
`;

export const ContentLayout: CT<{
  contentStyle?: React.CSSProperties;
  routes: RouteType[];
}> = ({ children, routes, contentStyle, className }) => {
  const history = useHistory();
  const [isShow, setShow] = useState(true);
  const [hasBeen, setHasBeen] = useState(false);
  usePageTitle(routes);

  useLayoutEffect(() => {
    const match = routes.find((route) =>
      matchPath(history.location.pathname, route.path)
    );
    setShow(match?.haveLayout ?? false);
    setHasBeen(true);
    return;
  }, [history, history.location, history.location.pathname]);

  if (!hasBeen) return <></>;
  if (!isShow) return <>{children}</>;
  return (
    <Wrapper className={className}>
      <HeaderComponent className={"123"} />
      <ContentRow>
        <ColumnMenu />
        <Content style={contentStyle} className={"content"}>
          {children}
        </Content>
      </ContentRow>
    </Wrapper>
  );
};
