import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useShowPayHistoryCollection } from "app/tables/useShowPayHistoryCollection";
import { _variables } from "ui/styles/_variables";

import { TableHead } from "../../components/Table/TableHead";
import { TableBody } from "../../components/Table/TableBody";
import { TableHeadOnceFilter } from "../../components/Table/Filters/TableHeadOnceFilter";
import { ContentPagination } from "../../components/ContentPagination";
import { TableContainerScroll } from "../../containers/TableContainerScroll";
import { ShowCount } from "../../components/ShowCount";
import { NotContentListsPage } from "../../components/NotContent/NotContentListsPage";
import { LoadContent } from "../../components/LoadContent";
import { useTableWorkspace } from "../../hooks/useTableWorkspace";
import { RowTabs } from "../../components/RowTabs";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px 5px 12px 32px;
`;

const Table = styled.table`
  display: grid;
  grid-template-columns:
    minmax(320px, 50%) minmax(320px, 50%) minmax(150px, 320px)
    minmax(150px, 320px);
  grid-auto-rows: auto;
  position: relative;
  z-index: 9;
`;

const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 31px;
  margin-bottom: 18px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const TableTop = styled(Table)`
  padding-right: 0;
  margin-top: 28px;
  row-gap: 0;
`;
const THead = styled(TableHead)`
  & th {
    border-bottom: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    border-bottom: 1px solid ${_variables.strokeColor};
    color: ${_variables.grayTextColor};
  }
`;

const StickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: white;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const TD = styled.td`
  color: ${_variables.grayTextColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const LoadWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const HistoryPayPage = () => {
  const history = useHistory();
  const { query, setPerPage, setPage, setSortBy } = useTableWorkspace({
    perPage: 25,
    page: 1,
    sortBy: "date_start",
    name: "",
  });

  const { data, isNotHaveContent, isLoad, isShowContent, countPage } =
    useShowPayHistoryCollection();

  const changePaginationHandler = setPage;
  const changePerPageHandler = setPerPage;
  const changeSortByHandler = setSortBy;

  if (isNotHaveContent) {
    return <NotContentListsPage />;
  }
  const getActiveTab = () => {
    if (window.location.pathname.includes("history-pay-validation"))
      return "email-validation";
    return "packages";
  };

  const items = [
    { name: "Пакеты писем", value: "packages" },
    { name: "Проверка email", value: "email-validation" },
    { name: "Привязанные карты", value: "cards" },
  ];

  return (
    <>
      <Content>
        <TableContainerScroll height={136}>
          <StickyTopContent>
            <HeadingRow>
              <Heading>История покупок</Heading>
            </HeadingRow>
            <RowTabs
              onClick={(val) => {
                if (val === "email-validation") {
                  history.push("/main/history-pay-validation");
                  return;
                }
                if (val === "cards") {
                  history.push("/main/user/card-pay");
                  return;
                }
                history.push("/main/history-pay");
              }}
              active={getActiveTab()}
              items={items}
            />
            <TableTop>
              <THead>
                <TableHeadOnceFilter
                  name={"date_start"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("date_start")}
                >
                  Дата покупки
                </TableHeadOnceFilter>
                <TableHeadOnceFilter
                  name={"date_end"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("date_end")}
                >
                  Дата сгорания
                </TableHeadOnceFilter>
                <TableHeadOnceFilter
                  name={"limit"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("limit")}
                >
                  Количество писем
                </TableHeadOnceFilter>
                <TableHeadOnceFilter
                  name={"value"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("value")}
                >
                  Сколько осталось
                </TableHeadOnceFilter>
              </THead>
            </TableTop>
          </StickyTopContent>
          {isLoad && (
            <LoadWrapper>
              <LoadContent />
            </LoadWrapper>
          )}
          {isShowContent && (
            <Table>
              <TableBody>
                {data.length > 0 &&
                  data.map((item) => (
                    <tr>
                      <TD>{item.date_start}</TD>
                      <TD>{item.date_end}</TD>
                      <TD>{item.limit}</TD>
                      <TD>{item.value}</TD>
                    </tr>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainerScroll>
        <Footer>
          <ShowCount
            defaultValue={Number(query["per-page"])}
            onChange={changePerPageHandler}
          />
          {countPage > 1 && data.length > 0 && (
            <ContentPagination
              force={Number(query.page - 1)}
              onClick={changePaginationHandler}
              pageCount={countPage}
            />
          )}
        </Footer>
      </Content>
    </>
  );
};
