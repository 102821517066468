import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import type {
  AddEventListener,
  Editor,
  EditorRef,
  EmailEditorProps,
  ExportHtml,
  LoadBlank,
  LoadDesign,
  RegisterCallback,
  SaveDesign,
  SetMergeTags,
} from "./types";

import { loadScript } from "./loadScript";
import { createOptions, defaultTools } from "./constants";

let lastEditorId = 0;

export const useUnlayerEditor = (props: EmailEditorProps) => {
  const {
    onLoad,
    onReady,
    scriptUrl,
    minHeight = "calc(100vh - 68px)",
    style = {},
  } = props;

  const [isPreloadScript, setPreloadScript] = useState(true);

  const options = useMemo(() => createOptions(props.options), []);

  const editorId = useRef(props.editorId || `editor-${++lastEditorId}`);

  const editorRef = useRef<EditorRef>(null);

  const isLoadedRef = useRef(false);

  const [editor, setEditor] = useState<Editor | null>(null);

  const loadEditor = useCallback(() => {
    setPreloadScript(false);
    if (isLoadedRef.current) return;
    isLoadedRef.current = true;

    options.tools = props.tools || defaultTools;
    if (props.projectId) options.projectId = props.projectId;
    if (props.appearance) options.appearance = props.appearance;
    if (props.locale) options.locale = props.locale;

    setEditor(
      unlayer.createEditor({
        ...props.options,
        //@ts-ignore
        displayMode: "email",
        ...options,
        id: editorId.current,
        features: {
          //@ts-ignore
          closeButton: "false",
        },
      })
    );
  }, [
    editorId.current,
    props.appearance,
    props.locale,
    props.options,
    props.projectId,
    props.tools,
  ]);

  const addEventListener = useCallback<AddEventListener>(
    (type, callback) => {
      editor?.addEventListener(type, callback);
    },
    [editor]
  );

  const registerCallback = useCallback<RegisterCallback>(
    (type: any, callback: any) => {
      editor?.registerCallback(type as any, callback as any);
    },
    [editor]
  );

  const loadDesign = useCallback<LoadDesign>(
    (design) => {
      editor?.loadDesign(design);
    },
    [editor]
  );

  const saveDesign = useCallback<SaveDesign>(
    (callback) => {
      editor?.saveDesign(callback);
    },
    [editor]
  );

  const exportHtml = useCallback<ExportHtml>(
    (callback, options) => {
      editor?.exportHtml(callback, options);
    },
    [editor]
  );

  const setMergeTags = useCallback<SetMergeTags>(
    (mergeTags) => {
      editor?.setMergeTags(mergeTags);
    },
    [editor]
  );

  const loadBlank = useCallback<LoadBlank>(
    (options) => {
      editor?.loadBlank(options);
    },
    [editor]
  );

  useEffect(() => {
    loadScript(loadEditor, scriptUrl);
  }, [loadEditor, scriptUrl]);

  useEffect(() => {
    if (!editor) return;

    // All properties starting with on[Name] are registered as event listeners.
    for (const [key, value] of Object.entries(props)) {
      if (/^on/.test(key) && key !== "onLoad" && key !== "onReady") {
        addEventListener(key, value);
      }
    }

    // @deprecated
    onLoad && onLoad.call(editor);

    if (onReady) editor.addEventListener("editor:ready", onReady.bind(editor));
  }, [editor, addEventListener, onLoad, onReady, props]);

  useImperativeHandle(
    editorRef,
    () => ({
      saveDesign,
      exportHtml,
      setMergeTags,
      editor,
      loadDesign,
      registerCallback,
      addEventListener,
      loadBlank,
    }),
    [
      saveDesign,
      exportHtml,
      setMergeTags,
      editor,
      loadDesign,
      registerCallback,
      addEventListener,
      loadBlank,
    ]
  );

  const Editor = useMemo(
    () => (
      <div
        style={{
          flex: 1,
          display: "flex",
          minHeight: minHeight,
        }}
      >
        <div
          ref={editorRef as any}
          id={editorId.current}
          style={{ ...style, flex: 1 }}
        />
      </div>
    ),
    []
  );

  return {
    Editor,
    editorRef,
    exportHtml,
    loadDesign,
    isPreloadScript,
  };
};
