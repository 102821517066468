import styled from "styled-components";

import { Button } from "ui/components/Buttons/Button";
import { useHistory } from "react-router-dom";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const Text = styled.p`
  font-size: 14px;
  opacity: 0.5;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const FormSubscription = ({ onClose }: { onClose: () => void }) => {
  const history = useHistory();
  return (
    <Root>
      <Text>
        Замените список в настройках формы, чтобы не нарушить ее работу
      </Text>
      <ButtonWrapper>
        <Button
          onClick={() => {
            onClose();
            history.push("/forms");
          }}
          variant="primary"
        >
          Формы подписок
        </Button>
        <Button onClick={onClose} variant="secondary">
          Отмена
        </Button>
      </ButtonWrapper>
    </Root>
  );
};

export default FormSubscription;
