import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import StarRatings from "react-star-ratings";

import { useGoBackLink } from "app/useGoBackLink";
import { useLoadTemplateById } from "app/useLoadTemplate";
import { createToastResponseError } from "lib/toastify";
import { useAppDispatch } from "services/hooks";
import { templatesService } from "services/templates";
import { setTemplateMailing } from "services/store/createMailingSlice";
import { LoadContent } from "ui/components/LoadContent";
import { _variables } from "ui/styles/_variables";
import { scroll } from "ui/styles/elements/scroll";
import { _reset } from "ui/styles/_reset";

import { Icon } from "../Icon";
import { Button } from "../Buttons/Button";

const STAR_SVG_PATH =
  "M13.6666 5.44853C13.6245 5.32659 13.5479 5.21949 13.4462 5.1402C13.3444 5.06092 13.2218 5.01286 13.0933 5.00187L9.29994 4.44853L7.59994 1.00187C7.54535 0.889153 7.46012 0.794095 7.354 0.727582C7.24789 0.66107 7.12518 0.625793 6.99994 0.625793C6.87471 0.625793 6.752 0.66107 6.64588 0.727582C6.53977 0.794095 6.45453 0.889153 6.39994 1.00187L4.69994 4.44187L0.906611 5.00187C0.783226 5.0194 0.667227 5.07118 0.571778 5.15131C0.476328 5.23144 0.405251 5.33672 0.366611 5.4552C0.331241 5.57098 0.328067 5.69421 0.357429 5.81166C0.386792 5.92911 0.447583 6.03635 0.533278 6.12187L3.28661 8.78853L2.61994 12.5752C2.59303 12.7008 2.60298 12.8315 2.6486 12.9516C2.69422 13.0717 2.77357 13.1761 2.8771 13.2521C2.98063 13.3282 3.10392 13.3727 3.23216 13.3804C3.3604 13.388 3.48812 13.3584 3.59994 13.2952L6.99994 11.5152L10.3999 13.2952C10.4935 13.348 10.5992 13.3756 10.7066 13.3752C10.8478 13.3757 10.9856 13.3314 11.0999 13.2485C11.2034 13.1744 11.2834 13.0723 11.3307 12.9542C11.3779 12.836 11.3904 12.7069 11.3666 12.5819L10.6999 8.7952L13.4533 6.12853C13.5495 6.04699 13.6207 5.9398 13.6584 5.81943C13.6962 5.69906 13.699 5.57045 13.6666 5.44853Z";

const widthConfig: { mobile: number; desktop: number } = {
  mobile: 375,
  desktop: 1000,
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  z-index: 9999;
`;
const Iframe = styled.iframe`
  ${_reset};
  overflow: hidden;
  background-color: transparent;
  height: 10000px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32px;
  justify-content: space-between;
  height: 68px;
  background-color: white;
  width: 100%;
  top: 0;
  border-bottom: 1px solid ${_variables.strokeColor};
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
  column-gap: 5px;
  cursor: pointer;
  font-weight: 600;
`;

const HeaderEnd = styled.div`
  display: flex;
  align-items: center;
  column-gap: 30px;
`;

const WindowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 44px;
  justify-content: space-around;
  width: 100%;
`;

const ContentWrap = styled.div`
  display: flex;
  max-width: 1500px;
  width: 100%;
  justify-content: space-between;
  column-gap: 36px;
`;

const Mobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  width: ${widthConfig.mobile + 40}px;
  & main {
    width: ${widthConfig.mobile + 40}px;
    height: min(700px, 80vh);
    padding: 40px 0;
  }
`;

const Desktop = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  width: ${widthConfig.desktop}px;
  & main {
    width: 100%;
    height: min(700px, 80vh);
    padding: 40px 0;
  }
`;

const MacActionWrapper = styled.div`
  width: 100px;
  display: flex;
  column-gap: 5px;
  position: relative;
  top: -20px;
  left: 14px;
`;
const MacAction = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  background: ${({ color }) => color};
  border-radius: 6px;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  max-height: max-content;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #f5f5f5;
  ${scroll}
`;
const ExitButton = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 8px;
  transition: background-color ${_variables.transition};
  background-color: transparent;

  &:hover {
    background-color: ${_variables.strokeColor};
  }

  & img {
    width: 100%;
    position: relative;
    height: 100%;
  }
`;

const Load = styled(LoadContent)`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TemplateViewer: CT<{
  html?: string;
  id?: string | number;
  name?: string;
  needUpload?: boolean;
  onClickExit: VoidFunction;
  rating?: number;
  has_scheme?: boolean;
  isDefaultTemplate?: boolean;
  isMailingCreationWay?: boolean;
}> = ({
  html,
  id,
  onClickExit,
  rating,
  has_scheme,
  isDefaultTemplate,
  isMailingCreationWay,
}) => {
  const [isLoadPage, setLoadPage] = useState(true);
  const [currentRating, setCurrentRating] = useState<number>(rating ?? 0);
  const { isLoadTemplate, templateData } = useLoadTemplateById(id);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const backLink = useGoBackLink();

  const [htmlData] = useState(html);

  const onRate = async (rating: number) => {
    try {
      const templaeResponse = await templatesService.rate(id!, rating);
      setCurrentRating(templaeResponse.data.data.template.rating);
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  const goToEditor = (idTemplate: string | number) => {
    if (has_scheme) {
      history.push(
        `/templates/creator?id=${idTemplate}&isMailingCreationWay=true`
      );
      return;
    }
    history.push(
      `/templates/text-editor?id=${idTemplate}&isMailingCreationWay=true`
    );
  };

  const duplicateHandler = async () => {
    try {
      const response = await templatesService.copy(id!);
      const idTemplate = response.data.data.template.id;
      backLink.create("/templates/cards?tab=templates");

      goToEditor(idTemplate);
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  const ActionButtons = useMemo(() => {
    if (isDefaultTemplate) {
      return (
        <Button
          onClick={() => {
            duplicateHandler();
            onClickExit();
          }}
        >
          Использовать шаблон
        </Button>
      );
    }
    return (
      <>
        <Button
          variant="secondary"
          onClick={() => {
            goToEditor(id!);
            onClickExit();
          }}
        >
          Редактировать
        </Button>
        <Button
          onClick={() => {
            dispatch(setTemplateMailing(+id!));
            history.push("/templates/create-mailing");
            onClickExit();
          }}
        >
          Использовать шаблон
        </Button>
      </>
    );
  }, []);

  // Костыль для плавного появления, оборачиваем подгрузку
  useEffect(() => {
    setTimeout(() => {
      setLoadPage(isLoadTemplate);
    }, 250);
  }, [isLoadTemplate]);

  return (
    <Wrapper>
      <Header>
        {isDefaultTemplate && isMailingCreationWay ? (
          <Rating>
            <p>{currentRating && currentRating > 0 ? currentRating : ""}</p>
            <StarRatings
              rating={currentRating ? Number(currentRating) : 0}
              starRatedColor={_variables.primaryColor}
              starHoverColor={_variables.primaryColor}
              changeRating={onRate}
              numberOfStars={5}
              name="rating"
              starSpacing="0px"
              starDimension="25px"
              svgIconViewBox="0 -3 22 22"
              svgIconPath={STAR_SVG_PATH}
            />
          </Rating>
        ) : (
          <div style={{ width: 40 }} />
        )}
        <HeaderEnd>
          {isMailingCreationWay && ActionButtons}
          <ExitButton onClick={onClickExit}>
            <Icon width={24} height={24} id={"cross-icon"} />
          </ExitButton>
        </HeaderEnd>
      </Header>
      <WindowWrapper>
        {isLoadPage && <Load />}
        {!isLoadPage && (
          <ContentWrap>
            <Desktop>
              <Main>
                <MacActionWrapper>
                  <MacAction color={_variables.decorMacOSToolbar.close} />
                  <MacAction color={_variables.decorMacOSToolbar.fold} />
                  <MacAction color={_variables.decorMacOSToolbar.fullScreen} />
                </MacActionWrapper>
                <Iframe
                  style={{
                    alignSelf: "center",
                    minHeight: "calc(100% - 12px)",
                    width: "100%",
                    minWidth: "340px",
                    overflow: "hidden",
                    borderRadius: "0 0 8px 8px",
                    borderTop: `1px solid rgba(0, 0, 0, 0.1)`,
                    boxSizing: "content-box",
                    opacity: isLoadPage ? "0" : "1",
                    backgroundColor: "white",
                  }}
                  srcDoc={htmlData ?? templateData?.content}
                />
              </Main>
            </Desktop>
            <Mobile>
              <Main>
                <MacActionWrapper>
                  <MacAction color={_variables.decorMacOSToolbar.close} />
                  <MacAction color={_variables.decorMacOSToolbar.fold} />
                  <MacAction color={_variables.decorMacOSToolbar.fullScreen} />
                </MacActionWrapper>
                <Iframe
                  style={{
                    alignSelf: "center",
                    minHeight: "100%",
                    width: "100%",
                    minWidth: "390px",
                    overflow: "hidden",
                    borderRadius: "0 0 16px 16px",
                    borderTop: `1px solid rgba(0, 0, 0, 0.1)`,
                    boxSizing: "content-box",
                    backgroundColor: "white",
                  }}
                  srcDoc={htmlData ?? templateData?.content}
                />
              </Main>
            </Mobile>
          </ContentWrap>
        )}
      </WindowWrapper>
    </Wrapper>
  );
};
