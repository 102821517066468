import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { _variables } from "ui/styles/_variables";

import { TableHead } from "../../components/Table/TableHead";
import { TableBody } from "../../components/Table/TableBody";
import { TableHeadOnceFilter } from "../../components/Table/Filters/TableHeadOnceFilter";
import { ContentPagination } from "../../components/ContentPagination";
import { TableContainerScroll } from "../../containers/TableContainerScroll";
import { ShowCount } from "../../components/ShowCount";
import { LoadContent } from "../../components/LoadContent";
import { useTableWorkspace } from "../../hooks/useTableWorkspace";
import { RowTabs } from "../../components/RowTabs";
import { useUserCardCollection } from "../../../app/tables/useUserCardCollection";
import { Button } from "ui/components/Buttons/Button";
import { useAppDispatch } from "services/hooks";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px 5px 12px 32px;
`;

const Table = styled.table`
  display: grid;
  grid-template-columns: minmax(320px, 320px) minmax(320px, 320px) minmax(
      320px,
      100%
    );
  grid-auto-rows: auto;
  position: relative;
  z-index: 9;
`;

const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 31px;
  margin-bottom: 18px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const TableTop = styled(Table)`
  padding-right: 0;
  margin-top: 28px;
  row-gap: 0;
`;
const THead = styled(TableHead)`
  & th {
    border-bottom: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    border-bottom: 1px solid ${_variables.strokeColor};
    color: ${_variables.grayTextColor};
  }
`;

const StickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: white;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const TD = styled.td`
  color: ${_variables.grayTextColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const LoadWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const UserCardPayPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { query, setPerPage, setPage } = useTableWorkspace({
    perPage: 25,
    page: 1,
    sortBy: "",
    name: "",
  });

  const { data, isLoad, isShowContent, countPage } = useUserCardCollection();

  const changePaginationHandler = setPage;
  const changePerPageHandler = setPerPage;

  const getActiveTab = () => {
    if (window.location.pathname.includes("card-pay")) return "cards";
    return "packages";
  };

  const items = [
    { name: "Пакеты писем", value: "packages" },
    { name: "Проверка email", value: "email-validation" },
    { name: "Привязанные карты", value: "cards" },
  ];

  const onUnSubscribeCardHandler = (id: number) => {
    dispatch(setModalData({ idCard: id, isNotShowClose: true }));
    dispatch(openModal("unsubscribe-card"));
  };

  return (
    <>
      <Content>
        <TableContainerScroll height={136}>
          <StickyTopContent>
            <HeadingRow>
              <Heading>Привязанные карты</Heading>
            </HeadingRow>
            <RowTabs
              onClick={(val) => {
                if (val === "email-validation") {
                  history.push("/main/history-pay-validation");
                  return;
                }
                if (val === "cards") {
                  history.push("/main/user/card-pay");
                  return;
                }
                history.push("/main/history-pay");
              }}
              active={getActiveTab()}
              items={items}
            />
            <TableTop>
              <THead>
                <TableHeadOnceFilter
                  name={""}
                  onClick={() => {}}
                  isActive={false}
                >
                  Номер карты
                </TableHeadOnceFilter>
                <TableHeadOnceFilter
                  name={""}
                  onClick={() => {}}
                  isActive={false}
                >
                  Дата привязки
                </TableHeadOnceFilter>
                <TableHeadOnceFilter name={""} />
              </THead>
            </TableTop>
          </StickyTopContent>
          {isLoad && (
            <LoadWrapper>
              <LoadContent />
            </LoadWrapper>
          )}
          {isShowContent && (
            <Table>
              <TableBody>
                {data.length > 0 &&
                  data.map((item) => (
                    <tr>
                      <TD>{item.title_full}</TD>
                      <TD>{item.date_in}</TD>
                      <TD>
                        <Button
                          onClick={() => {
                            onUnSubscribeCardHandler(item.id);
                          }}
                          variant={"secondary"}
                        >
                          Отвязать карту
                        </Button>
                      </TD>
                    </tr>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainerScroll>
        <Footer>
          <ShowCount
            defaultValue={Number(query["per-page"])}
            onChange={changePerPageHandler}
          />
          {countPage > 1 && data.length > 0 && (
            <ContentPagination
              force={Number(query.page - 1)}
              onClick={changePaginationHandler}
              pageCount={countPage}
            />
          )}
        </Footer>
      </Content>
    </>
  );
};
