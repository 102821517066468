import { useState } from "react";
import { useTitle } from "ahooks";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

import { appConfig } from "../../appConfig";
import { RegisterForm } from "../containers/forms/RegisterForm";
import { BackgroundPreview } from "../components/BackgroudPreview";
import { Link } from "../components/Link";

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Title = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.25px;
  color: ${_variables.blackColor};
  text-align: center;
`;

const Text = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  line-height: 21px;
  letter-spacing: -0.25px;
  color: ${_variables.grayColor};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-bottom: 32px;
`;

const SuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Success = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  max-width: 340px;
  width: 100%;
`;

export const RegisterPage = () => {
  useTitle("Регистрация" + appConfig.titleApp);
  const [email, setEmail] = useState("");

  const successRegisterHandler = (email: string) => {
    setEmail(email);
  };

  if (email)
    return (
      <BackgroundPreview>
        <SuccessWrapper>
          <Success>
            На почту {email} отправлено письмо для подтверждения.{" "}
          </Success>
        </SuccessWrapper>
      </BackgroundPreview>
    );
  return (
    <>
      <BackgroundPreview>
        <FormWrapper>
          <TextWrapper>
            <Title>Создать аккаунт</Title>
            <Text>
              Уже есть аккаунт?&nbsp;
              <Link color={"blue"} link={"/login"}>
                Войдите
              </Link>
            </Text>
          </TextWrapper>
          <RegisterForm onSuccessRegister={successRegisterHandler} />
        </FormWrapper>
      </BackgroundPreview>
    </>
  );
};
