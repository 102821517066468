import React, { useEffect } from "react";
import styled from "styled-components";

import { useShowDraftMailingCollection } from "app/tables/useShowDraftMailingCollection";

import { _variables } from "ui/styles/_variables";
import { TableHead } from "ui/components/Table/TableHead";
import { TableBody } from "ui/components/Table/TableBody";
import { LoadContent } from "ui/components/LoadContent";
import { SearchNotFound } from "ui/components/NotContent/SearchNotFound";
import { TableHeadOnceFilter } from "ui/components/Table/Filters/TableHeadOnceFilter";
import { DraftTableContent } from "ui/components/Table/DraftTableContent";
import { NotContentTable } from "ui/components/NotContent/NotContentTable";

const ITable = styled.table`
  display: grid;
  grid-template-columns:
    minmax(280px, 100%) minmax(223px, 400px) minmax(145px, 140px)
    170px;
  margin-top: 29px;
  grid-auto-rows: auto;
  position: relative;
  z-index: 9;

  & th {
    min-height: 24px;
  }
  & tr {
    &:hover {
      & td {
        background-color: inherit;
      }
    }
  }

  & tr td {
    height: 74px;
  }
`;

const THead = styled(TableHead)`
  & th {
    border-bottom: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${_variables.grayTextColor};
  }
`;

const TBody = styled(TableBody)`
  margin-top: 16px;
  position: relative;

  & td {
    border-bottom: none;
  }
`;

export const DraftContent: CT<{
  onChangeSortName: (val: string) => void;
  onSetCountPage: (val: number) => void;
}> = ({ onChangeSortName, onSetCountPage }) => {
  const { query, isLoad, isNotFound, isNotHaveContent, data, countPage } =
    useShowDraftMailingCollection();

  useEffect(() => {
    onSetCountPage(countPage);
  }, [countPage]);

  if (isLoad) return <LoadContent top={120} />;

  if (isNotHaveContent)
    return <NotContentTable>У вас пока нет черновиков</NotContentTable>;

  return (
    <>
      <ITable>
        <THead>
          <TableHeadOnceFilter
            name={"name"}
            onClick={onChangeSortName}
            isActive={query.sortBy.includes("name")}
          >
            Название
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            name={"date"}
            onClick={onChangeSortName}
            isActive={query.sortBy.includes("date")}
          >
            Дата создания
          </TableHeadOnceFilter>
          <th />
          <th />
        </THead>
        <TBody>
          {data.map((item) => (
            <DraftTableContent
              isActive={false}
              onClickCheck={() => {}}
              data={item}
            />
          ))}
        </TBody>
      </ITable>
      {isNotHaveContent && (
        <NotContentTable>У вас пока нет черновиков</NotContentTable>
      )}
      {isNotFound && <SearchNotFound />}
    </>
  );
};
