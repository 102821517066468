import { userEmailsService } from "services/userEmailsService";

export const useValidEmail = () => {
  const valid = async (email: string) => {
    try {
      const response = await userEmailsService.list(email);
      if (response.data.data.emails.length) {
        return response.data.data.emails.some(
          (item) =>
            item.email.toLowerCase().trim() === email.toLowerCase().trim()
        );
      } else {
        return false;
      }
    } catch (e) {
      return true;
    }
  };

  return valid;
};
