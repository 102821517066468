import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import { formElement, formWrapper } from "ui/styles/elements/formElements";
import IconSelectSVG from "ui/assets/media/icons/arrow-select-icon.svg";

const Wrapper = styled.div`
  ${formWrapper}
`;

const Element = styled.label<{ isError: boolean; haveIcon: boolean }>`
  ${formElement};
  position: relative;
  cursor: pointer;
  width: 100%;
  min-width: 240px;
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;

  &:focus-within {
    // border: 2px solid ${_variables.primaryColor};
    //& .icon {
    //  transform: translateY(-50%) rotate(180deg);
    //}
  }

  & select {
    cursor: pointer;
    border: ${(props) =>
      props.isError
        ? `2px solid ${_variables.supportColors.red}`
        : "2px solid transparent"};
    transition: all ${_variables.transition};

    &:focus {
      border: 2px solid ${_variables.primaryColor} !important;
    }

    width: 100%;
    padding: 0 16px;
    height: 100%;
    background: transparent;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: ${_variables.borderRadius};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;

    margin: 0;
  }

  & select:focus {
    border: none;
    outline: none;
  }
`;

const IconSelect = styled.div`
  background-image: url(${IconSelectSVG});
  background-position: center center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
`;

export const Select: CT<{
  error?: string;
  label?: string;
  values: {
    value: string;
    name: string | number;
    isDefaultChecked?: boolean;
  }[];
  onChange?: (val: string) => void;
  background?: string;
  placeholder?: string;
}> = ({
  values,
  onChange = () => {},
  label = "",
  error = "",
  className,
  background,
  placeholder = "",
}) => {
  return (
    <Wrapper className={className}>
      {label && <p className={"label"}>{label}</p>}
      <Element
        htmlFor={"select"}
        haveIcon={false}
        isError={error?.length > 0}
        style={{ backgroundColor: background }}
      >
        <IconSelect className={"icon"} />
        <select
          onChange={(e) => {
            onChange(e.target.value);
          }}
          tabIndex={0}
          name="select"
          placeholder={placeholder}
          id="select"
        >
          {values.map((item) => (
            <option
              selected={item.isDefaultChecked || false}
              value={item.value}
              key={item.value}
            >
              {item.name}
            </option>
          ))}
        </select>
      </Element>
      {error && <span className={"error"}>{error}</span>}
    </Wrapper>
  );
};
