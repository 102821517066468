import { useEffect } from "react";

import { deepEqual } from "lib/other";
import { useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";

import { appConfig } from "../../appConfig";

export const useUseYandexMetrika = () => {
  const userData = useAppSelector(selectUserData, deepEqual);

  useEffect(() => {
    if (!userData) return;
    setTimeout(() => {
      window?.ym &&
        window.ym(appConfig.yandexMetrikaId, "userParams", {
          UserID: String(userData.id),
        });
    }, 500);
  }, [userData]);
};
