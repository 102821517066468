import { useEffect, useState } from "react";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import Checked from "ui/assets/media/icons/checked-checkbox.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  position: relative;

  & input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  & input + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    transition: background-color ${_variables.transition};
    cursor: pointer;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  & input + label::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid ${_variables.strokeColor};
    background-color: ${_variables.strokeColor};
    border-radius: ${_variables.borderRadius};
    margin-right: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }

  & input:checked + label::before {
    border-color: ${_variables.primaryColor};
    background-color: ${_variables.primaryColor};
    background-image: url(${Checked});
    background-size: 10px;
  }
  & input:focus + label::before {
    border: 2px solid ${_variables.primaryColor};
  }
`;
export const Checkbox: CT<{
  name: string;
  checked?: boolean;
  onChecked?: (name: string, typeAction: boolean) => void;
}> = ({ name, children, checked = false, onChecked }) => {
  const [isChecked, setChecked] = useState(checked || false);

  const changeHandler = () => {
    onChecked && onChecked(name, !isChecked);
    if (checked === undefined) {
      setChecked(!isChecked);
    }
  };

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <Wrapper>
      <input
        checked={isChecked}
        id={name}
        name={name}
        onChange={changeHandler}
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key !== "Enter") return;
          changeHandler();
        }}
        type="checkbox"
      />
      <label htmlFor={name}>{children}</label>
    </Wrapper>
  );
};
