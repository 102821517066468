import React, { useCallback, useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import styled from "styled-components";
import { toast } from "react-toastify";

import { usePrepareHTMLTemplate } from "app/templates/usePrepareHTMLTemplate";
import { useCreateTemplate } from "app/templates/useCreateTemplate";
import { useGoBackLink } from "app/useGoBackLink";
import type { TemplateType } from "domain/templates";
import { hasBeenTemplateDiff } from "lib/template";
import { createStandardToast, createToastResponseError } from "lib/toastify";
import { openModal } from "services/store/modal";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { setModalData } from "services/store/modalDataSlice";

import { templatesService } from "services/templates";

import { HeaderEditor } from "./HeaderEditor";
import { TemplateViewer } from "./TemplateViewer";
import type { EditorType } from "./EditorType";
import { deepEqual } from "lib/other";
import { selectUserData } from "services/store/userSlice";

const Wrapper = styled.main`
  height: 100vh;
  position: relative;
  max-height: 100vh;
  padding-bottom: 5px;
`;

const Editor: CT<{
  loadHandler: VoidFunction;
  readyHandler: VoidFunction;
  emailEditorRef: any;
}> = React.memo(
  ({ emailEditorRef, loadHandler, readyHandler }) => {
    return (
      //@ts-ignore
      <EmailEditor
        options={{
          id: "email-wrapper",
          projectId: 74964,
          safeHtml: true,
          displayMode: "email",
          features: {
            preview: false,
            imageEditor: true,
            stockImages: true,
            undoRedo: true,
          },
          locale: "ru",
        }}
        minHeight={"calc(100vh - 68px)"}
        // @ts-ignore
        ref={emailEditorRef as unknown as React.RefObject<EmailEditor>}
        onLoad={loadHandler}
        onReady={readyHandler}
        tools={{
          text: {
            properties: {
              text: {
                value:
                  '<span style="font-size: 14px; line-height: 19.6px;">Текст</span>',
              },
            },
          },

          button: {
            properties: {
              text: {
                value:
                  '<span style="font-size: 14px; line-height: 19.6px;">Кнопка</span>',
              },
            },
          },

          html: {
            properties: {
              html: {
                value: "<b>Hello world</b>",
              },
            },
          },
        }}
      />
    );
    //Костыль из-за активного обновления конструктора
    // причину обновления не нашел
    // подозрение что идет цикл id target и сам компонент внутри того же id
  },
  () => true
);

export const TemplateCreator: CT<{
  isNewTemplate?: boolean;
  isPublicTemplate?: boolean; // публичный шаблон, что создает админ
  dataTemplate?: TemplateType | null;
  isMailingCreationWay: boolean;
  templateId: string;
}> = React.memo(
  ({
    isNewTemplate = true,
    isPublicTemplate = false,
    dataTemplate,
    isMailingCreationWay,
    templateId,
  }) => {
    const emailEditorRef = useRef<EditorType>(null);
    const { fixParagraphTagMarginMailRu, fixEmptyButtonTag } =
      usePrepareHTMLTemplate();
    const [isReady, setReady] = useState(false);
    const dispatch = useAppDispatch();
    const [isNotSavedTemplate, setNotSavedTemplate] =
      useState<boolean>(isNewTemplate);
    const userData = useAppSelector(selectUserData, deepEqual);
    const [htmlView, setHtmlView] = useState(dataTemplate?.content ?? "");
    const [isOpenViewer, setOpenViewer] = useState(false);
    const [preload, setPreload] = useState(true);
    const [isLoad, setLoad] = useState(false);
    const [currentTemplate, setCurrentTemplate] = useState<
      Pick<TemplateType, "id" | "name" | "content"> | null | undefined
    >(dataTemplate);
    const [templateName, setTemplateName] = useState(dataTemplate?.name ?? "");
    const { createPreview } = useCreateTemplate();
    const { goBack } = useGoBackLink();

    const loadHandler = useCallback(() => {
      setTimeout(() => {
        if (!isNewTemplate && dataTemplate && dataTemplate.scheme) {
          emailEditorRef.current!.editor.loadDesign(
            //@ts-ignore
            dataTemplate.scheme
          );
          return;
        }
      }, 200);
    }, []);

    const readyHandler = useCallback(() => {
      setReady(true);
      if (!dataTemplate?.scheme) {
        emailEditorRef!.current!.editor.setBodyValues({
          backgroundColor: "#ffffff",
        });
      }
      // основной обьект доступа - emailEditorRef.current!.editor
      // внутреннее апи -  emailEditorRef.current!.editor.__proto__
      if (isPublicTemplate) return;
      toast(
        <p>
          Ссылка отписки будет добавлена автоматически. В тестовых письмах
          ссылка не добавляется.
        </p>,
        {
          autoClose: false,
          toastId: "block-editor-notify",
        }
      );
    }, []);

    const saveHandler = async (withExit: boolean) => {
      if (!templateName.trim()) {
        createToastResponseError(
          null,
          "Введите название шаблона"
        )({ toastId: "res" });

        setTimeout(() => {
          setLoad(false);
        }, 1000);

        return;
      }
      if (!currentTemplate) return;
      emailEditorRef.current!.editor.exportHtml(async (data) => {
        setLoad(true);
        try {
          const { design, html } = data;
          const preparedHtml = fixParagraphTagMarginMailRu(
            fixEmptyButtonTag(html)
          );
          const preview = await createPreview(preparedHtml);
          await templatesService.edit({
            name: templateName,
            id: String(currentTemplate.id),
            scheme: JSON.stringify(design),
            content: preparedHtml,
            isPublic: !!userData?.is_admin && isPublicTemplate,
            preview,
          });
          await createStandardToast("Шаблон успешно сохранен")();
          if (withExit) {
            setTimeout(() => {
              goBack();
            }, 800);
          }
        } catch (e) {
          createToastResponseError(e)();
        } finally {
          setLoad(false);
        }
      });
    };

    const createHandler = (withExit: boolean) => {
      setLoad(true);
      if (!templateName.trim()) {
        createToastResponseError(
          null,
          "Введите название шаблона"
        )({ toastId: "res" });

        setTimeout(() => {
          setLoad(false);
        }, 1000);

        return;
      }
      try {
        emailEditorRef.current!.editor.exportHtml(async (data) => {
          const { design, html } = data;
          const preparedHtml = fixParagraphTagMarginMailRu(
            fixEmptyButtonTag(html)
          );
          const preview = await createPreview(preparedHtml);
          const response = await templatesService.create({
            name: templateName,
            scheme: JSON.stringify(design),
            content: preparedHtml,
            isPublic: !!userData?.is_admin && isPublicTemplate,
            preview,
          });
          await createStandardToast("Шаблон успешно сохранен")();
          await window.history.replaceState(
            {},
            "Редактирование шаблона",
            `/templates/creator?id=${response.data.data.template.id}&publicTemplate=${isPublicTemplate}`
          );
          if (withExit) {
            setTimeout(() => {
              goBack();
            }, 800);
          } else {
            setNotSavedTemplate(false);
            setCurrentTemplate({
              name: templateName,
              id: response.data.data.template.id,
              content: html,
            });
          }
        });
      } catch (e) {
        setLoad(false);
        createToastResponseError(e)();
      }
    };

    const onBackHandler = () => {
      emailEditorRef.current!.editor.exportHtml(() => {
        if (
          !isReady ||
          hasBeenTemplateDiff(
            dataTemplate?.content ?? "",
            currentTemplate?.content ?? ""
          ) ||
          isNewTemplate
        ) {
          dispatch(
            setModalData({
              message:
                "Вы уверены, что хотите вернуться без сохранения шаблона?",
              link: "/templates/cards?tab=new",
              title: "Выход из редактора",
            })
          );
          dispatch(openModal("back-to-link"));
          return;
        }
        window.history.back();
      });
    };

    const onExitViewer = () => {
      setOpenViewer(false);
    };

    const onClickViewMode = () => {
      emailEditorRef.current!.editor.exportHtml((data) => {
        setHtmlView(data.html);
        setOpenViewer(true);
      });
    };

    useEffect(() => {
      setTimeout(() => {
        setPreload(false);
      }, 200);
    });

    return (
      <>
        <Wrapper id={"email-wrapper"}>
          <HeaderEditor
            templateName={templateName}
            templateId={+templateId}
            onChangeTemplateName={(str) => setTemplateName(str)}
            clickViewHandler={onClickViewMode}
            onClickBack={onBackHandler}
            onCreate={createHandler}
            isNewTemplate={isNotSavedTemplate}
            onClickSave={saveHandler}
            isLoad={!isReady || isLoad}
            isMailingCreationWay={isMailingCreationWay}
          />
          {!preload && (
            //@ts-ignore
            <Editor
              emailEditorRef={emailEditorRef}
              loadHandler={loadHandler}
              readyHandler={readyHandler}
            />
          )}
          {isOpenViewer && (
            <TemplateViewer
              onClickExit={onExitViewer}
              name={dataTemplate?.name}
              html={htmlView}
            />
          )}
        </Wrapper>
      </>
    );
  },
  () => false
);
