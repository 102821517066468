import { useEffect, useLayoutEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import useUrlState from "@ahooksjs/use-url-state";
import { useHistory } from "react-router-dom";

import { useCreateTemplate } from "app/templates/useCreateTemplate";
import { useGoBackLink } from "app/useGoBackLink";
import type { TemplateType } from "domain/templates";
import { hasBeenTemplateDiff } from "lib/template";
import { createStandardToast, createToastResponseError } from "lib/toastify";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";
import { templatesService } from "services/templates";

import { HeaderEditor } from "./HeaderEditor";
import { templateDefault } from "./templateDefault";
import { TemplateViewer } from "./TemplateViewer";
import { CK } from "./editors/CK";
import { deepEqual } from "lib/other";

const Wrapper = styled.main`
  height: 100vh;
  position: relative;
`;

export const TextEditor: CT<{
  isNewTemplate?: boolean;
  dataTemplate?: TemplateType | null;
  isMailingCreationWay: boolean;
  templateId: string;
}> = ({
  isNewTemplate = true,
  dataTemplate,
  templateId,
  isMailingCreationWay,
}) => {
  const [value, setValue] = useState(dataTemplate?.content ?? templateDefault);

  const userData = useAppSelector(selectUserData, deepEqual);
  const history = useHistory();

  const [query] = useUrlState({
    id: "",
    publicTemplate: "false",
  });

  const isPublicTemplate = query.publicTemplate === "true"; // публичный шаблон, что создает админ

  // Если режим -> создаение публичного шаблона и пользователь не админ, редирект
  useEffect(() => {
    if (isPublicTemplate && !userData?.is_admin) history.push("/");
  }, [isPublicTemplate, userData]);

  const dispatch = useAppDispatch();

  const [isLoad, setLoad] = useState(false);
  const [isOpenViewer, setOpenViewer] = useState(false);
  const [templateName, setTemplateName] = useState(dataTemplate?.name ?? "");
  const { createPreview } = useCreateTemplate();
  const [isNotSavedTemplate, setNotSavedTemplate] =
    useState<boolean>(isNewTemplate);
  const [currentTemplate, setCurrentTemplate] = useState<
    Pick<TemplateType, "id" | "name" | "content"> | null | undefined
  >(dataTemplate);
  const { goBack } = useGoBackLink();

  const createHandler = async (withExit: boolean) => {
    setLoad(true);
    if (!templateName.trim()) {
      createToastResponseError(
        null,
        "Введите название шаблона"
      )({ toastId: "res" });

      setTimeout(() => {
        setLoad(false);
      }, 1000);

      return;
    }
    try {
      const preview = await createPreview(value);
      const response = await templatesService.create({
        name: templateName,
        scheme: null,
        content: value,
        isPublic: !!userData?.is_admin && isPublicTemplate,
        preview,
      });
      window.history.replaceState(
        {},
        "Редактирование шаблона",
        `/templates/text-editor?id=${response.data.data.template.id}&publicTemplate=${isPublicTemplate}`
      );
      createStandardToast("Шаблон успешно сохранен")();
      if (withExit) {
        setTimeout(() => {
          goBack();
        }, 800);
      } else {
        setNotSavedTemplate(false);
        setCurrentTemplate({
          name: templateName,
          id: response.data.data.template.id,
          content: value,
        });
      }
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setLoad(false);
    }
  };

  const editHandler = async (withExit: boolean) => {
    setLoad(true);
    if (!templateName.trim()) {
      createToastResponseError(
        null,
        "Введите название шаблона"
      )({ toastId: "res" });

      setTimeout(() => {
        setLoad(false);
      }, 1000);

      return;
    }
    if (!currentTemplate) return;
    try {
      const preview = await createPreview(value);
      await templatesService.edit({
        name: templateName,
        id: String(currentTemplate!.id),
        scheme: null,
        content: value,
        isPublic: !!userData?.is_admin && isPublicTemplate,
        preview,
      });
      createStandardToast("Шаблон успешно сохранен")();
      if (withExit) {
        setTimeout(() => {
          goBack();
        }, 800);
      }
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setLoad(false);
    }
  };

  const onBackHandler = () => {
    if (
      (dataTemplate &&
        hasBeenTemplateDiff(value ?? "", dataTemplate.content)) ||
      isNewTemplate
    ) {
      dispatch(
        setModalData({
          message: "Вы уверены, что хотите вернуться без сохранения шаблона?",
          link: "/templates/cards?tab=new",
          title: "Выход из редактора",
        })
      );
      dispatch(openModal("back-to-link"));
      return;
    }
    window.history.back();
  };

  const onClickViewMode = () => {
    setOpenViewer(true);
  };

  const onExitViewer = () => {
    setOpenViewer(false);
  };

  useLayoutEffect(() => {
    if (isPublicTemplate) return;
    toast(
      <p>
        Ссылка отписки будет добавлена автоматически. В тестовых письмах ссылка
        не добавляется.
      </p>,
      {
        autoClose: false,
        toastId: "text-editor-notify",
      }
    );
  }, []);

  return (
    <Wrapper>
      <HeaderEditor
        isLoad={isLoad}
        onCreate={createHandler}
        isNewTemplate={isNotSavedTemplate}
        onClickSave={editHandler}
        clickViewHandler={onClickViewMode}
        onClickBack={onBackHandler}
        onChangeTemplateName={(str) => setTemplateName(str)}
        templateName={templateName}
        templateId={+templateId}
        isMailingCreationWay={isMailingCreationWay}
      />

      <CK value={value} setValue={setValue} />

      {isOpenViewer && (
        <TemplateViewer
          onClickExit={onExitViewer}
          html={value}
          name={dataTemplate?.name}
        />
      )}
    </Wrapper>
  );
};
