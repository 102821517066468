import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "./store";

export type TemplateViewStateType = {
  data: {
    id: number | string;
    mode: "mobile" | "desktop";
    isDefaultTemplate?: boolean;
    rating?: number;
    has_scheme?: boolean;
    isMailingCreationWay?: boolean;
  } | null;
};

const initialState = {
  data: null,
} as TemplateViewStateType;

export const templateViewSlice = createSlice({
  name: "templateView",
  initialState,
  reducers: {
    setDataTemplateView(
      state,
      action: PayloadAction<TemplateViewStateType["data"]>
    ) {
      state.data = {
        mode: action.payload!.mode,
        id: action.payload!.id,
        isDefaultTemplate: action.payload?.isDefaultTemplate,
        rating: action.payload?.rating,
        has_scheme: action.payload?.has_scheme,
        isMailingCreationWay: action.payload?.isMailingCreationWay,
      };
    },
    clearTemplateView(state) {
      state.data = null;
    },
  },
});

export default templateViewSlice.reducer;

export const { clearTemplateView, setDataTemplateView } =
  templateViewSlice.actions;

export const selectTemplateViewData = (state: RootState) =>
  state.templateView.data;
