import { css } from "styled-components";

import { _variables } from "../_variables";

export const sidePanel = css`
  .panel {
    background-color: white;
    transition: width 2s ease-in;
  }
  .glass {
    background: ${_variables.blackColor};
    opacity: 0.5;
    transition: all 2s ease-in;
  }
`;
