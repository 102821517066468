import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  height: 52px;
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const ContactsHeaderRow: CT<unknown> = ({ className, children }) => {
  return (
    <Container className={className}>
      <Buttons>{children}</Buttons>
    </Container>
  );
};
