import type { Action, ThunkAction } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./appSlice";
import userReducer from "./userSlice";
import modalReducer from "./modalDataSlice";
import modalOpenReducer from "./modal";
import viewContactReducer from "./viewContantcsSlice";
import pagesReducer from "./pagesSlice";
import templateViewReducer from "./templateViewSlice";
import createMailingReducer from "./createMailingSlice";
import importReducer from "./importSlice";
import cjmReducer from "./cjm";
import packagePlanReducer from "./packagePlan";
import createFormClientReducer from "./createFormClientSlice";

export const store = configureStore({
  reducer: {
    cjm: cjmReducer,
    app: appReducer,
    user: userReducer,
    modal: modalOpenReducer,
    modalData: modalReducer,
    pagesState: pagesReducer,
    importContacts: importReducer,
    viewContact: viewContactReducer,
    templateView: templateViewReducer,
    createMailing: createMailingReducer,
    packagePlan: packagePlanReducer,
    createFormClientSlice: createFormClientReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
