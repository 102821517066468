import styled, { css } from "styled-components";

import { _variables } from "ui/styles/_variables";

import { Icon } from "../Icon";

const Container = styled.tr<{ isActive: boolean }>`
  cursor: pointer;
  & td {
    transition: background-color ${_variables.transition};
  }
  &:hover {
    & td {
      background: ${_variables.backgroundColor};
    }
  }
  ${(props) =>
    props.isActive &&
    css`
      & td {
        background: ${_variables.backgroundColor};
      }
    `}
`;

const NameWrapper = styled.td`
  padding-left: 12px;
  display: flex;
  column-gap: 16px;
  height: 100%;
  align-items: center;
`;

const Name = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: calc(100% - 80px);
  column-gap: 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;

  & span {
    display: inline-block;
    white-space: nowrap;
    width: 95%;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`;

const Text = styled.td`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const CheckedElem = styled.div<{ isActive: boolean }>`
  display: flex;
  border: 1.5px solid
    ${(props) => (props.isActive ? _variables.blackColor : "#e4e5e8")};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    background-color: ${_variables.blackColor};
    border-radius: 50%;
    left: 50%;
    display: ${(props) => (props.isActive ? "block" : "none")};
    transform: translate(-50%, -50%);
    background-color: ${_variables.blackColor};
  }
`;

export const RowContact: CT<{
  isActive: boolean;
  onClickCheck?: (id: number, name: string, count: number) => void;
  id: number;
  name: string;
  count: number;
}> = ({ onClickCheck, id, name, count, isActive }) => {
  return (
    <Container
      onClick={() => {
        onClickCheck && onClickCheck(id, name, count);
      }}
      isActive={isActive}
    >
      <NameWrapper>
        <CheckedElem
          onClick={(e) => {
            e.stopPropagation();
            onClickCheck && onClickCheck(id, name, count);
          }}
          isActive={isActive}
        />

        <Icon id={"book"} width={26} height={26} />
        <Name>
          <span>{name}</span>
        </Name>
      </NameWrapper>
      <Text>{count}</Text>
    </Container>
  );
};
