// Функция глубокого перебора с сериализацией даты
export function deepDateSerialize(obj: any): any {
  // Если obj является датой, преобразовать ее в строку
  if (obj instanceof Date) {
    return JSON.stringify(obj);
  }

  // Если obj является массивом, перебрать каждый элемент и рекурсивно вызвать функцию deepTraversal
  if (Array.isArray(obj)) {
    return obj.map(deepDateSerialize);
  }

  // Если obj является объектом, перебрать каждое свойство и рекурсивно вызвать функцию deepTraversal
  if (typeof obj === "object" && obj !== null) {
    const result: Record<string, any> = {};
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        result[key] = deepDateSerialize(obj[key]);
      }
    }
    return result;
  }
  // Если obj не является объектом, вернуть его как есть
  return obj;
}
