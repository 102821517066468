import { motion } from "framer-motion";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";

import { Icon } from "./Icon";

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999999;
  height: 100vh;
  background-color: ${`rgba(${hexToRgba(_variables.blackColor, 0.5)})`};
`;

const Content = styled(motion.div)<{ noPadding: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.noPadding ? "0px" : "36px")};
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 12px;
  background-color: white;
  transform: translate(-50%, -50%);
`;

const Header = styled.header<{ noPadding: boolean }>`
  display: flex;
  padding: ${(props) => (!props.noPadding ? "0px" : "36px")};
  padding-bottom: 0;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const Head = styled.p`
  font-size: 20px;
  font-style: normal;
  padding-right: 20px;
  font-weight: 700;
  width: 90%;
  line-height: 26px;
  letter-spacing: -0.25px;
  text-align: left;
`;
const ExitIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  opacity: 0.5;
  transition: opacity ${_variables.transition};
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  & img {
    width: 12px;
    height: 12px;
  }
`;

export const Modal: CT<{
  title?: string;
  onClose: VoidFunction;
  notContentPadding?: boolean;
  isNotShowClose: boolean;
  widthContent: string;
}> = ({
  onClose,
  isNotShowClose,
  children,
  title = "",
  notContentPadding = false,
  widthContent,
}) => {
  const Close: CT<{ isNotShowClose: boolean | undefined }> = ({
    isNotShowClose,
  }) => {
    if (!!isNotShowClose) return <></>;
    return (
      <ExitIcon onClick={onClose}>
        <Icon id={"cross-bold-icon"} width={24} height={24} />
      </ExitIcon>
    );
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: "easeIn" }}
    >
      <Content
        style={{ width: widthContent }}
        noPadding={notContentPadding}
        initial={{ transform: "translate(-50%, -100%)", opacity: 0 }}
        animate={{ transform: "translate(-50%, -50%)", opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4, ease: "easeIn" }}
      >
        <Header noPadding={notContentPadding}>
          <Head>{title}</Head>
          <Close isNotShowClose={isNotShowClose} />
        </Header>
        {children}
      </Content>
    </Container>
  );
};
