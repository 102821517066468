export const mimeTypes = [
  "text/plain",
  "text/csv",
  "text/сsv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/csv",
  "application/сsv",
];
export const maxSizeByte = 2e7; //20мб

export const importInterfaceStatusCatalog = {
  1: "not file",
  2: "load file",
  3: "success",
  4: " error",
  5: "bad mimetype",
  6: "bad size",
};

export type importInterfaceStatusTypes =
  keyof typeof importInterfaceStatusCatalog;

/**
 * 1- импорт конактов как вручную, так и файлом
 * 2- выбор полей
 */
export type stepImportInterface = 1 | 2 | 3 | 4 | 5;

// разделители полей
export const fileDelimiterCatalog = {
  1: "Табуляция",
  2: "Пробел",
  3: "Запятая",
  4: "Точка с запятой",
};

export type FilePreviewSettingUserType = {
  skipFirstRow: boolean;
  delimiter: 1 | 2 | 3 | 4;
  name: number; // номер колоноки
  email: number; // номер колонки
};

/***
 * Статусы отображаемых карточек
 * 1 - поле Name
 * 2 - поле Email
 * 3- Не импортировать
 * 4- Было изменено/нужно выбрать
 */
export type FilePreviewCardStatus = 1 | 2 | 3 | 4;

export type PreviewFileType = {
  /**
   * Соответствует показу плашек настройки
   */
  settings: {
    [key: string]: number;
  };
  columns: string[][]; // отображение колонок на шаблонизацию
  count: {
    contacts: number;
    columns: {
      ignore: number;
      import: number;
    };
  };
};
