import { appConfig } from "../appConfig";

export const helpLinks = {
  info: `https://cheapsender.notion.site/cheapsender/9d7d9c7d7a1a4228ab13f43da7b7c860`,
  help: "https://t.me/cheapsender",
  helpBot: "https://t.me/MyCheapsender_bot",
  privacyPolicy: "https://documents.timeweb.ru/files/policy/personal_data.pdf",
  spamPolicy: `https://${appConfig.domainName}/antispam-politika`,
  refInformation: `https://${appConfig.domainName}/spravochnaia-informatsiia`,
};
