import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import useUrlState from "@ahooksjs/use-url-state";

import type { MailingType } from "domain/mailing";
import { mailingService } from "services/mailing";
import { useTableStateLoad } from "ui/hooks/useTableStateLoad";

export const useShowDraftMailingCollection = () => {
  const [values, setValues] = useState<MailingType[]>([]);
  const [countPage, setCountPage] = useState(0);
  const [query] = useUrlState({
    "per-page": "",
    page: "",
    sortBy: "",
    name: "",
  });
  const [queryState, setQueryState] = useState({});
  const { setOffLoad, setOnLoad, ...ohter } = useTableStateLoad(
    values,
    query.name
  );

  const loadData = async () => {
    setOnLoad();
    try {
      const list = await mailingService.history(
        {
          ...query,
          perPage: query["per-page"],
          search: query.name,
        },
        true
      );
      setCountPage(list.headers["x-pagination-page-count"]);
      setValues(list.data.data.history);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  useEffect(() => {
    if (shallowEqual(queryState, query)) return;
    setValues([]);
    loadData();
    setQueryState(query);
  }, [query]);

  return {
    data: values,
    countPage,
    loadData,
    query,
    ...ohter,
  };
};
