import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import type {
  FilePreviewCardStatus,
  PreviewFileType,
} from "domain/fileContacts";
import { useAppSelector } from "services/hooks";
import {
  importStoreAction,
  selectImportUserSetting,
} from "services/store/importSlice";

const createStatus = (
  settings: Omit<PreviewFileType["settings"], "delimeter" | "skipFirstRow">,
  column: number
): FilePreviewCardStatus => {
  if (settings.name === column) return 1;
  if (settings.email === column) return 2;
  return 3;
};

export const useSettingFields = (data: PreviewFileType) => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState<
    {
      status: FilePreviewCardStatus;
      content: string[];
    }[]
  >([]);

  const serverSetting = data.settings;
  const userSetting = useAppSelector(selectImportUserSetting);

  const mergeSetting = {
    name: userSetting?.name ?? serverSetting?.name,
    email: userSetting?.email ?? serverSetting?.email,
  };

  useEffect(() => {
    const nameColumn = fields
      .map((item, i) => ({ ...item, i: i }))
      .find((item) => item.status === 1);
    const emailColumn = fields
      .map((item, i) => ({ ...item, i: i }))
      .find((item) => item.status === 2);

    if (nameColumn) {
      dispatch(importStoreAction.setNameSetting(nameColumn.i));
    } else {
      dispatch(importStoreAction.setNameSetting(null));
    }

    if (emailColumn) {
      dispatch(importStoreAction.setEmailSetting(emailColumn.i));
    } else {
      dispatch(importStoreAction.setNameSetting(null));
    }
  }, [fields]);

  const onChangeStatusCard = (
    status: FilePreviewCardStatus,
    column: number
  ) => {
    const newData = fields.map((item, i) => {
      const createStatus = () => {
        if (i === column) return status;
        if (item.status === status) {
          if (item.status === 3) return 3;
          return 4;
        }
        return item.status;
      };
      return {
        ...item,
        status: createStatus(),
      };
    });
    setFields(newData);
  };

  /**
   * С создание полей доступны только 3 статуса,
   * Статус "был изменен" не обрабатывается
   */
  const createFields = () => {
    setFields(
      Object.values(data.columns).map((item, i) => ({
        status: createStatus(mergeSetting, i),
        content: item,
      }))
    );
  };

  /**
   * Если пользователь сменил настроки табуляции или отмены первой строки, то
   * необходимо пересобрать полностью поля, тк сервер может предположить верные поля парсинга
   */
  useEffect(() => {
    createFields();
  }, [serverSetting]);

  return {
    serverSetting,
    createFields,
    fields,
    onChangeStatusCard,
  };
};
