import { shallowEqual } from "react-redux";
import styled from "styled-components";

import { getFormatNum } from "lib/numbers";
import { useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import { _variables } from "ui/styles/_variables";

import { Link } from "../components/Link";
import { Avatar } from "../components/Avatar";
import { Button } from "../components/Buttons/Button";
import { Icon } from "../components/Icon";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  width: 301px;
  min-height: 167px;
  background-color: white;
  border: 1px solid ${_variables.strokeColor};
  border-radius: ${_variables.borderRadius};

  & .gray {
    color: ${_variables.grayColor} !important;
  }

  & .light {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;
  }
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
`;
const Name = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;
const Id = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin: 16px 0;
  background-color: ${_variables.strokeColor};
`;

const MailBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 12px;
  justify-content: flex-start;
  row-gap: 2px;
  margin-bottom: 16px;
  position: relative;
  border-radius: 3px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 48px;
    width: 3px;
    border-radius: 3px;
    background-color: ${_variables.primaryColor};
  }
  & span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: ${_variables.grayTextColor};
  }
  & .count {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
`;
const ListItems = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  list-style: none;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.25px;
  text-align: left;
  & li {
    list-style: none;
    display: flex;
    align-items: center;
    column-gap: 12px;
    color: ${_variables.blackColor};
    transition: all ${_variables.transition};
    cursor: pointer;
    &:hover {
      color: ${_variables.primaryColor};
    }
    & a {
      display: flex;
      column-gap: 12px;
      align-items: center;
    }
  }
`;

export const UserMenu: CT<unknown> = ({ className }) => {
  const user = useAppSelector(selectUserData, shallowEqual);

  const clickLinkHandler = () => {
    setTimeout(() => {
      window.document.body.click();
    }, 50);
  };
  if (!user) return <></>;

  return (
    <Content className={className}>
      <Top>
        <Avatar
          src={user?.photo ?? ""}
          width={40}
          height={40}
          alt={user?.name ?? user?.email ?? "Пользователь"}
        />
        <Info>
          <Name>{user.email ?? ""}</Name> <Id>ID:{user.id}</Id>
        </Info>
      </Top>
      <Separator />

      <MailBlock>
        <span>Доступно писем</span>
        <p className={"count"}>{getFormatNum(user.balance)}</p>
      </MailBlock>
      <Link onClick={clickLinkHandler} link={"/packages/payment"}>
        <Button
          style={{ height: 40, marginBottom: 16 }}
          variant={"secondary"}
          widthMode={"full"}
        >
          Пополнить
        </Button>
      </Link>
      <ListItems>
        <li>
          <Link onClick={clickLinkHandler} link={"/main/history-pay"}>
            <Icon id={"clock-icon"} width={24} height={24} />
            История покупок
          </Link>
        </li>
        <li>
          <Link
            onClick={clickLinkHandler}
            link={"/main/user/setting?tab=account"}
          >
            <Icon id={"setting-icon"} width={24} height={24} />
            Настройки
          </Link>
        </li>
        <li>
          <Link
            onClick={clickLinkHandler}
            redirectMode={{ blank: false }}
            link={"/logout"}
          >
            <Icon id={"logout-icon"} width={24} height={24} />
            Выйти из аккаунта
          </Link>
        </li>
      </ListItems>
    </Content>
  );
};
