import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "services/hooks";
import {
  importStoreAction,
  selectImportMode,
  selectImportState,
  selectImportUserSetting,
} from "services/store/importSlice";
import { contactService } from "services/contact";
import type {
  FilePreviewSettingUserType,
  PreviewFileType,
} from "domain/fileContacts";
import { createToastResponseError } from "lib/toastify";

/**
 * Хук занимающийся логикой настройки полей импорта
 * настройка полей идет после загрузки файла контактов
 */

export const useSettingFile = () => {
  const [previewData, setPreviewData] = useState<{
    data: PreviewFileType;
  } | null>(null);
  const userSettings = useAppSelector(selectImportUserSetting);
  const importData = useAppSelector(selectImportState);
  const dispatch = useDispatch();
  const [isLoad, setLoad] = useState(true);
  const modeImport = useAppSelector(selectImportMode);

  const onLoadPreview = async () => {
    if (!importData.fileId || !userSettings) return;
    try {
      const response = await contactService.preview(
        importData.fileId,
        userSettings
      );
      dispatch(
        importStoreAction.setCounts({
          total: response.data.data.count.contacts,
          count: response.data.data.count.columns.import,
          ignore: response.data.data.count.columns.ignore,
        })
      );
      setPreviewData({ data: response.data.data });
      setLoad(false);
    } catch (e) {
      createToastResponseError(null, "Ошибка парсинга файла")();
      dispatch(importStoreAction.goBackToFileLoad(modeImport));
    }
  };

  const isValidData = userSettings.email !== null;
  const initialOpenField = isValidData;

  const onSetCheckSkipRow = (val: boolean) => {
    dispatch(importStoreAction.clearUserSettingFileds());
    dispatch(importStoreAction.setCheckSkipRow(val));
  };

  const onSetDelimiter = (val: FilePreviewSettingUserType["delimiter"]) => {
    dispatch(importStoreAction.clearUserSettingFileds());
    dispatch(importStoreAction.setDelimiter(val));
  };

  const onGoToNextStep = async () => {
    dispatch(importStoreAction.goToListStep());
  };

  useEffect(() => {
    if (!importData.fileId) return;
    onLoadPreview();
  }, [userSettings.delimiter, userSettings.skipFirstRow]);

  useEffect(() => {
    if (!importData) return;
    if (!importData.fileId) dispatch(importStoreAction.goToStartStep);
  }, [importData.fileId]);

  useEffect(() => {
    return () => {
      setLoad(true);
    };
  }, []);
  return {
    previewData,
    userSettings,
    isValidData,
    initialOpenField,
    isLoad,
    importData,
    actions: {
      onSetCheckSkipRow,
      onLoadPreview,
      onGoToNextStep,
      onSetDelimiter,
    },
  };
};
