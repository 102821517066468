import type { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import { shallowEqual } from "react-redux";
import { debounce } from "react-ace/lib/editorOptions";
import { useTitle } from "ahooks";
import styled from "styled-components";

import type { UserEmailDomainType } from "domain/userEmail";
import { createStandardToast, createToastResponseError } from "lib/toastify";
import { userEmailsService } from "services/userEmailsService";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { setModalData } from "services/store/modalDataSlice";
import { selectUserData } from "services/store/userSlice";
import { openModal } from "services/store/modal";
import { _variables } from "ui/styles/_variables";

import { TableHead } from "ui/components/Table/TableHead";
import { TableBody } from "ui/components/Table/TableBody";
import { Input } from "ui/components/FormsElements/Input";
import { useTableStateLoad } from "ui/hooks/useTableStateLoad";
import { LoadContent } from "ui/components/LoadContent";
import { SearchNotFound } from "ui/components/NotContent/SearchNotFound";
import { TableHeadOnceFilter } from "ui/components/Table/Filters/TableHeadOnceFilter";
import { Icon } from "ui/components/Icon";
import { TooltipHover } from "ui/components/TooltipHover";
import { BlackNotify } from "ui/components/BlackNotify";
import { Button } from "ui/components/Buttons/Button";
import { ChipDomainStatus } from "ui/components/Chips/ChipDomainStatus";

import { appConfig } from "../../../appConfig";

const Table = styled.table`
  padding-left: 40px;
  display: grid;
  grid-template-columns:
    minmax(322px, 100%) minmax(200px, 200px) 340px
    240px;
  grid-auto-rows: min-content;
  margin-top: 20px;
  position: relative;
  z-index: 25;

  & tr {
    min-height: 66px;
  }

  & tr td {
    word-break: break-all;
    border-bottom: 1px solid ${_variables.strokeColor};
    display: flex;
    min-height: 66px;
    height: inherit;
    padding: 10px 0;
    padding-right: 5px;
    align-items: center;
  }
`;

const THead = styled(TableHead)`
  & th {
    border-bottom: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    border-bottom: 1px solid ${_variables.strokeColor};
    text-align: left;
    color: ${_variables.grayTextColor};
  }
`;

const TBody = styled(TableBody)`
  margin-top: 16px;
  position: relative;
  top: -10px;

  & td {
    border-bottom: none;
  }
`;

const MainHeadingWrapper = styled.div`
  display: flex;

  justify-content: space-between;
  width: 100%;
`;
const MainHeading = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
`;
const InputSearch = styled(Input)`
  width: 308px;
`;
const HeadingLeftContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 32px;
`;

const Mailing = styled.div`
  display: flex;
  column-gap: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const IconWrap = styled.div`
  min-width: 24px;
  min-height: 24px;
`;

const Name = styled.span`
  font-size: 14px;
  color: ${_variables.grayTextColor};
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;

const IconCrossWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonReload = styled.button`
  border-radius: 8px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 1px solid ${_variables.strokeColor};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color ${_variables.transition};
  &:hover {
    background-color: ${_variables.strokeColor};
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
`;
const EmailWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
const Email = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  text-decoration: underline;
  color: ${_variables.grayTextColor};
`;
const HeadingWrapperButton = styled.div`
  column-gap: 24px;
  display: flex;
  align-items: center;
`;

export const EmailsPageHeader: CT<{
  onChangeSearch: (str: string) => void;
  value: string;
}> = ({ onChangeSearch, value }) => {
  const dispatch = useAppDispatch();
  const changeHandler = debounce((e: ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(e.target.value);
  }, 300);
  useTitle(`Подтвержденные email${appConfig.titleApp}`);
  return (
    <MainHeadingWrapper>
      <HeadingLeftContent>
        <MainHeading>Настройки аккаунта</MainHeading>
        <HeadingWrapperButton>
          <InputSearch
            value={value}
            onChange={changeHandler}
            name={"email"}
            startIcon={{
              id: "search-glass-icon",
              width: 24,
              height: 24,
            }}
            placeholder={"Поиск по email"}
          />
          <Button
            onClick={() => {
              dispatch(openModal("add-email"));
            }}
          >
            <Icon
              style={{ marginRight: 8 }}
              id={"plus-circle-icon"}
              width={24}
              height={24}
            />{" "}
            <span>Добавить</span>
          </Button>
        </HeadingWrapperButton>
      </HeadingLeftContent>
    </MainHeadingWrapper>
  );
};

const RowContent: CT<{ data: UserEmailDomainType }> = ({ data }) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUserData, shallowEqual);
  const [isLoad, setLoad] = useState(false);

  const resendEmailLinkHandler = async () => {
    setLoad(true);
    try {
      const response = await userEmailsService.resendConfirm(data.email);
      createStandardToast(response.data.message)();
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setLoad(false);
    }
  };

  const deleteHandler = () => {
    dispatch(setModalData({ email: data.email, id: data.id }));
    dispatch(openModal("delete-email"));
  };

  return (
    <tr>
      <td>
        <Mailing>
          {data.active ? (
            <TooltipHover
              offset={[-40, -55]}
              content={<BlackNotify right={150}>Email подтверждён</BlackNotify>}
            >
              <IconWrap>
                <Icon id={"email-success-icon"} width={24} height={24} />
              </IconWrap>
            </TooltipHover>
          ) : (
            <TooltipHover
              offset={[-40, -55]}
              content={
                <BlackNotify right={170}>Email не подтверждён</BlackNotify>
              }
            >
              <IconWrap>
                <Icon id={"not-active-email-icon"} width={24} height={24} />
              </IconWrap>
            </TooltipHover>
          )}

          {data.email}
        </Mailing>
      </td>
      <td>
        <Name>{data.name}</Name>
      </td>
      <td>
        <span>
          <ChipDomainStatus status={data.status} />
        </span>
      </td>
      <td>
        <EmailWrapper>
          <Email>{data.domain}</Email>
          <IconsWrapper>
            {!data.active && (
              <ButtonReload disabled={isLoad} onClick={resendEmailLinkHandler}>
                <Icon id={"double-reload-icon"} width={24} height={24} />
              </ButtonReload>
            )}

            {userData!.email.trim().toLowerCase() !==
              data.email.trim().toLowerCase() && (
              <IconCrossWrapper onClick={deleteHandler}>
                <Icon id={"cross-bold-icon"} width={24} height={24} />
              </IconCrossWrapper>
            )}
          </IconsWrapper>
        </EmailWrapper>
      </td>
    </tr>
  );
};

export const EmailPageContent: CT<{
  query: {
    perPage?: any;
    page?: any;
    name?: any;
    sortBy?: any;
  };
  onSetCountPage: (val: number) => void;
  onChangeSortBy: (val: string) => void;
}> = ({ query, onSetCountPage, onChangeSortBy }) => {
  const [data, setData] = useState<UserEmailDomainType[]>([]);
  const dispatch = useAppDispatch();
  const { setOffLoad, setOnLoad, isLoad, isNotFound } = useTableStateLoad(
    data,
    query.name
  );
  const { perPage } = query;
  const [queryState, setQueryState] = useState({});
  const [params] = useUrlState({
    action: "",
  });

  const loadData = async () => {
    setOnLoad();
    try {
      const list = await userEmailsService.listTable({
        ...query,
        perPage,
        search: query.name,
      });
      onSetCountPage(list.headers["x-pagination-page-count"]);
      setData(list.data.data.emails);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };
  useEffect(() => {
    if (shallowEqual(queryState, query)) return;
    setData([]);
    loadData();
    setQueryState(query);
    //eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    onChangeSortBy("emails");
  }, []);

  useEffect(() => {
    if (params.action === "add") {
      dispatch(openModal("add-email"));
    }
  }, []);

  if (isLoad) return <LoadContent top={120} />;

  return (
    <>
      <Table>
        <THead>
          <TableHeadOnceFilter
            name={"email"}
            onClick={onChangeSortBy}
            isActive={query.sortBy.includes("email")}
          >
            Email
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            name={"name"}
            onClick={onChangeSortBy}
            isActive={query.sortBy.includes("name")}
          >
            Имя
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            name={"active"}
            onClick={onChangeSortBy}
            isActive={query.sortBy.includes("active")}
          >
            Верификация домена
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            name={"domain"}
            onClick={onChangeSortBy}
            isActive={query.sortBy.includes("domain")}
          >
            Домен
          </TableHeadOnceFilter>
        </THead>
        <TBody>
          {data.map((item) => (
            <RowContent key={item.id + item.date_in} data={item} />
          ))}
        </TBody>
      </Table>
      {isNotFound && <SearchNotFound />}
    </>
  );
};
