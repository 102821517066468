import type { ChangeEvent } from "react";
import React from "react";
import { debounce } from "react-ace/lib/editorOptions";
import { shallowEqual } from "react-redux";
import styled from "styled-components";

import { openModal } from "services/store/modal";
import { useAppDispatch } from "services/hooks";

import { Input } from "ui/components/FormsElements/Input";
import IconFilter from "ui/assets/media/icons/filter.svg";
import _variables from "ui/styles/_variables";
import { Icon } from "ui/components/Icon";

const IMainHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const IMainHeading = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
`;
const InputSearch = styled(Input)`
  width: 308px;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
  margin-left: 8px;
  border-radius: 6px;
  background-color: ${_variables.backgroundColor};
  cursor: pointer;
  &: hover {
    background-color: ${_variables.strokeColor};
  }
`;

export const HistoryHeading: CT<{
  onChangeSearch: (str: string) => void;
  value: string;
  isFiltered: boolean;
  onResetFilters: () => void;
}> = React.memo(({ onChangeSearch, value, isFiltered, onResetFilters }) => {
  const changeHandler = debounce((e: ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(e.target.value);
  }, 300);

  const dispatch = useAppDispatch();

  return (
    <IMainHeadingWrapper>
      <IMainHeading>История рассылок</IMainHeading>
      <div style={{ width: 360, display: "flex" }}>
        <InputSearch
          value={value}
          name={"name"}
          onChange={changeHandler}
          startIcon={{
            id: "search-glass-icon",
            width: 24,
            height: 24,
          }}
          placeholder={"Найти рассылку"}
        />
        <FilterWrapper onClick={() => dispatch(openModal("mailing-filter"))}>
          <img alt="фильтр" src={IconFilter} style={{ marginRight: "4px" }} />
          {isFiltered && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onResetFilters();
              }}
            >
              <Icon id={"cross-icon"} height={20} width={20}></Icon>
            </div>
          )}
        </FilterWrapper>
      </div>
    </IMainHeadingWrapper>
  );
}, shallowEqual);
