import { useTitle } from "ahooks";
import { appConfig } from "../../../../../appConfig";
import { debounce } from "react-ace/lib/editorOptions";
import type { ChangeEvent } from "react";
import styled from "styled-components";
import { Input } from "../../../../components/FormsElements/Input";

const IMainHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const IMainHeading = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const InputSearch = styled(Input)`
  width: 308px;
`;

export const DraftHeading: CT<{
  onChangeSearch: (str: string) => void;
  value: string;
}> = ({ onChangeSearch, value }) => {
  useTitle(`Текущие черновики${appConfig.titleApp}`);
  const changeHandler = debounce((e: ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(e.target.value);
  }, 300);
  return (
    <IMainHeadingWrapper>
      <IMainHeading>Текущие черновики</IMainHeading>
      <div style={{ width: 308 }}>
        <InputSearch
          value={value}
          onChange={changeHandler}
          name={"email"}
          startIcon={{
            id: "search-glass-icon",
            width: 24,
            height: 24,
          }}
          placeholder={"Найти черновик"}
        />
      </div>
    </IMainHeadingWrapper>
  );
};
