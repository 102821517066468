import styled, { css } from "styled-components";

import { createDataForTable } from "lib/date";
import { _variables } from "ui/styles/_variables";

import { Icon } from "../Icon";
import { ListCheckedIcon } from "../ListCheckedIcon";

const Container = styled.tr<{ isActive: boolean }>`
  cursor: pointer;
  &:hover {
    & td {
      background: ${_variables.backgroundColor};
    }
  }
  ${(props) =>
    props.isActive &&
    css`
      & td {
        background: ${_variables.backgroundColor};
      }
    `}
`;

const NameWrapper = styled.td`
  padding-left: 12px;
  display: flex;
  column-gap: 16px;
  height: 100%;
  align-items: center;
`;

const Name = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  font-weight: 600;
  width: calc(100% - 80px);
  column-gap: 14px;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;

  & span {
    display: inline-block;
    white-space: nowrap;
    width: 95%;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`;

const Text = styled.td`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
  & span {
    display: inline-block;
    white-space: nowrap;
    width: 95%;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`;

const Status = styled.td`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

export const ContactTableContent: CT<{
  isActive: boolean;
  onClickCheck?: (id: number) => void;
  id: number;
  name: string;
  date: string;
  subscribe?: boolean;
  statusName?: string;
  email: string;
}> = ({ onClickCheck, statusName, id, name, date, isActive, email }) => {
  createDataForTable(date);
  return (
    <Container
      onClick={() => {
        onClickCheck && onClickCheck(id);
      }}
      isActive={isActive}
    >
      <NameWrapper>
        <ListCheckedIcon
          onClick={(e) => {
            e.stopPropagation();
            onClickCheck && onClickCheck(id);
          }}
          isActive={isActive}
        />
        <Icon id={"send-table-icon"} width={26} height={21} />
        <Name>
          <span>{email}</span>
        </Name>
      </NameWrapper>
      <Text>
        <span>{name}</span>
      </Text>
      <Text>{createDataForTable(date)}</Text>
      <Status>{statusName ?? ""}</Status>
    </Container>
  );
};
