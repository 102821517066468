import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTitle } from "ahooks";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

import { appConfig } from "../../appConfig";
import { RecoveryPasswordForm } from "../containers/forms/RecoveryPasswordForm";
import { BackgroundPreview } from "../components/BackgroudPreview";

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.25px;
  color: ${_variables.blackColor};
  text-align: center;
`;

const Text = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  line-height: 21px;
  letter-spacing: -0.25px;
  color: ${_variables.grayColor};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-bottom: 32px;
`;

export const RecoveryPasswordPage = () => {
  const params = useParams<{ token: string }>();
  const history = useHistory();
  useTitle("Восстановление пароля" + appConfig.titleApp);

  useEffect(() => {
    if (!localStorage?.getItem("email")?.length) {
      history.push("/login");
    }
    //eslint-disable-next-line
  }, []);

  return (
    <BackgroundPreview>
      <FormWrapper>
        <TextWrapper>
          <Title>Установить пароль</Title>
          <Text>Придумайте новый сложный пароль</Text>
        </TextWrapper>
        <RecoveryPasswordForm
          email={localStorage!.getItem("email") as string}
          token={params.token}
        />
      </FormWrapper>
    </BackgroundPreview>
  );
};
