import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

import { Icon } from "./Icon";

const Container = styled.div`
  display: flex;
  //position: absolute;
  //top: 50%;
  //transform: translateY(-50%);
  color: ${_variables.grayTextColor};
  cursor: pointer;
  transition: color ${_variables.transition};
  &:hover {
    color: ${_variables.blackColor};
  }
`;
export const ClearInputIcon: CT<{
  offsetRight?: number | string;
  offsetTop?: number | string;
  onClick?: VoidFunction;
}> = ({ offsetRight = 0, onClick = () => {} }) => {
  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();
        onClick();
      }}
      style={{ right: offsetRight }}
    >
      <Icon id={"cross-bold-icon"} width={24} height={25} />
    </Container>
  );
};
