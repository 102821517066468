import { Link } from "react-router-dom";
import styled from "styled-components";

import type { Idea } from "domain/idea";
import { statuses } from "domain/idea";
import { useLike } from "lib/useLike";
import { _variables } from "ui/styles/_variables";

import { Icon } from "../Icon";

const Heading = styled.h3`
  font-size: 16px;
  font-weight: 500;
`;

const Container = styled(Link)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin: 30px 0;
  color: ${_variables.blackColor};
  &:hover ${Heading} {
    color: ${_variables.primaryColor};
  }
`;

const LikeButton = styled.button<{ isLiked: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 16px;
  margin-right: 16px;
  background-color: ${_variables.backgroundColor};
  border: 1px solid
    ${({ isLiked }) =>
      isLiked ? _variables.primaryColor : _variables.backgroundColor};
  border-radius: 8px;
  color: ${({ isLiked }) =>
    isLiked ? _variables.primaryColor : _variables.blackColor};
  font-size: 14px;
  &:hover {
    border: 1px solid ${_variables.primaryColor};
    color: ${_variables.primaryColor};
  }
`;

const InfoContainer = styled.div`
  padding: 0 8px;
`;

const Text = styled.p`
  padding: 8px 0;
  font-size: 14px;
  color: ${_variables.grayColor};
  & svg {
    margin-right: 4px;
  }
`;

const AdditionalInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    margin: 0 8px;
  }
`;

const TagName = styled.p`
  background-color: ${_variables.backgroundColor};
  border-radius: 4px;
  padding: 4px 8px;
  color: ${_variables.grayColor};
`;

export const IdeaItem = ({
  id,
  likeCount,
  canLike,
  theme,
  content,
  userName,
  tags,
  status,
  commentCount,
}: Idea) => {
  const { isLiked, currentLikeCount, onLike } = useLike({
    isLikedInitial: !canLike,
    initialLikeCount: likeCount,
  });

  return (
    <Container to={`/ideas/${id}`}>
      <LikeButton
        isLiked={isLiked}
        onClick={(e) => {
          e.preventDefault();
          onLike(id);
        }}
      >
        <Icon id="arrow-up-icon" width={16} height={8} />
        <span>{currentLikeCount}</span>
      </LikeButton>
      <InfoContainer>
        <Heading>{theme}</Heading>
        <Text>{content}</Text>
        <AdditionalInfo>
          <Text>
            <Icon id="comment-icon" width={16} height={16} />
            {commentCount}
          </Text>
          {tags.map((tag) => (
            <TagName key={tag.id}>{tag.name}</TagName>
          ))}
          <Text>
            <Icon id={statuses[status].iconId!} />
            {statuses[status].name}
          </Text>
          <Text>{userName}</Text>
        </AdditionalInfo>
      </InfoContainer>
    </Container>
  );
};
