import axios from "axios";

import type { UserEmailDomainType } from "domain/userEmail";
import type { ServiceListPropTypes } from "domain/api";
import type { DKIMType, DomainType } from "domain/domain";

export const userEmailsService = {
  path: "user-email/",

  isNewDomain(domain: string) {
    // domain - yandex.ru, blabla.com
    return axios.get<ApiResponseBodyType<{ result: boolean }>>(
      `${this.path}domain-is-new?domain=${domain}`
    );
  },

  listTable({
    perPage,
    page,
    sortBy = "",
    search = "",
  }: ServiceListPropTypes<{ search?: string }>) {
    return axios.get<ApiResponseBodyType<{ emails: UserEmailDomainType[] }>>(
      `${this.path}list?page=${page}&per-page=${perPage}&sort=${sortBy}&UserEmailSearch[email]=${search}`
    );
  },

  list(email: string) {
    return axios.get<ApiResponseBodyType<{ emails: UserEmailDomainType[] }>>(
      `${this.path}list?UserEmailSearch[email]=${email}`
    );
  },
  async listAll() {
    const data = await this.listTable({ perPage: 25, sortBy: "-emails" });
    const maxPages = Number(data.headers["x-pagination-page-count"]);
    const array = new Array(maxPages).fill(0);
    const allData = await Promise.all(
      array.map((_, i) =>
        this.listTable({ page: i + 1, perPage: 25, sortBy: "-emails" })
      )
    )
      .then((result) => result.map((item) => item.data.data.emails))
      .catch(() => []);
    return allData.flat(Infinity) as UserEmailDomainType[];
  },
  async create(name: string, email: string) {
    const body = new FormData();
    body.append("UserEmailCreateForm[name]", name);
    body.append("UserEmailCreateForm[email]", email);
    return axios.post<ApiResponseBodyType<unknown>>(`${this.path}create`, body);
  },
  delete(id: number) {
    return axios.get<ApiResponseBodyType<unknown>>(
      `${this.path}delete?id=${id}`
    );
  },
  domainsList({
    perPage,
    page,
    sortBy = "",
    search = "",
  }: ServiceListPropTypes<{ search?: string }>) {
    return axios.get<ApiResponseBodyType<{ domains: DomainType[] }>>(
      `${this.path}domains?page=${page}&per-page=${perPage}&sort=${sortBy}&DomainSearch[domain]=${search}`
    );
  },
  resendConfirm(email: string) {
    return axios.get<ApiResponseBodyType<unknown>>(
      `${this.path}resend-confirm?ResendUserEmailConfirmForm[email]=${email}`
    );
  },
  domainInfo(domain: string) {
    return axios.get<ApiResponseBodyType<DKIMType>>(
      `${this.path}domain-info?domain=${domain}`
    );
  },
};
