import { useEffect, useState } from "react";
import ru from "date-fns/locale/ru";
import { differenceInCalendarMonths, isToday } from "date-fns";
import DatePickerLib from "react-datepicker";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";

import { DatePickerDay } from "./DatePickerDay";
import { DatePickerHeader } from "./DatePickerHeader";

const ContainerStyle = styled.div`
  width: 336px;

  & .react-datepicker__month-container {
    width: 100%;
    border: 1px solid ${_variables.strokeColor};
    border-radius: 12px;
    overflow: hidden;
    background-color: white;
  }

  & .react-datepicker__day-names {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 12px;
  }

  & .react-datepicker__day-name {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    width: calc(100% / 7);
    letter-spacing: -0.25px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  & .react-datepicker__header {
    background-color: white;
    border-bottom: none;
    padding: 0;
  }

  & .react-datepicker__month {
    margin: 0;
    padding: 0;
  }

  & .react-datepicker__month {
    width: 100%;
  }

  & .react-datepicker__day {
    width: calc(100% / 7);
    height: 48px;
    min-width: 48px;
    padding: 0;
    margin: 0;
    border-radius: ${_variables.borderRadius};
    transition: all ${_variables.transition};

    &:hover {
      border-radius: ${_variables.borderRadius};
      background-color: rgba(${hexToRgba(_variables.primaryColor, 0.1)});
    }
  }

  & .react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: ${_variables.blackColor};
  }

  & .react-datepicker__day--today {
    border-radius: ${_variables.borderRadius} !important;
    background-color: rgba(${hexToRgba(_variables.primaryColor, 0.1)});

    & span {
      color: ${_variables.primaryColor} !important;
    }
  }

  & .react-datepicker__day--selected {
    background-color: ${_variables.primaryColor};
    border-radius: ${_variables.borderRadius};

    & span {
      color: ${_variables.whiteColor} !important;
    }

    &:hover {
      background-color: ${_variables.primaryColor} !important;

      & span {
        color: ${_variables.whiteColor} !important;
      }
    }
  }
`;

const HeaderStyle = styled(DatePickerHeader)`
  margin-bottom: 20px;
`;
const Wrapper = styled.div``;

const Container: CT<unknown> = ({ children }) => {
  return <ContainerStyle>{children}</ContainerStyle>;
};

export const DatePickerCreateMailing: CT<{
  datePick: Date | null;
  onPickDate: (date: Date | null) => void;
  isMinDayNow?: boolean;
}> = ({ onPickDate, datePick, className, isMinDayNow = true }) => {
  // Визуальная дата, нужна для обработки месяца на календаре
  const [currentDateView, setCurrentDate] = useState(datePick);
  //Выбранная пользователем дата
  const [selectDate, setSelectDate] = useState<Date | null>(datePick!);

  const onSelectDate = (date: Date) => {
    setCurrentDate(date);
    setSelectDate(date);
    onPickDate(date);
  };

  useEffect(() => {
    setSelectDate(datePick);
    setCurrentDate(datePick!);
  }, [datePick]);

  return (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();
      }}
      onChange={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();
      }}
      onBlur={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();
      }}
    >
      <DatePickerLib
        minDate={isMinDayNow ? new Date() : undefined}
        className={className}
        renderCustomHeader={({ increaseMonth, decreaseMonth, monthDate }) => (
          <HeaderStyle
            onClickTodayDate={() => {
              onSelectDate(new Date("1997"));
              setTimeout(() => {
                onSelectDate(new Date());
              }, 50);
            }}
            nextMonthHandler={increaseMonth}
            prevMonthHandler={decreaseMonth}
            month={monthDate}
          />
        )}
        renderDayContents={(dayOfMonth, date) => (
          <DatePickerDay
            isPrevMonth={
              differenceInCalendarMonths(currentDateView!, date!) !== 0
            }
            isToday={isToday(date!)}
          >
            {dayOfMonth}
          </DatePickerDay>
        )}
        shouldCloseOnSelect={false}
        onClickOutside={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopPropagation();
        }}
        calendarContainer={Container}
        locale={ru}
        focusSelectedMonth={true}
        onMonthChange={(date) => {
          setCurrentDate(date);
        }}
        selected={selectDate}
        inline
        onChange={(date) => {
          onSelectDate(date!);
        }}
      />
    </Wrapper>
  );
};
