import styled from "styled-components";

import { getFormatNum } from "../../../lib/numbers";

import { _variables } from "../../styles/_variables";
import { counterShowStyle } from "./styles";
import type { StatisticBlockProps } from "./types";

import type { ServiceIconComponentPropsType } from "../Icons/StatisticIcon";
import { StatisticIcon } from "../Icons/StatisticIcon";
import { ArrowsIcon } from "../Icons/ArrowsIcon";

const IContainer = styled.div<{ haveCount: boolean; invertedCount: boolean }>`
  display: flex;
  padding: 8px;
  position: relative;
  border-radius: 12px;
  width: 164px;
  height: 64px;
  background-color: ${_variables.backgroundColor};
  column-gap: 12px;
  cursor: ${(props) => (props.haveCount ? "pointer" : "default")};
  transition: all ${_variables.transition};

  ${(props) => {
    return counterShowStyle(props.invertedCount);
  }}
`;

const IImage = styled.div`
  width: 24px;
  height: 24px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 100%;
    height: 100%;
  }
`;

const IBox = styled.div`
  display: flex;
  column-gap: 11px;
`;

const ITextContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ITitle = styled.span`
  color: ${_variables.grayTextColor};
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const IPercent = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const ArrowDecoration = styled(ArrowsIcon)`
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: #777991;
`;

type variantType =
  | "success"
  | "mail"
  | "eye"
  | "click"
  | "unsubscribe"
  | "spam";

const iconConfiguration: Pick<
  ServiceIconComponentPropsType,
  "width" | "height" | "variant"
> = {
  width: 32,
  height: 32,
  variant: "glyph",
};
const icons = {
  success: <StatisticIcon {...iconConfiguration} name={"send"} />,
  mail: <StatisticIcon {...iconConfiguration} name={"delivered"} />,
  eye: <StatisticIcon {...iconConfiguration} name={"eye"} />,
  click: <StatisticIcon {...iconConfiguration} name={"transition"} />,
  spam: <StatisticIcon {...iconConfiguration} name={"spam"} />,
  unsubscribe: <StatisticIcon {...iconConfiguration} name={"unsubscribe"} />,
};

const subHeadings = {
  success: "Отправлено",
  mail: "Доставлено",
  eye: "Открыто",
  click: "Клики",
  spam: "Жалобы на спам",
  unsubscribe: "Отписки",
};

export const StatisticBlockCount: CT<
  StatisticBlockProps<{
    variant: variantType;
  }>
> = ({ variant, invertedCount = false, category, onClick, percent, count }) => {
  return (
    <IContainer
      invertedCount={invertedCount}
      haveCount={!!count}
      onClick={() => {
        if (count === 0) return;
        onClick(category);
      }}
    >
      <IBox>
        <IImage>{icons[variant]}</IImage>
        <ITextContent>
          <ITitle>{subHeadings[variant]}</ITitle>
          <IPercent className={"statistic__percent"}>{percent}%</IPercent>
          <IPercent className={"statistic__count"}>
            {getFormatNum(count)}
          </IPercent>
        </ITextContent>
        <ArrowDecoration
          width={16}
          height={16}
          variant={"tail"}
          name={"to-top-right"}
        />
      </IBox>
    </IContainer>
  );
};
