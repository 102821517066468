import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectModalData, setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";
import { _variables } from "ui/styles/_variables";

import { Button } from "../../components/Buttons/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 432px;
`;

const Text = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  opacity: 0.5;
  color: ${_variables.blackColor};
`;
const Buttons = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const RegistrationScenarioUser: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector(selectModalData);
  return (
    <Container>
      <Text>{modalData?.message ?? ""}</Text>
      <Buttons>
        <Button
          onClick={() => {
            onClose();
            setTimeout(() => {
              dispatch(
                setModalData({
                  isNotShowClose: true,
                  id: modalData?.id,
                })
              );
              dispatch(openModal("domain-setting"));
            }, 250);
          }}
        >
          Спасибо
        </Button>
      </Buttons>
    </Container>
  );
};
