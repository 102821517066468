import React, { useEffect, useRef, useState } from "react";
import { useClickAway, useFocusWithin } from "ahooks";
import { debounce } from "lodash";
import styled from "styled-components";

import { contactsListService } from "services/list";

import { Input } from "../../components/FormsElements/Input";
import { SelectBox } from "../../components/SelectBox";
import { Button } from "ui/components/Buttons/Button";
import { useAppDispatch } from "services/hooks";
import { openModal } from "services/store/modal";
import useUrlState from "@ahooksjs/use-url-state";

const Container = styled.div`
  display: flex;
  column-gap: 12px;
`;
const Wrapper = styled.div`
  position: relative;
  z-index: 11;
`;
const Select = styled(SelectBox)`
  position: absolute;
  top: calc(100% + 10px);
  width: 100%;
  background-color: white;
`;

export const InputUserContactList: CT<
  Omit<React.ComponentProps<typeof Input>, "onChange" | "onBlur"> & {
    onChange: (
      val: React.ComponentProps<typeof SelectBox>["values"][number]
    ) => void;
    onBlur: (
      val: React.ComponentProps<typeof SelectBox>["values"][number]
    ) => void;
    initialData: {
      name: string;
      id: number;
      count: number;
    };
    isShowButtonAdd?: boolean;
    color?: "white" | "standard";
  }
> = ({
  onChange,
  initialData,
  onBlur,
  color = "standard",
  isShowButtonAdd,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const [chooseValue, setChooseValue] = useState<
    React.ComponentProps<typeof SelectBox>["values"][number]
  >({
    id: initialData.id,
    name: initialData.name,
    data: {
      count: initialData.count,
    },
  });
  const [value, setValue] = useState(initialData.name);
  const [showSelect, setShowSelect] = useState(false);
  const wrapperRef = useRef(null);
  const [values, setValues] = useState<
    React.ComponentProps<typeof SelectBox>["values"]
  >([]);
  const [urlState] = useUrlState();
  const changeHandler = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
    300
  );

  const clickSelectHandler = (
    data: React.ComponentProps<typeof SelectBox>["values"][number]
  ) => {
    setChooseValue(values.find((item) => item.id == data.id)!);
    setValue(data.name);
    setShowSelect(false);
  };

  const pasteByUrlHandler = (
    data: React.ComponentProps<typeof SelectBox>["values"][number]
  ) => {
    setChooseValue(data);
    setValue(data.name);
    setShowSelect(false);
  };

  const load = async () => {
    try {
      const response = await contactsListService.list({ name: value });
      setValues(
        response.data.data.books.map((item) => ({
          name: item.name,
          id: item.id,
          data: {
            count: item.count,
          },
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  const clickAddHandler = () => {
    dispatch(openModal("create-list"));
  };

  useClickAway(() => {
    setTimeout(() => {
      setValue(chooseValue!.name);
      setShowSelect(false);
      onBlur(chooseValue);
    }, 100);
  }, wrapperRef);

  useFocusWithin(wrapperRef, {
    onFocus: () => {
      setShowSelect(true);
    },
  });

  useEffect(() => {
    if (!props.value) return;
    setValue(props.value!);
  }, [props.value]);

  useEffect(() => {
    if (!urlState?.createdListId) return;
    if (urlState?.id === chooseValue.id) return;

    setTimeout(() => {
      pasteByUrlHandler({
        id: urlState?.createdListId,
        data: { count: 0 },
        name: urlState?.createdListName,
      });
    }, 50);
  }, [urlState?.createdListId]);

  useEffect(() => {
    onChange(chooseValue);
  }, [chooseValue]);

  useEffect(() => {
    load();
  }, [value]);

  return (
    <Container>
      <Wrapper className={"selectWrapper"} ref={wrapperRef}>
        <Input
          color={"white"}
          autoComplete={"off"}
          {...props}
          value={value}
          onChange={changeHandler}
        />
        {showSelect && (
          <Select onClickElement={clickSelectHandler} values={values} />
        )}
      </Wrapper>
      {isShowButtonAdd && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            clickAddHandler();
          }}
        >
          Создать новый
        </Button>
      )}
    </Container>
  );
};
