const statusCatalog = {
  1: "Проверка",
  2: "Включен в отправку",
  3: "Ошибка",
};

/**
 * Эмейлы, что добавляет пользователь для рассылки
 */
export type UserEmailDomainType = {
  id: id;
  name: nameType;
  email: email;
  date_in: date_in;
  domain: domain;
  status: keyof typeof statusCatalog;
  active: boolean;
};

export type PublicDomainsType = string[];

export const userEmailIsSuccess = (status: UserEmailDomainType["status"]) =>
  status === 2;
export const userEmailInProgress = (status: UserEmailDomainType["status"]) =>
  status === 1;
export const userEmailInError = (status: UserEmailDomainType["status"]) =>
  status === 3;

/**
 *  Если статус активный -> значит пользователь потвердил его по почте
 */
export const userEmailIsActive = (active: UserEmailDomainType["active"]) =>
  active;

export const checkDomainPublic = (
  email: string,
  publicDomains: PublicDomainsType
): boolean => {
  const domain = email?.split("@");
  if (domain && domain.length > 1) {
    return !publicDomains.includes(domain[domain.length - 1]);
  }
  return false;
};
