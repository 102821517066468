import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

import { Icon } from "../Icon";

const Crumb = styled.div`
  display: flex;
  column-gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  &.black {
    color: ${_variables.blackColor};
  }

  &.gray {
    color: ${_variables.grayTextColor};
  }
  align-items: center;
`;
const Crumbs = styled.div`
  display: flex;
  row-gap: 8px;
`;
const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  border-radius: 50%;
  text-align: center;
  color: white;

  &.black {
    background-color: ${_variables.blackColor};
  }

  &.gray {
    background-color: ${_variables.grayTextColor};
  }
`;

const Separator = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 8px;
  align-items: center;
  justify-content: center;
  &.black {
    & svg {
      color: ${_variables.blackColor};
    }
  }

  &.gray {
    & svg {
      color: ${_variables.grayTextColor};
    }
  }
`;

export const CrumbsHorizontal: CT<{
  values: { title: string; isActive: boolean }[];
}> = ({ values }) => {
  return (
    <Crumbs>
      {values.map((item, i) => {
        return (
          <>
            <Crumb className={item.isActive ? "black" : "gray"}>
              <Number className={item.isActive ? "black" : "gray"}>
                {i + 1}
              </Number>
              <span>{item.title}</span>
            </Crumb>
            {i + 1 !== values.length && (
              <Separator className={values[i + 1].isActive ? "black" : "gray"}>
                <Icon id={"arrow-right-color-icon"} width={24} height={24} />
              </Separator>
            )}
          </>
        );
      })}
    </Crumbs>
  );
};
