import { useEffect, useMemo, useState } from "react";
import Masonry from "react-responsive-masonry";
import styled from "styled-components";

import type { TemplateCategory, TemplateType } from "domain/templates";
import { templatesService } from "services/templates";
import { SelectCustom } from "ui/components/FormsElements/SelectCustom";

import { LoadContent } from "../../components/LoadContent";
import { useTableStateLoad } from "../../hooks/useTableStateLoad";
import { useTableWorkspace } from "../../hooks/useTableWorkspace";
import { TemplateDefaultItem } from "../../components/TemplateCards/TemplateDefaultItem";
import { NotContentDefaultTemplatePage } from "../../components/NotContent/NotContentDefaultTemplatePage";

const sortTypes = {
  popular: {
    value: "-rating",
    name: "Популярное",
    isDefaultChecked: true,
  },
  new: {
    value: "-is_new",
    name: "Новые",
  },
};

const MasonryWrapper = styled.div`
  .masonry {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    align-items: space-between;
  }

  .masonry > div {
    flex: 0 1 auto !important;
    width: 18% !important;
  }
`;

const NoContent = styled(NotContentDefaultTemplatePage)`
  margin-top: 120px;
`;
const Load = styled(LoadContent)`
  margin-top: 120px;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;

  & .selectCategory {
    width: 30%;
  }
`;

export const DefaultTemplatesContent: CT<{
  setShowAbsoluteButton: (isShow: boolean) => void;
  onSetCountPage: (val: number) => void;
}> = ({ setShowAbsoluteButton, onSetCountPage }) => {
  const [categories, setCategories] = useState<TemplateCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>(sortTypes.popular.value);

  const [data, setData] = useState<TemplateType[]>([]);
  const { isLoad, setOffLoad, setOnLoad } = useTableStateLoad(data);
  const { query } = useTableWorkspace({
    perPage: 2,
    page: 1,
    name: "",
  });

  const loadData = async () => {
    setData([]);
    setOnLoad();
    try {
      const list = await templatesService.userPublicTemplates({
        ...query,
        category: selectedCategories,
        sortBy,
        perPage: query["per-page"],
      });

      const categoriesResponse = await templatesService.categories();
      setCategories(categoriesResponse.data.data["template-categories"]);

      onSetCountPage(list.headers["x-pagination-page-count"]);
      setData(list.data.data.templates);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  useEffect(() => {
    loadData();
  }, [query, selectedCategories, sortBy]);

  useEffect(() => {
    if (data.length > 0) {
      setShowAbsoluteButton(true);
      return;
    }
    setShowAbsoluteButton(false);
  }, [data]);

  const Content = useMemo(() => {
    if (!isLoad && data.length === 0) return <NoContent />;

    if (isLoad) return <Load top={120} />;

    return (
      <MasonryWrapper>
        <Masonry columnsCount={5} gutter="40px 0" className="masonry">
          {data.map((item) => {
            return (
              <TemplateDefaultItem
                key={item.name}
                data={item}
                src={item.preview}
              />
            );
          })}
        </Masonry>
      </MasonryWrapper>
    );
  }, [isLoad, data]);

  return (
    <>
      <Toolbar>
        <SelectCustom
          className="selectCategory"
          values={[
            { name: "Все", value: "", isDefaultChecked: true },
            ...categories.map(({ name, id }) => ({ name, value: id })),
          ]}
          onChange={({ value }) => {
            setSelectedCategories(value as string);
          }}
        />
        <SelectCustom
          values={Object.values(sortTypes)}
          onChange={(sort) => {
            setSortBy(sort.value as string);
          }}
        />
      </Toolbar>
      {Content}
    </>
  );
};
