import { useTitle } from "ahooks";
import styled from "styled-components";
import { debounce } from "react-ace/lib/editorOptions";
import FileSaver from "file-saver";
import type { ChangeEvent } from "react";

import { useBlockTimeout } from "app/useBlockTimeout";
import { mailingService } from "services/mailing";
import { createToastResponseError } from "lib/toastify";

import { Input } from "ui/components/FormsElements/Input";
import { Button } from "ui/components/Buttons/Button";
import { ActionIcon } from "ui/components/Icons/ActionIcon";

import { appConfig } from "../../../../../appConfig";

const IMainHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const IMainHeading = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
`;

const InputSearch = styled(Input)`
  width: 308px;
`;

const IHeadingLeftContent = styled.div`
  display: flex;
  column-gap: 32px;
`;

const ButtonDownload = styled(Button)`
  display: flex;
  column-gap: 12px;
  & svg {
    width: 24px;
    height: 24px;
  }
`;

export const UnsubscribeHeading: CT<{
  onChangeSearch: (str: string) => void;
  value: string;
}> = ({ onChangeSearch, value }) => {
  useTitle(`Отписки${appConfig.titleApp}`);

  const { timeout, setTimer } = useBlockTimeout();

  const changeHandler = debounce((e: ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(e.target.value);
  }, 300);

  const loadFileHandler = async () => {
    try {
      setTimer(3);
      const response = await mailingService.getUnsubscribeCSV();
      let blob = new Blob([response.data]);
      FileSaver.saveAs(blob, `Список отписавшихся.csv`);
    } catch (e) {
      createToastResponseError(null, "Ошибка создания отчета")();
    }
  };

  return (
    <IMainHeadingWrapper>
      <IHeadingLeftContent>
        <IMainHeading>Текущие отписки</IMainHeading>
        <div style={{ width: 308 }}>
          <InputSearch
            value={value}
            onChange={changeHandler}
            name={"email"}
            startIcon={{
              id: "search-glass-icon",
              width: 24,
              height: 24,
            }}
            placeholder={"Поиск по email"}
          />
        </div>
      </IHeadingLeftContent>
      <ButtonDownload disabled={timeout > 0} onClick={loadFileHandler}>
        <ActionIcon variant={"download"} name={"arrow-bottom"} />
        Скачать отчет
      </ButtonDownload>
    </IMainHeadingWrapper>
  );
};
