import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "./store";

type FirstStateType = {
  theme: string;
  address: string;
  name: string;
  isValid?: boolean;
};
type SecondStateType = {
  listId: number;
  name: string;
  count: number;
};
type ParamsType = {
  isNow: boolean;
  date: string;
  time: string;
  timeZone: string;
};

export type TemplateViewStateType = {
  data: {
    templateStep?: number;
    firstStep?: FirstStateType;
    secondStep?: SecondStateType;
    params?: ParamsType;
    isReadyPushMail: boolean;
    isChooseMailing: boolean;
  } | null;
  isOpenAutoMailing: boolean;
  isOpenDeferredMailing: boolean;
};

const initialState = {
  data: null,
  isOpenAutoMailing: false,
  isOpenDeferredMailing: false,
} as TemplateViewStateType;

export const createMailingSlice = createSlice({
  name: "templateView",
  initialState,
  reducers: {
    clearTemplateMailingData(state) {
      state.data = null;
    },

    setTemplateMailingData(
      state,
      action: PayloadAction<{
        templateStep: number;
        firstStep: FirstStateType;
        secondStep: SecondStateType;
        params?: ParamsType;
      }>
    ) {
      state.data = {
        ...action.payload,
        isReadyPushMail: false,
        isChooseMailing: false,
      };
    },
    setTemplateMailing(state, action: PayloadAction<number>) {
      state.data = {
        ...state.data,
        isReadyPushMail: false,
        templateStep: action.payload,
        isChooseMailing: false,
      };
    },
    setFirstStepData(state, action: PayloadAction<FirstStateType>) {
      state.data = {
        ...state.data,
        isReadyPushMail: false,
        firstStep: action.payload,
        isChooseMailing: false,
      };
    },
    setSecondStepData(state, action: PayloadAction<SecondStateType>) {
      state.data = {
        ...state.data,
        isReadyPushMail: false,
        secondStep: action.payload,
        isChooseMailing: true,
      };
    },
    setMailingParams(state, action: PayloadAction<ParamsType>) {
      state.data = {
        ...state.data,
        isReadyPushMail: false,
        params: action.payload,
        isChooseMailing: false,
      };
    },
    setReadyPushMail(state) {
      state.data = {
        ...state.data,
        isReadyPushMail: true,
        isChooseMailing: false,
      };
    },
    setChooseMailing(state, action: PayloadAction<boolean>) {
      state.data = {
        ...state.data,
        isReadyPushMail: false,
        isChooseMailing: action.payload,
      };
    },
    setOpenAutoMailing(state, action: PayloadAction<boolean>) {
      state.isOpenAutoMailing = action.payload;
    },
    setOpenDeferredMailing(state, action: PayloadAction<boolean>) {
      state.isOpenDeferredMailing = action.payload;
    },
    clearReadyPushMail(state) {
      state.data = {
        ...state.data,
        isReadyPushMail: false,
        isChooseMailing: false,
      };
    },
  },
});

export default createMailingSlice.reducer;

export const {
  setFirstStepData,
  setSecondStepData,
  setTemplateMailing,
  setTemplateMailingData,
  clearTemplateMailingData,
  setMailingParams,
  setReadyPushMail,
  clearReadyPushMail,
  setChooseMailing,
  setOpenAutoMailing,
  setOpenDeferredMailing,
} = createMailingSlice.actions;

export const selectCreateMailingData = (
  state: RootState
): TemplateViewStateType => state.createMailing;

export const selectIsOpenAutoMailing = (state: RootState) =>
  state.createMailing.isOpenAutoMailing;
export const selectIsOpenDefferedMailing = (state: RootState) =>
  state.createMailing.isOpenDeferredMailing;
