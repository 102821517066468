import { css } from "styled-components";

import { _variables } from "ui/styles/_variables";

const wrapperStyle = css`
  & p:first-child {
    margin-block-start: 0;
  }
  & p:last-child {
    margin-block-end: 0;
  }
`;

const marginBlockMode = css`
  & p,
  ul,
  ol,
  table {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
`;

const textStyle = css`
  font-size: ${_variables.newsPrimaryFontSize};
  font-weight: 500;
  line-height: 1.625;
  letter-spacing: 0;
  color: ${_variables.newsTextPrimaryColor};

  & a {
    color: ${_variables.primaryColor};
  }

  & b,
  strong {
    font-weight: 700;
  }
  & i,
  em {
    font-style: italic;
  }
`;

const listStyle = css`
  & ul {
    & li {
      list-style: disc;
      list-style-position: inherit;
      margin-left: 1em;
    }
  }
  & ol {
    & li {
      list-style: auto;
      list-style-position: inherit;
      margin-left: 1.1em;
    }
  }
`;

const headingStyle = css`
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4em;
    color: ${_variables.newsHeadingPrimaryColor};
  }
  & h1 {
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 16px;
  }
  & h2 {
    font-size: 1.325em;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 16px;
  }
  & h3 {
    font-size: 1.15em;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 16px;
  }
  & h4 {
    font-size: 1em;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 16px;
  }
  & h5 {
    font-size: 0.8em;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 16px;
  }
  & h6 {
    font-size: 0.5em;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 16px;
  }
`;

const tableStyle = css`
  & table {
    border: 1px solid ${_variables.grayColor};
  }
  & tr {
    border: 1px solid ${_variables.grayColor};
  }
  & td {
    border: 1px solid ${_variables.grayColor};
  }
`;

const relationshipElemStyle = css``;

const imageStyle = css`
  & img {
    width: 100%;
    object-fit: cover;
  }
`;

export const styleNewsContent = css`
  ${wrapperStyle}
  ${textStyle}
  ${listStyle}
  ${headingStyle}
  ${marginBlockMode}
  ${tableStyle}
  ${imageStyle}
  ${relationshipElemStyle}
`;
