import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import useUrlState from "@ahooksjs/use-url-state";

import type { UserPayCardDataType } from "domain/user";
import { serviceUser } from "services/user";

import { useTableStateLoad } from "ui/hooks/useTableStateLoad";

export const useUserCardCollection = () => {
  const [values, setValues] = useState<UserPayCardDataType[]>([]);
  const [countPage, setCountPage] = useState(0);
  const [query] = useUrlState({
    "per-page": "25",
    page: "",
    sortBy: "",
  });
  const [queryState, setQueryState] = useState({});
  const { setOffLoad, setOnLoad, ...tableState } = useTableStateLoad(values);

  const loadData = async () => {
    setOnLoad();
    try {
      const list = await serviceUser.getUserCardsCollection({
        ...query,
        perPage: query["per-page"],
      });

      setCountPage(list.headers["x-pagination-page-count"]);
      setValues(list.data.data["user-payment-cards"]);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (shallowEqual(queryState, query)) return;
    setValues([]);
    loadData();
    setQueryState(query);
  }, [query]);

  return {
    data: values,
    countPage,
    loadData,
    query,
    ...tableState,
  };
};
