import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled, { css } from "styled-components";

import type { Idea } from "domain/idea";
import { statuses } from "domain/idea";
import { createDateForIdea } from "lib/date";
import { useLike } from "lib/useLike";
import { serviceIdea } from "services/idea";
import { mxm } from "ui/styles/_mixin";
import { _variables } from "ui/styles/_variables";

import { LoadContent } from "../../../components/LoadContent";
import { Icon } from "../../../components/Icon";
import { IdeaComments } from "./IdeaComments";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  overflow: scroll;
`;

const Back = styled(Link)`
  display: flex;
  align-items: center;
  color: ${_variables.grayColor};
  & svg {
    margin-right: 4px;
  }
  &:hover {
    color: ${_variables.primaryColor};
  }
`;

const Heading = styled.h1`
  color: ${_variables.blackColor};
  margin-top: 16px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
`;

const SubHeader = styled.div`
  display: flex;
  font-size: 15px;
  color: ${_variables.grayColor};
  & > * {
    margin-right: 4px;
  }
`;

const LikeButton = styled.button`
  background: none;
  border: none;
  color: ${_variables.primaryColor};
`;

const AdvancedInfo = styled.div`
  margin: 16px 0;
  width: 40%;
  background-color: ${_variables.backgroundColor};
  padding: 20px;
  border-radius: 8px;
  ${mxm(
    1300,
    css`
      width: 60%;
    `
  )}
`;

const Message = styled.p`
  font-size: 15px;
`;

const DateAndAuthor = styled.p`
  display: flex;
  padding-top: 16px;
  & span {
    margin: 0 4px;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  width: 40%;
  ${mxm(
    1300,
    css`
      width: 60%;
    `
  )}
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  background-color: ${_variables.backgroundColor};
  margin: 0 4px;
  padding: 4px 16px;
  border-radius: 8px;
  font-size: 14px;
`;

const CommentsWrapper = styled.div`
  width: 40%;
  ${mxm(
    1300,
    css`
      width: 60%;
    `
  )}
`;

const IdeaPage = () => {
  const [idea, setIdea] = useState<Idea>();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    serviceIdea.get(id).then((response) => {
      setIdea(response.data.data.idea);
    });
  }, [id]);

  const { isLiked, currentLikeCount, onLike } = useLike({
    isLikedInitial: !idea?.canLike,
    initialLikeCount: idea?.likeCount ?? 0,
  });

  return (
    <Content>
      <Back to="/ideas">
        <Icon id="arrow-left-back" height={16} />
        Назад
      </Back>
      {idea ? (
        <>
          <Heading>{idea.theme}</Heading>
          <SubHeader>
            <p>{statuses[idea.status].name}</p>
            <span>•</span>
            <p>{currentLikeCount} голосов</p>
            <span>•</span>
            <LikeButton
              onClick={(e) => {
                e.preventDefault();
                onLike(idea?.id);
              }}
            >
              {isLiked ? "Убрать голос" : "Проголосовать"}
            </LikeButton>
          </SubHeader>
          <AdvancedInfo>
            <Message>{idea.content}</Message>
            <DateAndAuthor>
              <p>{createDateForIdea(idea.dateIn)}</p>
              <span>•</span>
              <p>{idea.userName}</p>
            </DateAndAuthor>
          </AdvancedInfo>
          <TagWrapper>
            {idea.tags.map((tag) => (
              <Tag key={tag.id}>{tag.name}</Tag>
            ))}
          </TagWrapper>
          <CommentsWrapper>
            <IdeaComments ideaId={id} />
          </CommentsWrapper>
        </>
      ) : (
        <LoadContent />
      )}
    </Content>
  );
};

export default IdeaPage;
