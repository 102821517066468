import axios from "axios";

import type {
  FilePreviewSettingUserType,
  PreviewFileType,
} from "domain/fileContacts";

export type ContactPreviewProps = Omit<
  FilePreviewSettingUserType,
  "name" | "email"
>;

export type ImportPropsType = {
  book_id: number;
  skipFirstRow: ContactPreviewProps["skipFirstRow"];
  delimiter: ContactPreviewProps["delimiter"];
  emailColumn: number;
  nameColumn: number;
};

type ImportPreviewStatusType = {
  1: "Предпросмотр";
  2: "В очереди на загрузку";
  3: "Идет загрузка";
  4: "Загрузка завершена";
  5: "Ошибка загрузки";
};

export type ImportPreviewType = {
  id: number;
  count_processed: number;
  count_added: number;
  count_updated: number;
  count_errors: number;
  status: keyof ImportPreviewStatusType;
  statusName: string;
  percent: number;
};

export const contactService = {
  path: "contact/",
  upload(data: File) {
    const body = new FormData();
    body.append("ImportUploadForm[file]", data);
    return axios.post<ApiResponseBodyType<{ id: id }>>(
      `${this.path}upload-file-to-import`,
      body
    );
  },

  preview(
    id: id,
    props: ContactPreviewProps & { name: null | number; email: null | number }
  ) {
    const name =
      props.name === null
        ? ""
        : `&ImportContactForm[settings][name]=${props.name}`;
    const email =
      props.email === null
        ? ""
        : `&ImportContactForm[settings][email]=${props.email}`;

    return axios.get<ApiResponseBodyType<PreviewFileType>>(
      `${
        this.path
      }import-file-preview?id=${id}&ImportContactForm[skipFirstRow]=${
        Number(props.skipFirstRow) ?? ""
      }&ImportContactForm[delimiter]=${props.delimiter ?? ""}${name}${email}`
    );
  },
  import(idFile: id, props: ImportPropsType) {
    const name =
      props.nameColumn === null
        ? ""
        : `&ImportContactForm[settings][name]=${props.nameColumn}`;
    const email =
      props.emailColumn === null
        ? ""
        : `&ImportContactForm[settings][email]=${props.emailColumn}`;

    return axios.get<ApiResponseBodyType<{ count: number }>>(
      `${
        this.path
      }import-file?id=${idFile}&ImportContactForm[skipFirstRow]=${Number(
        props.skipFirstRow
      )}&ImportContactForm[delimiter]=${Number(
        props.delimiter
      )}&ImportContactForm[book_id]=${props.book_id}${name}${email}`
    );
  },
  importPreview(idFile: id) {
    return axios.get<
      ApiResponseBodyType<{ "import-preview": ImportPreviewType }>
    >(`${this.path}import-progress?id=${idFile}`);
  },
  errorImportCSV(id: number) {
    return () => {
      return axios.get<ApiResponseBodyType<unknown>>(
        `${this.path}import-errors?id=${id}`
      );
    };
  },
};
