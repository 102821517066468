import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import styled from "styled-components";
import useUrlState from "@ahooksjs/use-url-state";

import { createToastResponseError } from "lib/toastify";
import { useAppDispatch } from "services/hooks";
import { importStoreAction } from "services/store/importSlice";
import { contactsListService } from "services/list";

import { Input } from "../../components/FormsElements/Input";
import { Button } from "../../components/Buttons/Button";
import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";
import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`;

export const CreateList: CT<{
  onClose: VoidFunction;
  redirectToImport?: boolean;
}> = ({ onClose, redirectToImport = false }) => {
  const [isLoad, setLoad] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  //@ts-ignore
  //eslint-disable-next-line
  const [urlState, setUrlState] = useUrlState();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Поле обязательно для заполнения"),
  });

  const onSubmit = async (data: { name: string }) => {
    setLoad(true);
    try {
      const request = await contactsListService.create(data.name);
      const { id, name } = request.data.data.book;
      toast(request.data.message, {
        autoClose: 3000,
      });
      // для сцепки с формой InputUserContactList
      setUrlState({
        createdListName: name,
        createdListId: id,
      });

      if (redirectToImport) {
        dispatch(
          importStoreAction.setListData({
            name,
            id,
            count: 0,
          })
        );
        history.push("/contacts/import");
      }
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setLoad(false);
      onClose();
      setTimeout(() => {
        document.body.click();
      }, 50);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <ModalDefaultFormContainer>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Input
          label={"Название списка"}
          type="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          name={"name"}
          placeholder={"Введите название списка"}
          error={formik.errors.name}
        />
        <ModalButtonsContainer style={{ marginTop: 12 }}>
          <Button size={"normal"} isLoad={isLoad} type={"submit"}>
            Создать
          </Button>
          <Button
            onClick={onClose}
            size={"normal"}
            variant={"secondary"}
            type={"submit"}
          >
            Отменить
          </Button>
        </ModalButtonsContainer>
      </Form>
    </ModalDefaultFormContainer>
  );
};
