import { useEffect, useState } from "react";
import styled from "styled-components";

import type { UserPayCardDataType } from "domain/user";
import { useUserBuyPackage } from "app/useUserBuyPackage";
import { serviceUser } from "services/user";
import { useAppSelector } from "services/hooks";

import { selectModalData } from "services/store/modalDataSlice";
import { createToastResponseError } from "lib/toastify";

import { _variables } from "ui/styles/_variables";
import { Button } from "ui/components/Buttons/Button";
import { ModalDefaultFormContainer } from "ui/components/Modals/ModalDefaultFormContainer";
import { CheckboxSmallCircle } from "ui/components/FormsElements/CheckboxSmallCircle";
import { LoadDots } from "ui/components/LoadDots";

const Container = styled(ModalDefaultFormContainer)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const CheckboxBox = styled.div`
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  row-gap: 18px;
  & span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: ${_variables.blackColor};
    opacity: 0.5;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  column-gap: 8px;
`;

const ButtonPay = styled(Button)`
  width: 101px;
`;

const LoadWrapper = styled.div`
  display: flex;
  min-height: 150px;
  align-items: center;
  justify-content: center;
`;

export const PayPlanForm: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const modalData = useAppSelector(selectModalData);

  const [cards, setCards] = useState<UserPayCardDataType[]>([]);
  const [checkId, setCheckId] = useState(0);
  const [isLoad, setLoad] = useState(false);

  const { onPay, isLoadPayData } = useUserBuyPackage();

  const loadData = async () => {
    setLoad(true);
    try {
      const data = await serviceUser.getPaymentCards();
      const cards = data.data.data["user-payment-cards"];
      setCards(cards);
      if (cards.length > 0) setCheckId(Number(cards[0].id));
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setTimeout(() => {
        setLoad(false);
      }, 100);
    }
  };

  const payHandler = async () => {
    await onPay(modalData!.planId, checkId);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoad) {
    return (
      <Container>
        <LoadWrapper>
          <LoadDots />
        </LoadWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          return false;
        }}
      >
        <CheckboxBox>
          {cards.map((item) => (
            <CheckboxSmallCircle
              name={String(item.id)}
              checked={checkId === item.id}
              onChecked={(val) => {
                setCheckId(Number(val));
              }}
            >
              <span>{item.title}</span>
            </CheckboxSmallCircle>
          ))}
          <CheckboxSmallCircle
            name={"0"}
            checked={checkId === 0}
            onChecked={(val) => {
              setCheckId(Number(val));
            }}
          >
            <span>Оплата новой картой</span>
          </CheckboxSmallCircle>
        </CheckboxBox>

        <ButtonRow>
          <ButtonPay
            disabled={isLoadPayData}
            isLoad={isLoadPayData}
            onClick={payHandler}
          >
            Оплатить
          </ButtonPay>
          <Button onClick={onClose} variant={"secondary"}>
            Закрыть
          </Button>
        </ButtonRow>
      </Form>
    </Container>
  );
};
