import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";

const Date = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.25px;
  text-align: center;
  border-radius: ${_variables.borderRadius};

  &:hover {
    color: ${_variables.primaryColor};
    background-color: rgba(${hexToRgba(_variables.primaryColor, 0.1)});
  }
`;

const PrevDate = styled(Date)`
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.25px;
  position: relative;
  z-index: 11;
  text-align: center;
  color: rgba(${hexToRgba(_variables.blackColor, 0.5)});
`;

const Today = styled(Date)`
  font-size: 13px;
  position: relative;
  z-index: 11;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.25px;
  text-align: center;
  color: ${_variables.primaryColor};
  background-color: rgba(${hexToRgba(_variables.primaryColor, 0.1)});
  border-radius: ${_variables.borderRadius};
`;

export const DatePickerDay: CT<{ isPrevMonth: boolean; isToday: boolean }> = ({
  children,
  isToday,
  isPrevMonth,
}) => {
  if (isToday)
    return (
      <Today className={"datePicker-today"}>
        <span>{children}</span>
      </Today>
    );
  if (isPrevMonth)
    return (
      <PrevDate className={"datePicker-prev"}>
        <span>{children}</span>
      </PrevDate>
    );
  return (
    <Date>
      <span>{children}</span>
    </Date>
  );
};
