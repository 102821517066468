import React, { useEffect, useRef, useState } from "react";
import { useClickAway } from "ahooks";
import Picker from "emoji-picker-react";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

import { Input } from "../../components/FormsElements/Input";
import { Icon } from "../../components/Icon";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 112;
`;
const PickerWrapper = styled.div`
  display: flex;
  position: absolute;
  top: calc(100% + 10px);
  z-index: 11;
  right: 0;
`;
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Button = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 2px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${_variables.grayTextColor};
  cursor: pointer;
  transition: color ${_variables.transition};

  &:hover {
    color: ${_variables.primaryColor};
  }
`;

export const InputWithEmoji: CT<
  Omit<React.ComponentProps<typeof Input>, "onChange"> & {
    onChange: (val: string) => void;
  }
> = ({ onChange, ...props }) => {
  const [isShow, setShow] = useState(false);
  const [value, setValue] = useState("");
  const emojiRef = useRef(null);
  const [isFoces, setFocus] = useState(false);

  const openHandler = () => {
    setFocus(true);
    setTimeout(() => setShow(true), 10);
  };
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onClickEmoji: React.ComponentProps<typeof Picker>["onEmojiClick"] = (
    _,
    data
  ) => {
    setFocus(false);
    setValue(value + data.emoji);
  };

  useClickAway(() => {
    if (!isShow) return;
    setFocus(false);
    setShow(false);
  }, emojiRef);

  useEffect(() => {
    if (!props.value) return;
    setValue(props.value!);
  }, [props.value]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <Wrapper>
      <InputWrapper>
        <Input
          {...props}
          isFocus={isFoces}
          onChange={onChangeInput}
          value={value}
          style={{ paddingRight: 50 }}
        />
        <Button onClick={openHandler}>
          <Icon id={"smile-icon"} width={24} height={24} />
        </Button>
      </InputWrapper>
      {isShow && (
        <PickerWrapper ref={emojiRef}>
          <Picker
            onEmojiClick={onClickEmoji}
            disableSkinTonePicker={true}
            disableSearchBar={true}
            groupNames={{
              smileys_people: "Люди",
              animals_nature: "Животные",
              food_drink: "Еда",
              travel_places: "Путешествия",
              activities: "Активности",
              objects: "Объекты",
              symbols: "Символы",
              flags: "Флаги",
              recently_used: "Использованные ранее",
            }}
          />
        </PickerWrapper>
      )}
    </Wrapper>
  );
};
