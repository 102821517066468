import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import arrowBottom from "ui/assets/media/icons/arrow-select-icon.svg";

const Container = styled.div`
  display: flex;
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
  align-items: center;
`;

const Select = styled.select<{ isBig: boolean }>`
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  padding: 0 1em 0 0;
  margin: 0;
  width: ${(props) => (props.isBig ? "50px" : "45px")};
  border: none;
  cursor: pointer;
  color: ${_variables.grayTextColor};
  background-image: url(${arrowBottom});
  background-position: right center;
  background-repeat: no-repeat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;

  &:focus {
    outline: none;
    border: none;
  }
`;

export const ShowCount: CT<{
  defaultValue: number;
  onChange: (val: number) => void;
}> = ({ onChange, defaultValue }) => {
  return (
    <Container>
      Показать:&nbsp;&nbsp;&nbsp;
      <Select
        isBig={defaultValue === 100}
        onChange={(e) => {
          //@ts-ignore
          onChange && onChange(Number(e.target.value));
        }}
        id="select123"
      >
        <option value="25" selected={defaultValue === 25}>
          25
        </option>
        <option value="100" selected={defaultValue === 100}>
          100
        </option>
      </Select>
    </Container>
  );
};
