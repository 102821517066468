import styled, { css } from "styled-components";

import { _variables } from "ui/styles/_variables";
import { DecorIcon } from "ui/components/Icons/DecorIcon";
import { TooltipHover } from "ui/components/TooltipHover";
import { BlackNotify } from "ui/components/BlackNotify";

const IContent = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  border-bottom: 1px solid ${_variables.strokeColor};
  column-gap: 24px;
`;

const ItemWrapper = styled.div<{ disable: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 6px;
  height: 46px;

  color: ${_variables.grayTextColor};

  &:hover {
    color: ${_variables.blackColor};
  }

  ${(props) => {
    if (props.disable)
      return css`
        opacity: 0.7;
        cursor: auto;
        &:hover {
          color: ${_variables.grayTextColor};
        }
      `;
  }}
`;

const IItem = styled.div<{ disable: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  top: 1px;
  cursor: pointer;
  transition: color ${_variables.transition};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 1px solid transparent;

  &.active {
    border-bottom: 1px solid ${_variables.primaryColor};
    color: ${_variables.blackColor};
  }
  ${(props) => {
    if (props.disable)
      return css`
        cursor: auto;
        &:hover {
          color: ${_variables.grayTextColor};
        }
      `;
  }}
`;

const Tooltip = styled(TooltipHover)`
  height: 100%;
`;

export type RowTabsPropsType = {
  items: {
    name: string;
    value: string;
    disable?: boolean;
    tooltip?: string;
  }[];
  active: string;
  onClick: (tab: string) => void;
};

export const RowTabs: CT<RowTabsPropsType> = ({ items, active, onClick }) => {
  const WrapperTooltip: CT<{ tooltipData: string }> = ({
    tooltipData,
    children,
  }) => {
    if (!!tooltipData)
      return (
        <Tooltip
          offset={[80, 60]}
          content={
            <BlackNotify right={228} triangePosition={"top"}>
              {tooltipData}
            </BlackNotify>
          }
        >
          {children}
        </Tooltip>
      );
    return <>{children}</>;
  };

  return (
    <IContent>
      {items.map((item) => (
        <WrapperTooltip tooltipData={item.tooltip ?? ""}>
          <ItemWrapper
            disable={item.disable === undefined ? false : item.disable}
          >
            <IItem
              key={item.name}
              disable={item.disable === undefined ? false : item.disable}
              onClick={() => {
                if (item.disable) return;
                onClick(item.value);
              }}
              className={item.value === active ? "active" : ""}
            >
              {item.name}
            </IItem>
            {item.disable && <DecorIcon name={"lock"} variant={"info"} />}
          </ItemWrapper>
        </WrapperTooltip>
      ))}
    </IContent>
  );
};
