import { useState } from "react";

import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  importStoreAction,
  selectImportState,
} from "services/store/importSlice";
import { contactService } from "services/contact";
import { createToastResponseError } from "lib/toastify";

export const useImportFile = () => {
  const dispatch = useAppDispatch();
  const importingData = useAppSelector(selectImportState);
  const [isLoad, setLoad] = useState(false);

  const onSetUserListData = (id: number, name: string, count: number) => {
    dispatch(importStoreAction.setListData({ name, id, count }));
  };

  const onImport = async () => {
    setLoad(true);
    try {
      await contactService.import(importingData.fileId!, {
        book_id: importingData.list!.id,
        skipFirstRow: importingData.userSetting.skipFirstRow,
        delimiter: importingData.userSetting.delimiter,
        emailColumn: importingData.userSetting.email!,
        nameColumn: importingData.userSetting.name!,
      });
      dispatch(importStoreAction.goToImportStep());
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setLoad(false);
    }
  };

  const isValid =
    !!importingData.list &&
    !!importingData.list.id &&
    !!importingData.list.name;

  return {
    count: importingData.countImport ?? 0,
    ignore: importingData.countIgnore ?? 0,
    allContacts: importingData.totalCount ?? 0,
    isValid,
    isLoad,
    importingData,
    actions: {
      onSetUserListData,
      onImport,
    },
  };
};
