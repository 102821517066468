import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useClickAway, useFocusWithin } from "ahooks";
import styled from "styled-components";

import { userEmailsService } from "services/userEmailsService";

import { _variables } from "ui/styles/_variables";
import { Input } from "ui/components/FormsElements/Input";
import { SelectBox } from "ui/components/SelectBox";
import { ClearInputIcon } from "ui/components/ClearInputIcon";
import { Icon } from "ui/components/Icon";
import type { UserEmailDomainType } from "domain/userEmail";

const IWrapper = styled.div`
  position: relative;
  z-index: 11;
`;

const Select = styled(SelectBox)`
  position: absolute !important;
  top: calc(100% + 10px);
  width: 100%;
  background-color: white;
`;

const IAddButton = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
  font-size: 14px;
  height: 32px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  background-color: ${_variables.backgroundColor};
  padding-left: 16px;
  color: ${_variables.blackColor};
  cursor: pointer;
`;

const AddMail = () => {
  const history = useHistory();

  return (
    <IAddButton
      onClick={() => {
        history.push("/main/user/setting?action=add&tab=emails");
      }}
    >
      <Icon id={"plus-color-icon"} width={24} height={24} />
      Создать новый email
    </IAddButton>
  );
};

const InputWrapper = styled(Input)`
  &:disabled {
    color: ${_variables.blackColor};
    cursor: pointer;
  }
`;

type ChangeHandler = (val: string, data?: UserEmailDomainType) => void;

const inputDefaultData = { name: "", id: "" };

export const InputUserMails: CT<
  Omit<React.ComponentProps<typeof Input>, "onChange" | "onBlur"> & {
    onChange: ChangeHandler;
    onBlur: ChangeHandler;
  }
> = ({ onChange, onBlur, ...props }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const wrapperRef = useRef(null);

  const [inputData, setInputData] =
    useState<React.ComponentProps<typeof SelectBox>["values"][number]>(
      inputDefaultData
    );
  const [listData, setListData] = useState<
    React.ComponentProps<typeof SelectBox>["values"]
  >([]);

  const [isShowSelect, setIsShowSelect] = useState(false);

  const resetInputHandler = () => {
    setInputData(inputDefaultData);
  };

  const clickSelectHandler = (
    data: React.ComponentProps<typeof SelectBox>["values"][number]
  ) => {
    setInputData(data);
    setIsShowSelect(false);
    setTimeout(() => {
      inputRef.current!.focus();
      inputRef.current!.blur();
    }, 200);
  };

  const load = async () => {
    try {
      const response = await userEmailsService.listAll();
      setListData(
        response.map((item) => ({
          name: item.email,
          id: item.id,
          data: item,
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  // Реагируем на клики внутри обертки, чтобы вызвать показ селекта
  useFocusWithin(wrapperRef, {
    onFocus: () => {
      setIsShowSelect(true);
    },
    onBlur: () => {
      setIsShowSelect(false);
      onBlur(inputData?.name ?? "", inputData.data);
      if (document.activeElement === wrapperRef.current) {
        inputRef.current!.focus();
        inputRef.current!.blur();
      }
    },
  });

  // Первоначальное пропихивание данных, если они есть
  useEffect(() => {
    if (!props.value) return;
    setInputData({ ...inputDefaultData, name: props.value });
  }, [props.value]);

  // Реагируем на изменения в выборе и прокидываем событие вверх
  useEffect(() => {
    onChange(inputData.name, inputData.data);
    load();
  }, [inputData]);

  // Реагируем на клик вне компонента
  useClickAway(() => {
    if (document.activeElement === wrapperRef.current) {
      inputRef.current!.focus();
      inputRef.current!.blur();
    }
  }, wrapperRef);

  return (
    <IWrapper
      id={"input-user-email"}
      onClick={() => {
        setIsShowSelect(true);
      }}
      tabIndex={-1}
      ref={wrapperRef}
    >
      <InputWrapper
        autoComplete={"off"}
        {...props}
        value={inputData?.name ?? ""}
        disabled={true}
        ref={inputRef}
        isFocus={document.activeElement === wrapperRef.current}
        style={{ paddingRight: 40 }}
        rightIconContent={
          inputData ? <ClearInputIcon onClick={resetInputHandler} /> : <></>
        }
      />

      {isShowSelect && (
        <Select
          fixedElement={<AddMail />}
          onClickElement={clickSelectHandler}
          values={listData}
        />
      )}
    </IWrapper>
  );
};
