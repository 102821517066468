import styled from "styled-components";
import { useEffect, useState } from "react";
import { addDays, format, parse, setHours, setMinutes } from "date-fns";
import { ru } from "date-fns/locale";
import { debounce } from "lodash";

import { apiDateFormat } from "domain/time";
import type { BookSplitListType } from "domain/lists";
import { useCreateMailig } from "app/createMailing/useCreateMailig";
import { contactsListService } from "services/list";
import { portProMailing } from "services/mailing";
import type { TemplateViewStateType } from "services/store/createMailingSlice";

import { ActionIcon } from "ui/components/Icons/ActionIcon";
import { MailScheduleCounter } from "ui/components/MailScheduleCounter";
import { Button } from "ui/components/Buttons/Button";
import { SelectTimePicker } from "ui/components/FormsElements/TimePickers/SelectTimePicker";
import { Icon } from "ui/components/Icon";
import { AutoMailingStyled } from "./styles";
import { LineSelect } from "ui/components/FormsElements/LineInputs/LineSelect";
import { Switch } from "ui/components/FormsElements/Switch";

const {
  IBoxTitle,
  IBoxTopLine,
  IInfoBox,
  IContent,
  ICount,
  ISelectCountBox,
  FromToWrapper,
  IIconWrapper,
  Wrapper,
  ITitleIconMessage,
  IRow,
  IRowButtons,
  IDate,
  IRowTimeTable,
  ITimetable,
  DatePicker,
  ISelectLineBox,
  ICheckboxWrapper,
  ICheckboxTitle,
  ISwitchWrapper,
} = AutoMailingStyled;

const SwitchItem = styled(Switch)`
  width: auto !important;
`;

const createShowMailScheduleData = (
  data: BookSplitListType
): { title: string; counter: number }[] =>
  data.map((item) => ({
    counter: item.limit,
    title: format(parse(item.date, apiDateFormat, new Date()), "d MMM", {
      locale: ru,
    }),
  }));

const createTitleDate = (data: BookSplitListType) => {
  if (data.length === 0) return "";
  const lastDate = parse(data[data.length - 1].date, apiDateFormat, new Date());
  const startDate = parse(data[0].date, apiDateFormat, new Date());

  const startDay = format(startDate, "d");
  const lastDay = format(lastDate, "d");
  return `${startDay} - ${lastDay} ${format(lastDate, "MMMM yyyy", {
    locale: ru,
  })} года`;
};

const initSplitCount = 7;
const initCoefficient = 2;
const initWithHoliday = false;

export const AutoMailingProForm: CT<{
  onClose: VoidFunction;
  mailingData: NonNullable<TemplateViewStateType["data"]>;
  onOpenLightMode: VoidFunction;
}> = ({ mailingData, onOpenLightMode }) => {
  const { submitMailing, isLoad: isSubmiting } = useCreateMailig();

  const [isOpenEditDate, setOpenEditDate] = useState(false);
  const [isOpenEditTime, setIsOpenEditTime] = useState(false);
  const [mailingDatesSplit, setMailingDatesSplit] = useState<BookSplitListType>(
    []
  );

  const [splitCount, setSplitCount] = useState(initSplitCount);
  const [coefficient, setCoefficient] = useState(initCoefficient);
  const [withHoliday, setHoliday] = useState(initWithHoliday);

  const [fromDate, setFromDate] = useState(addDays(new Date(), 1));
  const [fromDateTime, setFromDateTime] = useState(
    setHours(setMinutes(new Date(), 0), 18)
  );

  const currentBookId = mailingData.secondStep?.listId;
  const totalCount = mailingData.secondStep?.count;

  const submitHandler = async () => {
    const time = format(fromDateTime, "HH:mm");

    const prepareData = portProMailing(
      mailingData,
      time,
      fromDate,
      1,
      splitCount,
      coefficient,
      !withHoliday
    );
    await submitMailing(prepareData);
  };

  const editTimeToggleHandler = () => {
    setIsOpenEditTime(!isOpenEditTime);
  };

  const editDateToggleHandler = () => {
    setOpenEditDate(!isOpenEditDate);
  };

  const changeTimeHandler = (val: Date) => {
    setFromDateTime(val);
    setIsOpenEditTime(false);
  };

  const changeDateHandler = (val: Date | null) => {
    setFromDate(val ?? fromDate);
    setOpenEditDate(false);
  };

  const changeDaysHandler = debounce((val: any) => {
    setSplitCount(val[0]);
  }, 300);

  const changeCoefficientHandler = debounce((val: any) => {
    setCoefficient(val[0]);
  }, 300);

  const changeHolidayCheck = debounce((val: boolean) => {
    setHoliday(val);
  }, 300);

  useEffect(() => {
    if (!currentBookId) return;
    (async () => {
      try {
        const data = await contactsListService.getSplitBook({
          id: currentBookId,
          fromDate: fromDate,
          days: splitCount,
          multiplier: coefficient,
          withHolydays: !withHoliday,
        });
        setMailingDatesSplit(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [currentBookId, fromDate, coefficient, splitCount, withHoliday]);

  return (
    <Wrapper>
      <IContent>
        <ISelectCountBox>
          <IBoxTopLine>
            <IBoxTitle>Количество дней</IBoxTitle>
            <ITitleIconMessage>
              <Icon id={"info-icon"} width={22} height={22} />
              <span>Укажите количество дней прогрева</span>
            </ITitleIconMessage>
          </IBoxTopLine>
          <ISelectLineBox>
            <span>От 5 до 14 дней</span>
            <LineSelect
              onChange={changeDaysHandler}
              step={1}
              min={5}
              max={14}
              values={[initSplitCount]}
            />
          </ISelectLineBox>
        </ISelectCountBox>

        <ISelectCountBox>
          <IBoxTopLine>
            <IBoxTitle>Динамика</IBoxTitle>
            <ITitleIconMessage>
              <Icon id={"info-icon"} width={22} height={22} />
              <span>Выставите коэффициент прогрева</span>
            </ITitleIconMessage>
          </IBoxTopLine>
          <ISelectLineBox>
            <span>От 1 до 4</span>
            <LineSelect
              onChange={changeCoefficientHandler}
              step={0.1}
              min={1}
              max={4}
              values={[initCoefficient]}
            />
          </ISelectLineBox>
        </ISelectCountBox>
        <ITimetable>
          <IBoxTopLine>
            <IBoxTitle>Количество писем в каждой рассылке</IBoxTitle>
            <ICount>Всего писем: {totalCount}</ICount>
          </IBoxTopLine>
          <IRowTimeTable count={mailingDatesSplit.length + 1}>
            {createShowMailScheduleData(mailingDatesSplit).map((item) => (
              <MailScheduleCounter key={item.title} {...item} />
            ))}
          </IRowTimeTable>
        </ITimetable>
        <IRow>
          <IInfoBox>
            <IBoxTopLine>
              <IBoxTitle>Даты рассылки</IBoxTitle>
              <IIconWrapper onClick={editDateToggleHandler}>
                <ActionIcon
                  variant={"edit"}
                  height={16}
                  width={16}
                  name={"pen"}
                />
              </IIconWrapper>
            </IBoxTopLine>
            {isOpenEditDate ? (
              <FromToWrapper>
                <DatePicker
                  positionTopPicker={true}
                  isMinDayNow={true}
                  initialDate={fromDate}
                  onChangeDate={changeDateHandler}
                />
              </FromToWrapper>
            ) : (
              <IDate>{createTitleDate(mailingDatesSplit)}</IDate>
            )}
          </IInfoBox>
          <IInfoBox>
            <IBoxTopLine>
              <IBoxTitle>Время начала отправки (по мск)</IBoxTitle>
              <IIconWrapper onClick={editTimeToggleHandler}>
                <ActionIcon
                  variant={"edit"}
                  height={16}
                  width={16}
                  name={"pen"}
                />
              </IIconWrapper>
            </IBoxTopLine>
            {isOpenEditTime ? (
              <FromToWrapper>
                <SelectTimePicker
                  positionTopPicker={true}
                  dateFrom={fromDateTime}
                  onChangeFromDateHandler={changeTimeHandler}
                />
              </FromToWrapper>
            ) : (
              <IDate>C {format(fromDateTime, "HH:mm")}</IDate>
            )}
          </IInfoBox>
        </IRow>
        <ICheckboxWrapper>
          <ICheckboxTitle>
            <Icon id={"info-icon"} width={24} height={24} />
            <span>Включите, если не хотите отправлять в выходные</span>
          </ICheckboxTitle>
          <ISwitchWrapper>
            <span>Не прогревать в выходные</span>
            <SwitchItem
              isShowTextPreview={false}
              size={{
                height: 20,
                width: 32,
                radius: 14,
              }}
              onChange={changeHolidayCheck}
              value={withHoliday}
            />
          </ISwitchWrapper>
        </ICheckboxWrapper>
        <IRowButtons>
          <Button widthMode={"full"} onClick={onOpenLightMode} variant={"grey"}>
            Вернуться назад
          </Button>

          <Button
            onClick={submitHandler}
            disabled={isSubmiting}
            widthMode={"full"}
          >
            Запустить автопрогрев
          </Button>
        </IRowButtons>
      </IContent>
    </Wrapper>
  );
};
