import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual } from "react-redux";
import { useTitle } from "ahooks";
import styled from "styled-components";

import type { categoryMailingType, MailingType } from "domain/mailing";
import { statusCanCancel, statusMailCatalog } from "domain/mailing";
import type { MailingFilters } from "domain/api";
import { mailingService } from "services/mailing";
import { useAppDispatch } from "services/hooks";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";
import { createDateForMail } from "lib/date";

import { _variables } from "ui/styles/_variables";
import { StatusMailingChip } from "ui/components/StatusMailingChip";
import { Icon } from "ui/components/Icon";
import { useTableStateLoad } from "ui/hooks/useTableStateLoad";
import { LoadContent } from "ui/components/LoadContent";
import { SearchNotFound } from "ui/components/NotContent/SearchNotFound";
import { NotContentMailingPage } from "ui/components/NotContent/NotContentMailingPage";
import { Link } from "ui/components/Link";
import { StatisticBlockCount } from "ui/components/StatisticBlocks/StatisticBlockCount";
import { Button } from "ui/components/Buttons/Button";

import { appConfig } from "../../../../../appConfig";

const IList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const IListItem = styled.li`
  display: flex;
  justify-content: space-between;
  min-height: 184px;
  width: 100%;
  border-top: 1px solid ${_variables.strokeColor};
  padding: 24px 0;

  &:first-child {
    border-top: none;
  }

  & .link {
    align-self: flex-end;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  &:hover {
    & .link {
      opacity: 1;
    }
  }
`;

const ILeftBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const IRightBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  row-gap: 6px;
  padding-bottom: 5px;
  align-items: flex-end;
  justify-content: space-between;
`;

const IHeading = styled.h3`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const ISubRow = styled.div`
  display: flex;
  column-gap: 12px;
  margin-top: 12px;
  align-items: center;
`;

const ISeparator = styled.div`
  width: 1px;
  height: 16px;
  background-color: ${_variables.strokeColor};
`;

const ISubText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const IStatisticsRow = styled.div`
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
  row-gap: 16px;
  margin-top: 22px;
`;

const IButtonAction = styled.button`
  height: 40px;
  position: relative;
  top: -8px;
  display: flex;
  cursor: pointer;
  background-color: white;
  border: none;
  align-items: center;
  column-gap: 8px;
  border-radius: 8px;
  transition: background-color ${_variables.transition};

  &:hover {
    background-color: ${_variables.backgroundColor};
  }
`;

const IDateText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
  color: ${_variables.grayTextColor};
  padding-right: 24px;
`;

const IInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 210px;
  row-gap: 6px;
`;

const MailingLine: CT<{
  data: MailingType;
}> = ({ data }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useTitle(`История рассылок${appConfig.titleApp}`);

  const cancelHandler = () => {
    dispatch(setModalData({ id: data.id }));
    dispatch(openModal("cancel-mailing"));
  };

  const clickStatusMailingHandler = (
    category: keyof typeof categoryMailingType | null
  ) => {
    dispatch(setModalData({ category, noPaddingContent: true, id: data.id }));
    dispatch(openModal("list-status-mailing"));
  };

  return (
    <IListItem>
      <ILeftBox>
        <IHeading>{data.name}</IHeading>
        <ISubRow>
          <StatusMailingChip status={data.status}>
            {statusMailCatalog[data.status]}
          </StatusMailingChip>
          <ISeparator />
          <Icon id="document-icon-scale" height={15} width={15} />
          <ISubText>{data.template_name}</ISubText>
          <ISeparator />
          <Icon id="book" height={15} width={15} />
          <ISubText>{data.book_name}</ISubText>
        </ISubRow>
        <IStatisticsRow>
          {/*Отправлено*/}
          <StatisticBlockCount
            type={3}
            category={1}
            variant={"success"}
            percent={data.total_percent}
            count={data.total}
            onClick={clickStatusMailingHandler}
          />
          {/*Доставленно*/}
          <StatisticBlockCount
            type={2}
            category={2}
            onClick={clickStatusMailingHandler}
            variant={"mail"}
            percent={data.delivered_percent}
            count={data.delivered}
          />
          {/*Открыто*/}
          <StatisticBlockCount
            variant={"eye"}
            type={1}
            category={3}
            percent={data.open_percent}
            count={data.open}
            onClick={clickStatusMailingHandler}
          />
          {/*Переходы*/}
          <StatisticBlockCount
            variant={"click"}
            type={4}
            category={4}
            percent={data.click_percent}
            count={data.click}
            onClick={clickStatusMailingHandler}
          />
        </IStatisticsRow>
      </ILeftBox>
      <IRightBox>
        <IInfo>
          {statusCanCancel(data.status) ? (
            <IButtonAction onClick={cancelHandler}>
              <Icon id={"cancel-icon"} width={24} height={24} /> Отменить
            </IButtonAction>
          ) : (
            <>
              {data.can_repeat && (
                <IButtonAction
                  onClick={() => {
                    history.push(`/mailing/create-mailing?id=${data.id}`);
                  }}
                >
                  <Icon id={"repeat-icon"} width={24} height={24} /> Повторить
                  рассылку
                </IButtonAction>
              )}
            </>
          )}
          <IDateText>{createDateForMail(data.date_start)}</IDateText>
        </IInfo>

        <Link link={`/mailing/analytic/${data.id}`} className={"link"}>
          <Button variant={"black"} widthMode={"content"}>
            Подробная аналитика
          </Button>
        </Link>
      </IRightBox>
    </IListItem>
  );
};

export const HistoryContent: CT<{
  query: {
    perPage?: any;
    page?: any;
    name?: any;
  } & MailingFilters;
  onSetCountPage: (val: number) => void;
  isFiltered: boolean;
}> = ({ query, onSetCountPage, isFiltered }) => {
  const [data, setData] = useState<MailingType[]>([]);
  const { perPage } = query;
  const { setOffLoad, setOnLoad, isLoad, isNotFound, isNotHaveContent } =
    useTableStateLoad(data, query.name);
  const [queryState, setQueryState] = useState({});

  const loadData = async () => {
    setData([]);
    setOnLoad();
    try {
      const list = await mailingService.history({
        ...query,
        perPage,
        search: query.name,
      });

      setData(list.data.data.history);
      onSetCountPage(list.headers["x-pagination-page-count"]);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  useEffect(() => {
    if (shallowEqual(queryState, query)) return;
    loadData();
    setQueryState(query);
    //eslint-disable-next-line
  }, [query]);

  if (isLoad) return <LoadContent top={120} />;

  if (isNotHaveContent && !isFiltered) return <NotContentMailingPage />;

  return (
    <IList>
      {data.map((item) => {
        return <MailingLine key={item.id + item.name} data={item} />;
      })}
      {!data.length || isNotFound ? <SearchNotFound /> : null}
    </IList>
  );
};
