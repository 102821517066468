import * as cheerio from "cheerio";

export const getBodyByStringHTML = (html: string) => {
  const $ = cheerio.load(html);
  return $("body").toString();
};
export const getHeadByStringHTML = (html: string) => {
  const $ = cheerio.load(html);
  return $("head").toString();
};
