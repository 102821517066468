import React from "react";
import { shallowEqual } from "react-redux";
import styled from "styled-components";
import { Link } from "./Link";

import { _variables } from "ui/styles/_variables";
import Image from "ui/assets/media/images/background-preview.svg";

import { SectionPreloader } from "./SectionPreloader";
import { Logo } from "./Logo";

const Background = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
`;
const Content = styled.main`
  width: 50%;
  background-color: #fff;
  padding: 52px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  position: relative;
`;

const Preview = styled.main`
  background: ${_variables.additionalColors.purple};
  background-image: url(${Image});
  width: 50%;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 90% auto;
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  padding-top: 40px;
  padding-left: 40px;
  align-items: center;
`;

export const BackgroundPreview: CT<{
  isLoadSection?: boolean;
}> = React.memo(({ isLoadSection = false, children }) => {
  return (
    <Background>
      <Preview>
        <Header>
          <Link link={"/"}>
            <Logo color={"black"} />
          </Link>
        </Header>
      </Preview>
      <Content>
        {isLoadSection && <SectionPreloader />}
        {children}
      </Content>
    </Background>
  );
}, shallowEqual);
