import { useImportFile } from "app/importContacts/useImportFile";

import { CounterBox } from "ui/components/ImportContacts/CounterBox";
import { SettingBox } from "ui/components/ImportContacts/SettingBox";
import { HeadingImportContacts } from "ui/components/HeadingImportContacts";
import { ChooseList } from "ui/components/ImportContacts/ChooseList";
import { Button } from "ui/components/Buttons/Button";
import { WrapperContent } from "ui/components/ImportContacts/WrapperContent";
import { LeftColumnContent } from "ui/components/ImportContacts/LeftColumnContent";

export const ImportFileContent: CT<{ isComplete: boolean }> = ({
  isComplete,
}) => {
  const {
    count,
    allContacts,
    importingData,
    ignore,
    actions,
    isValid,
    isLoad,
  } = useImportFile();

  return (
    <WrapperContent>
      <LeftColumnContent>
        <CounterBox
          fileName={importingData.file}
          isDisable={isComplete}
          count={allContacts}
        />
        <div>
          <HeadingImportContacts>Настройте поля</HeadingImportContacts>
          <SettingBox
            isDisable={isComplete}
            importCount={count}
            ignoreCount={ignore}
          />
        </div>
        {!isComplete && (
          <div>
            <HeadingImportContacts>
              Выберите список контактов
            </HeadingImportContacts>
            <ChooseList onChangeList={actions.onSetUserListData} />
          </div>
        )}
        {!isComplete && (
          <Button
            isLoad={isLoad}
            onClick={actions.onImport}
            disabled={!isValid}
          >
            Импортировать
          </Button>
        )}
      </LeftColumnContent>
    </WrapperContent>
  );
};
