import React from "react";
import { shallowEqual } from "react-redux";

import Sprite from "ui/assets/media/icons/sprite.svg";

export type IconType = {
  id: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
};
export const Icon: CT<IconType> = React.memo(
  ({ id, height, width, className, style }) => {
    return (
      <svg
        className={className}
        style={{ width: width ?? "24px", height: height ?? "24px", ...style }}
      >
        <use xlinkHref={`${Sprite}#${id}`} />
      </svg>
    );
  },
  shallowEqual
);
