export type Idea = {
  id: number;
  theme: string;
  content: string;
  dateIn: string;
  likeCount: number;
  status: number;
  userName: string;
  commentCount: number;
  tags: {
    name: string;
    id: number;
  }[];
  canLike: boolean;
};

type Base64 = string;

export type CommentAnswerType = {
  id: number;
  content: string;
  dateIn: string;
  isAnswer: boolean;
  reply_to: number | null;
  userName: string;
  userPhoto: Base64 | null;
};

export type CommentType = CommentAnswerType & {
  answers: CommentAnswerType[];
};

export type Status = {
  name: string;
  iconId?: string;
  count: number;
  id: number;
};

export const statuses: { [key: number]: Status } = {
  1: {
    name: "Новые идеи",
    iconId: "new-idea",
    count: 0,
    id: 1,
  },
  2: {
    name: "В работе",
    iconId: "worker",
    count: 0,
    id: 2,
  },
  3: {
    name: "Отклонено",
    iconId: "rejected",
    count: 0,
    id: 3,
  },
  4: {
    name: "Реализовано",
    iconId: "implemented",
    count: 0,
    id: 4,
  },
  5: {
    name: "Все",
    iconId: "all",
    count: 0,
    id: 0,
  },
};
