import styled, { css } from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { useListNews } from "app/news/useListNews";

import { scroll } from "ui/styles/elements/scroll";
import { MainNewsContent } from "ui/pages/News/Content/MainNewsContent";
import { NewsListContent } from "ui/pages/News/Content/NewsListContent";
import { mxm } from "ui/styles/_mixin";

const IContainer = styled.div`
  display: flex;
  column-gap: 32px;
  width: 100%;
  height: 100vh;
  padding: 0 5px 0 36px;
  ${mxm(
    1320,
    css`
      padding: 0 5px 0 20px;
    `
  )}
`;
const INewsWrapper = styled.div`
  max-width: 900px;
  min-width: 670px;
  width: 100%;
  height: min-content;
`;
const ScrollContent = styled.div`
  display: flex;
  width: 100%;
  padding-right: 40px;
  justify-content: center;
  padding-top: 36px;
  max-height: calc(100vh);
  overflow-y: scroll;
  column-gap: 32px;
  height: min-content;
  padding-bottom: 120px;

  ${mxm(
    1440,
    css`
      justify-content: flex-start;
    `
  )}

  ${mxm(
    1320,
    css`
      justify-content: space-between;
      column-gap: 12px;
      padding-top: 36px;
      padding-right: 15px;
    `
  )}
  ${scroll};
`;

export const NewsPage = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  const { preloadData } = useListNews();

  const [isNotShowContent, setNotShowContent] = useState(false);

  /**
   * Пользователь заходит на страницу
   * Если нету заданного id, то грузим список и вставляем первый (или выводим нет контента)
   * Если id есть, то выводим новость
   */
  const preload = async () => {
    if (!id) {
      const data = await preloadData();
      if (data && data.length > 0) {
        history.push(`/news/${data[0].id}`);
        return;
      }
      setNotShowContent(true);
    }
  };

  useEffect(() => {
    preload();
  }, [id]);

  if (isNotShowContent) {
    return (
      <IContainer>
        <ScrollContent>
          <IContainer>Новостей пока нет</IContainer>;
        </ScrollContent>
      </IContainer>
    );
  }

  return (
    <IContainer>
      <ScrollContent>
        <INewsWrapper>
          <MainNewsContent pageIsReady={!!id} idNews={Number(id)} />
        </INewsWrapper>
        <NewsListContent currentId={Number(id) ?? 0} pageIsReady={!!id} />
      </ScrollContent>
    </IContainer>
  );
};
