import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import styled from "styled-components";

import { createToastResponseError } from "lib/toastify";
import type { APIUserChangePassword } from "services/user";
import { serviceUser } from "services/user";
import { InputPassword } from "ui/components/FormsElements/InputPassword";

import { Button } from "../../components/Buttons/Button";

const IForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  min-width: 280px;
  width: 100%;
`;

export const ChangePasswordForm: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const [isLoad, setLoad] = useState(false);

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Поле обязательно для заполнения"),
    password: Yup.string()
      .required("Поле обязательно для заполнения")
      .min(6, "Минимум 6 символов"),
    passwordConfirm: Yup.string()
      .required("Поле обязательно для заполнения")
      .oneOf([Yup.ref("password"), null], "Пароли не совпадают"),
  });

  const initialValues = {
    oldPassword: "",
    password: "",
    passwordConfirm: "",
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    onSubmit: async (data: APIUserChangePassword) => {
      try {
        setLoad(true);
        await serviceUser.changePassword(data);
        toast("Пароль изменен", { autoClose: 3000 });
        onClose();
      } catch (e) {
        createToastResponseError(e)();
        console.log(e);
      } finally {
        setLoad(false);
      }
    },
    validationSchema: validationSchema,
  });

  return (
    <IForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
        return false;
      }}
    >
      <InputPassword
        name="oldPassword"
        placeholder="Старый пароль"
        handleChange={formik.handleChange}
        error={formik.errors.oldPassword}
        value={formik.values.oldPassword}
      />

      <InputPassword
        name="password"
        placeholder="Новый пароль"
        handleChange={formik.handleChange}
        error={formik.errors.password}
        value={formik.values.password}
      />

      <InputPassword
        name="passwordConfirm"
        placeholder="Повтор пароля"
        handleChange={formik.handleChange}
        error={formik.errors.passwordConfirm}
        value={formik.values.passwordConfirm}
      />
      <Button isLoad={isLoad} type={"submit"}>
        Сохранить
      </Button>
    </IForm>
  );
};
