import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";

import { useValidEmail } from "app/useValidEmail";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { setFirstStepData } from "services/store/createMailingSlice";
import { mailingService } from "services/mailing";
import { _variables } from "ui/styles/_variables";

import { Input } from "../../components/FormsElements/Input";
import { InputUserMails } from "../specialFormElements/InputUserMails";
import { InputWithEmoji } from "../specialFormElements/InputWithEmoji";
import { Button } from "../../components/Buttons/Button";
import { selectCJMSavedMailingUserData } from "services/store/cjm";

type ValuesType = {
  theme: string;
  address: string;
  name: string;
};

const IFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 12px;
  padding: 32px;
  width: 676px;
  row-gap: 16px;
  margin-bottom: 20px;
`;

export const FirstCreateMailing = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const validationEmail = useValidEmail();
  const cjmData = useAppSelector(selectCJMSavedMailingUserData);
  const validationSchema = Yup.object().shape({
    address: Yup.mixed().test(
      "bla",
      "Необходимо выбрать email адрес",
      async (val) => {
        return await validationEmail(val);
      }
    ),
    theme: Yup.string().required("Поле обязательно для заполнения"),
    name: Yup.string().required("Поле обязательно для заполнения"),
  });

  const submitHandler = (data: ValuesType) => {
    dispatch(setFirstStepData(data));
    if (!!cjmData?.secondStep) {
      history.push("/mailing/create-mailing");
      return;
    }
    history.push("/templates/choose-list");
  };

  const formik = useFormik({
    initialValues: {
      theme: "",
      address: "",
      name: "",
    },
    validateOnChange: false,
    onSubmit: submitHandler,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    (async () => {
      try {
        await mailingService.getDefaultEmail();
        await formik.setFieldValue("address", "");
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const isReadySend =
    !!formik.values.theme.length &&
    !!formik.values.address.length &&
    !!formik.values.name.length;

  return (
    <form
      action=""
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
        return false;
      }}
    >
      <IFormWrapper>
        <InputWithEmoji
          name={"theme"}
          label={"Тема письма"}
          value={formik.values.theme}
          onChange={(val: string) => {
            formik.setFieldValue("theme", val);
            setTimeout(() => {
              formik.validateField("theme");
            }, 0);
          }}
          placeholder={"Введите тему письма"}
        />
        <InputUserMails
          onBlur={() => formik.validateField("address")}
          error={formik.errors.address}
          value={formik.values.address}
          onChange={(val: string, data) => {
            formik.setFieldValue("address", val);

            if (data) {
              formik.setFieldValue("name", data.name);
            }
          }}
          name={"address"}
          label={"Адрес отправителя"}
          placeholder={"Введите с какого адреса будет отправка"}
        />
        <Input
          name={"name"}
          value={formik.values.name}
          onChange={(e) => {
            formik.handleChange(e);
            setTimeout(() => {
              formik.validateField("name");
            }, 0);
          }}
          label={"От кого"}
          placeholder={"Имя, название компании"}
        />
      </IFormWrapper>
      <Button disabled={!formik.isValid || !isReadySend} type={"submit"}>
        Продолжить
      </Button>
    </form>
  );
};
