import { useState } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

import { Link } from "../../components/Link";
import { Icon } from "../../components/Icon";
import { RowTabs } from "../../components/RowTabs";
import { TableContainerScroll } from "../../containers/TableContainerScroll";
import { TemplatesContent } from "./TemplatesContent";
import { NewTemplatesContent } from "./NewTemplatesContent";
import { ShadowButton } from "../../components/Buttons/ShadowButton";
import { CrumbsHorizontal } from "../../components/Crumbs/CrumbsHorizontal";
import { ContentPagination } from "../../components/ContentPagination";
import { useTableWorkspace } from "../../hooks/useTableWorkspace";
import { ShowCount } from "../../components/ShowCount";
import { DefaultTemplatesContent } from "./DefaultTemplatesContent";

const Footer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
const ContentPage = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 40px 5px 0px 32px;
  row-gap: 6px;
`;
const StickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 16;
  background-color: white;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
`;
const Heading = styled.h1`
  color: ${_variables.blackColor};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const AbsoluteLink = styled(Link)`
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 1111;
`;

const initialTab = {
  tab: "templates",
  perPage: 25,
  page: 1,
  sortBy: "",
};
export const TemplateCardsPage = () => {
  const [queryTab, setQueryTab] = useUrlState({
    tab: initialTab.tab,
  });
  const [countPage, setCountPage] = useState(0);
  const { query, setPerPage, setPage } = useTableWorkspace({
    perPage: initialTab.perPage,
    page: initialTab.page,
    sortBy: initialTab.sortBy,
    name: "",
  });

  const [isShowButtonAdd, setShowButtonAdd] = useState(false);

  const changePerPageHandler = setPerPage;
  const changePageHandler = setPage;

  return (
    <ContentPage>
      <TableContainerScroll showScroll={true} height={167}>
        <StickyTopContent>
          <Top>
            <Heading>Создайте письмо</Heading>
            <CrumbsHorizontal
              values={[
                {
                  title: "Выберите шаблон",
                  isActive: true,
                },
                {
                  title: "Настройте поля",
                  isActive: false,
                },
                {
                  title: "Выберите список контактов",
                  isActive: false,
                },
                {
                  title: "Импорт",
                  isActive: false,
                },
              ]}
            />
          </Top>
          <RowTabs
            onClick={(val) => {
              setQueryTab({ tab: val });
            }}
            active={queryTab.tab}
            items={[
              { name: "Готовые шаблоны", value: "default-templates" },
              { name: "Мои шаблоны", value: "templates" },
              { name: "Новый шаблон", value: "new" },
            ]}
          />
        </StickyTopContent>

        <>
          {queryTab.tab === "default-templates" && (
            <DefaultTemplatesContent
              onSetCountPage={setCountPage}
              setShowAbsoluteButton={setShowButtonAdd}
            />
          )}
          {queryTab.tab === "templates" && (
            <TemplatesContent
              onSetCountPage={setCountPage}
              setShowAbsoluteButton={setShowButtonAdd}
            />
          )}
          {queryTab.tab === "new" && <NewTemplatesContent />}
        </>
      </TableContainerScroll>
      {queryTab.tab === "templates" && isShowButtonAdd && (
        <AbsoluteLink link={"/templates/cards?tab=new"}>
          <ShadowButton>
            <Icon
              style={{ marginRight: 8 }}
              id={"plus-icon"}
              height={16}
              width={16}
            />
            Создать шаблон
          </ShadowButton>
        </AbsoluteLink>
      )}
      {queryTab.tab === "templates" && (
        <Footer>
          <ShowCount
            defaultValue={Number(query["per-page"])}
            onChange={changePerPageHandler}
          />
          {countPage > 1 && (
            <ContentPagination
              force={Number(query.page - 1)}
              onClick={changePageHandler}
              pageCount={countPage}
            />
          )}
        </Footer>
      )}
    </ContentPage>
  );
};
