import { routeIsEveryConfirm } from "./everyConfirmedRoutes";

export const useInnerPromptRedirect = (acceptRoutes: string[]) => {
  const isShowConfirmRedirect = (
    location: Location,
    noShowConditionFn?: () => boolean
  ) => {
    return (
      (noShowConditionFn && noShowConditionFn()) ||
      routeIsEveryConfirm(location) ||
      acceptRoutes.some((item) =>
        (location.pathname + location.search).startsWith(item)
      )
    );
  };
  return { isShowConfirmRedirect };
};
