import styled from "styled-components";
import { _variables } from "ui/styles/_variables";
import { RadioStandard } from "ui/components/FormsElements/StandartForms/RadioStandard";
import { CheckboxStandard } from "ui/components/FormsElements/StandartForms/CheckboxStandard";
import { Input } from "ui/components/FormsElements/Input";
import { Button } from "ui/components/Buttons/Button";
import {
  createFormClientActions,
  selectFormClientState,
} from "services/store/createFormClientSlice";
import { useAppDispatch, useAppSelector } from "services/hooks";

import type { ChangeEvent } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const WrapperLine = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  column-gap: 16px;
`;

const TitleRow = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const FormRadio = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const Box = styled.div`
  display: flex;
  min-width: 284px;
  flex-direction: column;
  height: fit-content;
  row-gap: 8px;
  padding: 16px;
  background-color: ${_variables.backgroundColor};
  border-radius: 12px;
`;
const TitleForm = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const InputPage = styled(Input)`
  height: 40px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
`;

const ButtonRow = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const Btn = styled(Button)`
  width: 166px;
`;

export const SettingStep = () => {
  const data = useAppSelector(selectFormClientState).setting;
  const dispatch = useAppDispatch();

  const clickBackHandler = () => {
    dispatch(createFormClientActions.goToChooseFormat());
  };
  const clickNextHandler = () => {
    dispatch(createFormClientActions.goToChooseListStep());
  };

  return (
    <Container>
      <WrapperLine>
        <TitleRow>Внешний вид</TitleRow>
        <Row>
          <Box>
            <TitleForm>Расположение</TitleForm>
            <FormRadio
              onChange={(e) => {
                const val = (e as unknown as ChangeEvent<HTMLInputElement>)
                  .target.value;
                dispatch(
                  //@ts-ignore
                  createFormClientActions.setSettingPosition(Number(val))
                );
              }}
            >
              <RadioStandard
                checked={data.position === 1}
                id={"position1"}
                value={1}
                name={"position"}
              >
                По центру
              </RadioStandard>
              <RadioStandard
                checked={data.position === 2}
                id={"position2"}
                value={2}
                name={"position"}
              >
                Справа снизу
              </RadioStandard>
              <RadioStandard
                checked={data.position === 3}
                id={"position3"}
                value={3}
                name={"position"}
              >
                Слева снизу
              </RadioStandard>
            </FormRadio>
          </Box>
          <Box>
            <TitleForm>Анимация</TitleForm>
            <FormRadio
              onChange={(e) => {
                const val = (e as unknown as ChangeEvent<HTMLInputElement>)
                  .target.value;
                dispatch(
                  //@ts-ignore
                  createFormClientActions.setSettingAnimation(Number(val))
                );
              }}
            >
              <RadioStandard
                checked={data.animation === 1}
                disabled={true}
                id={"animation1"}
                value={1}
                name={"animation"}
              >
                Появление
              </RadioStandard>
              <RadioStandard
                checked={data.animation === 2}
                disabled={true}
                id={"animation2"}
                value={2}
                name={"animation"}
              >
                Вылет (в разработке)
              </RadioStandard>
              <RadioStandard
                checked={data.animation === 2}
                disabled={true}
                id={"animation23"}
                value={3}
                name={"animation"}
              >
                Развертывание (в разработке)
              </RadioStandard>
            </FormRadio>
          </Box>
        </Row>
      </WrapperLine>
      <WrapperLine>
        <TitleRow>Условия показа</TitleRow>
        <Row>
          <Box>
            <TitleForm>Страница для показа</TitleForm>
            <FormRadio
              onChange={(e) => {
                const current = (e as unknown as ChangeEvent<HTMLInputElement>)
                  .target;
                dispatch(
                  createFormClientActions.setSettingPageCheck({
                    //@ts-ignore
                    name: current.name,
                    //@ts-ignore
                    value: !data.pages[current.name].checked,
                  })
                );
              }}
            >
              <CheckboxStandard
                checked={data.pages.all.checked}
                id={"PagesAll"}
                value={"all"}
                name={"all"}
              >
                Все страницы
              </CheckboxStandard>
              <CheckboxStandard
                checked={data.pages.only.checked}
                id={"PagesOnly"}
                value={"only"}
                name={"only"}
              >
                Только на страницах
              </CheckboxStandard>
              <InputPage
                onChange={(e) => {
                  dispatch(
                    createFormClientActions.setSettingPageValue({
                      name: "only",
                      value: e.target.value,
                    })
                  );
                }}
                value={data.pages.only.string}
                placeholder={"site.com/only-page"}
                color={"white"}
              />

              <CheckboxStandard
                checked={data.pages.startWith.checked}
                id={"PagesStartWith"}
                value={"startWith"}
                name={"startWith"}
              >
                URL начинается на
              </CheckboxStandard>
              <InputPage
                value={data.pages.startWith.string}
                onChange={(e) => {
                  dispatch(
                    createFormClientActions.setSettingPageValue({
                      name: "startWith",
                      value: e.target.value,
                    })
                  );
                }}
                placeholder={"site.com/url"}
                color={"white"}
              />

              <CheckboxStandard
                checked={data.pages.endWith.checked}
                id={"PagesEndWith"}
                value={"endWith"}
                name={"endWith"}
              >
                URL заканчивается на
              </CheckboxStandard>
              <InputPage
                onChange={(e) => {
                  dispatch(
                    createFormClientActions.setSettingPageValue({
                      name: "endWith",
                      value: e.target.value,
                    })
                  );
                }}
                value={data.pages.endWith.string}
                placeholder={"specialoffer"}
                color={"white"}
              />

              <CheckboxStandard
                checked={data.pages.exact.checked}
                id={"PagesExact"}
                value={"exact"}
                name={"exact"}
              >
                URL точно совпадает
              </CheckboxStandard>
              <InputPage
                onChange={(e) => {
                  dispatch(
                    createFormClientActions.setSettingPageValue({
                      name: "exact",
                      value: e.target.value,
                    })
                  );
                }}
                value={data.pages.exact.string}
                placeholder={"site.com/exactly"}
                color={"white"}
              />
            </FormRadio>
          </Box>
          <Box>
            <TitleForm>Когда показывать?</TitleForm>
            <FormRadio>
              <CheckboxStandard
                id={"howShow"}
                name={"howShow"}
                checked={data.show === 1}
                disabled={true}
              >
                Всегда
              </CheckboxStandard>
            </FormRadio>
          </Box>
        </Row>
      </WrapperLine>
      <WrapperLine>
        <TitleRow>Платформа</TitleRow>
        <Row>
          <Box>
            <TitleForm>Платформа для показа</TitleForm>
            <FormRadio>
              <CheckboxStandard
                checked={data.platform === 1}
                id={"platformAll"}
                name={"platformAll"}
                disabled={true}
              >
                Все
              </CheckboxStandard>
            </FormRadio>
          </Box>
          <Box>
            <TitleForm>ОС</TitleForm>
            <FormRadio>
              <CheckboxStandard
                id={"OSAll"}
                name={"OS"}
                checked={data.os === 1}
                disabled={true}
              >
                Всегда
              </CheckboxStandard>
            </FormRadio>
          </Box>
        </Row>
      </WrapperLine>
      <WrapperLine>
        <Row>
          <Box>
            <TitleForm>Посетители</TitleForm>
            <FormRadio>
              <CheckboxStandard
                checked={data.users === 1}
                id={"UsersAll"}
                name={"UsersAll"}
              >
                Все
              </CheckboxStandard>
            </FormRadio>
          </Box>
        </Row>
      </WrapperLine>
      <ButtonRow>
        <Btn onClick={clickBackHandler} variant={"grey"}>
          Назад
        </Btn>
        <Btn onClick={clickNextHandler} variant={"primary"}>
          Далее
        </Btn>
      </ButtonRow>
    </Container>
  );
};
