import styled from "styled-components";
import { _variables } from "../../styles/_variables";
import { ArrowsIcon } from "../Icons/ArrowsIcon";
import { getFormatNum } from "../../../lib/numbers";
import type { StatisticBlockProps } from "./types";
import { counterShowStyle } from "./styles";

const IContainer = styled.div<{ haveCount: boolean; invertedCount: boolean }>`
  display: flex;
  padding: 12px;
  position: relative;
  border-radius: 12px;
  width: 212px;
  height: 88px;
  background-color: ${_variables.backgroundColor};
  column-gap: 12px;
  transition: all ${_variables.transition};
  cursor: ${(props) => (props.haveCount ? "pointer" : "default")};

  ${(props) => {
    return counterShowStyle(props.invertedCount);
  }}
`;

const IBox = styled.div`
  display: flex;
  column-gap: 11px;
`;

const ITextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ITitle = styled.span`
  color: ${_variables.grayTextColor};
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const IPercent = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const ArrowDecoration = styled(ArrowsIcon)`
  position: absolute;
  bottom: 12px;
  right: 12px;
  color: #777991;
`;

type variantType = "full" | "not exist" | "spam" | "other";

const subHeadings = {
  full: "Ящик переполнен",
  "not exist": "Адрес не существует",
  spam: "Сервер отклонил как СПАМ",
  other: "Доставка не удалась",
};

export const StatisticErrorCount: CT<
  StatisticBlockProps<{
    variant: variantType;
  }>
> = ({ variant, invertedCount = false, category, onClick, percent, count }) => {
  return (
    <IContainer
      invertedCount={invertedCount}
      haveCount={!!count}
      onClick={() => {
        if (count === 0) return;
        onClick(category);
      }}
    >
      <IBox>
        <ITextContent>
          <ITitle>{subHeadings[variant]}</ITitle>
          <IPercent className={"statistic__percent"}>{percent}%</IPercent>
          <IPercent className={"statistic__count"}>
            {getFormatNum(count)}
          </IPercent>
        </ITextContent>
        <ArrowDecoration
          width={24}
          height={24}
          variant={"tail"}
          name={"to-top-right"}
        />
      </IBox>
    </IContainer>
  );
};
