import type { InputHTMLAttributes } from "react";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  position: relative;

  & input {
    position: absolute;
    z-index: -1;
  }

  & input + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    transition: background-color ${_variables.transition};
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;
    position: relative;
    & * {
      font-size: 12px;
    }
  }

  & input + label span {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }

  & input + label::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid ${_variables.strokeColor};
    border-radius: 2px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: white;
    background-size: 50% 50%;
  }

  & input:checked + label::before {
    border-color: ${_variables.primaryColor};
    background-color: transparent;
    background-size: 10px;
    z-index: 1;
  }
  & input:checked + label::after {
    z-index: 2;
    border-color: ${_variables.primaryColor};
    background-color: ${_variables.primaryColor};
    background-size: 10px;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 2px;
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
  }

  & input:focus + label::before {
    border: 1px solid ${_variables.primaryColor};
  }
  & input:disabled + label {
    cursor: auto;
  }
`;
export const CheckboxStandard: CT<
  {} & InputHTMLAttributes<HTMLInputElement>
> = ({ children, ...rest }) => {
  return (
    <Wrapper>
      <input id={rest.id} tabIndex={0} type="checkbox" {...rest} />
      <label htmlFor={rest.id}>
        <span>{children}</span>
      </label>
    </Wrapper>
  );
};
