import styled from "styled-components";

import { useLoadContactImport } from "../../../../../app/importContacts/useLoadContactImport";

import { LoadFile } from "../../../../components/ImportContacts/LoadFile";
import { Button } from "ui/components/Buttons/Button";
import { Icon } from "ui/components/Icon";
import { Link } from "ui/components/Link";

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  margin-top: 20px;
`;

const ResetLink = styled(Link)`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const Links = styled.div`
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  align-items: flex-start;
`;

export const LoadFileContent = () => {
  const { onChangeFile, file, onReset, status, onGoToNextStep } =
    useLoadContactImport();

  const isShowResetLink = status !== 1 && status !== 2;

  const resetHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    onReset();
  };

  return (
    <>
      <LoadFile onChangeFile={onChangeFile} file={file} status={status} />
      <ButtonRow>
        <Button onClick={onGoToNextStep} disabled={status !== 3}>
          Продолжить
        </Button>
        <Links>
          {isShowResetLink ? (
            <ResetLink onClick={resetHandler} link={"/reload"} color={"gray"}>
              <Icon id={"reload-icon"} width={24} height={24} />{" "}
              <span>Загрузить другой файл</span>
            </ResetLink>
          ) : (
            <Link
              link={
                "https://cheapsender.notion.site/aa4e2ef903bc4d2ebad74e6149a96776"
              }
              color={"gray"}
              redirectMode={{ blank: true }}
            >
              Как импортировать контакты?
            </Link>
          )}
        </Links>
      </ButtonRow>
    </>
  );
};
