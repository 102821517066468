import { useState } from "react";
import styled from "styled-components";

import { useAppDispatch } from "services/hooks";
import { setChooseMailing } from "services/store/createMailingSlice";
import { _variables } from "ui/styles/_variables";

import { InputUserContactList } from "../../containers/specialFormElements/InputUserList";
import { Icon } from "../Icon";

const Container = styled.div`
  background-color: ${_variables.backgroundColor};
  border-radius: 12px;
  padding: 24px 32px 32px 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 114;
`;

const Heading = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
const TextData = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const HeadingWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
`;

const EditButton = styled.button`
  display: flex;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;

  border: none;
  column-gap: 8px;
  outline: none;
  height: 40px;
  transition: background-color ${_variables.transition};
  border-radius: 8px;
  padding: 0 8px;
  align-items: center;
  &:hover {
    background-color: ${_variables.backgroundColor};
  }
  & svg {
    color: ${_variables.grayTextColor};
  }
`;

const Count = styled.span`
  font-size: 18px;
  color: ${_variables.grayTextColor};
  font-weight: 600;
  margin-left: 8px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

export const ChooseListForm: CT<{
  changeValue: (id: number, name: string, count: number) => void;
  defaultValue: { name: string; id: number; count: number };
}> = ({ defaultValue, changeValue }) => {
  const [mode] = useState<"show" | "edit">("show");
  const dispatch = useAppDispatch();
  return (
    <Container>
      <HeadingWrapper>
        <Heading>
          Адресаты<Count>{defaultValue.count}</Count>
        </Heading>
        {mode === "show" && (
          <EditButton
            onClick={() => {
              dispatch(setChooseMailing(true));
            }}
          >
            <Icon id={"edit-table-icon"} height={24} width={24} />
            Редактировать
          </EditButton>
        )}
      </HeadingWrapper>
      <Data>
        {mode === "show" && <TextData>{defaultValue.name}</TextData>}
        {mode === "edit" && (
          <InputUserContactList
            label={"Список контактов"}
            initialData={defaultValue}
            onBlur={(data) => {
              //@ts-ignore
              changeValue(Number(data.id), data.name, data.data.count);
            }}
            onChange={(data) => {
              //@ts-ignore
              changeValue(Number(data.id), data.name, data.data.count);
            }}
          />
        )}
      </Data>
    </Container>
  );
};
