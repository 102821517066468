import styled from "styled-components";

import { useAppDispatch } from "services/hooks";
import { openModal } from "services/store/modal";

import { ShadowButton } from "../Buttons/ShadowButton";
import { Icon } from "../Icon";

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px 32px 0px 32px;
  align-items: center;
  margin-top: 300px;
`;

const Text = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 24px;
`;

export const NotContentDefaultTemplate = () => {
  const dispatch = useAppDispatch();

  const openSelectEditorModal = () => {
    dispatch(openModal("select-editor-type"));
  };

  return (
    <Content>
      <Text>Публичных шаблонов нету</Text>
      <ShadowButton onClick={openSelectEditorModal}>
        <Icon
          id={"plus-icon"}
          height={16}
          width={16}
          style={{ marginRight: 8 }}
        />
        Создать шаблон
      </ShadowButton>
    </Content>
  );
};
