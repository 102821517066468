import { css } from "styled-components";

import { _variables } from "../_variables";

export const pagination = css`
  .pagination {
    display: flex;
    & li {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      &.active {
        background-color: ${_variables.backgroundColor};
      }
      &:hover {
        background-color: ${_variables.backgroundColor};
      }
      & a {
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        letter-spacing: 0px;
        text-align: center;
      }
    }
  }
`;
