import { useEffect, useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { datePickerFormat } from "domain/time";

import { _variables } from "ui/styles/_variables";

import { SelectCustom } from "../FormsElements/SelectCustom";
import { InputDatePicker } from "../FormsElements/InputDatePicker";
import { InputTimePicker } from "../FormsElements/TimePickers/InputTimePicker";
import { SelectTimeZone } from "../../containers/specialFormElements/SelectTimeZone";
import { useAppDispatch } from "services/hooks";
import { setOpenDeferredMailing } from "services/store/createMailingSlice";

const Container = styled.div`
  background-color: ${_variables.backgroundColor};
  border-radius: 12px;
  padding: 32px 32px 32px 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const DatePick = styled(InputDatePicker)`
  width: 50%;
  position: relative;
  z-index: 112;
`;
const TimePick = styled(InputTimePicker)`
  width: 50%;
  position: relative;
  z-index: 112;
`;

const Forms = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
const SelectParam = styled(SelectCustom)`
  position: relative;
  z-index: 113;
`;

const TimeRow = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const ParametersForm: CT<{
  initialData: {
    isNow: boolean;
    date: Date;
    time: Date;
    timeZone: string;
  };
  changeHandler: ({
    isNow,
    date,
    time,
    timeZone,
  }: {
    isNow: boolean;
    date: Date;
    time: Date;
    timeZone: string;
  }) => void;
}> = ({ changeHandler, initialData }) => {
  const dispatch = useAppDispatch();
  const [isOpenEdit, setOpenEdit] = useState(!initialData.isNow ?? false);
  const [timeDate, setTimeDate] = useState(
    new Date(initialData.time) ?? new Date()
  );
  const [datePickerValue, setDatePickerValue] = useState<Date | null>(
    new Date(initialData.date) ?? new Date()
  );

  const [init, setInit] = useState(false);
  const [timeZone, setTimeZone] = useState(initialData.timeZone);

  const changeSelectHandler = (val: any) => {
    if (!init) return;
    setOpenEdit(!!val.value);
    setTimeDate(new Date());
    setDatePickerValue(initialData.date);
  };

  useEffect(() => {
    setTimeout(() => {
      setInit(true);
    }, 500);
  }, []);

  useEffect(() => {
    dispatch(setOpenDeferredMailing(isOpenEdit));
  }, [isOpenEdit]);

  useEffect(() => {
    if (!init) return;
    changeHandler({
      time: timeDate,
      date: datePickerValue!,
      isNow: !isOpenEdit,
      timeZone: timeZone,
    });
  }, [isOpenEdit, timeDate, datePickerValue, timeZone]);

  const isTodayMode =
    format(new Date(), datePickerFormat) ===
    format(datePickerValue!, datePickerFormat);

  return (
    <Container>
      <Heading>Параметры отправки</Heading>
      <Data>
        <SelectParam
          background={_variables.whiteColor}
          onChange={changeSelectHandler}
          values={[
            {
              value: 0,
              name: "Отправить сразу",
              isDefaultChecked: !isOpenEdit,
            },
            { value: 1, name: "Отправить позже", isDefaultChecked: isOpenEdit },
          ]}
        />
        {!!isOpenEdit && (
          <Forms>
            <TimeRow>
              <DatePick
                background={_variables.whiteColor}
                initialDate={datePickerValue!}
                onChangeDate={(date) => {
                  setDatePickerValue(date);
                  setTimeDate(new Date());
                }}
              />
              <TimePick
                background={_variables.whiteColor}
                isTodayMode={isTodayMode}
                value={timeDate}
                onChange={(date) => {
                  setTimeDate(date!);
                }}
              />
            </TimeRow>
            <SelectTimeZone
              background={_variables.whiteColor}
              onChange={(val: string) => setTimeZone(val)}
              initialTimezone={timeZone}
            />
          </Forms>
        )}
      </Data>
    </Container>
  );
};
