import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  importStoreAction,
  selectImportState,
} from "services/store/importSlice";
import { getFormatNum } from "lib/numbers";

import { _variables } from "ui/styles/_variables";
import img from "ui/assets/media/images/import-setting-preview.svg";

import { Link } from "../Link";

const Content = styled.div`
  display: flex;
  height: 80px;
  padding: 16px;
  align-items: center;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 8px;
  width: 100%;
  column-gap: 48px;
  position: relative;
  &:before {
    height: 24px;
    position: absolute;
    left: -0.5px;
    top: 50%;
    content: "";
    width: 1.5px;
    background-color: ${_variables.primaryColor};
    transform: translateY(-50%);
  }
`;
const LeftData = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;
const Preview = styled.div`
  display: flex;
  width: 48px;
  height: 48px;

  & img {
    width: 100%;
    height: 100%;
  }
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const Separator = styled.div`
  width: 1px;
  height: 48px;
  background-color: ${_variables.strokeColor};
`;
const RightData = styled.div`
  display: flex;
  column-gap: 16px;
`;
const AdditionalText = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const SettingBox: CT<{
  importCount: number;
  ignoreCount: number;
  isDisable?: boolean;
  isHideBackLink?: boolean;
}> = ({
  ignoreCount,
  isHideBackLink = false,
  importCount,
  isDisable = false,
}) => {
  const dispatch = useAppDispatch();
  const importData = useAppSelector(selectImportState);

  return (
    <Content>
      <LeftData>
        <Preview>
          <img src={img} alt="" />
        </Preview>
        <TextBox>
          <Text>
            Всего полей: {getFormatNum(importCount)} импортируем, &nbsp;
            {getFormatNum(ignoreCount)} игнорируем
          </Text>
          {isHideBackLink ? (
            <div />
          ) : (
            <Link
              onClick={(e) => {
                e.preventDefault();
                dispatch(importStoreAction.goBackToSettingField());
              }}
              disabled={isDisable}
              link={"/"}
              color={"gray"}
            >
              Изменить
            </Link>
          )}
        </TextBox>
      </LeftData>
      <RightData>
        <Separator />
        <AdditionalText>
          <Text>Импортируем поля</Text>
          <Text>
            <span>{importData.userSetting.name !== null && "Имя,"}</span>
            <span>{importData.userSetting.email !== null && "Email"}</span>
          </Text>
        </AdditionalText>
      </RightData>
    </Content>
  );
};
