import styled, { css } from "styled-components";

import { hexToRgba } from "ui/styles/_mixin";
import { _variables } from "ui/styles/_variables";

import { Button } from "./Buttons/Button";
import { Link } from "./Link";

type ColorType = "orangeLight" | "blueLight";

type PreviewType = {
  width: number;
  height: number;
  src: string;
  posX: string;
  posY: string;
};

const Content = styled.div<{ color: ColorType }>`
  width: 100%;
  display: flex;
  height: 224px;
  position: relative;
  padding: 24px;
  border-radius: 12px;
  background-color: ${({ color }) => {
    if (color === "orangeLight")
      return `rgba(${hexToRgba(_variables.additionalColors.orange, 0.07)})`;
    if (color === "blueLight")
      return `rgba(${hexToRgba(_variables.additionalColors.blue, 0.07)})`;
  }};
`;

const LeftContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const TextContent = styled.div`
  display: flex;
  row-gap: 12px;
  flex-direction: column;
`;

const Heading = styled.h4`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const Message = styled.p`
  font-size: 14px;
  width: 244px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const Preview = styled.div<{ data: PreviewType }>`
  display: flex;
  position: absolute;

  ${({ data }) => css`
    left: ${data.posX};
    top: ${data.posY};
    width: ${data.width}px;
    height: ${data.height}px;
  `}
  & img {
    width: 100%;
    height: 100%;
  }
`;

export const DecorationCardButton: CT<{
  title: string;
  message: JSX.Element | string;
  onClickButton?: VoidFunction;
  link: {
    blank: boolean;
    src: string;
    isRedirect: boolean;
  };
  color: "orangeLight" | "blueLight";
  buttonTitle: string;
  preview?: PreviewType;
}> = ({ onClickButton, title, color, link, message, buttonTitle, preview }) => {
  return (
    <Content color={color}>
      <LeftContent>
        <TextContent>
          <Heading>{title}</Heading>
          <Message>{message}</Message>
        </TextContent>
        {link.isRedirect ? (
          <Link
            onClick={onClickButton}
            link={link.src}
            redirectMode={{ blank: link.blank }}
          >
            <Button variant={"black"}>{buttonTitle}</Button>
          </Link>
        ) : (
          <Link onClick={onClickButton} link={link.src}>
            <Button variant={"black"}>{buttonTitle}</Button>
          </Link>
        )}
      </LeftContent>
      {preview && (
        <Preview data={preview}>
          <img src={preview.src} alt="" />
        </Preview>
      )}
    </Content>
  );
};
