import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

const Content = styled.thead`
  width: 100%;
  display: contents;

  & th {
    height: 33px;
    border-bottom: 1px solid ${_variables.strokeColor};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: ${_variables.grayTextColor};
    &:first-child {
      padding-left: 5px;
    }
  }
`;

export const TableHead: CT<unknown> = ({ children, className }) => {
  return <Content className={className}>{children}</Content>;
};
