// хук что дает доступ к отметке о том, что пользователь делал оплату для валидации списка
// при необходимости редиректит на страницу списков контактов
// данные хранятся в LS
import type { BookListType } from "domain/lists";
import { deepEqual } from "lib/other";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";
import { selectUserData } from "services/store/userSlice";

export const usePayValidationRedirect = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUserData, deepEqual);
  const LOCALE_STORAGE_NAME = "pay_validation_data";

  const savePaymentData = (data: { bookId: BookListType["id"] }) => {
    localStorage.setItem(LOCALE_STORAGE_NAME, JSON.stringify(data));
  };

  const checkInfoPaymentData = () => {
    return !!localStorage.getItem(LOCALE_STORAGE_NAME);
  };

  const getInfoPaymentData = () => {
    try {
      const data = localStorage?.getItem(LOCALE_STORAGE_NAME) ?? null;
      if (data) return JSON.parse(data);
      return null;
    } catch (e) {
      return null;
    }
  };

  const clearInfo = () => {
    localStorage.removeItem(LOCALE_STORAGE_NAME);
  };
  const callAlert = () => {
    const data = getInfoPaymentData();
    dispatch(setModalData({ id: data.bookId }));
    dispatch(openModal("redirect-after-validate-pay"));
  };
  const callAlertInfoPaymentData = () => {
    if (window.location.pathname.includes("/payment/response")) return;
    if (!userData) return;
    if (!checkInfoPaymentData()) return;
    const data = getInfoPaymentData();
    if (!data) return;
    callAlert();
  };

  return {
    checkInfoPaymentData,
    getInfoPaymentData,
    clearInfo,
    savePaymentData,
    callAlertInfoPaymentData,
    callAlert,
  };
};
