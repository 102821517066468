import { useTitle } from "ahooks";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import SuccessSubscription from "../assets/media/images/success-subscription.svg";

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${_variables.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  padding: 30px;
  border-radius: 12px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: white;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  font-style: normal;
  line-height: 34px;
  letter-spacing: -0.25px;
  color: ${_variables.blackColor};
`;

const ImageWrapper = styled.div`
  margin: 30px auto;
`;

export const SuccessSubscribe = () => {
  useTitle("Спасибо за подписку!");

  return (
    <Root>
      <FormWrapper>
        <Title>Спасибо за подписку!</Title>
        <ImageWrapper>
          <img src={SuccessSubscription} />
        </ImageWrapper>
      </FormWrapper>
    </Root>
  );
};
