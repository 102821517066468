import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

const Container = styled.ul`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  row-gap: 14px;
  min-width: 220px;
  width: max-content;
  background: #ffffff;
  border: 1px solid #e4e5e8;
  box-sizing: border-box;
  box-shadow: 4px 4px 24px rgba(14, 16, 59, 0.07);
  border-radius: 8px;
  & li {
    display: flex;
    height: 24px;
    column-gap: 15px;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    & svg {
      color: ${_variables.grayTextColor};
    }
  }
`;
export const ContentMenu: CT<unknown> = ({ children }) => {
  return <Container>{children}</Container>;
};
