import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { statusIsModeration } from "domain/mailing";
import { clearReadyPushMail } from "services/store/createMailingSlice";
import { openModal } from "services/store/modal";
import { useAppDispatch } from "services/hooks";
import { clearUserCJMMailingData } from "services/store/cjm";
import { createStandardToast, createToastResponseError } from "lib/toastify";
import type { ApiMailingCreatePropType } from "services/mailing";
import { mailingService } from "services/mailing";

import { useNotification } from "ui/containers/common/useNotification";
import { Link } from "ui/components/Link";

export const useCreateMailig = () => {
  const dispatch = useAppDispatch();
  const { addNotification } = useNotification();
  const [isSuccessSend, setSuccessSend] = useState(false);
  const [isLoad, setLoad] = useState(false);
  const history = useHistory();

  const errorHandler = (e: any) => {
    if (e.response?.data?.code === 1004) {
      addNotification(
        <p>
          Необходимо настроить DNS-записи домена. <br />
          Для настройки перейдите в раздел{" "}
          <Link
            color={"primary"}
            link={
              "/main/user/setting?name=&page=1&per-page=25&sortBy=domain&tab=domains"
            }
          >
            Подтвержденные домены
          </Link>
        </p>
      );

      dispatch(clearReadyPushMail());
      return;
    }
    if (e.response?.data?.code === 1002) {
      addNotification(
        <p>
          Эта почта недоступна для использования. Нужно перейти по ссылке
          подтверждения, отправленной на этот ящик. Отправить письмо со ссылкой
          повторно можно в разделе{" "}
          <Link
            color={"primary"}
            link={
              "/main/user/setting?name=&page=1&per-page=25&sortBy=domain&tab=domains"
            }
          >
            Подтвержденные email
          </Link>{" "}
        </p>
      );
      dispatch(clearReadyPushMail());
      return;
    }
    if (e.response?.data?.code === 1001) {
      dispatch(openModal("not-count-mailing"));
    } else {
      createToastResponseError(e)();
    }
    dispatch(clearReadyPushMail());
  };

  const submitMailing = async (data: ApiMailingCreatePropType) => {
    try {
      setLoad(true);
      dispatch(clearUserCJMMailingData());
      const response = await mailingService.createMailing(data);
      if (statusIsModeration(response.data.data.mailing.status)) {
        dispatch(openModal("mailing-moderation"));
        return;
      }
      setSuccessSend(true);
      dispatch(openModal("mailing-success"));
    } catch (e) {
      errorHandler(e);
    } finally {
      setLoad(false);
    }
  };

  const saveMailing = async (data: ApiMailingCreatePropType) => {
    setSuccessSend(true);
    try {
      await mailingService.createMailing(data, 1);
      createStandardToast("Черновик сохранен")();
      setTimeout(() => {
        history.push("/");
      }, 500);
    } catch (e) {
      dispatch(clearReadyPushMail());
      if (e.response.data.code == 1001) {
        createStandardToast(
          <p>
            Рассылка сохранена в{" "}
            <Link
              color={"onlyPrimary"}
              link={"/mailing/?page=1&per-page=25&sortBy=name"}
            >
              черновики
            </Link>
          </p>
        )();
        setTimeout(() => {
          history.push("/");
        }, 1000);
        return;
      }
      toast(e.response.data.message, { autoClose: 3000, type: "warning" });
      console.log(e);
    }
  };

  return {
    submitMailing,
    saveMailing,
    isLoad,
    isSuccessSend,
  };
};
