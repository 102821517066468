import queryString from "query-string";
import type { useHistory } from "react-router-dom";

export const updateQueryParams = (
  history: ReturnType<typeof useHistory>,
  values: { [key: string]: string }
) => {
  const params = queryString.parse(history.location.search);
  const url = new URLSearchParams({
    ...params,
    ...values,
    updated: new Date().getTime().toString(),
  });

  history.push({
    ...history.location,
    search: url.toString(),
  });
};
