import useUrlState from "@ahooksjs/use-url-state";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { useAppDispatch } from "services/hooks";
import { createFormClientActions } from "services/store/createFormClientSlice";
import {
  userDomainDataStateAdapter,
  userFormService,
} from "services/userForms";
import { createToastResponseError } from "lib/toastify";

import { LoadContent } from "ui/components/LoadContent";
import { CreateFormPageContent } from "ui/pages/ClientFormPage/CreateFormPageContent";

const PreloadContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ClientFormPage = () => {
  const { id } = useParams<{ id?: string }>();
  const [isLoad, setLoad] = useState(Boolean(id));
  const dispatch = useAppDispatch();
  const [urlState] = useUrlState({ by: "" });

  const preloadData = async () => {
    // Пришли с конструктора, предполагается что дата есть
    if (urlState.by === "constructor") {
      setLoad(false);
      return;
    }
    // Если новый шаблон
    if (!Boolean(id)) return;

    try {
      const response = await userFormService.view(Number(id));
      const data = userDomainDataStateAdapter(
        response.data.data["subscribe-form"]
      );

      dispatch(createFormClientActions.setData(data));
      setLoad(false);
    } catch (e) {
      createToastResponseError(null, "Ошибка загрузки формы")();
    }
  };

  useEffect(() => {
    preloadData();
  }, []);

  if (isLoad) {
    return (
      <PreloadContainer>
        <LoadContent height={12} top={0} />
      </PreloadContainer>
    );
  }
  return <CreateFormPageContent />;
};
