import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import { deepEqual } from "lib/other";

export const UnAuthRoute: CT<unknown> = ({ children }) => {
  const user = useAppSelector(selectUserData, deepEqual);
  const history = useHistory();
  useEffect(() => {
    if (user) history.push("/main");
  }, [user]);

  return !user ? <>{children}</> : <></>;
};
