import React, { useEffect, useState } from "react";
import { useTitle } from "ahooks";
import styled from "styled-components";

import { clearAppError, selectAppError } from "services/store/appSlice";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { scroll } from "ui/styles/elements/scroll";
import { _variables } from "ui/styles/_variables";

import { ContentContainer } from "../../components/ContentContainer";
import { Wrapper } from "../../components/Wrapper";
import { Button } from "../../components/Buttons/Button";
import { appConfig } from "../../../appConfig";

const Content = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const Heading = styled.h1`
  font-size: 48px;
  font-weight: 600;
`;

const LogBox = styled.div`
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${_variables.strokeColor};
  opacity: 0.5;
  max-width: 100%;
  max-height: 500px;
  overflow: scroll;
  white-space: pre-wrap;
  ${scroll};
`;

const Link = styled.a`
  margin-top: 40px;
  width: fit-content;
`;
export const ErrorPage = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectAppError);
  const [data, setData] = useState<AppError | null>(null);

  useTitle("Ошибка приложения" + appConfig.titleApp);

  useEffect(() => {
    if (error) {
      setData(error);
    }
    return () => {
      dispatch(clearAppError());
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Wrapper>
        <Content>
          <Heading>Ой, что-то пошло не так</Heading>
          <Link href="/">
            <Button>Вернуться на главную</Button>
          </Link>
          {data && (
            <LogBox>
              <p>Статус: {data.status}</p>
              <p>Сообщение: {data.message}</p>
              <p>Лог: {data.log}</p>
            </LogBox>
          )}
        </Content>
      </Wrapper>
    </>
  );
};
