import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "./store";
import type { PayPlanType } from "domain/payPlan";

export type PlanStateType = {
  ab: string;
  plans: PayPlanType[];
};

const initialState = {
  plans: [],
  ab: "",
} as PlanStateType;

export const packagePlanSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    setData(_, action: PayloadAction<PlanStateType>) {
      return action.payload;
    },
  },
});

export const packagePlanActions = packagePlanSlice.actions;
export default packagePlanSlice.reducer;

export const selectPackagePlans = (state: RootState) => state.packagePlan.plans;
