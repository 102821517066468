import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

const Content = styled.tbody`
  display: contents;
  height: 64px;
  border-bottom: 1px solid ${_variables.strokeColor};
  & tr {
    display: contents;
    align-items: center;
    & td {
      transition: background-color ${_variables.transition};
      height: 64px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${_variables.strokeColor};
      &:first-child {
        padding-left: 10px;
      }
      &:last-child {
        padding-right: 10px;
      }
    }
  }
`;

export const TableBody: CT<unknown> = ({ children }) => {
  return <Content>{children}</Content>;
};
