import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "ahooks";
import styled from "styled-components";

import type { MailingType } from "domain/mailing";
import { mailingService } from "services/mailing";
import { getFormatNum } from "lib/numbers";
import { createDateForMail } from "lib/date";

import { _variables } from "ui/styles/_variables";
import { Icon } from "ui/components/Icon";
import { TableHead } from "ui/components/Table/TableHead";
import { TableBody } from "ui/components/Table/TableBody";
import { Button } from "ui/components/Buttons/Button";
import { useTableStateLoad } from "ui/hooks/useTableStateLoad";
import { LoadContent } from "ui/components/LoadContent";
import { NotContentTable } from "ui/components/NotContent/NotContentTable";

import { appConfig } from "../../../../../appConfig";

type MedalType = 1 | 2 | 3 | "other";

const ITable = styled.table`
  display: grid;
  grid-template-columns:
    minmax(420px, 100%) minmax(330px, 330px) calc(372px / 3)
    calc(372px / 3) calc(372px / 3);
  grid-auto-rows: min-content;
  margin-top: 32px;
  position: relative;
  z-index: 9;

  & tr:first-child td {
    margin-top: 4px;
  }

  & tr td {
    margin-bottom: 24px;
  }

  & tr td {
    height: auto;
    border-bottom: none;
    display: flex;
    align-items: flex-start !important;
  }
`;

const THead = styled(TableHead)`
  & th {
    border-bottom: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${_variables.grayTextColor};
  }
`;

const TBody = styled(TableBody)`
  margin-top: 16px;
  position: relative;
  top: -10px;

  & td {
    border-bottom: none;
  }
`;

const IFirstTD = styled.td`
  display: flex;
  column-gap: 16px;
  padding-right: 42px;
`;

const IMedal = styled.div<{ at: MedalType }>`
  display: flex;
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: ${(props) =>
    props.at === "other"
      ? `1px solid ${_variables.strokeColor};`
      : "1px solid transparent"}
  border-radius: 50%;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  border-radius: 50%;
  background: ${(props) => {
    if (props.at === "other") return "transparent";
    if (props.at === 1)
      return "linear-gradient(180deg, #F9DBA0 0%, #D1A349 100%)";
    if (props.at === 2)
      return "linear-gradient(180deg, #F6F7F8 0%, #E4E5E8 100%)";
    if (props.at === 3)
      return "linear-gradient(180deg, #F3AF94 0%, #BD717A 100%)";
  }};

`;

const IContentFirstTD = styled.div`
  display: flex;
  flex-direction: column;
`;

const IName = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 8px;
  max-width: 100%;
`;

const RepeatButton = styled(Button)`
  height: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
  color: ${_variables.grayTextColor};

  padding: 4px 8px;
`;

const IDate = styled.span`
  color: ${_variables.grayTextColor};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
`;

const INameTemplate = styled.td`
  color: ${_variables.grayTextColor};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  line-height: 20px;
  letter-spacing: 0px;
  padding-right: 72px;
  text-align: left;
`;

const IStatistic = styled.td`
  display: flex;
  flex-direction: column;
`;

const ICount = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const IPercent = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const TableRowContent: CT<{
  medal: MedalType;
  medalValue: number;
  data: MailingType;
}> = ({ medal, medalValue, data }) => {
  useTitle(`Лучшие рассылки${appConfig.titleApp}`);
  const history = useHistory();
  return (
    <tr>
      <IFirstTD>
        <IMedal at={medal}>{medalValue}</IMedal>
        <IContentFirstTD>
          <IName>{data.name}</IName>
          <div style={{ display: "flex", columnGap: 12 }}>
            {data.can_repeat && (
              <RepeatButton
                onClick={() => {
                  history.push(`/mailing/create-mailing?id=${data.id}`);
                }}
                variant={"secondary"}
              >
                <Icon
                  id={"repeat-icon"}
                  style={{ marginRight: 4 }}
                  width={16}
                  height={16}
                />
                Повторить рассылку
              </RepeatButton>
            )}

            <IDate>{createDateForMail(data.date_start)}</IDate>
          </div>
        </IContentFirstTD>
      </IFirstTD>
      <INameTemplate>{data.template_name}</INameTemplate>
      <IStatistic>
        <ICount>{getFormatNum(data.delivered)}</ICount>
        <IPercent>{data.delivered_percent}%</IPercent>
      </IStatistic>
      <IStatistic>
        <ICount>{getFormatNum(data.open)}</ICount>
        <IPercent>{data.open_percent}%</IPercent>
      </IStatistic>
      <IStatistic>
        <ICount>{getFormatNum(data.unsubscribe)}</ICount>
        <IPercent>{data.unsubscribe_percent}%</IPercent>
      </IStatistic>
    </tr>
  );
};

export const HistoryTopContent: CT<unknown> = () => {
  const [data, setData] = useState<MailingType[]>([]);
  const { setOffLoad, setOnLoad, isLoad, isNotHaveContent } =
    useTableStateLoad(data);

  const loadData = async () => {
    setData([]);
    setOnLoad();
    try {
      const list = await mailingService.top();
      setData(list.data.data.history);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoad) return <LoadContent top={120} />;

  if (isNotHaveContent)
    return <NotContentTable>У вас пока нет рассылок</NotContentTable>;

  return (
    <ITable>
      <THead>
        <th>Название письма</th>
        <th>Название шаблона</th>
        <th>Доставлено</th>
        <th>Открыто</th>
        <th>Отписалось</th>
      </THead>
      <TBody>
        {data.map((item, i) => (
          <TableRowContent
            key={item.id + item.name}
            //@ts-ignore
            medal={i + 1 > 3 ? "other" : i + 1}
            medalValue={i + 1}
            data={item}
          />
        ))}
      </TBody>
    </ITable>
  );
};
