import { useState } from "react";

export const useTableStateLoad = (data: any[], findString = "") => {
  const [isLoad, setLoad] = useState(true);
  const isNotHaveContent =
    !isLoad && !findString.length && !Boolean(data.length);
  const isShowContent = !isLoad && !!data.length;
  const isNotFound = !isLoad && !!findString.length && !Boolean(data.length);
  const setOnLoad = () => {
    setLoad(true);
  };
  const setOffLoad = () => {
    setLoad(false);
  };

  return {
    isLoad,
    isShowContent,
    isNotHaveContent,
    isNotFound,
    setOnLoad,
    setOffLoad,
  };
};
