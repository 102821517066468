import * as Yup from "yup";
import styled from "styled-components";
import { useFormik } from "formik";

import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  createFormClientActions,
  selectFormClientState,
} from "services/store/createFormClientSlice";

import { _variables } from "ui/styles/_variables";
import { Input } from "ui/components/FormsElements/Input";
import { Button } from "ui/components/Buttons/Button";

type ValuesType = {
  name: string;
};

const IFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${_variables.backgroundColor};
  border-radius: 12px;
  padding: 32px;
  width: 676px;
  row-gap: 16px;
  margin-bottom: 20px;
  & .label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 24px;
  }
`;

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

export const InputNameStep = () => {
  const dispatch = useAppDispatch();
  const name = useAppSelector(selectFormClientState).name;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Поле обязательно для заполнения"),
  });

  const submitHandler = (data: ValuesType) => {
    dispatch(createFormClientActions.setName(data.name));
    dispatch(createFormClientActions.goToConstructor());
  };

  const formik = useFormik({
    initialValues: {
      name: name || "",
    },
    validateOnChange: false,
    onSubmit: submitHandler,
    validationSchema: validationSchema,
  });

  // useEffect(() => {
  //   if (!createMailingData.data?.templateStep) {
  //     history.push("/templates/cards?by=import");
  //   }
  // }, [createMailingData]);

  const isReadySend = !!formik.values.name.length;

  return (
    <Content>
      <form
        action=""
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <IFormWrapper>
          <Input
            name={"name"}
            color={"white"}
            value={formik.values.name}
            onChange={(e) => {
              formik.handleChange(e);
              setTimeout(() => {
                formik.validateField("name");
              }, 0);
            }}
            label={"Придумайте название"}
            placeholder={"Введите название"}
          />
        </IFormWrapper>
        <Button disabled={!formik.isValid || !isReadySend} type={"submit"}>
          Продолжить
        </Button>
      </form>
    </Content>
  );
};
