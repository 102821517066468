// Уровень модальных окон
// Компонент подключается на верхнем уровне приложения
// Слушает URL строку, параметр event.
// При прослушивании вызывает необходимые модальные окна
import { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { usePayValidationRedirect } from "app/usePayValidationRedirect";
import { getFormatNum } from "lib/numbers";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { clearModalData, selectModalData } from "services/store/modalDataSlice";
import { clearModal, selectOpenModal } from "services/store/modal";
import {
  clearTemplateMailingData,
  setOpenAutoMailing,
} from "services/store/createMailingSlice";

import { Modal } from "ui/components/Modal";
import { ChangePasswordForm } from "../forms/ChangePasswordForm";
import { UserSettings } from "../forms/UserSettings";
import { DeleteLists } from "../forms/DeleteLists";
import { CreateList } from "../forms/CreateList";
import { DeleteContacts } from "../forms/DeleteContacts";
import { RenameListForm } from "../forms/RenameListForm";
import { MoveListMove } from "../forms/MoveListForm";
import { DeleteTemplates } from "../forms/DeleteTemplates";
import { CreateTemplate } from "../forms/CreateTemplateForm";
import { BackToLinkForm } from "../forms/BackToLinkForm";
import { EditTemplate } from "../forms/EditTemplateForm";
import { CancelMailingForm } from "../forms/CancelMailing";
import { CreateTestMailing } from "../forms/CreateTestMailing";
import { MailingFinishPlaning } from "ui/components/AlertsModal/MailingFinishPlaning";
import { MailingFinishSend } from "ui/components/AlertsModal/MailingFinishSend";
import { MailingSendSuccess } from "ui/components/AlertsModal/MailingSendSuccess";
import { MailingModeration } from "ui/components/AlertsModal/MailingModeration";
import { DeleteEmail } from "../forms/DeleteEmail";
import { AddEmail } from "../forms/AddEmail";
import { DeleteDraft } from "../forms/DeleteDraft";
import { MailingNotCount } from "ui/components/AlertsModal/MailingNotCount";
import { DKIMDomain } from "ui/components/AlertsModal/DKIMDomain";
import { PaymentSuccessAlert } from "ui/components/AlertsModal/PaymentSuccessAlert";
import { MailingStatusList } from "../forms/MailingStatusList";
import { ProjectStatusList } from "../forms/ProjectStatusList";
import { DuplicateForm } from "../forms/DuplicateForm";
import { RegistrationScenarioUser } from "../forms/RegistrationScenarioUser";
import { DomainSettingNotify } from "../forms/DomainSettingNotify";
import { PromptPayValidate } from "../forms/PromptPayValidate";
import { DeleteUnValidateContacts } from "../forms/DeleteUnValidateContacts";
import { RedirectAfterValidateContacts } from "../forms/RedirectAfterValidateContacts";
import { TestMailing } from "../forms/TestMailingFromTemplate";
import { PayPlanForm } from "ui/containers/forms/PayPlanForm";
import { UnsubscribeCardForm } from "ui/containers/forms/UnsubscribeCardForm";
import { SelectEditor } from "../forms/SelectEditor";
import { UserNotifyContent } from "ui/containers/forms/UserNotifyContent";
import { AutoMailingManagerForm } from "ui/containers/forms/AutoMailing/AutoMailingManagerForm";
import FormSubscription from "../forms/FormSubscription";
import MailingFilter from "../forms/MailingFilter";
import { DeleteClientForm } from "ui/containers/forms/DeleteClientForm";
import { MergeLists } from "ui/containers/forms/MergeLists";

export const ModalLayer: CT<unknown> = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const payValidationRedirect = usePayValidationRedirect();
  const dispatch = useAppDispatch();
  const openModal = useAppSelector(selectOpenModal);
  const modalData = useAppSelector(selectModalData);
  const history = useHistory();

  const closeModalHandler = () => {
    dispatch(clearModalData());
    dispatch(clearModal());
    setOpen(false);
  };

  const closeAutoMailingHandler = () => {
    dispatch(setOpenAutoMailing(false));
    dispatch(clearModalData());
    dispatch(clearModal());
    setOpen(false);
  };

  const closePaymentSuccessHandler = () => {
    dispatch(clearModalData());
    dispatch(clearModal());
    setOpen(false);
    history.push("/");
  };

  const closeSuccessHandler = () => {
    dispatch(clearModalData());
    dispatch(clearModal());
    dispatch(clearTemplateMailingData);
    history.push("/main?by=import");
    setOpen(false);
  };

  const closeWithoutRedirect = () => {
    dispatch(clearModalData());
    dispatch(clearModal());
    dispatch(clearTemplateMailingData);
    setOpen(false);
  };
  const closeAddMailModal = () => {
    dispatch(clearModalData());
    dispatch(clearModal());
    dispatch(clearTemplateMailingData);
    setOpen(false);
    history.push("/main/user/setting?tab=emails");
  };

  const closeTestMailingModal = () => {
    dispatch(clearModalData());
    dispatch(clearModal());
    dispatch(clearTemplateMailingData);
    setOpen(false);
  };

  const closeRedirectAfterValidateContacts = () => {
    payValidationRedirect.clearInfo();
    setTimeout(() => {
      closeModalHandler();
    }, 100);
  };

  const closeSelectEditorModal = () => {
    dispatch(clearModalData());
    dispatch(clearModal());
    setOpen(false);
  };

  const closeMailingFilterModal = () => {
    dispatch(clearModalData());
    dispatch(clearModal());
    setOpen(false);
  };

  const closeFormSubscriptionModal = () => {
    dispatch(clearModalData());
    dispatch(clearModal());
    setOpen(false);
  };

  const eventsClose: { [key: string]: VoidFunction } = {
    "mailing-success": closeSuccessHandler,
    "mailing-moderation": closeSuccessHandler,
    "add-email": closeAddMailModal,
    "payment-success": closePaymentSuccessHandler,
    "auto-mailing-form": closeAutoMailingHandler,
  };

  const event: {
    [key: string]: {
      content: JSX.Element;
      title: string;
    };
  } = {
    "change-password": {
      title: "Смена пароля",
      content: <ChangePasswordForm onClose={closeModalHandler} />,
    },
    "create-list": {
      title: "Создать список",
      content: <CreateList onClose={closeModalHandler} />,
    },
    "create-list-import": {
      title: "Создать список",
      content: (
        <CreateList redirectToImport={true} onClose={closeModalHandler} />
      ),
    },
    "user-setting": {
      title: "Настройка аккаунта",
      content: <UserSettings onClose={closeModalHandler} />,
    },
    "delete-contacts": {
      title: "Удалить контакты",
      content: <DeleteContacts onClose={closeModalHandler} />,
    },
    "rename-list": {
      title: "Переименовать список",
      content: <RenameListForm onClose={closeModalHandler} />,
    },
    "move-list": {
      title: "Переместить контакты",
      content: <MoveListMove onClose={closeModalHandler} />,
    },
    "delete-list": {
      title: "Удаление списков",
      content: <DeleteLists onClose={closeModalHandler} />,
    },
    "merge-list": {
      title: "Объединить списки",
      content: <MergeLists onClose={closeModalHandler} />,
    },
    "delete-templates": {
      title: "Удаление шаблонов",
      content: <DeleteTemplates onClose={closeModalHandler} />,
    },
    "create-template": {
      title: "Создание шаблона",
      content: <CreateTemplate onClose={closeModalHandler} />,
    },
    "edit-template": {
      title: "Редактирование шаблона",
      content: <EditTemplate onClose={closeModalHandler} />,
    },
    "back-to-link": {
      title: modalData?.title ?? "",
      content: <BackToLinkForm onClose={closeModalHandler} />,
    },
    "cancel-mailing": {
      title: "Отмена рассылки",
      content: <CancelMailingForm onClose={closeModalHandler} />,
    },
    "not-count-mailing": {
      title: "",
      content: <MailingNotCount onClose={closeModalHandler} />,
    },
    "delete-draft": {
      title: "Удаление черновика",
      content: <DeleteDraft onClose={closeModalHandler} />,
    },
    "create-test-mailing": {
      title: "Отправить тестовое письмо",
      content: <CreateTestMailing onClose={closeModalHandler} />,
    },
    "test-mailing-form-template": {
      title: "Отправить тестовое письмо",
      content: <TestMailing onClose={closeTestMailingModal} />,
    },
    "final-mailing-paling": {
      title: "",
      content: <MailingFinishPlaning onClose={closeModalHandler} />,
    },
    "final-mailing-send": {
      title: "",
      content: <MailingFinishSend onClose={closeModalHandler} />,
    },
    "mailing-success": {
      title: "",
      content: (
        <MailingSendSuccess
          onCloseLink={closeWithoutRedirect}
          onClose={closeSuccessHandler}
        />
      ),
    },
    "mailing-moderation": {
      title: "",
      content: <MailingModeration onClose={closeSuccessHandler} />,
    },
    "delete-email": {
      title: "",
      content: <DeleteEmail onClose={closeModalHandler} />,
    },
    "add-email": {
      title: "Добавить email",
      content: <AddEmail onClose={closeAddMailModal} />,
    },
    dkim: {
      title: "Настройка DKIM & SPF",
      content: <DKIMDomain onClose={closeModalHandler} />,
    },
    "payment-success": {
      title: "",
      content: <PaymentSuccessAlert onClose={closePaymentSuccessHandler} />,
    },
    "list-status-mailing": {
      title: `Контакты: ${getFormatNum(modalData?.count ?? 0)}`,
      content: <MailingStatusList onClose={closeModalHandler} />,
    },
    "list-status-project": {
      title: `Контакты: ${getFormatNum(modalData?.count ?? 0)}`,
      content: <ProjectStatusList onClose={closeModalHandler} />,
    },
    "pay-validate-list": {
      title: `Оплата проверки`,
      content: <PromptPayValidate onClose={closeModalHandler} />,
    },
    "duplicate-template": {
      title: "Дублирование шаблона",
      content: <DuplicateForm onClose={closeModalHandler} />,
    },
    "domain-setting": {
      title: "Настройка домена",
      content: <DomainSettingNotify onClose={closeModalHandler} />,
    },
    "delete-unValidate-contacts": {
      title: "Удалить контакты?",
      content: <DeleteUnValidateContacts onClose={closeModalHandler} />,
    },
    "user-pay-plan": {
      title: "Способ оплаты",
      content: <PayPlanForm onClose={closeModalHandler} />,
    },
    "unsubscribe-card": {
      title: "Отвязать банковскую карту?",
      content: <UnsubscribeCardForm onClose={closeModalHandler} />,
    },
    "pay-with-card": {
      title: "Удалить контакты?",
      content: <DeleteUnValidateContacts onClose={closeModalHandler} />,
    },
    "redirect-after-validate-pay": {
      title: "Проверка контактов",
      content: (
        <RedirectAfterValidateContacts
          onClose={closeRedirectAfterValidateContacts}
        />
      ),
    },
    "select-editor-type": {
      title: "Выберите редактор",
      content: <SelectEditor onClose={closeSelectEditorModal} />,
    },
    "auto-mailing-form": {
      title: `${modalData?.title ?? "Автоматический прогрев"}`,
      content: <AutoMailingManagerForm onClose={closeAutoMailingHandler} />,
    },

    // *** Пользовательские уведомления
    "registration-scenario-notify-modal": {
      title: `${modalData?.title}`,
      content: <RegistrationScenarioUser onClose={closeModalHandler} />,
    },
    "user-notify": {
      title: `${modalData?.title}`,
      content: <UserNotifyContent onClose={closeModalHandler} />,
    },
    "mailing-filter": {
      title: "Фильтровать",
      content: <MailingFilter onClose={closeMailingFilterModal} />,
    },
    "form-subscription": {
      title: `${modalData?.title}`,
      content: <FormSubscription onClose={closeFormSubscriptionModal} />,
    },
    "delete-client-form": {
      title: "Удалить форму подписки",
      content: <DeleteClientForm onClose={closeMailingFilterModal} />,
    },
  };

  useLayoutEffect(() => {
    if (event?.[openModal ?? ""]) {
      setOpen(true);
    }
    //eslint-disable-next-line
  }, [openModal]);

  // modalData.isNotShowClose - отключает крестик выхода
  // modalData.widthContent - Устанавливает ширину контента модалки
  return (
    <>
      {children}
      <>
        {isOpen && (
          <Modal
            widthContent={
              modalData?.widthContent === undefined
                ? "auto"
                : modalData?.widthContent
            }
            isNotShowClose={
              modalData?.isNotShowClose === undefined
                ? false
                : modalData.isNotShowClose
            }
            title={event?.[openModal ?? ""]?.title}
            onClose={eventsClose[openModal] ?? closeModalHandler}
            notContentPadding={modalData && modalData.noPaddingContent}
          >
            {event?.[openModal ?? ""]?.content}
          </Modal>
        )}
      </>
    </>
  );
};
