import { appConfig } from "../../../appConfig";
import { useEffect } from "react";

export const useSetAppStorageData = () => {
  // Пути до CDN прокидываются, чтобы внешние плагины могли им пользоваться
  // CKeditor
  const setCDNPathData = () => {
    localStorage.setItem("CDNPath", appConfig.cdnCKEditorPath);
  };

  useEffect(() => {
    setCDNPathData();
  }, []);
};
