import ReactPaginate from "react-paginate";
import styled from "styled-components";

import { Icon } from "./Icon";

const PrevButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

const NextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transform: rotate(180deg);
`;

export const Prev: CT<unknown> = () => {
  return (
    <PrevButton>
      <Icon id={"arrow-left-icon"} width={24} height={24} />
    </PrevButton>
  );
};

export const Next: CT<unknown> = () => {
  return (
    <NextButton>
      <Icon id={"arrow-left-icon"} width={24} height={24} />
    </NextButton>
  );
};

export const ContentPagination: CT<{
  pageCount: number;
  onClick?: (num: number) => void;
  force?: number;
}> = ({ pageCount, onClick, force = 0 }) => {
  const onChangeHandler = ({
    isActive,
    selected,
  }: {
    isActive: boolean;
    selected: number;
  }) => {
    if (isActive) return;
    onClick && onClick(selected + 1);
  };

  return (
    <ReactPaginate
      className={"pagination"}
      breakLabel="..."
      forcePage={force}
      nextLabel={<Next />}
      activeClassName={"active"}
      pageRangeDisplayed={4}
      pageCount={pageCount}
      onPageChange={onChangeHandler}
      marginPagesDisplayed={1}
      previousLabel={<Prev />}
    />
  );
};
