import styled from "styled-components";
import cn from "classnames";

import { scroll } from "ui/styles/elements/scroll";

const Container = styled.div`
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  scroll-behavior: smooth;
  padding-right: 29px;

  ${scroll}
`;

export const TableContainerScroll: CT<{
  showScroll?: boolean;
  height: number;
}> = ({ height, showScroll = true, children, className }) => {
  return (
    <Container
      className={cn(showScroll ? "" : "hidden", className)}
      style={{
        height: `calc(100vh - ${height ?? 100}px)`,
      }}
    >
      {children}
    </Container>
  );
};
