import styled from "styled-components";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import useUnlayerEditor from "app/features/unlayerEditor";
import { useAppDispatch } from "services/hooks";
import { userFormService } from "services/userForms";
import { createFormClientActions } from "services/store/createFormClientSlice";
import { useCreateClientFormPageState } from "app/createClientForm/useCreateClientFormPageState";
import { createToastResponseError } from "lib/toastify";

import { HeaderPopupCreator } from "ui/components/TemplateEditor/HeaderPopupCreator";
import { LoadContent } from "ui/components/LoadContent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Load = styled(LoadContent)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PopupCreator: CT<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { data } = useCreateClientFormPageState();
  const [isShowMode, setShowMode] = useState(false);
  const [hasInit, setInit] = useState(false);

  const { id } = useParams<{ id?: string }>();

  const { Editor, exportHtml, loadDesign, isPreloadScript } = useUnlayerEditor({
    options: {
      id: "popup-wrapper",
      displayMode: "popup",
    },
    //@ts-ignore
    tools: {
      closeButton: {
        enabled: false,
      },
      form: {
        usageLimit: 1,
        enabled: true,
        properties: {
          //@ts-ignore
          fields: {
            //@ts-ignore
            value: [
              {
                name: "name",
                type: "text",
                label: "Имя",
                placeholder_text: "Имя",
                show_label: true,
                required: true,
              },
              {
                name: "email",
                type: "email",
                label: "Email",
                placeholder_text: "Email",
                show_label: true,
                required: true,
              },
            ],
            editor: {
              data: {
                allowCustomUrl: false,
                allowAddNewField: false,
                defaultFields: [
                  { name: "email", label: "Email", type: "email" },
                  { name: "name", label: "Имя", type: "text" },
                ],
              },
            },
          },
        },
      },
    },

    onReady() {
      // Подгрузка шаблона, если он существует
      if (hasInit) return;
      setTimeout(() => {
        if (!!data.data) {
          loadDesign(data.data.design);
          setInit(true);
          return;
        }
        // Если заход был сразу на конструктор, то подгрузить
        if (!Boolean(data.data) && Number(id)) {
          (async () => {
            try {
              const response = await userFormService.view(Number(id));
              setShowMode(true);
              setInit(true);
              loadDesign(
                response.data.data["subscribe-form"].settings.data.design
              );
            } catch (e) {
              createToastResponseError(e)();
            }
          })();
        }
        localStorage.setItem("popupInit", "1");
        setInit(true);
      }, 500);
    },
  });

  const saveHandler = (callback: VoidFunction) => {
    exportHtml((val) => {
      dispatch(createFormClientActions.setFormData(val));
      callback();
    });
  };

  const goToNextHandler = () => {
    saveHandler(() => {
      dispatch(createFormClientActions.goToChooseFormat());

      if (id) {
        history.push(`/forms/create/${id}?by=constructor`);
        return;
      }
      history.push("/forms/create?by=constructor");
    });
  };

  const goToBackHandler = () => {
    saveHandler(() => {
      dispatch(createFormClientActions.goToNameChange());
      if (id) {
        history.push(`/forms/create/${id}?by=constructor`);
        return;
      }
      history.push("/forms/create?by=constructor");
    });
  };

  const closeShowHandler = () => {
    history.push("/");
  };

  useEffect(() => {
    // Если пользователь попал на страницу вне конструктора
    if (!data?.name && !Number(id)) history.push("/forms/create");
    return () => {
      localStorage.setItem("popupInit", "0");
    };
  }, [data]);

  return (
    <Container>
      <HeaderPopupCreator
        isLoad={isPreloadScript || !hasInit}
        isShowMode={isShowMode}
        onClickCloseShow={closeShowHandler}
        onClickBackButton={goToBackHandler}
        onClickSaveButton={goToNextHandler}
      />
      {isPreloadScript && <Load height={12} top={0} />}
      {Editor}
    </Container>
  );
};
