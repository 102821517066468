import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { createToastResponseError } from "lib/toastify";
import { useValidEmail } from "app/useValidEmail";
import { mailingService } from "services/mailing";
import { useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import { _variables } from "ui/styles/_variables";

import { InputMailsTextArea } from "../specialFormElements/InputMailsTextArea";
import { Button } from "../../components/Buttons/Button";
import { InputUserMails } from "../specialFormElements/InputUserMails";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const SubText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.25px;
  text-align: left;
  color: ${_variables.blackColor};
  opacity: 0.5;
  margin-top: 10px;
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
`;

export const TestMailing: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const [isLoad, setLoad] = useState(false);
  const [values, setValues] = useState<
    React.ComponentProps<typeof InputMailsTextArea>["mails"]
  >([]);

  const searchParams = new URLSearchParams(document.location.search);
  const templateId = searchParams.get("id");

  const userData = useAppSelector(selectUserData, shallowEqual);

  const validationEmail = useValidEmail();

  const validationSchema = Yup.object().shape({
    address: Yup.mixed().test(
      "bla",
      "Необходимо выбрать email адрес",
      async (val) => {
        return await validationEmail(val);
      }
    ),
  });

  const submitHandler = async ({ address }: { address: string }) => {
    const data = {
      email: address,
      emails: values.map((value) => value.email),
      name: "тестовое письмо",
      template_id: +templateId!,
      from: "тест",
    };
    try {
      setLoad(true);
      const response = await mailingService.testMailing(data);
      toast(response.data.message, { autoClose: 3000 });
      onClose();
    } catch (e) {
      createToastResponseError(e)();
      console.log(e);
    } finally {
      setLoad(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      address: "",
    },
    validateOnChange: false,
    onSubmit: submitHandler,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    (async () => {
      try {
        await mailingService.getDefaultEmail();
        await formik.setFieldValue("address", "");
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const removeHandler = (id: number) => {
    setValues(values.filter((item) => item.id !== id));
  };
  const addHandler = (name: string) => {
    if (values.length > 5) return;
    setValues([...values, { email: name, id: values.length + 1 }]);
  };

  useEffect(() => {
    setValues([{ id: 0, email: userData!.email }]);
  }, []);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <InputUserMails
        name={"address"}
        label={"Адрес отправителя"}
        placeholder={"Введите с какого адреса будет отправка"}
        onBlur={() => formik.validateField("address")}
        error={formik.errors.address}
        value={formik.values.address}
        onChange={(val: string) => {
          formik.setFieldValue("address", val);
        }}
      />
      <InputMailsTextArea
        onAdd={addHandler}
        onRemove={removeHandler}
        mails={values}
      />
      <SubText>Впишите новый адрес и нажмите enter</SubText>
      <Buttons>
        <Button disabled={!values.length || isLoad} isLoad={isLoad}>
          Отправить письмо
        </Button>
        <Button onClick={onClose} variant={"secondary"}>
          Отменить
        </Button>
      </Buttons>
    </Form>
  );
};
