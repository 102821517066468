import styled from "styled-components";
import { Button } from "ui/components/Buttons/Button";

const Container = styled.div`
  display: flex;
  height: 68px;
  width: 100%;
  //position: sticky;
  //top: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
`;

const WrapperButton = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const HeaderPopupCreator: CT<{
  isLoad: boolean;
  isShowMode?: boolean;
  onClickSaveButton: VoidFunction;
  onClickBackButton: VoidFunction;
  onClickCloseShow: VoidFunction;
}> = ({
  isLoad = false,
  onClickSaveButton,
  onClickCloseShow,
  isShowMode = false,
  onClickBackButton,
}) => {
  return (
    <Container>
      <WrapperButton>
        {isShowMode && (
          <Button disabled={isLoad} onClick={onClickCloseShow}>
            Закончить просмотр
          </Button>
        )}
        {!isShowMode && (
          <>
            <Button
              disabled={isLoad}
              variant={"secondary"}
              onClick={onClickBackButton}
            >
              Назад
            </Button>
            <Button disabled={isLoad} onClick={onClickSaveButton}>
              Далее
            </Button>
          </>
        )}
      </WrapperButton>
    </Container>
  );
};
