import type { AxiosResponse } from "axios";

const isBadStatus = (status: number) => status >= 400;

export type MailingFilters = {
  name?: string;
  book_id?: string;
  status?: string;
  dateFrom?: string;
  dateTo?: string;
};

export const isBadBodyStatusResponse = (data: ApiResponseBodyType<unknown>) =>
  isBadStatus(data.status);

export const isBadStatusResponse = (res: AxiosResponse) =>
  isBadStatus(res.status);

export type ServiceListPropTypes<T> = {
  page?: number;
  perPage?: number;
  sortBy?: string;
  search?: string;
} & MailingFilters &
  T;
