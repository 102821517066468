import styled from "styled-components";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";

import { useGetNews } from "app/news/useGetNews";
import { createDateForNews } from "lib/date";

import { styleNewsContent } from "ui/pages/News/Content/styleContent";
import { useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import { useHistory } from "react-router-dom";
import { _variables } from "ui/styles/_variables";
import { useUserWorkflow } from "app/user/useUserWorkflow";
import { deepEqual } from "lib/other";

const IContent = styled.div`
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  width: 100%;
  height: min-content;
`;

const ITitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 16px;
`;

const ITitleRed = styled(ITitle)`
  color: ${_variables.supportColors.red};
`;

const LikeBox = styled.div<{ isLike: boolean }>`
  display: flex;
  margin-bottom: 32px;
  & span {
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    color: #777991;
  }
  & .like {
    cursor: pointer;
    & .heart {
      color: ${(props) =>
        props.isLike ? _variables.supportColors.red : "inherit"};
    }
    &:hover {
      & .heart {
        color: ${_variables.supportColors.red};
      }
    }
  }
`;

const INewsWrapper = styled.div`
  ${styleNewsContent}
`;
export const MainNewsContent: CT<{ pageIsReady: boolean; idNews: number }> = ({
  idNews,
  className,
  pageIsReady,
}) => {
  const userData = useAppSelector(selectUserData, deepEqual);
  const userWorkflow = useUserWorkflow();
  const { data, isLoad, view, likeNews, onUnLikeNews, onLikeNews, isLike } =
    useGetNews();
  const history = useHistory();

  const toggleLikeHandler = () => {
    if (isLike) {
      onUnLikeNews(data!.id);
      return;
    }
    onLikeNews(data!.id);
  };

  useEffect(() => {
    if (!data) return;
    if (!userData!.is_admin && !data.isReady) {
      history.push("/news");
    }
  }, [userData, data]);

  useEffect(() => {
    if (!pageIsReady || !Boolean(idNews)) return;
    view(idNews);
    userWorkflow.profile();
  }, [idNews]);

  if (!data || isLoad || !pageIsReady)
    return (
      <IContent className={className}>
        <ITitle>
          <Skeleton height={20} width={570} />
        </ITitle>
        <LikeBox isLike={false}>
          <Skeleton height={9} width={150} />
        </LikeBox>
        <div>
          <div style={{ width: "100%", marginBottom: 24 }}>
            <Skeleton height={289} style={{ borderRadius: "17px" }} />
          </div>
          <Skeleton height={12} style={{ marginBottom: 6 }} />
          <Skeleton height={12} style={{ marginBottom: 6 }} />
          <Skeleton height={12} style={{ marginBottom: 6, width: "85%" }} />
          <Skeleton height={12} style={{ marginBottom: 6, width: "55%" }} />
        </div>
      </IContent>
    );

  const likeString = (
    <span>
      {`•`}&nbsp;
      <span onClick={toggleLikeHandler} className={"like"}>
        <span className={"heart"}>♥</span>&nbsp;
        {!!likeNews ? likeNews : ""}
      </span>
    </span>
  );

  return (
    <IContent className={className}>
      {!data.isReady && <ITitleRed>(Не опубликовано)</ITitleRed>}
      <ITitle>{data.title}</ITitle>
      <LikeBox isLike={isLike}>
        <span>
          {createDateForNews(data.dateIn)} {likeString}
        </span>
      </LikeBox>
      <INewsWrapper dangerouslySetInnerHTML={{ __html: data.content }} />
    </IContent>
  );
};
