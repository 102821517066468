import type { CSSProperties } from "react";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

const Container = styled.div`
  display: flex;
  padding: 18px 32px;
  background-color: ${_variables.blackColor};
  position: relative;
  border-radius: 12px;
`;
const Triangle = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  content: "";
  border-right: 12px solid ${_variables.blackColor};
  bottom: -16px;
  transform: rotate(-90deg);
  right: 25px;
`;
const TriangleTop = styled(Triangle)`
  transform: rotate(90deg);
  bottom: auto;
  top: -16px;
`;
const Text = styled.p`
  font-size: 14px;
  color: white;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  & span {
    color: ${_variables.additionalColors.orange};
  }
`;

export const BlackNotify: CT<{
  style?: CSSProperties;
  right?: number;
  triangePosition?: "top" | "bottom";
}> = ({
  children,
  triangePosition = "bottom",
  className,
  style,
  right = 25,
}) => (
  <Container style={style} className={className}>
    {triangePosition === "bottom" && <Triangle style={{ right: right }} />}
    {triangePosition === "top" && <TriangleTop style={{ right: right }} />}

    <Text>{children}</Text>
  </Container>
);
