import { useCallback, useState } from "react";
import NumberFormat from "react-number-format";

import { Input } from "../../components/FormsElements/Input";

export const TelephoneInput: CT<{
  onChange?: (val: string) => void;
  onBlur?: (val: string) => void;
  value?: string;
  label?: string;
  error?: string;
  placeholder?: string;
}> = ({ value, error, label, placeholder, onChange, onBlur }) => {
  const [curValue, setCurrentValue] = useState(value ?? "");

  const InputCustom = useCallback(
    (props: any) => (
      <Input label={label} error={error} placeholder={placeholder} {...props} />
    ),
    [error]
  );

  return (
    <>
      <NumberFormat
        onValueChange={(val) => {
          setCurrentValue(val.value);
        }}
        onBlur={() => {
          onBlur && onBlur(curValue);
        }}
        onChange={() => {
          onChange && onChange(curValue);
        }}
        value={curValue}
        format="+7 (###) ###-##-##"
        mask="_"
        customInput={InputCustom}
      />
    </>
  );
};
