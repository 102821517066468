import { useAppDispatch, useAppSelector } from "services/hooks";

import {
  importStoreAction,
  selectImportState,
  selectImportStep,
} from "services/store/importSlice";

/**
 * Отвечает за логику шагов и консистентности стора в странице импорта
 * переиспользуется в других хуках -> не стоит добавлять сайд эффектов
 */
export const useImportPageState = () => {
  const step = useAppSelector(selectImportStep);
  const importData = useAppSelector(selectImportState);
  const { importMode } = importData;

  const dispatch = useAppDispatch();

  /*
    Меняет режим импорта -> с файла/вручную
   */
  const onChangeModeImport = (val: string) => {
    if (val === importMode) return;
    dispatch(importStoreAction.setModeImport(val as "write" | "load"));
    dispatch(importStoreAction.clearAllWithoutWrite());
  };

  return {
    state: {
      step,
      importData,
    },
    action: {
      onChangeModeImport,
    },
  };
};
