import { useHistory } from "react-router-dom";
import { useEffect } from "react";

export const ConstructorStep: CT<{ id?: number }> = ({ id }) => {
  const history = useHistory();
  useEffect(() => {
    if (id) {
      history.push(`/forms/constructor/${id}`);
      return;
    }
    history.push("/forms/constructor");
  });
  return <></>;
};
