import type { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";

const Content = styled.button<{ widthMode?: "full" | "content" }>`
  color: ${_variables.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.25px;
  padding: 12px;
  text-align: center;
  cursor: pointer;
  border: 1px solid ${_variables.primaryColor};
  border-radius: ${_variables.borderRadius};
  transition: all ${_variables.transition};
  width: ${(props) => (props.widthMode === "full" ? "100%" : "fit-content")};
  background: rgba(${hexToRgba(_variables.primaryColor, 0.1)});
  height: 48px;
  &:hover {
    color: white;
    background-color: ${_variables.primaryColor};
  }
`;
export const ButtonOpacity: CT<
  { widthMode?: "full" | "content" } & ButtonHTMLAttributes<unknown>
> = ({ widthMode, children, ...props }) => {
  return (
    <Content widthMode={widthMode} {...props}>
      {children}
    </Content>
  );
};
