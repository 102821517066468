import { useState } from "react";

import type { MailItemViewType } from "domain/mailing";
import { mailingService } from "services/mailing";
import { createToastResponseError } from "lib/toastify";

export const useAnalyticMailing = () => {
  const [dataMailing, setDataMailing] = useState<MailItemViewType | null>(null);

  const loadData = async (id: string | number) => {
    try {
      const data = await mailingService.viewMailing(id);
      setDataMailing(data.data.data.mailing);
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  return {
    loadData,
    dataMailing,
  };
};
