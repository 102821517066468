import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { TemplateViewStateType } from "services/store/createMailingSlice";
import type { RootState } from "services/store/store";

type firstStepMailingData = NonNullable<
  TemplateViewStateType["data"]
>["firstStep"];
type secondStepMailingData = NonNullable<
  TemplateViewStateType["data"]
>["secondStep"];

//Customer Journey Map
//State для сквозных клиентских действий
export type CJMType = {
  savedUserMailingForm: {
    firstStep: firstStepMailingData | null;
    secondStep: secondStepMailingData | null;
  } | null;
};

const initialState = {
  savedUserMailingForm: null,
} as CJMType;

export const cjmSlice = createSlice({
  name: "cjm",
  initialState,
  reducers: {
    saveUserMailingData(
      state,
      action: PayloadAction<{
        firstStep: firstStepMailingData | null;
        secondStep: secondStepMailingData | null;
      }>
    ) {
      return {
        ...state,
        savedUserMailingForm: {
          ...action.payload,
        },
      };
    },
    clearUserCJMMailingData(state) {
      return {
        ...state,
        savedUserMailingForm: null,
      };
    },
  },
});

export default cjmSlice.reducer;

export const { saveUserMailingData, clearUserCJMMailingData } =
  cjmSlice.actions;

export const selectCJMSavedMailingUserData = (state: RootState) =>
  state.cjm.savedUserMailingForm;
