import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { createToastResponseError } from "lib/toastify";
import styled from "styled-components";

import type { PublicDomainsType } from "domain/userEmail";
import { checkDomainPublic } from "domain/userEmail";
import { updateQueryParams } from "lib/queryParams";
import { userEmailsService } from "services/userEmailsService";
import { serviceUser } from "services/user";
import { _variables } from "ui/styles/_variables";

import { Input } from "../../components/FormsElements/Input";
import { Button } from "../../components/Buttons/Button";
import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
`;

const RequiredText = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
`;

export const AddEmail: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const [publicDomains, setPublicDomains] = useState<PublicDomainsType>([]);
  const [isLoad, setLoad] = useState(false);
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Поле обязательно для заполнения"),
    email: Yup.string()
      .email("Введите валидный email")
      .required("Поле обязательно для заполнения")
      .test(
        "Check Domain",
        "Добавить email на общедоступном домене нельзя",
        (email) => !!email && checkDomainPublic(email, publicDomains)
      ),
  });

  useEffect(() => {
    setLoad(true);
    serviceUser.getPublicDomains().then((publicDomain: string[]) => {
      setPublicDomains(publicDomain);
      setLoad(false);
    });
  }, []);

  const onSubmit = async (data: { name: string; email: string }) => {
    setLoad(true);
    try {
      const request = await userEmailsService.create(data.name, data.email);
      toast(request.data.message, {
        autoClose: 3000,
      });
      updateQueryParams(history, {
        page: "1",
      });
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setLoad(false);
      onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <ModalDefaultFormContainer>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Input
          label={"Email*"}
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          name={"email"}
          placeholder={"Введите email"}
          error={formik.errors.email}
        />
        <Input
          label={"Имя*"}
          type="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          name={"name"}
          placeholder={"Введите имя"}
          error={formik.errors.name}
        />
        <Buttons>
          <Button size={"normal"} isLoad={isLoad} type={"submit"}>
            Подтвердить
          </Button>
          <RequiredText>* обязательное поле</RequiredText>
        </Buttons>
      </Form>
    </ModalDefaultFormContainer>
  );
};
