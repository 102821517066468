import styled from "styled-components";

const WrapperElement = styled.div`
  position: relative;
  max-width: 1104px;
  margin: 0 auto;
  padding: 0 10px;
`;

export const Wrapper: CT<unknown> = ({ className, children }) => {
  return <WrapperElement className={className}>{children}</WrapperElement>;
};
