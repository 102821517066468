import { useEffect, useState } from "react";
import { templatesService } from "services/templates";
import { createToastResponseError } from "lib/toastify";
import type { TemplateType } from "domain/templates";

export const useLoadTemplateById = (id: string | number | undefined) => {
  const [data, setData] = useState<TemplateType | null>(null);
  const [templateName, setTemplateName] = useState("");
  const [isLoad, setLoad] = useState(false);

  const loadData = async () => {
    if (!id) return;
    setLoad(true);
    try {
      const request = await templatesService.view(id);
      setData(request.data.data.template);
      setTemplateName(request.data.data.template.name);
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [id]);

  return {
    isLoadTemplate: isLoad,
    templateData: data,
    templateName,
  };
};
