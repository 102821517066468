import styled, { css } from "styled-components";

import { mxm } from "ui/styles/_mixin";
import { _variables } from "ui/styles/_variables";

const Container = styled.div`
  padding-top: 56px;
  padding-bottom: 80px;
  ${mxm(
    _variables.tabletWidth,
    css`
      padding-top: 36px;
      padding-bottom: 60px;
    `
  )}
`;
export const ContentContainer: CT<unknown> = ({ className, children }) => {
  return <Container className={className}>{children}</Container>;
};
