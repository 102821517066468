import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import { Icon } from "../Icon";
import { Input } from "./Input";

const IWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const IInputPassword = styled(Input)`
  padding-right: 45px;
`;

const IEyeIcon = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  opacity: 0.5;
  transition: ${_variables.transition};
  & img {
    width: 100%;
    height: 100%;
  }
  &:hover {
    opacity: 1;
  }
`;

type InputPasswordProps = {
  error?: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  value: string;
  name: string;
  placeholder: string;
};

export const InputPassword = ({
  handleChange,
  error,
  value,
  name,
  placeholder,
}: InputPasswordProps) => {
  const [isShowPassword, setShowPassword] = useState(false);
  const passwordRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (value.length <= 0) {
      setShowPassword(false);
    }
  }, [value]);

  return (
    <IWrapper>
      <IInputPassword
        ref={passwordRef}
        placeholder={placeholder}
        type={isShowPassword ? "text" : "password"}
        name={name}
        onChange={handleChange}
        error={error}
        EndComponent={
          <IEyeIcon
            onClick={() => {
              setShowPassword(!isShowPassword);
              const input = passwordRef.current;
              input?.focus();
              setTimeout(() => {
                input?.setSelectionRange(1000, 1000);
              }, 10);
            }}
          >
            <Icon id={"eye-icon"} width={24} height={24} />
          </IEyeIcon>
        }
      />
    </IWrapper>
  );
};
