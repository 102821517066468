import styled from "styled-components";
import cn from "classnames";

import { _variables } from "ui/styles/_variables";

import type { IconType } from "../Icon";
import { Icon } from "../Icon";

const Button = styled.button`
  height: 40px;
  display: flex;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0 8px;
  align-items: center;
  cursor: pointer;

  &.active {
    & svg {
      transition: all ${_variables.transition};
      color: ${_variables.primaryColor};
    }
  }

  & svg {
    color: ${_variables.grayColor};
  }

  &:hover {
    & svg {
      transition: all ${_variables.transition};
      color: ${_variables.primaryColor};
    }
  }
`;

const IconButton = styled(Icon)`
  margin-right: 8px;
`;

export const ButtonTable: CT<{
  iconId: IconType["id"];
  onClick?: VoidFunction;
  iconWidth?: number;
  iconHeight?: number;
  isActive?: boolean;
}> = ({
  iconId,
  iconWidth = 20,
  iconHeight = 20,
  children,
  isActive = false,
  onClick,
  className,
}) => {
  return (
    <Button
      className={cn(isActive ? "active" : "", className)}
      onClick={onClick}
    >
      <IconButton width={iconWidth} height={iconHeight} id={iconId} />
      {children}
    </Button>
  );
};
