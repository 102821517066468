import SwitchPlugin from "react-switch";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
`;

const Text = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.25px;
  text-align: left;
`;

export const Switch: CT<{
  value?: boolean;
  onChange?: (val: boolean) => void;
  isShowTextPreview?: boolean;
  size?: {
    width: number;
    height: number;
    radius: number;
  };
}> = ({
  value = false,
  onChange = () => {},
  size = {
    width: 24,
    height: 16,
    radius: 8,
  },
  isShowTextPreview = true,
  className,
}) => {
  return (
    <Container className={className}>
      {isShowTextPreview && <Text>{value ? "Включено" : "Выключено"}</Text>}
      <SwitchPlugin
        draggable={false}
        uncheckedIcon={false}
        checkedIcon={false}
        onChange={onChange}
        checked={value}
        onColor={_variables.primaryColor}
        offColor={_variables.grayColor}
        width={size.width}
        height={size.height}
        handleDiameter={size.radius}
      />
    </Container>
  );
};
