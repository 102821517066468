import { useEffect, useState } from "react";
import Masonry from "react-responsive-masonry";
import styled from "styled-components";

import type { TemplateType } from "domain/templates";
import { templatesService } from "services/templates";

import { TemplateItem } from "../../components/TemplateCards/TemplateItem";
import { NotContentTemplateCardPage } from "../../components/NotContent/NotContentTemplateCardPage";
import { LoadContent } from "../../components/LoadContent";
import { useTableStateLoad } from "../../hooks/useTableStateLoad";
import { useTableWorkspace } from "../../hooks/useTableWorkspace";

const MasonryWrapper = styled.div`
  margin-top: 40px;
  .masonry {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    align-items: space-between;
  }

  .masonry > div {
    flex: 0 1 auto !important;
    width: 18% !important;
  }
`;

const NoContent = styled(NotContentTemplateCardPage)`
  margin-top: 120px;
`;
const Load = styled(LoadContent)`
  margin-top: 120px;
`;

export const TemplatesContent: CT<{
  setShowAbsoluteButton: (isShow: boolean) => void;
  onSetCountPage: (val: number) => void;
}> = ({ setShowAbsoluteButton, onSetCountPage }) => {
  const [data, setData] = useState<TemplateType[]>([]);
  const { isLoad, setOffLoad, setOnLoad } = useTableStateLoad(data);
  const { query } = useTableWorkspace({
    perPage: 2,
    page: 1,
    sortBy: "name",
    name: "",
  });

  const loadData = async () => {
    setData([]);
    setOnLoad();
    try {
      const list = await templatesService.listPreview({
        ...query,
        perPage: query["per-page"],
        name: "",
      });
      onSetCountPage(list.headers["x-pagination-page-count"]);
      setData(list.data.data.templates);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  useEffect(() => {
    loadData();
  }, [query]);

  useEffect(() => {
    if (data.length > 0) {
      setShowAbsoluteButton(true);
      return;
    }
    setShowAbsoluteButton(false);
  }, [data]);

  if (isLoad) return <Load top={120} />;
  if (!isLoad && data.length === 0) return <NoContent />;

  return (
    <MasonryWrapper>
      <Masonry columnsCount={5} gutter="40px 0" className="masonry">
        {data.map((item) => {
          return (
            <TemplateItem key={item.name} data={item} src={item.preview} />
          );
        })}
      </Masonry>
    </MasonryWrapper>
  );
};
