import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  selectCreateMailingData,
  setTemplateMailingData,
} from "services/store/createMailingSlice";

export const useCreateMailingStore = () => {
  const mailingData = useAppSelector(selectCreateMailingData);
  const [isLoad, setLoad] = useState(true);
  const dispatch = useAppDispatch();

  const clear = () => {
    if (!!sessionStorage.getItem("mailingData")) {
      sessionStorage.removeItem("mailingData");
    }
  };

  const save = () => {
    if (mailingData.data) {
      sessionStorage.setItem("mailingData", JSON.stringify(mailingData.data));
    }
  };

  const load = async () => {
    if (mailingData.data) {
      clear();
      return;
    }
    if (!!sessionStorage.getItem("mailingData")) {
      dispatch(
        setTemplateMailingData(
          JSON.parse(sessionStorage.getItem("mailingData")!)
        )
      );
    }
    setLoad(false);
  };

  const hasMailingDataInStorage = () => {
    return !!sessionStorage.getItem("mailingData");
  };

  useEffect(() => {
    load();
  }, []);

  return {
    mailingData,
    load,
    clear,
    save,
    isLoad,
    hasMailingDataInStorage,
  };
};
