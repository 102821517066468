import { createToastResponseError } from "lib/toastify";
import { newsService } from "services/news";
import { useState } from "react";
import type { NewsDomainType } from "domain/news";

export const useGetNews = () => {
  const [data, setData] = useState<NewsDomainType | null>(null);
  const [isLoad, setLoad] = useState(false);

  const [likeNews, setLikeNews] = useState(0);
  const [isLike, setIsLike] = useState(false);

  const onLikeNews = async (id: number) => {
    try {
      await newsService.like(id);
      setLikeNews((val) => val + 1);
      setIsLike(true);
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  const onUnLikeNews = async (id: number) => {
    try {
      await newsService.unLike(id);
      setLikeNews((val) => val - 1);
      setIsLike(false);
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  const view = async (id: number | string) => {
    setLoad(true);
    try {
      const request = await newsService.view(id);
      const data = request.data.data.news;

      setData(data);
      setLikeNews(data.likeCount);
      setIsLike(!data.canLike);
      setLoad(false);
      return data;
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  return {
    view,
    data,
    isLoad,
    likeNews,
    onLikeNews,
    onUnLikeNews,
    isLike,
  };
};
