import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "./store";

export type modalDataStateType = { [key: string]: any } | null;

const initialState = null as modalDataStateType;

export const modalDataSlice = createSlice({
  name: "modalData",
  initialState,
  reducers: {
    setModalData(_, action: PayloadAction<any>) {
      return action.payload;
    },
    clearModalData() {
      return null;
    },
  },
});

export const { setModalData, clearModalData } = modalDataSlice.actions;
export default modalDataSlice.reducer;

export const selectModalData = (state: RootState) => state.modalData;
