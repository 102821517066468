import { useState } from "react";

import type { NewsDomainType } from "domain/news";
import { newsService } from "services/news";
import { createToastResponseError } from "lib/toastify";

const PER_PAGE = 20;

export const useListNews = () => {
  const [newsData, setData] = useState<NewsDomainType[]>([]);
  const [isLoad, setLoad] = useState(false);
  const [countPage, setCountPage] = useState(20);
  const [page, setPage] = useState(1);

  const preloadData = async () => {
    try {
      const request = await newsService.listNews({
        perPage: 3,
      });
      const data = request.data.data.news;
      if (request.headers["x-pagination-page-count"] <= 1) {
        setCountPage(0);
      }
      setData(data);

      return data;
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  const onLoadMore = async () => {
    setLoad(true);
    try {
      const request = await newsService.listNews({
        perPage: PER_PAGE,
        page: page,
      });

      const data = request.data.data.news;

      if (newsData.length < PER_PAGE) {
        setData(data);
      } else {
        setData([...newsData, ...data]);
      }
      setCountPage(request.headers["x-pagination-page-count"]);
      setPage((prev) => prev + 1);
      setLoad(false);
      return data;
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  return {
    onLoadMore,
    data: newsData,
    preloadData,
    isLoadMore: isLoad,
    isShowButtonUpload: page <= countPage,
  };
};
