import { useLayoutEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import type { UserPayCardDataType } from "domain/user";
import { usePayValidationRedirect } from "app/usePayValidationRedirect";
import { serviceUser } from "services/user";
import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { contactsListService } from "services/list";
import { createToastResponseError } from "lib/toastify";
import { pricePlural } from "lib/plural";

import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";
import { Button } from "ui/components/Buttons/Button";
import { ModalButtonsContainer } from "ui/components/Modals/ModalButtonsContainer";
import { ModalDefaultFormContainer } from "ui/components/Modals/ModalDefaultFormContainer";
import { CheckboxSmallCircle } from "ui/components/FormsElements/CheckboxSmallCircle";
import { LoadDots } from "ui/components/LoadDots";

const Form = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`;

const CheckboxBox = styled.div`
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  row-gap: 18px;
  & span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: ${_variables.blackColor};
    opacity: 0.5;
  }
`;

const IText = styled.div`
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  align-items: center;
  letter-spacing: -0.25px;
  display: flex;
  text-align: left;
  color: rgba(${hexToRgba(_variables.blackColor, 0.5)});
`;

const LoadWrapper = styled.div`
  display: flex;
  min-height: 150px;
  align-items: center;
  justify-content: center;
`;

export const PromptPayValidate: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const [price, setPrice] = useState<null | number>(null);
  const [cards, setCards] = useState<UserPayCardDataType[]>([]);
  const [checkId, setCheckId] = useState(0);
  const modalData = useAppSelector(selectModalData);
  const validationRedirect = usePayValidationRedirect();
  const [isLoad, setLoad] = useState(false);

  const clickPayHandler = async () => {
    try {
      const response = await contactsListService.buyValidation(
        modalData!.id!,
        checkId
      );
      validationRedirect.savePaymentData({
        bookId: modalData!.id!,
      });
      window.dataLayer.push({ event: "cp_click_pay_payment" });
      window.dataLayer.push({ event: "cp_click_pay_payment_valid" });
      const link = response.data.data.sale.conformation_url;
      const linkElem = document.createElement("a");
      linkElem.href = link;
      linkElem.click();
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  const loadPriceData = async () => {
    try {
      const response = await contactsListService.calculateValidation({
        id: modalData!.id!,
      });
      setPrice(response.data.data.price);
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  const loadCardData = async () => {
    setLoad(true);
    try {
      const data = await serviceUser.getPaymentCards();
      const cards = data.data.data["user-payment-cards"];
      setCards(cards);
      if (cards.length > 0) setCheckId(Number(cards[0].id));
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setTimeout(() => {
        setLoad(false);
      }, 100);
    }
  };

  useLayoutEffect(() => {
    loadPriceData();
    loadCardData();
  }, []);

  return (
    <ModalDefaultFormContainer>
      <Form>
        <IText>
          <span>Стоимость проверки:&nbsp;</span>
          {price ? (
            <span>{pricePlural(price)}</span>
          ) : (
            <div>
              &nbsp;
              <Skeleton width={50} />
            </div>
          )}
        </IText>
        {isLoad ? (
          <LoadWrapper>
            <LoadDots />
          </LoadWrapper>
        ) : (
          <CheckboxBox>
            {cards.map((item) => (
              <CheckboxSmallCircle
                name={String(item.id)}
                checked={checkId === item.id}
                onChecked={(val) => {
                  setCheckId(Number(val));
                }}
              >
                <span>{item.title}</span>
              </CheckboxSmallCircle>
            ))}
            <CheckboxSmallCircle
              name={"0"}
              checked={checkId === 0}
              onChecked={(val) => {
                setCheckId(Number(val));
              }}
            >
              <span>Оплата новой картой</span>
            </CheckboxSmallCircle>
          </CheckboxBox>
        )}

        <ModalButtonsContainer style={{ marginTop: 12 }}>
          <Button
            onClick={clickPayHandler}
            size={"normal"}
            disabled={!price}
            type={"submit"}
          >
            Оплатить
          </Button>
          <Button
            onClick={onClose}
            size={"normal"}
            variant={"secondary"}
            type={"submit"}
          >
            Отмена
          </Button>
        </ModalButtonsContainer>
      </Form>
    </ModalDefaultFormContainer>
  );
};
