import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";
import icon1 from "ui/assets/media/images/decoration-icon-1.svg";
import icon2 from "ui/assets/media/images/decoration-icon-2.svg";
import icon3 from "ui/assets/media/images/decoration-icon-3.svg";

import { Link } from "./Link";

const Content = styled.div`
  border: 1px solid ${_variables.strokeColor};
  border-radius: 12px;
  display: flex;
  column-gap: 16px;
  align-items: center;
`;
const ImageWrapper = styled.div`
  background-color: rgba(
    ${hexToRgba(_variables.additionalColors.orange, 0.07)}
  );
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  & img {
    width: 80px;
    height: 80px;
  }
`;

const Heading = styled.h5`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const LinkText = styled(Link)`
  color: ${_variables.primaryColor};
`;

const icons = {
  1: icon1,

  2: icon2,

  3: icon3,
};
export const DecorationHelpCard: CT<{
  decorationIcon: 1 | 2 | 3;
  title: string;
  link: React.ComponentProps<typeof Link> & { title: string };
}> = ({ decorationIcon, title, link }) => {
  return (
    <Content>
      <ImageWrapper>
        <img width={80} height={80} src={icons[decorationIcon]} alt="" />
      </ImageWrapper>
      <TextBlock>
        <Heading>{title}</Heading>
        <LinkText {...link}>{link.title}</LinkText>
      </TextBlock>
    </Content>
  );
};
