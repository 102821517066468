import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { createToastResponseError } from "lib/toastify";
import { updateQueryParams } from "lib/queryParams";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { contactsListService } from "services/list";
import { clearViewContactAll } from "services/store/viewContantcsSlice";
import { clearCheckPageState } from "services/store/pagesSlice";

import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";
import { ModalTextContainer } from "../../components/Modals/ModalTextContainer";
import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";
import { Button } from "../../components/Buttons/Button";

export const DeleteContacts: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const modalData = useAppSelector(selectModalData);
  const [isLoad, setLoad] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onDeleteData = async () => {
    try {
      setLoad(true);
      let response;
      if (!modalData?.deleteBy) return;
      if (modalData!.deleteBy === "all") {
        response = await contactsListService.deleteContactsByAll(
          modalData.bookId,
          modalData!.ids
        );
      } else {
        response = await contactsListService.deleteContactsByList(
          modalData.bookId,
          modalData!.ids
        );
      }

      toast(response.data.message, { autoClose: 3000 });
      dispatch(clearViewContactAll());
      dispatch(clearCheckPageState({ page: "contactsChecked" }));
      updateQueryParams(history, {
        page: "1",
      });
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      onClose();
    }
  };

  return (
    <ModalDefaultFormContainer>
      <ModalTextContainer>
        {modalData?.deleteBy === "all" ? (
          <p>Вы уверены, что хотите удалить контакты во всех списках?</p>
        ) : (
          <p>Вы уверены, что хотите удалить контакты в текущем списке?</p>
        )}
      </ModalTextContainer>
      <ModalButtonsContainer>
        <Button isLoad={isLoad} onClick={onDeleteData} type={"submit"}>
          Подтвердить
        </Button>
        <Button onClick={onClose} type={"submit"} variant={"secondary"}>
          Отмена
        </Button>
      </ModalButtonsContainer>
    </ModalDefaultFormContainer>
  );
};
