import { _variables } from "ui/styles/_variables";

export const ListCheckedIcon: CT<{
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  isActive?: boolean;
}> = ({ isActive = false, onClick, className }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill={isActive ? _variables.blackColor : "none"}
    >
      <rect
        x="0.75"
        y="0.75"
        width="22.5"
        height="22.5"
        rx="11.25"
        stroke={isActive ? _variables.blackColor : _variables.grayTextColor}
        strokeWidth="1.5"
      />
      <path
        d="M14.6481 9.65895L14.6481 9.65899L14.6536 9.65355C14.8583 9.44882 15.1417 9.44882 15.3464 9.65355C15.5512 9.85829 15.5512 10.1417 15.3464 10.3464L11.3464 14.3464C11.1429 14.55 10.8617 14.5512 10.6573 14.3501L8.66207 12.2552L8.66218 12.2551L8.65355 12.2464C8.44882 12.0417 8.44882 11.7583 8.65355 11.5536C8.85829 11.3488 9.14171 11.3488 9.34645 11.5536L10.6464 12.8536L10.9946 13.2017L11.3481 12.8589L14.6481 9.65895Z"
        fill={isActive ? _variables.whiteColor : _variables.grayTextColor}
        stroke={isActive ? _variables.whiteColor : _variables.grayTextColor}
      />
    </svg>
  );
};
