export const statusMailCatalog = {
  1: "Планируется", // Черновик ->отменить
  2: "Выполняется", //'В работе'
  3: "Отправлено", //'Завешршено'
  4: "Отменено",
  5: "На модерации", // На проверке -> отменить
  6: "Планируется", // В ожидании -> отменить
  7: "Не пройдена модерация, свяжитесь с нами", // не пройдена модерация
};

// статус рассылки в истории рассылок
export const statusMailingType = {
  1: "open", //открыто
  2: "sended", // доставлено
  3: "total", // отправлено
  4: "click", // клики
  5: "spam", //абьзы
  6: "unsubscribe", // отписки
};

// категории рассылки в истории рассылок
export const categoryMailingType = {
  1: "sended", // отправлено
  2: "delivered", // доставлено
  3: "open", //открыто
  4: "click", // клики
  5: "spam", //абьзы
  6: "unsubscribe", // отписки
  7: "error full",
  8: "error not exist",
  9: "error spam",
  10: "error other",
};

/**
 * Тип рассылки, что создает пользователь
 */
export type MailingType = {
  id: number;
  name: string;
  template_id: number;
  book_id: number;
  delivered: number;
  delivered_percent: number;
  status: keyof typeof statusMailCatalog;
  date_start: string;
  date_end: string;
  total: number;
  open: number;
  click: number;
  template_name: string;
  total_percent: number;
  open_percent: number;
  click_percent: number;
  unsubscribe: number;
  unsubscribe_percent: number;
  can_repeat: boolean;
  book_name: string;
};

export type StatusMailType = keyof typeof statusMailCatalog;

export const statusCanCancel = (status: StatusMailType) =>
  status === 5 || status === 1 || status === 6;

export const statusIsModeration = (status: StatusMailType) => {
  return status === 5;
};

/**
 * Тип просмотра рассылки, актуален для пересоздания рассылки
 */
export type MailItemViewType = MailingType & {
  id: number;
  name: string;
  from: string;
  email: string;
  template_id: number;
  template_name: string;
  book_id: number;
  book_count: number;
  book_name: string;
  bounce: number;
  abuse: number;
  abuse_percent: number;
  error_full: number;
  error_full_percent: number;
  error_not_exists: number;
  error_not_exists_percent: number;
  error_spam: number;
  error_spam_percent: number;
  error_other: number;
  error_other_percent: number;
  unsubscribe: number;
  unsubscribe_percent: number;
};

/**
 * Тип списка, что возвращается при клике на плашки "Доставлено"|"Отправлено"|"Открыто"
 */
export type MailingContactsList = {
  totalCount: number;
  mailingContacts: {
    id: number;
    email: string;
    statusName: string;
  }[];
};
