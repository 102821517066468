import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

/**
 * 1: "Предпросмотр";
 2: "В очереди на загрузку";
 3: "Идет загрузка";
 4: "Загрузка завершена";
 5: "Ошибка загрузки";
 */
type StatusType = 1 | 2 | 3 | 4 | 5;

const IContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px;
  background-color: ${_variables.backgroundColor};
  border-radius: 24px;
`;

const IRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ITitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;

const ITitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const IPercentBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const IPercent = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: right;
`;

const IStatus = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: ${_variables.grayColor};
`;

const ISubTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: ${_variables.grayColor};
`;

const ILine = styled.div<{ percent: number; status: StatusType }>`
  height: 8px;
  border-radius: 33px;
  margin-top: 9px;
  position: relative;
  background-color: #e4e5e8;
  z-index: 5;
  &:before {
    position: absolute;
    left: 0;
    z-index: 11;
    top: 0;
    content: "";
    height: 8px;
    display: block;
    border-radius: 33px;
    transition: width 0.2s ease;
    background-color: ${(props) => {
      if (props.status <= 2) return _variables.supportColors2.yellow;
      if (props.status === 3 || props.status === 4)
        return _variables.supportColors2.green;
      if (props.status === 5) return _variables.supportColors2.red;
    }};

    width: ${(props) => props.percent + "%"};
  }
`;

const titleByStatus = {
  1: "Предпросмотр", // никогда не появится
  2: "В очереди на загрузку", // возможно появится
  3: "Загрузка",
  4: "Контакты загружены!",
  5: "Произошла ошибка",
};

const subTitle = {
  1: "Предпросмотр", // никогда не появится
  2: "Ожидайте очереди загрузки", // возможно появится
  3: "Ожидайте загрузку данных",
  4: "Можно приступать к рассылке",
  5: "Сожалеем, но произошел сбой загрузки",
};

export const ImportLineStatus: CT<{
  status: StatusType;
  serverStatusText: string;
  percent: number;
}> = ({ status, percent, serverStatusText }) => {
  return (
    <IContainer>
      <IRow>
        <ITitleBlock>
          <ITitle>{titleByStatus[status]}</ITitle>
          <ISubTitle>{subTitle[status]}</ISubTitle>
        </ITitleBlock>
        <IPercentBlock>
          <IPercent>{percent + "%"}</IPercent>
          <IStatus>{serverStatusText}</IStatus>
        </IPercentBlock>
      </IRow>
      <ILine status={status} percent={percent} />
    </IContainer>
  );
};
