import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type {
  FilePreviewSettingUserType,
  importInterfaceStatusTypes,
  stepImportInterface,
} from "domain/fileContacts";

import type { RootState } from "./store";

type AppStateType = {
  file: string | null;
  fileId: id | null;
  status: importInterfaceStatusTypes;
  step: stepImportInterface;
  userSetting: Omit<FilePreviewSettingUserType, "email" | "name"> & {
    email: number | null;
    name: number | null;
  };
  countImport: number | null;
  countIgnore: number | null;
  totalCount: number | null;
  importMode: "write" | "load";
  list: {
    name: string;
    id: number;
    count: number;
  } | null;
  hasBeenChangeWriteFile: boolean;
  writeFile: string | null;
};

export const initialStateImportSlice = {
  file: null,
  fileId: null,
  status: 1,
  step: 1,
  countIgnore: null,
  countImport: null,
  totalCount: null,
  writeFile: null,
  hasBeenChangeWriteFile: false,
  userSetting: {
    delimiter: 3,
    skipFirstRow: true,
    email: null,
    name: null,
  },

  importMode: "load",
  list: null,
} as AppStateType;

export const importSlice = createSlice({
  name: "app",
  initialState: initialStateImportSlice,
  reducers: {
    setFile(state, action: PayloadAction<string | null>) {
      state.file = action.payload;
    },
    clearFile(state) {
      state.file = null;
    },
    clearAllForm() {
      return {
        ...initialStateImportSlice,
      };
    },
    clearAllWithoutWrite(state) {
      return {
        ...initialStateImportSlice,
        list: state.list
          ? {
              name: state.list.name,
              id: state.list.id,
              count: state.list.count,
            }
          : null,
        importMode: state.importMode,
        writeFile: state.writeFile,
      };
    },
    setStatus(state, action: PayloadAction<importInterfaceStatusTypes>) {
      state.status = action.payload;
    },
    goToSettingStep(state) {
      state.step = 2;
    },
    goToStartStep(state) {
      state.step = 1;
    },
    goToListStep(state) {
      state.step = 3;
    },
    goToImportStep(state) {
      state.step = 4;
    },
    goToFinalStep(state) {
      state.step = 5;
    },
    goBackToFileLoad(state, action: PayloadAction<"write" | "load">) {
      if (action.payload === "load")
        return {
          ...initialStateImportSlice,
          importMode: action.payload,
          writeFile: state.writeFile,
          step: 1,
        };
      if (action.payload === "write")
        return {
          ...initialStateImportSlice,
          importMode: action.payload,
          writeFile: state.writeFile,
          step: 1,
        };
    },
    goBackToSettingField(state) {
      state.step = 2;
    },
    goToWriteMode(state) {
      state.step = 1;
    },

    setId(state, action: PayloadAction<id>) {
      state.fileId = action.payload;
    },
    setUserSetting(state, action: PayloadAction<FilePreviewSettingUserType>) {
      state.userSetting = action.payload;
    },
    setHasBeenChange(state) {
      state.hasBeenChangeWriteFile = true;
    },
    clearHasBeenChange(state) {
      state.hasBeenChangeWriteFile = false;
    },
    clearUserSettingFileds(state) {
      state.userSetting = {
        ...state.userSetting,
        email: null,
        name: null,
      };
    },
    setEmailSetting(state, action: PayloadAction<number | null>) {
      state.userSetting.email = action.payload;
    },
    setNameSetting(state, action: PayloadAction<number | null>) {
      state.userSetting.name = action.payload;
    },
    setDefaultUserSetting(state) {
      state.userSetting = initialStateImportSlice["userSetting"];
    },
    setCheckSkipRow(state, action: PayloadAction<boolean>) {
      state.userSetting.skipFirstRow = action.payload;
    },
    setWriteFile(state, action: PayloadAction<string | null>) {
      state.writeFile = action.payload;
    },
    setDelimiter(
      state,
      action: PayloadAction<FilePreviewSettingUserType["delimiter"]>
    ) {
      state.userSetting.delimiter = action.payload;
    },

    setCounts(
      state,
      action: PayloadAction<{
        total: AppStateType["totalCount"];
        count: AppStateType["countImport"];
        ignore: AppStateType["countIgnore"];
      }>
    ) {
      state.totalCount = action.payload.total;
      state.countImport = action.payload.count;
      state.countIgnore = action.payload.ignore;
    },
    setModeImport(state, action: PayloadAction<AppStateType["importMode"]>) {
      state.importMode = action.payload;
    },
    setListData(state, action: PayloadAction<Required<AppStateType["list"]>>) {
      state.list = {
        name: action.payload!.name,
        id: action.payload!.id,
        count: action.payload!.count,
      };
    },
  },
});

export const importStoreAction = importSlice.actions;
export default importSlice.reducer;

export const selectWriteContent = (state: RootState) =>
  state.importContacts.writeFile;
export const selectImportState = (state: RootState) => state.importContacts;
export const selectImportStep = (state: RootState) => state.importContacts.step;
export const selectImportUserSetting = (state: RootState) =>
  state.importContacts.userSetting;
export const selectImportMode = (state: RootState) =>
  state.importContacts.importMode;
