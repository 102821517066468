import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import { useGoBackLink } from "app/useGoBackLink";
import type { TemplateType } from "domain/templates";
import { createToastResponseError } from "lib/toastify";
import { useAppDispatch } from "services/hooks";
import { setDataTemplateView } from "services/store/templateViewSlice";
import { templatesService } from "services/templates";
import { _variables } from "ui/styles/_variables";
import { hexToRgba, mxmHeight } from "ui/styles/_mixin";
import { Icon } from "../Icon";

const ButtonWrapper = styled.div<{ top: number }>`
  position: absolute;
  top: ${({ top }) => top}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
`;

const Button = styled.button<{ hasBackground?: boolean }>`
  padding: 0 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  border-radius: 8px;
  color: white;
  border: none;
  background-color: ${({ hasBackground }) =>
    hasBackground ? _variables.blackColor : "rgb(0,0,0,0)"};
  height: 36px;
  align-items: center;
  display: flex;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 25px;
  overflow-y: hidden;
  position: relative;
`;

const Label = styled.div<{ statusColor: string }>`
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 22px;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 12;
  border-radius: 8px;
  color: white;
  background-color: ${({ statusColor }) => statusColor};
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  padding: 0 8px;

  & img {
    z-index: 2;
    width: calc(100% + 2px);
    position: relative;
    left: -1px;
    object-position: right top;
    height: auto;
    max-height: 800px;
    object-fit: cover;
    ${mxmHeight(
      800,
      css`
        max-height: 300px;
      `
    )}
  }
`;

const ContentShow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 13;
  display: flex;
  align-items: center;
  opacity: 0;
  justify-content: center;
  background-color: rgba(${hexToRgba(_variables.defaultTemplates.hover, 0.4)});
  transition: opacity ${_variables.transition};

  &:hover {
    opacity: 1;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  padding: 25px 15px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: none;
  align-items: center;
  justify-content: flex-end;
`;

const Rating = styled.div`
  display: flex;
  color: white;
  column-gap: 5px;
`;

export const TemplateDefaultItem: CT<{
  src: string;
  data: TemplateType;
}> = ({ data, src }) => {
  const [buttonPosition, setButtonPosition] = useState<number>(75);

  const { id } = data;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const backLink = useGoBackLink();
  const onClickShow = () => {
    dispatch(
      setDataTemplateView({
        mode: "desktop",
        id,
        isDefaultTemplate: true,
        rating: data.rating,
        has_scheme: data.has_scheme,
        isMailingCreationWay: true,
      })
    );
  };

  const duplicateHandler = async () => {
    try {
      const response = await templatesService.copy(id);
      const idTemplate = response.data.data.template.id;
      backLink.create("/templates/cards?tab=templates");
      if (data.has_scheme) {
        history.push(
          `/templates/creator?id=${idTemplate}&isMailingCreationWay=true`
        );
        return;
      }
      history.push(
        `/templates/text-editor?id=${idTemplate}&isMailingCreationWay=true`
      );
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  return (
    <Content>
      {data.is_new && (
        <Label statusColor={_variables.supportColors2.green}>New</Label>
      )}
      <Image>
        <img src={src} alt="" />
      </Image>
      <ContentShow
        className={"content"}
        onMouseOver={(event) => {
          event.stopPropagation();
          const offsetY = event.nativeEvent.offsetY;
          const maxHeight = event.currentTarget.clientHeight - 120;

          if (Math.abs(offsetY - buttonPosition) < 90) return;

          if (offsetY < 75) return setButtonPosition(75);
          if (offsetY > maxHeight) return setButtonPosition(maxHeight);

          setButtonPosition(event.nativeEvent.offsetY - 30);
        }}
      >
        <IconsWrapper>
          <Label
            statusColor={
              data.has_scheme
                ? _variables.additionalColors.yellow
                : _variables.defaultTemplates.labelHTML
            }
          >
            {data.has_scheme ? "Конструктор" : "HTML"}
          </Label>
          <Rating>
            <p>{data.rating}</p>
            <Icon width={20} id="ratingStar" />
          </Rating>
        </IconsWrapper>

        <ButtonWrapper
          onMouseOver={(event) => {
            event.stopPropagation();
          }}
          top={buttonPosition}
        >
          <Button
            onClick={duplicateHandler}
            hasBackground={true}
            onMouseOver={(event) => {
              event.stopPropagation();
            }}
          >
            Использовать
          </Button>

          <Button
            onMouseOver={(event) => {
              event.stopPropagation();
            }}
            onClick={onClickShow}
          >
            Просмотреть
          </Button>
        </ButtonWrapper>
      </ContentShow>
    </Content>
  );
};
