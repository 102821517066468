import styled, { css } from "styled-components";
import Skeleton from "react-loading-skeleton";
import React, { useEffect } from "react";

import { useListNews } from "app/news/useListNews";

import { Link } from "ui/components/Link";
import { _variables } from "ui/styles/_variables";
import { Button } from "ui/components/Buttons/Button";
import { LoadDots } from "ui/components/LoadDots";

const IContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  min-width: 300px;
  width: 300px;
  height: min-content;
`;
const INewsBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  background-color: ${_variables.backgroundColor};
  border-radius: 12px;
  width: 100%;
  padding: 16px 0;
  & h4 {
    padding-left: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }
`;
const List = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const Item = styled.li<{ isActive: boolean }>`
  display: flex;
  width: 100%;
  padding: 0 16px;
  flex-direction: column;
  row-gap: 8px;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    width: 2px;
    left: 0;
    position: absolute;
    top: calc(50% + 3px);
    transform: translateY(-50%);
    height: calc(100% - 10px);
    display: block;
    background-color: ${_variables.primaryColor};
    opacity: 0;
    transition: opacity ${_variables.transition};
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    & h5 {
      color: ${_variables.primaryColor};
    }
  }
  ${(props) => {
    if (props.isActive)
      return css`
        &:before {
          opacity: 1;
        }
      `;
  }}
  h5 {
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    transition: color ${_variables.transition};
    color: ${(props) =>
      props.isActive ? _variables.primaryColor : _variables.blackColor};
    & a {
      font-size: inherit;
    }
  }
  & span {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
    color: #83899f;
    &.red {
      color: ${_variables.supportColors.red};
    }
  }
`;

const LoadMoreButton = styled(Button)`
  width: 100%;
`;

const LoadMoreDots = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const NewsListContent: CT<{
  pageIsReady: boolean;
  currentId: number;
}> = ({ pageIsReady, currentId }) => {
  const { preloadData, data, onLoadMore, isShowButtonUpload, isLoadMore } =
    useListNews();

  useEffect(() => {
    if (!pageIsReady) return;
    preloadData();
  }, []);

  if (!pageIsReady || data.length === 0)
    return (
      <IContent>
        <INewsBlock>
          <h4>Другие новости </h4>
          <List>
            {[0, 0, 0].map(() => (
              <Item isActive={false}>
                <h5>
                  <Skeleton width={120} />
                </h5>
                <Skeleton width={42} />
              </Item>
            ))}
          </List>
        </INewsBlock>
      </IContent>
    );

  return (
    <IContent>
      <INewsBlock>
        <h4>Другие новости </h4>
        <List>
          {data.map((item) => (
            <Link link={`/news/${item.id}`}>
              <Item isActive={item.id == currentId}>
                <h5>{item.title}</h5>
                <span>{item.dateIn}</span>
                {!item.isReady && (
                  <span className={"red"}>(Не опубликовано)</span>
                )}
              </Item>
            </Link>
          ))}
        </List>
      </INewsBlock>
      {isLoadMore && (
        <LoadMoreDots>
          <LoadDots />
        </LoadMoreDots>
      )}
      {!isLoadMore && isShowButtonUpload && (
        <LoadMoreButton onClick={onLoadMore}>Загрузить еще</LoadMoreButton>
      )}
    </IContent>
  );
};
