import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { planService } from "services/plan";
import type { PayPlanType } from "domain/payPlan";

export const usePayEntity = () => {
  const coefficient = 0.014;
  const [data, setData] = useState<PayPlanType[]>([]);
  const packages = [0, 5000, 10000, 25000, 50000, 150000, 250000, 500000];
  const [isLoad, setLoad] = useState(true);
  const getSum = (val: number) => val * coefficient;

  useEffect(() => {
    (async () => {
      try {
        const response = await planService.list();
        setData([
          { id: -1, limit: 0, price: "0", name: "0" },
          ...response.data.data.plans,
        ]);
        setLoad(false);
      } catch (e) {
        toast("Ошибка загрузки планов", { autoClose: 5000, type: "warning" });
      }
    })();
  }, []);

  const val = data.map((item, i) => ({
    count: item.limit,
    price: Number(item.price.split(".")[0]),
    step: i,
    id: item.id,
  }));

  return {
    getSumPay: getSum,
    stepLength: packages.length - 1,
    values: val,
    isLoadPriceData: isLoad,
  };
};
