import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { createToastResponseError } from "lib/toastify";
import { serviceUser } from "services/user";

import { Button } from "../../components/Buttons/Button";
import { Input } from "../../components/FormsElements/Input";
import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 320px;
`;
export const RecoveryPasswordForm: CT<{ token: string; email: string }> = ({
  email,
  token,
}) => {
  const [isLoad, setLoad] = useState(false);
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Поле обязательно для заполнения")
      .email("Необходимо ввести корректный email"),
    password: Yup.string()
      .required("Поле обязательно для заполнения")
      .min(6, "Минимум 6 символов"),
  });

  const formik = useFormik({
    initialValues: {
      email: email,
      password: "",
    },
    validateOnChange: false,
    onSubmit: async (data) => {
      setLoad(true);
      try {
        await serviceUser.forgotPassword({
          ...data,
          password_confirm: data.password,
          token: token,
        });
        localStorage.removeItem("email");
        toast("Пароль успешно установлен!", { autoClose: 3000 });
        setTimeout(() => {
          history.push({
            pathname: "/login",
          });
        }, 1000);
      } catch (err) {
        setLoad(false);
        formik.setErrors({ password: " " });
        createToastResponseError(err)();
      }
    },
    validationSchema: validationSchema,
  });
  return (
    <ModalDefaultFormContainer>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Input
          error={formik.errors.password}
          type="password"
          onChange={formik.handleChange}
          name={"password"}
          value={formik.values.password}
          placeholder={"Введите новый пароль"}
        />
        <Button widthMode={"full"} isLoad={isLoad} type={"submit"}>
          Сохранить и войти
        </Button>
      </Form>
    </ModalDefaultFormContainer>
  );
};
