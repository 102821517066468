import styled from "styled-components";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { createToastResponseError } from "lib/toastify";
import { updateQueryParams } from "lib/queryParams";
import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { userFormService } from "services/userForms";

import { Button } from "ui/components/Buttons/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 285px;
`;

const Buttons = styled.div`
  display: flex;

  column-gap: 8px;
`;

export const DeleteClientForm: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const modalData = useAppSelector(selectModalData);
  const history = useHistory();
  const onDelete = async () => {
    try {
      await userFormService.delete(modalData!.id);
      toast("Форма успешно удалена", { autoClose: 3000 });
      onClose();
      updateQueryParams(history, {});
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  return (
    <Container>
      <Buttons>
        <Button onClick={onDelete}>Удалить</Button>
        <Button onClick={onClose} variant={"secondary"}>
          Отменить
        </Button>
      </Buttons>
    </Container>
  );
};
