import { Link as LinkRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import cn from "classnames";

import { _variables } from "ui/styles/_variables";

type ColorType =
  | "primary"
  | "black"
  | "blue"
  | "gray"
  | "inherit"
  | "onlyPrimary";

const styleLink = css<{ color: ColorType }>`
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: left;
  transition: color ${_variables.transition};
  color: ${(props) => {
    if (props.color === "onlyPrimary") return `${_variables.primaryColor}`;
    if (props.color === "primary") return `${_variables.primaryColor}`;
    if (props.color === "black") return `${_variables.blackColor}`;
    if (props.color === "blue") return `${_variables.primaryColor}`;
    if (props.color === "gray") return `${_variables.additionalColors.blue}`;
    if (props.color === "inherit") return `inherit`;
  }};

  &:hover {
    color: ${(props) => {
      if (props.color === "onlyPrimary") return `${_variables.primaryColor}`;
      if (props.color === "primary") return `${_variables.blackColor}`;
      if (props.color === "black") return `${_variables.primaryColor}`;
      if (props.color === "blue") return `${_variables.blackColor}`;
      if (props.color === "gray") return `${_variables.blackColor}`;
      if (props.color === "inherit") return `inherit`;
    }};
  }
  &.disabled {
    color: ${_variables.grayTextColor};
    opacity: 0.5;
    cursor: default;
    &:hover {
      color: ${_variables.grayTextColor};
    }
  }
`;

const LinkRedirect = styled.a<{ color: ColorType; underline: boolean }>`
  ${styleLink}
  ${(props) => {
    if (props.underline)
      return css`
        text-decoration: underline;
      `;
  }}
`;
const LinkRouterDom = styled(LinkRouter)<{
  color: ColorType;
  underline: boolean;
}>`
  ${styleLink}
  ${(props) => {
    if (props.underline)
      return css`
        text-decoration: underline;
      `;
  }}
`;

export const Link: CT<{
  redirectMode?: {
    blank: boolean;
  };
  underline?: boolean;
  color?: ColorType;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  link: string;
  disabled?: boolean;
}> = ({
  className,
  onClick = () => {},
  underline = false,
  children,
  link,
  redirectMode,
  color = "black",
  disabled,
}) => {
  if (redirectMode)
    return (
      <LinkRedirect
        color={color}
        underline={underline}
        target={redirectMode?.blank ? "_blank" : "_self"}
        className={cn(className, disabled && "disabled")}
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
            return;
          }
          onClick && onClick(e);
        }}
        href={link}
      >
        {children}
      </LinkRedirect>
    );
  return (
    <LinkRouterDom
      className={cn(className, disabled && "disabled")}
      underline={underline}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
          return;
        }
        onClick &&
          onClick(e as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
      }}
      color={color}
      to={link}
    >
      {children}
    </LinkRouterDom>
  );
};
