import styled from "styled-components";

const Copy = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  opacity: 0.5;
`;

const year = new Date().getFullYear();

export const Copyright: CT<unknown> = ({ className }) => {
  return <Copy className={className}>© 2022-{year} Cheapsender</Copy>;
};
