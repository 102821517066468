import type { RouteType } from "./routes";
import { ProtectedRoute } from "./ProtectedRoute";
import { UnAuthRoute } from "./UnAuthRoute";

export const ModeRoute: CT<{ mode: RouteType["mode"] }> = ({
  mode,
  children,
}) => {
  if (mode === "protected") return <ProtectedRoute>{children}</ProtectedRoute>;
  if (mode === "unAuth") return <UnAuthRoute>{children}</UnAuthRoute>;
  return <>{children}</>;
};
