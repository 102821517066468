import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { format, setHours } from "date-fns";
import { useClickAway } from "ahooks";

import { formElement } from "ui/styles/elements/formElements";
import { _variables } from "ui/styles/_variables";
import { ArrowsIcon } from "ui/components/Icons/ArrowsIcon";
import { TimePicker } from "ui/components/Datepicker/TimePicker";

const IWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ElemWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ISelect = styled.label<{ isError: boolean; haveIcon: boolean }>`
  ${formElement};
  position: relative;
  cursor: pointer;
  width: 100%;
  background-color: white;
  padding: 0px 10px;
  height: 28px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  &:hover {
    background-color: white;
  }
  & .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }
  &:focus-within {
    & .icon {
      transform: translateY(-50%);
    }
  }

  & select {
    cursor: pointer;
    border: 2px solid transparent;
    transition: all ${_variables.transition};
    &:focus {
      border: 2px solid ${_variables.primaryColor} !important;
    }
    width: 100%;
    padding: 0 16px;
    height: 100%;
    background: transparent;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: ${_variables.borderRadius};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;

    margin: 0;
  }
  & select:focus {
    border: none;
    outline: none;
  }
`;

const SelectContent = styled.div<{ isTop: boolean }>`
  display: flex;
  position: absolute;
  top: calc(100% + 10px);
  ${(props) =>
    props.isTop &&
    css`
      top: auto;
      bottom: calc(100% + 10px);
    `}
`;

export const SelectTimePicker: CT<{
  dateFrom: Date;
  onChangeFromDateHandler: (date: Date) => void;
  positionTopPicker?: boolean;
}> = ({ dateFrom, onChangeFromDateHandler, positionTopPicker = false }) => {
  const [formOpen, setFromOpen] = useState(false);

  const [fromDate, setFromDate] = useState(dateFrom);

  const refFrom = useRef(null);

  const selectFromHandler = (date: Date | null) => {
    setFromOpen(false);
    if (!date) return;
    setFromDate(date);
  };

  useEffect(() => {
    if (fromDate === dateFrom) return;
    onChangeFromDateHandler(fromDate);
  }, [fromDate]);

  useClickAway(() => {
    setFromOpen(false);
  }, refFrom.current);

  return (
    <IWrapper>
      <ElemWrapper ref={refFrom}>
        <ISelect
          onFocus={() => {
            setFromOpen(true);
          }}
          onClick={() => {
            setFromOpen(true);
          }}
          isError={false}
          haveIcon={false}
        >
          От {format(fromDate, "HH.mm")}
          <ArrowsIcon
            className={"icon"}
            width={16}
            height={16}
            name={"arrow"}
            variant={"select"}
          />
        </ISelect>
        {formOpen && (
          <SelectContent isTop={positionTopPicker}>
            <TimePicker
              isHideDisableSelect={true}
              minTime={setHours(new Date(), 9)}
              maxTime={setHours(new Date(), 19)}
              onSelectTime={selectFromHandler}
              value={fromDate}
              intervalMinutes={60}
            />
          </SelectContent>
        )}
      </ElemWrapper>
    </IWrapper>
  );
};
