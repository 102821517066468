import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import { ArrowsIcon } from "ui/components/Icons/ArrowsIcon";

const IContainer = styled.th`
  display: flex;
  min-height: 48px;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid ${_variables.strokeColor};
`;

const IClickWrapper = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  cursor: pointer;
  column-gap: 12px;
  width: fit-content;
`;

const IText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 24px;
  min-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

/**
 * Компонент одиночного фильтра, показывается только тогда, когда есть активация.
 * Пример -> страница шаблонов
 */
export const TableHeadOnceFilter: CT<{
  isActive?: boolean;
  name: string;
  onClick?: (name: string) => void;
}> = ({ children, isActive = false, className, name, onClick }) => {
  return (
    <IContainer className={className}>
      <IClickWrapper
        onClick={() => {
          onClick && onClick(name);
        }}
      >
        <IText>{children}</IText>
        {isActive && (
          <ArrowsIcon
            variant={"filter"}
            name={"double"}
            width={20}
            height={20}
          />
        )}
      </IClickWrapper>
    </IContainer>
  );
};
