import axios from "axios";

import type { ServiceListPropTypes } from "domain/api";
import type { ProjectContactsList, ProjectDomainType } from "domain/project";

export const projectService = {
  path: "project/",
  async create(data: {
    name: string;
    domain: string;
    howToUse: string;
    howToCollectEmails: string;
  }) {
    return axios.post<ApiResponseBodyType<unknown>>(`${this.path}create`, data);
  },

  async index({ perPage, page }: ServiceListPropTypes<unknown>) {
    return axios.get<ApiResponseBodyType<{ projects: ProjectDomainType[] }>>(
      `${this.path}index?expand=token&page=${page}&per-page=${perPage}`
    );
  },
  async getMails() {
    return axios.get(`${this.path}get-project-mails`);
  },
  async getProjectMails(
    params: Omit<
      ServiceListPropTypes<{ id: number; category: number }>,
      "sortBy"
    >
  ) {
    return axios.get<ApiResponseBodyType<ProjectContactsList>>(
      `${this.path}get-project-mails?id=${params.id}&MailingContactSearch[category]=${params.category}&page=${params.page}&per-page=${params.perPage}`
    );
  },
  async getMailingCSVContacts({
    id,
    category,
  }: {
    id: number;
    category: number;
  }) {
    return axios.get(
      `${this.path}get-project-mails-csv?id=${id}&MailingContactSearch[category]=${category}`
    );
  },
  // скачать отчет
  async getMailsCSV(id: string | number) {
    return axios.get(`${this.path}get-project-mails-csv?id=${id}`);
  },
};
