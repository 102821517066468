import { useAppSelector } from "services/hooks";
import { selectFormClientState } from "services/store/createFormClientSlice";

export const useCreateClientFormPageState = () => {
  const data = useAppSelector(selectFormClientState);

  const createWidgetButtonText = (id: number) => {
    return `<button onclick="window.cheapsender._eventBus.publish('container_${id}_show');"> Вызвать форму подписки </button>
    `;
  };

  const createWidgetScriptText = ({
    id,
    uuid,
    token,
  }: {
    id: number;
    uuid: string;
    token: string;
  }) => {
    return `<script src="${window.location.origin}/form/index.js" defer></script>
<script defer>
    var cheapsenderFormInit = function () {
        new window.cheapsender.PopupWidget({
            uuid:"${uuid}",
            token:"${token}",
            id:${id}
        }).init();
    };
    if (document.readyState === "loading") {
        document.addEventListener('DOMContentLoaded', function () {
            cheapsenderFormInit()
        })
    } else {
        cheapsenderFormInit()
    }
</script>
     
    `;
  };

  return {
    data,
    createWidgetScriptText,
    createWidgetButtonText,
  };
};
