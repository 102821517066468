import type { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { createToastResponseError } from "lib/toastify";
import { _variables } from "ui/styles/_variables";
import noAvatar from "ui/assets/media/icons/no-avatar-icon.svg";

const imagesMimeTypes = ["image/gif", "image/jpg", "image/png", "image/jpeg"];

const LoadBox = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const IconWrap = styled.div`
  background-color: white;
  position: relative;
  width: 100%;
  height: 100%;
  color: #eff0f2;
  & img {
    width: 100%;
    height: 100%;
  }
`;

const LoadText = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  & label {
    cursor: pointer;
    color: ${_variables.primaryColor};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;
    margin-bottom: 4px;
  }
  & span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;
    color: ${_variables.grayColor};
  }
`;

const FileLoad = styled.input`
  color: red;
  display: none;
`;

export const LoadImage: CT<{
  defaultImage?: string;
  onChange?: (e: File) => void;
  loadImage?: boolean;
}> = ({ className, loadImage = false, defaultImage, onChange }) => {
  const [file, setFile] = useState<any>(null);
  const [preview, setPreview] = useState("");
  useEffect(() => {
    if (!defaultImage) return;
    setPreview(defaultImage);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!file) return;
    setPreview(URL.createObjectURL(file));
    if (onChange) {
      onChange(file);
    }
    //eslint-disable-next-line
  }, [file]);

  const changeFileHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) return;
    if (!file.type || !imagesMimeTypes.includes(file.type)) return;
    if (file.size > 500000) {
      createToastResponseError(null, "Слишком большой размер файла")();
      return;
    }
    setFile(file);
  };

  const previewText = loadImage
    ? ["Изменить фотографию", "Загрузить фотографию"]
    : ["Изменить аватар", "Загрузить аватар"];

  const descriptionText = loadImage
    ? "JPG,PNG"
    : "JPG, GIF или PNG. Максимум 500Кб";

  return (
    <LoadBox className={className}>
      <Label>
        {preview ? (
          <img src={preview} alt="" />
        ) : (
          <IconWrap>
            <img src={noAvatar} alt="" />
          </IconWrap>
        )}
        {/*<LoadFileInput onChange={changeFileHandler} type="file" />*/}
      </Label>
      <LoadText>
        <label>
          {preview ? previewText[0] : previewText[1]}
          <FileLoad onChange={changeFileHandler} type="file" />
        </label>
        <span>{descriptionText}</span>
      </LoadText>
    </LoadBox>
  );
};
