import { useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

import { Button } from "../Buttons/Button";

type CommentFieldProps = {
  addComment: (comment: string, replyTo?: number) => Promise<void>;
  parentCommentId?: number;
};

const Form = styled.form`
  width: 100%;
  background-color: ${_variables.backgroundColor};
  border: 2px solid ${_variables.backgroundColor};
  padding: 20px;
  border-radius: 8px;
  &:focus-within {
    border: 2px solid ${_variables.primaryColor};
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  background: none;
  border: none;
  resize: none;
`;

export const CommentField = ({
  addComment,
  parentCommentId,
}: CommentFieldProps) => {
  const onSubmit = useCallback(
    ({ comment }: { comment: string }, { resetForm }) => {
      addComment(comment, parentCommentId).then(resetForm);
    },
    [addComment]
  );

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validateOnChange: true,
    validationSchema,
    validateOnMount: true,
    onSubmit,
  });

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Textarea
        name="comment"
        maxLength={5000}
        placeholder="Ваш комментарий"
        value={formik.values.comment}
        onChange={formik.handleChange}
      />
      <Button disabled={!formik.isValid} type="submit" variant="primary">
        Отправить
      </Button>
    </Form>
  );
};
