import type { IconPropsType } from "./types";

import { IIconWrapper } from "./styles";

import Sprite from "../../assets/media/icons/sprites/analytic-icon-sprite.svg";

export type ServiceIconComponentPropsType = IconPropsType<{
  variant: "glyph" | "circle";
  name: "eye" | "send" | "delivered" | "transition" | "spam" | "unsubscribe";
}>;

export const StatisticIcon: CT<ServiceIconComponentPropsType> = ({
  variant,
  wrapper,
  className,
  style,
  height = 24,
  width = 24,
  name = "send",
}) => {
  return (
    <IIconWrapper
      style={{
        width: wrapper ? wrapper.width : "auto",
        height: wrapper ? wrapper.height : "auto",
        ...(wrapper?.style ?? ""),
      }}
    >
      <svg
        className={className}
        style={{ width: width, height: height, ...style }}
      >
        <use href={`${Sprite}#${name}-${variant}-icon`} />
      </svg>
    </IIconWrapper>
  );
};
