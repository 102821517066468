import styled, { css } from "styled-components";

import { _variables } from "ui/styles/_variables";
import { CheckIcon } from "ui/components/Icons/CheckIcon";

const Crumbs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1px;
  color: ${_variables.grayColor};
`;

const Crumb = styled.div<{ isActive: boolean; isSuccess: boolean }>`
  display: flex;
  width: 100%;
  min-height: 38px;
  background-color: ${_variables.backgroundColor};
  border-radius: 12px;
  justify-content: space-between;
  padding: 12px 16px 12px 12px;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  ${(props) => {
    if (props.isActive)
      return css`
        color: ${_variables.primaryColor};
      `;
  }}
  ${(props) => {
    if (props.isSuccess)
      return css`
        color: ${_variables.blackColor};
      `;
  }}
`;

const TextContent = styled.div`
  display: flex;
`;

const Number = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
`;

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
`;

const Separator = styled.div`
  width: 24px;
  height: 14px;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before {
    width: 4px;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    border-radius: 38px;
    transform: translateX(-50%);
    content: "";
    background-color: ${_variables.grayColor};
  }
  &.active {
    &:before {
      background-color: #00ba96;
    }
  }
`;

export const CrumbsVertialUpd: CT<{
  values: { title: string; isActive: boolean; isSuccess?: boolean }[];
}> = ({ values, className }) => {
  return (
    <Crumbs className={className}>
      {values.map((item, i) => {
        return (
          <>
            <Crumb isActive={item.isActive} isSuccess={!!item.isSuccess}>
              <TextContent>
                <Number>{i + 1}</Number>
                <span>{item.title}</span>
              </TextContent>
              {item.isSuccess && (
                <>
                  <IconWrapper>
                    <CheckIcon
                      name={"circle-green"}
                      variant={"success"}
                      width={16}
                      height={16}
                    />
                  </IconWrapper>
                </>
              )}
            </Crumb>
            {i + 1 !== values.length && (
              <Separator
                className={
                  values[i + 1].isActive || values[i + 1].isSuccess
                    ? "active"
                    : ""
                }
              />
            )}
          </>
        );
      })}
    </Crumbs>
  );
};
