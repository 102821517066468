import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  & path {
    fill: white;
    opacity: 0.5;
  }
  &:hover {
    & path {
      opacity: 1;
    }
  }
`;
export const ToastExitButton: CT<unknown> = () => {
  return (
    <Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" fill="none">
        <path
          d="M7.41 5.99994L10.71 2.70994C10.8983 2.52164 11.0041 2.26624 11.0041 1.99994C11.0041 1.73364 10.8983 1.47825 10.71 1.28994C10.5217 1.10164 10.2663 0.99585 10 0.99585C9.7337 0.99585 9.4783 1.10164 9.29 1.28994L6 4.58994L2.71 1.28994C2.5217 1.10164 2.2663 0.99585 2 0.99585C1.7337 0.99585 1.4783 1.10164 1.29 1.28994C1.1017 1.47825 0.995908 1.73364 0.995908 1.99994C0.995908 2.26624 1.1017 2.52164 1.29 2.70994L4.59 5.99994L1.29 9.28994C1.19627 9.38291 1.12188 9.4935 1.07111 9.61536C1.02034 9.73722 0.994202 9.86793 0.994202 9.99994C0.994202 10.132 1.02034 10.2627 1.07111 10.3845C1.12188 10.5064 1.19627 10.617 1.29 10.7099C1.38296 10.8037 1.49356 10.8781 1.61542 10.9288C1.73728 10.9796 1.86799 11.0057 2 11.0057C2.13201 11.0057 2.26272 10.9796 2.38458 10.9288C2.50644 10.8781 2.61704 10.8037 2.71 10.7099L6 7.40994L9.29 10.7099C9.38296 10.8037 9.49356 10.8781 9.61542 10.9288C9.73728 10.9796 9.86799 11.0057 10 11.0057C10.132 11.0057 10.2627 10.9796 10.3846 10.9288C10.5064 10.8781 10.617 10.8037 10.71 10.7099C10.8037 10.617 10.8781 10.5064 10.9289 10.3845C10.9797 10.2627 11.0058 10.132 11.0058 9.99994C11.0058 9.86793 10.9797 9.73722 10.9289 9.61536C10.8781 9.4935 10.8037 9.38291 10.71 9.28994L7.41 5.99994Z"
          fill="white"
        />
      </svg>
    </Wrapper>
  );
};
