import styled from "styled-components";

import { ShadowButton } from "../Buttons/ShadowButton";
import { Icon } from "../Icon";
import { Link } from "../Link";

const Content = styled.div<{ marginTop?: string }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? "300px"};
`;

const Text = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 24px;
`;

export const NotContentFormsPage = ({ marginTop }: { marginTop?: string }) => {
  return (
    <Content marginTop={marginTop}>
      <Text>У вас пока нет созданных форм подписки</Text>
      <Link link={"/forms/create"}>
        <ShadowButton>
          <Icon
            id={"plus-circle-icon"}
            height={20}
            width={20}
            style={{ marginRight: 8 }}
          />
          Создать форму
        </ShadowButton>
      </Link>
    </Content>
  );
};
