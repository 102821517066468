import type { IconPropsType } from "./types";

import { IIconWrapper } from "./styles";

import Sprite from "../../assets/media/icons/sprites/decor-icon-sprite.svg";

export type ActionIconComponentPropsType = IconPropsType<{
  variant: "info";
  name: "alert" | "lock";
}>;

/**
 * Декоративные иконки -> восклицательные знаки, вопросительные и пр пр
 */

export const DecorIcon: CT<ActionIconComponentPropsType> = ({
  variant,
  wrapper,
  className,
  style,
  height = 24,
  width = 24,
  name = "alert",
}) => {
  return (
    <IIconWrapper
      style={{
        width: wrapper ? wrapper.width : "auto",
        height: wrapper ? wrapper.height : "auto",
        ...(wrapper?.style ?? ""),
      }}
    >
      <svg
        className={className}
        style={{ width: width, height: height, ...style }}
      >
        <use href={`${Sprite}#${name}-${variant}-icon`} />
      </svg>
    </IIconWrapper>
  );
};
