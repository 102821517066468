import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { debounce } from "lodash";
import styled from "styled-components";

import type {
  BookListType,
  BookValidateType,
  ListItemType,
} from "domain/lists";
import { importStoreAction } from "services/store/importSlice";
import { useAppDispatch } from "services/hooks";
import { contactsListService } from "services/list";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";

import { Icon } from "../../components/Icon";
import { TableContainerScroll } from "../../containers/TableContainerScroll";
import { ContactsHeaderRow } from "../../components/ContactsHeaderRow";
import { ButtonTable } from "../../components/Buttons/ButtonTable";
import { AdditionalMenuTable } from "../../components/Menus/AdditionalMenuTable";
import { TableHead } from "../../components/Table/TableHead";
import { TableHeadOnceFilter } from "../../components/Table/Filters/TableHeadOnceFilter";
import { LoadContent } from "../../components/LoadContent";
import { TableBody } from "../../components/Table/TableBody";
import { ShowCount } from "../../components/ShowCount";
import { ContentPagination } from "../../components/ContentPagination";
import { ContactTableContent } from "../../components/Table/ContantTableContent";
import { NotContentContactPage } from "../../components/NotContent/NotContentContactPage";
import { useTableWorkspace } from "../../hooks/useTableWorkspace";
import { useCheckedTableData } from "../../hooks/useCheckedTableData";
import { useTableStateLoad } from "../../hooks/useTableStateLoad";
import { AlertListPageContent } from "./AlertListPageContent";
import { BreadcrumbsTitle } from "../../components/ BreadcrumbsTitle";
import { Input } from "../../components/FormsElements/Input";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px 5px 12px 32px;
`;

const Table = styled.table`
  display: grid;
  grid-template-columns: minmax(100px, 2fr) minmax(100px, 1fr) 1fr 1fr;
  grid-auto-rows: auto;
  position: relative;
  z-index: 9;
`;

const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 31px;
  margin-bottom: 18px;
`;

const Heading = styled(BreadcrumbsTitle)`
  margin-top: 31px;
  margin-bottom: 18px;
`;

const HeadingRightContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
`;

const InputSearch = styled(Input)`
  width: 308px;
`;

const TableTop = styled(Table)`
  padding-right: 0;
  row-gap: 0;
`;

const StickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 11;
  background-color: white;
`;

const FirstTH = styled(TableHeadOnceFilter)`
  padding-left: 4px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
`;

const NoContent = styled(NotContentContactPage)``;

const LoadWrapper = styled.div`
  display: flex;

  height: 100%;
`;

const Alert = styled(AlertListPageContent)`
  margin-bottom: 28px;
`;

export const ContactPage = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ListItemType[]>([]);
  const [countPage, setCountPage] = useState(0);
  const [bookData, setBookData] = useState<BookListType | null>(null);
  const [bookValidateData, setBookValidateData] =
    useState<BookValidateType | null>(null);
  const [countMaxContacts, setMaxContacts] = useState<null | string>(null);
  const [searchValue, setSearchValue] = useState<string>("");

  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { query, setPerPage, setPage, setSortBy } = useTableWorkspace({
    perPage: 25,
    page: 1,
    sortBy: "email",
  });
  const { isNotHaveContent, isLoad, setOffLoad, setOnLoad, isShowContent } =
    useTableStateLoad(data);
  const { checkedArray, clearChecked, toggleCheck, setCheckedArray } =
    useCheckedTableData("contactsChecked");

  const loadData = async () => {
    setData([]);
    setOnLoad();
    try {
      const list = await contactsListService.view({
        id: Number(params.id),
        sortBy: query.sortBy,
        page: query.page,
        perPage: query["per-page"],
        search: searchValue,
      });

      setCountPage(list.headers["x-pagination-page-count"]);
      setMaxContacts(list.headers["x-pagination-total-count"]);
      setData(list.data.data.contacts);
      setBookData(list.data.data.book);
      setBookValidateData(list.data.data.bookValidate);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  const searchChangeHandler = debounce((value: string) => {
    setSearchValue(value);
    setPage(1);
  }, 300);

  const uploadStatus = async () => {
    try {
      const list = await contactsListService.view({
        id: Number(params.id),
        sortBy: query.sortBy,
        page: query.page,
        perPage: query["per-page"],
      });

      setBookValidateData(list.data.data.bookValidate);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  const changePaginationHandler = setPage;
  const changePerPageHandler = setPerPage;
  const changeSortByHandler = setSortBy;
  const checkedItemHandler = toggleCheck;
  const allCheckClickHandler = () => {
    if (checkedArray.length < data.length) {
      setCheckedArray(data.map((item) => item.id));
      return;
    }
    setCheckedArray([]);
  };

  const deleteCollectionListHandler = () => {
    dispatch(
      setModalData({
        ids: checkedArray,
        bookId: bookData?.id,
        deleteBy: "list",
      })
    );
    dispatch(openModal("delete-contacts"));
  };
  const deleteCollectionAllHandler = () => {
    dispatch(
      setModalData({ ids: checkedArray, bookId: bookData?.id, deleteBy: "all" })
    );
    dispatch(openModal("delete-contacts"));
  };
  const clickAddContactsHandler = () => {
    dispatch(
      importStoreAction.setListData({
        id: bookData!.id,
        name: bookData!.name,
        count: bookData!.count,
      })
    );
    history.push("/contacts/import");
  };
  const renameListHandler = () => {
    dispatch(setModalData({ id: params.id, name: bookData?.name ?? "" }));
    dispatch(openModal("rename-list"));
  };
  const moveContactsHandler = () => {
    dispatch(
      setModalData({ id: params.id, bookId: params.id, ids: checkedArray })
    );
    dispatch(openModal("move-list"));
  };

  useEffect(() => {
    return () => {
      clearChecked();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timeout: null | any = null;
    const timeouteFn = () => {
      timeout = setTimeout(() => {
        uploadStatus();
        timeouteFn();
      }, 15000);
    };
    timeouteFn();
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  useEffect(() => {
    loadData();
    //eslint-disable-next-line
  }, [query, searchValue]);

  const checkedCount = checkedArray.length > 0 ? `${checkedArray.length} ` : "";

  const isShowAdditionalMenu = checkedArray.length > 0;
  const Menu = () => {
    return (
      <>
        <li onClick={moveContactsHandler}>
          <Icon id={"document-upload-icon"} width={24} height={24} />
          Переместить в список
        </li>
        <li onClick={deleteCollectionAllHandler}>
          <Icon id={"document-move-icon"} width={24} height={24} />
          Удалить из списков
        </li>
        <li onClick={deleteCollectionListHandler}>
          <Icon id={"delete-icon"} width={24} height={24} />
          Удалить в текущем
        </li>
      </>
    );
  };

  return (
    <>
      <Content>
        <TableContainerScroll height={136}>
          <StickyTopContent>
            <ContactsHeaderRow>
              <Buttons>
                <ButtonTable
                  onClick={allCheckClickHandler}
                  iconId={"check-icon"}
                  isActive={checkedCount.length > 0}
                >{`${checkedCount}Выделить все`}</ButtonTable>
                <ButtonTable
                  onClick={clickAddContactsHandler}
                  iconId={"add-circle-icon"}
                >
                  Добавить&nbsp;контакты
                </ButtonTable>

                <ButtonTable
                  onClick={renameListHandler}
                  iconId={"edit-table-icon"}
                >
                  Переименовать&nbsp;список
                </ButtonTable>
              </Buttons>
              {isShowAdditionalMenu && (
                <AdditionalMenuTable
                  offset={[-190, 45]}
                  contentMenu={<Menu />}
                />
              )}
            </ContactsHeaderRow>
            <HeadingRow>
              <Heading
                data={[
                  { link: "/contacts/", title: "Ваши списки" },
                  { title: bookData?.name ?? "" },
                ]}
              />
              <HeadingRightContent>
                <InputSearch
                  startIcon={{
                    id: "search-glass-icon",
                    width: 24,
                    height: 24,
                  }}
                  key={"searchTemplate"}
                  value={searchValue}
                  placeholder={"Поиск по email"}
                  onChange={(e) => {
                    searchChangeHandler(e.target.value);
                  }}
                />
              </HeadingRightContent>
            </HeadingRow>

            {bookValidateData && (
              <Alert
                saleComplete={bookValidateData.saleComplete}
                bookId={Number(params!.id!)}
                name={bookData?.name ?? ""}
                date={bookValidateData.date_in}
                maxCount={countMaxContacts}
                status={bookValidateData.status}
                countNotValide={bookValidateData.countNotValidContacts}
              />
            )}
            <TableTop>
              <TableHead>
                <FirstTH
                  name={"email"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("email")}
                >
                  Email
                </FirstTH>
                <TableHeadOnceFilter
                  name={"name"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("name")}
                >
                  Имя
                </TableHeadOnceFilter>
                <TableHeadOnceFilter
                  name={"date"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("date")}
                >
                  Дата добавления
                </TableHeadOnceFilter>
                <TableHeadOnceFilter
                  name={"status"}
                  onClick={() => {}}
                  isActive={query.sortBy.includes("status")}
                >
                  Статус
                </TableHeadOnceFilter>
              </TableHead>
            </TableTop>
          </StickyTopContent>
          {isLoad && (
            <LoadWrapper>
              <LoadContent />
            </LoadWrapper>
          )}
          {isShowContent && (
            <Table>
              <TableBody>
                {data.length > 0 &&
                  data.map((item) => (
                    <ContactTableContent
                      key={item.id}
                      onClickCheck={checkedItemHandler}
                      id={item.id}
                      isActive={checkedArray.includes(item.id)}
                      date={item.date_in}
                      name={item.name}
                      email={item.email}
                      statusName={item.statusName}
                    />
                  ))}
              </TableBody>
            </Table>
          )}
          {isNotHaveContent && <NoContent />}
        </TableContainerScroll>
        <Footer>
          <ShowCount
            defaultValue={Number(query["per-page"])}
            onChange={changePerPageHandler}
          />
          {countPage > 1 && data.length > 0 && (
            <ContentPagination
              force={Number(query.page - 1)}
              onClick={changePaginationHandler}
              pageCount={countPage}
            />
          )}
        </Footer>
      </Content>
    </>
  );
};
