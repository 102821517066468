import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useLoadContactImport } from "app/importContacts/useLoadContactImport";

import { _variables } from "ui/styles/_variables";
import { scroll } from "ui/styles/elements/scroll";
import { Button } from "../../../../components/Buttons/Button";
import { WrapperContent } from "../../../../components/ImportContacts/WrapperContent";
import { LeftColumnContent } from "../../../../components/ImportContacts/LeftColumnContent";
import { Link } from "../../../../components/Link";

const Substring = styled.div`
  font-size: 14px;
  font-weight: 500;

  line-height: 20px;
  margin-bottom: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;
const WriteContent = styled.div`
  display: flex;
  min-height: 204px;
  border-radius: 12px;
  background-color: ${_variables.backgroundColor};
  max-height: 204px;
  resize: none;
  border: none;
  padding: 8px;

  & textarea {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    padding: 24px;
    width: 100%;
    resize: none;
    color: #898d9a;
    border: none;
    background-color: transparent;
    transition: color ${_variables.transition};
    ${scroll};
    &:focus {
      color: ${_variables.blackColor};
    }
  }
`;

const SubstringRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  column-gap: 20px;
`;

const LeftContent = styled(LeftColumnContent)`
  row-gap: 0;
`;

export const WriteFileContent: CT<unknown> = () => {
  const {
    onChangeFile,
    status,
    onGoToNextStep,
    writeContent,
    setHasBeenChangeWriteFile,
    clearHasBeenChangeWriteFile,
    onClearWriteFile,
    isLoadFile,
  } = useLoadContactImport();
  const [value, setValue] = useState(writeContent ?? "");

  useEffect(() => {
    if (!!writeContent?.length) {
      setHasBeenChangeWriteFile();
    }
  }, [writeContent]);

  useEffect(() => {
    if (status === 3 && !isLoadFile) {
      onGoToNextStep();
    }
  }, [status, isLoadFile]);

  useEffect(() => {
    if (value.length === 0) clearHasBeenChangeWriteFile();
    if (!!value.length) {
      setHasBeenChangeWriteFile();
      return;
    }
  }, [value]);

  return (
    <WrapperContent>
      <LeftContent>
        <WriteContent>
          <textarea
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={() => {}}
            onKeyDown={(e) => {
              if (e.keyCode === 9) {
                e.preventDefault();
                e.currentTarget.setRangeText(
                  "\t",
                  e.currentTarget.selectionStart,
                  e.currentTarget.selectionStart,
                  "end"
                );
              }
            }}
          />
        </WriteContent>
        <SubstringRow>
          <Substring>
            Разделите информацию о контакте с помощью запятой, <br /> точки с
            запятой или табуляции
          </Substring>
          <Link
            link={
              "https://cheapsender.notion.site/aa4e2ef903bc4d2ebad74e6149a96776"
            }
            color={"gray"}
            redirectMode={{ blank: true }}
          >
            Как импортировать контакты?
          </Link>
        </SubstringRow>
        <Button
          isLoad={isLoadFile}
          disabled={value.length === 0}
          onClick={() => {
            if (!value) {
              onClearWriteFile();
              return;
            }
            let blob = new Blob([value], {
              type: "text/plain",
            });
            let file = new File([blob], "file.txt", {
              type: "text/plain",
            });

            onChangeFile(file, value);
          }}
        >
          Продолжить
        </Button>
      </LeftContent>
    </WrapperContent>
  );
};
