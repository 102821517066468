import type { NotificationTitleMessage } from "react-notifications-component";
import { Store } from "react-notifications-component";

export const useNotification = () => {
  const addNotification = (
    message: NotificationTitleMessage,
    config?: {
      dismissTime?: number;
      id?: string;
      onRemoval?: VoidFunction;
    }
  ) => {
    const dismiss = config?.dismissTime ? { duration: 3000 } : undefined;

    Store.addNotification({
      message: <div id={"notify" + config?.id}>{message}</div>,
      type: "success",
      insert: "bottom",

      //@ts-ignore
      width: "fit-content",
      onRemoval: config?.onRemoval,
      container: "bottom-right",
      //@ts-ignore
      dismiss: { showIcon: true, click: false, ...dismiss },
      animationIn: ["animate__animated", "animate__zoomIn", "animate__faster"],
      animationOut: [
        "animate__animated",
        "animate__fadeOut",
        "animate__faster",
      ],
    });
  };

  const removeById = (id: string) => {
    const elem = document.getElementById("notify" + id);
    if (!elem) return;
    const parent = elem.closest(".rnc__notification");
    if (!parent) return;
    parent.remove();
  };

  const removeAllNotifications = () => {
    const all = document.querySelectorAll(".rnc__notification");
    all.forEach((item) => {
      item.remove();
    });
  };

  return {
    addNotification,
    removeById,
    removeAllNotifications,
  };
};
