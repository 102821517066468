import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import styled from "styled-components";

import { useUserWorkflow } from "app/user/useUserWorkflow";
import type { Status } from "domain/idea";
import { statuses } from "domain/idea";
import type { SortType, Tag } from "services/idea";
import { _variables } from "ui/styles/_variables";

import { Icon } from "../../../components/Icon";
import { Button } from "../../../components/Buttons/Button";
import { SelectCustom } from "../../../components/FormsElements/SelectCustom";
import { CategorySelect } from "../../../components/CategorySelect";
import { Input } from "../../../components/FormsElements/Input";
import { IdeaList } from "../../../components/Idea/IdeaList";
import { LoadContent } from "../../../components/LoadContent";
import { ContentPagination } from "../../../components/ContentPagination";
import { sortValues } from "../constants";
import useIdeaListFetcher from "./useIdeaListFetcher";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  overflow: scroll;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

const Logo = styled.div`
  background-color: ${_variables.primaryColor};
  padding: 16px;
  border-radius: 100%;
  margin-right: 16px;
  color: white;
`;

const Heading = styled.h1`
  color: ${_variables.blackColor};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
`;

const SubHeading = styled.h2`
  font-size: 14px;
  color: ${_variables.grayTextColor};
`;

const CreateNewIdea = styled(Link)`
  margin-left: auto;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IdeasList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
`;

const InputSearch = styled(Input)`
  width: 100%;
  font-size: 14px;
`;

const SelectedTag = styled.div`
  display: flex;
  align-items: center;
  background-color: ${_variables.backgroundColor};
  margin: 16px 0;
  padding: 8px 16px;
  border-radius: 12px;
  font-weight: 500;
`;

const IconWrapper = styled.div`
  color: ${_variables.grayColor};
  cursor: pointer;
  &hover {
    color: ${_variables.primaryColor};
  }
`;

const GrayText = styled.p`
  color: ${_variables.grayColor};
  padding: 0 4px;
`;

const PaginationWrapper = styled.div`
  margin: 8px auto;
`;

const FiltersBar = styled.aside`
  width: 25%;
  & > * {
    margin-bottom: 20px;
  }
`;

const IdeaListPage = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortValue, setSortValue] = useState<SortType>(sortValues[0].value);
  const [selectedStatus, setSelectedStatus] = useState<Status>(statuses[1]);
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);
  const userWorkflow = useUserWorkflow();

  const {
    isLoading,
    isPageLoading,
    ideaList,
    tagList,
    statusList,
    pageCount,
    setCurrentPage,
  } = useIdeaListFetcher({
    sort: sortValue,
    theme: searchValue,
    statusId: selectedStatus.id,
    tagId: selectedTag?.id,
  });

  const searchChangeHandler = debounce((value: string) => {
    setSearchValue(value);
    setCurrentPage(1);
  }, 300);

  useEffect(() => {
    if (!isLoading) {
      userWorkflow.profile();
    }
  }, [isLoading]);

  return (
    <Content>
      <Top>
        <Logo>
          <Icon id="idea" />
        </Logo>
        <div>
          <Heading>У вас есть идея?</Heading>
          <SubHeading>
            Предлагайте свои идеи по улучшению Cheapsender
          </SubHeading>
        </div>
        <CreateNewIdea to="ideas/new">
          <Button>Предложить идею</Button>
        </CreateNewIdea>
      </Top>
      {isPageLoading ? (
        <LoadContent />
      ) : (
        <MainContent>
          <IdeasList>
            <InputSearch
              startIcon={{
                id: "search-glass-icon",
                width: 24,
                height: 24,
              }}
              key={"searchTemplate"}
              value={searchValue}
              placeholder="Поиск по идеям"
              onChange={(e) => {
                searchChangeHandler(e.target.value);
              }}
            />
            {selectedTag ? (
              <SelectedTag>
                <p>{selectedTag.name}</p>
                <GrayText>{selectedTag.count}</GrayText>
                <IconWrapper onClick={() => setSelectedTag(null)}>
                  <Icon id="cross-bold-icon" width={20} height={20} />
                </IconWrapper>
              </SelectedTag>
            ) : null}

            {isLoading ? <LoadContent /> : <IdeaList ideas={ideaList} />}

            {pageCount > 1 ? (
              <PaginationWrapper>
                <ContentPagination
                  pageCount={pageCount}
                  onClick={setCurrentPage}
                />
              </PaginationWrapper>
            ) : null}
          </IdeasList>
          <FiltersBar>
            <SelectCustom
              values={sortValues}
              onChange={(value) => {
                setSortValue(value.value as SortType);
                setCurrentPage(1);
              }}
            />
            <CategorySelect
              options={statusList}
              selectedOption={selectedStatus}
              onChange={(status) => {
                setSelectedStatus(status);
                setCurrentPage(1);
              }}
            />
            <CategorySelect
              heading="Теги"
              reverseColors
              elementsToShow={8}
              options={tagList}
              selectedOption={selectedTag}
              onChange={(tag) => {
                setSelectedTag(tag);
                setCurrentPage(1);
              }}
            />
          </FiltersBar>
        </MainContent>
      )}
    </Content>
  );
};

export default IdeaListPage;
