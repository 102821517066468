import { useEffect, useState } from "react";

export const useBlockTimeout = () => {
  const [second, setSecond] = useState(0);

  const setTimer = (val: number) => {
    setSecond(val);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSecond((seconds) => seconds - 1);
    }, 1000);

    if (second <= 0) clearInterval(interval);
    return () => clearInterval(interval);
  }, [second]);

  return {
    timeout: second,
    setTimer,
  };
};
