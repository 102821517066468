import React, { useEffect, useRef, useState } from "react";
import { shallowEqual } from "react-redux";
import { debounce } from "lodash";
import useUrlState from "@ahooksjs/use-url-state";
import { useClickAway, useFocusWithin } from "ahooks";
import styled from "styled-components";

import { contactsListService } from "services/list";
import { openModal } from "services/store/modal";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectImportState } from "services/store/importSlice";

import { _variables } from "ui/styles/_variables";
import { Input } from "ui/components/FormsElements/Input";
import { SelectBox } from "ui/components/SelectBox";
import { Icon } from "ui/components/Icon";

const Wrapper = styled.div`
  position: relative;
  z-index: 11;
`;
const Select = styled(SelectBox)`
  position: absolute !important;
  top: calc(100% + 10px);
  width: 100%;
  background-color: white;
`;

const AddButton = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
  font-size: 14px;
  height: 32px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  background-color: ${_variables.backgroundColor};
  padding-left: 16px;
  color: ${_variables.blackColor};
  cursor: pointer;
`;

const AddList: CT<{ onClick: VoidFunction }> = ({ onClick }) => {
  return (
    <AddButton onClick={onClick}>
      <Icon id={"plus-color-icon"} width={24} height={24} />
      Создать новый список
    </AddButton>
  );
};

export const ImportEmailChooseUserList: CT<
  Omit<React.ComponentProps<typeof Input>, "onChange" | "onBlur"> & {
    onChange: (
      val: React.ComponentProps<typeof SelectBox>["values"][number]
    ) => void;
    onBlur: (
      val: React.ComponentProps<typeof SelectBox>["values"][number]
    ) => void;
    initialData: {
      name: string;
      id: number;
      count: number;
    };
    fixedElement?: React.ComponentProps<typeof SelectBox>["fixedElement"];
    canAdd?: boolean;
  }
> = ({
  onChange,
  initialData,
  onBlur,
  fixedElement,
  canAdd = true,
  ...props
}) => {
  const dispatch = useAppDispatch();

  const [query, setQuery] = useUrlState({
    listId: "",
    listName: "",
  });
  const [chooseValue, setChooseValue] = useState<
    React.ComponentProps<typeof SelectBox>["values"][number]
  >({
    id: initialData.id,
    name: initialData.name,
    data: {
      count: initialData.count,
    },
  });
  const [value, setValue] = useState(initialData.name);
  const [showSelect, setShowSelect] = useState(false);
  const wrapperRef = useRef(null);
  const [values, setValues] = useState<
    React.ComponentProps<typeof SelectBox>["values"]
  >([]);

  const userSetting = useAppSelector(selectImportState);
  useEffect(() => {
    if (userSetting.list && !shallowEqual(userSetting.list, chooseValue)) {
      setChooseValue(userSetting.list);
    }
  }, [userSetting.list]);

  const changeHandler = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
    300
  );

  const clickSelectHandler = (
    data: React.ComponentProps<typeof SelectBox>["values"][number]
  ) => {
    if (data.id === chooseValue.id) {
      setShowSelect(false);
    }
    setChooseValue(values.find((item) => item.id == data.id)!);
    setValue(data.name);
    setShowSelect(false);
    onChange(values.find((item) => item.id == data.id)!);
  };

  const load = async () => {
    try {
      const response = await contactsListService.list({
        perPage: 999,
        name: value,
      });
      setValues(
        response.data.data.books.map((item) => ({
          name: item.name,
          id: item.id,
          data: {
            count: item.count,
          },
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  useClickAway(() => {
    setTimeout(() => {
      setValue(chooseValue!.name);
      setShowSelect(false);
      onBlur(chooseValue);
    }, 100);
  }, wrapperRef);

  useFocusWithin(wrapperRef, {
    onFocus: () => {
      setShowSelect(true);
    },
  });

  const clickAddListHandler = () => {
    dispatch(openModal("create-list-import"));
  };

  useEffect(() => {
    if (!props.value) return;
    setValue(props.value!);
  }, [props.value]);

  useEffect(() => {
    if (!query.listId || !query.listName) return;
    setChooseValue({
      ...chooseValue,
      name: query.listName,
      id: query.listId,
    });
    setValue(query.listName);
    setTimeout(() => setQuery({ ...query, listId: "", listName: "" }), 100);
  }, [query.listId, query.listName]);

  useEffect(() => {
    load();
  }, [value]);

  return (
    <Wrapper
      onClick={() => {
        setShowSelect(true);
      }}
      tabIndex={0}
      className={"selectWrapper"}
      ref={wrapperRef}
    >
      <Input
        autoComplete={"off"}
        {...props}
        value={value}
        placeholder={"Выберите список"}
        onChange={changeHandler}
      />
      {showSelect && (
        <Select
          fixedElement={
            canAdd ? <AddList onClick={clickAddListHandler} /> : undefined
          }
          onClickElement={clickSelectHandler}
          values={values}
        />
      )}
    </Wrapper>
  );
};
