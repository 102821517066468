import { useState } from "react";

import { planService } from "services/plan";
import { createToastResponseError } from "lib/toastify";

export const useUserBuyPackage = () => {
  const [isLoad, setLoad] = useState(false);

  const payHandler = async (planId: number, cardId: number | undefined) => {
    try {
      setLoad(true);
      window.dataLayer.push({ event: "cp_click_pay_payment" });
      window.dataLayer.push({ event: "cp_click_pay_payment_pack" });
      const response = await planService.buy(planId, cardId);
      const link = response.data.data.sale.conformation_url;
      const linkElem = document.createElement("a");
      linkElem.href = link;
      linkElem.click();
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setLoad(false);
    }
  };

  return {
    isLoadPayData: isLoad,
    onPay: payHandler,
  };
};
