import styled from "styled-components";

import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { _variables } from "ui/styles/_variables";

import { Button } from "../../components/Buttons/Button";
import { serviceUser } from "services/user";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 432px;
`;

const Text = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  opacity: 0.5;
  color: ${_variables.blackColor};
`;
const Buttons = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const UserNotifyContent: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const modalData = useAppSelector(selectModalData);
  return (
    <Container>
      <Text>{modalData?.message ?? ""}</Text>
      <Buttons>
        <Button
          onClick={async () => {
            await serviceUser.deleteNotify(modalData!.id);
            setTimeout(onClose, 50);
          }}
        >
          Спасибо
        </Button>
      </Buttons>
    </Container>
  );
};
