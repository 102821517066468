import { getBodyByStringHTML, getHeadByStringHTML } from "../lib/parceDocument";

const styleConfig = `
html, body, div, span, applet, object,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
body {
line-height: 1;
}
width:640px;
height:600px;
background:white;
// position:fixed;
// top:0;
// left:0;
`;

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const useTemplatePreviewFrame = () => {
  let iframe: HTMLIFrameElement | null = null;
  const createPreviewFrame = async (html: string) => {
    try {
      const head = getHeadByStringHTML(html);
      const body = getBodyByStringHTML(html);
      if (!body || !head) new Error("Ошибка парсинга HTML");
      let frame = document.createElement("iframe");
      await timeout(200);
      if (frame) {
        frame!.style.cssText = styleConfig;
        frame!.id = "iframePreview";
        document.body.append(frame!);
        await timeout(200);

        frame.width = "600px";
        frame.height = "600px";
        frame!.contentDocument!.write(body);
        frame!.contentDocument!.head.innerHTML = head;
        frame!.contentDocument!.body.style.padding = "0";
        frame!.contentDocument!.body.style.margin = "0";
        iframe = frame;
        return frame;
      }
    } catch (e) {
      return null;
    }
  };
  const destroyFrame = async () => {
    if (iframe) iframe.remove();
    iframe = null;
  };
  return {
    createPreviewFrame,
    destroyFrame,
  };
};
