import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "./store";
import type { UserFormDomainType } from "domain/userForm";

export type CreateFormClientType = {
  name?: UserFormDomainType["name"] | undefined;
  bookName: string;
  version: UserFormDomainType["settings"]["version"];
  step: 1 | 2 | 3 | 4 | 5 | 6;
  bookId: number;
  format: UserFormDomainType["type"]; // 1 - всплывающая 2 - статика
  setting: UserFormDomainType["settings"]["setting"];
  data: UserFormDomainType["settings"]["data"];
};

export const initialStateFormCreateSlice = {
  step: 1,
  format: 1,
  bookId: 0,
  bookName: "",
  data: null,
  setting: {
    position: 1,
    animation: 1,
    pages: {
      all: {
        checked: true,
        string: "",
      },
      only: {
        checked: false,
        string: "",
      },
      startWith: {
        checked: false,
        string: "",
      },
      endWith: {
        checked: false,
        string: "",
      },
      exact: {
        checked: false,
        string: "",
      },
    },
    os: 1,
    isHideMobile: false,
    displayCondition: 1,
    users: 1,
    platform: 1,
    show: 1,
  },
} as CreateFormClientType;

export const createFormClientSlice = createSlice({
  name: "app",
  initialState: initialStateFormCreateSlice,
  reducers: {
    clearData: (_) => {
      return initialStateFormCreateSlice;
    },
    setData: (_, action: PayloadAction<CreateFormClientType>) => {
      return action.payload;
    },

    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setFormat: (
      state,
      action: PayloadAction<CreateFormClientType["format"]>
    ) => {
      state.format = action.payload;
    },

    setSettingPosition: (
      state,
      action: PayloadAction<CreateFormClientType["setting"]["position"]>
    ) => {
      state.setting.position = action.payload;
    },
    setSettingDisplayCondition: (
      state,
      action: PayloadAction<CreateFormClientType["setting"]["displayCondition"]>
    ) => {
      state.setting.displayCondition = action.payload;
    },
    setMobileHide: (
      state,
      action: PayloadAction<CreateFormClientType["setting"]["isHideMobile"]>
    ) => {
      state.setting.isHideMobile = action.payload;
    },
    setSettingAnimation: (
      state,
      action: PayloadAction<CreateFormClientType["setting"]["animation"]>
    ) => {
      state.setting.animation = action.payload;
    },

    setBookId: (state, action: PayloadAction<number>) => {
      state.bookId = action.payload;
    },

    setSettingPageValue: (
      state,
      action: PayloadAction<{
        name: keyof CreateFormClientType["setting"]["pages"];
        value: string;
      }>
    ) => {
      state.setting.pages = {
        ...state.setting.pages,
        [action.payload.name]: {
          ...state.setting.pages[action.payload.name],
          string: action.payload.value,
        },
      };
    },

    setSettingPageCheck: (
      state,
      action: PayloadAction<{
        name: keyof CreateFormClientType["setting"]["pages"];
        value: boolean;
      }>
    ) => {
      state.setting.pages = {
        ...state.setting.pages,
        [action.payload.name]: {
          ...state.setting.pages[action.payload.name],
          checked: action.payload.value,
        },
      };
    },
    setFormData: (state, action: PayloadAction<any & { design: any }>) => {
      state.data = action.payload;
    },

    goToNameChange: (state) => {
      state.step = 1;
    },
    goToConstructor: (state) => {
      state.step = 2;
    },
    goToChooseFormat: (state) => {
      state.step = 3;
    },
    goToSetting: (state) => {
      state.step = 4;
    },
    goToChooseListStep: (state) => {
      state.step = 5;
    },
    goToFinalStep: (state) => {
      state.step = 6;
    },
  },
});

export const createFormClientActions = createFormClientSlice.actions;
export default createFormClientSlice.reducer;

export const selectFormClientState = (state: RootState) =>
  state.createFormClientSlice;
