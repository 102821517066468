import styled from "styled-components";

import img from "ui/assets/media/images/mailing-not-count-preview.svg";

import { Button } from "../Buttons/Button";
import { Link } from "../Link";
import { _variables } from "ui/styles/_variables";

const IContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 432px;
`;
const Preview = styled.div`
  width: 432px;
  height: 211px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const ITextContent = styled.div`
  row-gap: 12px;
  display: flex;
  flex-direction: column;
`;
const IHeading = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const IButtons = styled.div`
  display: flex;
  column-gap: 8px;
`;

const IText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.25px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

export const MailingNotCount: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  return (
    <IContainer>
      <Preview>
        <img src={img} alt="" />
      </Preview>
      <ITextContent>
        <IHeading>Недостаточно писем для рассылки</IHeading>
      </ITextContent>
      <IText>
        После оплаты вы сможете найти свою рассылку в черновиках в разделе “Мои
        рассылки”.
      </IText>
      <IButtons>
        <Link onClick={() => onClose()} link={"/packages/payment"}>
          <Button>Пополнить</Button>
        </Link>
      </IButtons>
    </IContainer>
  );
};
