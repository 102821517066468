import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import useUrlState from "@ahooksjs/use-url-state";

import type { BookValidateHistoryType } from "domain/lists";
import { contactsListService } from "services/list";
import { useTableStateLoad } from "ui/hooks/useTableStateLoad";

export const useShowPayValidationHistoryCollection = () => {
  const [values, setValues] = useState<BookValidateHistoryType[]>([]);
  const [countPage, setCountPage] = useState(0);
  const [query] = useUrlState({
    "per-page": "25",
    page: "1",
    sortBy: "",
  });
  const [queryState, setQueryState] = useState({});
  const { setOffLoad, setOnLoad, ...tableState } = useTableStateLoad(values);

  const loadData = async () => {
    setOnLoad();
    try {
      const list = await contactsListService.getHistoryValidates({
        ...query,
        perPage: query["per-page"],
      });
      setCountPage(list.headers["x-pagination-page-count"]);
      setValues(list.data.data.bookValidates);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (shallowEqual(queryState, query)) return;
    setValues([]);
    loadData();
    setQueryState(query);
    //@eslint-disable-next-line
  }, [query]);

  return {
    data: values,
    countPage,
    loadData,
    query,
    ...tableState,
  };
};
