import axios from "axios";

import type { TemplateCategory, TemplateType } from "domain/templates";
import type { ServiceListPropTypes } from "domain/api";

export const templatesService = {
  path: "template/",

  async list({
    page = 0,
    perPage = 5,
    sortBy = "name",
    name = "",
  }: {
    page?: number;
    perPage?: number;
    sortBy?: string;
    name?: string;
  }) {
    return axios.get<ApiResponseBodyType<{ templates: TemplateType[] }>>(
      `${this.path}list?page=${page}&per-page=${perPage}&sort=${sortBy}&TemplateSearch[name]=${name}&expand=preview`
    );
  },
  async defaultList({
    page = 0,
    perPage = 5,
    sortBy = "name",
    name = "",
  }: {
    page?: number;
    perPage?: number;
    sortBy?: string;
    name?: string;
  }) {
    return axios.get<ApiResponseBodyType<{ templates: TemplateType[] }>>(
      `${this.path}list?page=${page}&per-page=${perPage}&sort=${sortBy}&TemplateSearch[name]=${name}&expand=preview`
    );
  },
  async listPreview({
    page = 0,
    perPage = 25,
  }: {
    page?: number;
    perPage?: number;
    sortBy?: string;
    name?: string;
  }) {
    return axios.get<ApiResponseBodyType<{ templates: TemplateType[] }>>(
      `${this.path}list?page=${page}&per-page=${perPage}&expand=preview`
    );
  },

  delete(ids: number[]) {
    const body = new FormData();
    ids.forEach((item) => {
      body.append("ids[]", item.toString());
    });
    return axios.post<ApiResponseBodyType<unknown>>(
      `${this.path}delete-by-ids`,
      body
    );
  },
  create({
    name,
    scheme,
    content,
    preview,
    isPublic = false,
  }: {
    name: string;
    content: string;
    preview: string;
    scheme: any;
    isPublic?: boolean; // признак создания публичного шаблона
  }) {
    const body = new FormData();
    body.append("TemplateForm[name]", name);
    body.append("TemplateForm[content]", content);
    //@ts-ignore
    body.append("TemplateForm[scheme]", scheme);
    body.append("TemplateForm[preview]", preview);
    //@ts-ignore
    body.append("TemplateForm[public]", Number(isPublic));
    return axios.post<
      ApiResponseBodyType<{
        template: {
          date_edit: string;
          date_in: string;
          has_scheme: boolean;
          id: number;
          name: string;
        };
      }>
    >(`${this.path}create`, body);
  },
  edit({
    id,
    name,
    scheme,
    content,
    preview,
    isPublic = false,
  }: {
    id: string;
    name: string;
    content: string;
    preview: string;
    scheme: string | null;
    isPublic?: boolean; // признак создания публичного шаблона
  }) {
    const body = new FormData();
    body.append("TemplateForm[name]", name);
    body.append("TemplateForm[content]", content);
    //@ts-ignore
    body.append("TemplateForm[scheme]", scheme);
    body.append("TemplateForm[preview]", preview);
    //@ts-ignore
    body.append("TemplateForm[public]", Number(isPublic));
    return axios.post<ApiResponseBodyType<unknown>>(
      `${this.path}update?id=${id}`,
      body
    );
  },

  viewWithPreview(id: number | string) {
    return axios.get<ApiResponseBodyType<{ template: TemplateType }>>(
      `${this.path}view?id=${id}&expand=content,scheme,preview`
    );
  },
  view(id: number | string) {
    return axios.get<ApiResponseBodyType<{ template: TemplateType }>>(
      `${this.path}view?id=${id}&expand=content,scheme`
    );
  },

  userPublicTemplates({
    perPage,
    page,
    sortBy,
    category,
  }: ServiceListPropTypes<unknown> & { category: string }) {
    return axios.get<ApiResponseBodyType<{ templates: TemplateType[] }>>(
      `${this.path}public-list?page=${page}&per-page=${perPage}&sort=${sortBy}&expand=status&TemplateSearch[status]=2&expand=preview&TemplateSearch[category_id]=${category}`
    );
  },

  // список публичных шаблонов для админов
  publicTemplates({ perPage, page, sortBy }: ServiceListPropTypes<unknown>) {
    return axios.get<ApiResponseBodyType<{ templates: TemplateType[] }>>(
      `${this.path}public-list?page=${page}&per-page=${perPage}&sort=${sortBy}&expand=status`
    );
  },

  // публикация публичных шаблонов для админа
  publishTemplate(idTemplate: number, scheme: string | null) {
    const body = new FormData();
    body.append("TemplateForm[status]", "2");
    //@ts-ignore
    body.append("TemplateForm[scheme]", scheme);
    return axios.post<ApiResponseBodyType<unknown>>(
      `${this.path}update?id=${idTemplate}`,
      body
    );
  },

  // копирование шаблона
  copy(id: number | string) {
    return axios.post<ApiResponseBodyType<{ template: TemplateType }>>(
      `${this.path}copy?id=${id}`
    );
  },

  dublicate(id: number | string, name: string) {
    return axios.post<ApiResponseBodyType<{ template: TemplateType }>>(
      `${this.path}copy?id=${id}`,
      { name }
    );
  },

  categories() {
    return axios.get<
      ApiResponseBodyType<{ "template-categories": TemplateCategory[] }>
    >(`${this.path}categories`);
  },

  rate(id: number | string, rating: number) {
    return axios.post<ApiResponseBodyType<{ template: TemplateType }>>(
      `${this.path}rate`,
      {
        TemplateUserRating: {
          template_id: id,
          rating: rating,
        },
      }
    );
  },
};
