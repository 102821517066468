import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { createStandardToast, createToastResponseError } from "lib/toastify";
import { openModal } from "services/store/modal";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { setModalData } from "services/store/modalDataSlice";
import { useShowFormsCollection } from "app/tables/useShowFormsCollection";
import { userFormService } from "services/userForms";
import { useEffect, useState } from "react";
import { createFormClientActions } from "services/store/createFormClientSlice";

import { _variables } from "ui/styles/_variables";
import { TableHead } from "../../components/Table/TableHead";
import { TableBody } from "../../components/Table/TableBody";
import { ContentPagination } from "../../components/ContentPagination";
import { TableContainerScroll } from "../../containers/TableContainerScroll";
import { ShowCount } from "../../components/ShowCount";
import { LoadContent } from "../../components/LoadContent";
import { useTableWorkspace } from "../../hooks/useTableWorkspace";
import { RowTabs } from "../../components/RowTabs";
import { Switch } from "ui/components/FormsElements/Switch";
import { ActionIcon } from "ui/components/Icons/ActionIcon";
import { NotContentFormsPage } from "ui/components/NotContent/NotContentFormsPage";
import { Icon } from "ui/components/Icon";
import { Link } from "ui/components/Link";
import { Button } from "ui/components/Buttons/Button";
import { selectUserData } from "services/store/userSlice";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px 5px 12px 32px;
`;

const Table = styled.table`
  display: grid;
  grid-template-columns:
    minmax(320px, 50%) minmax(320px, 50%) minmax(150px, 320px)
    minmax(150px, 320px) minmax(150px, 320px);
  grid-auto-rows: auto;
  position: relative;
  z-index: 9;
`;

const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 31px;
  margin-bottom: 18px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const TableTop = styled(Table)`
  padding-right: 0;
  margin-top: 28px;
  row-gap: 0;
`;
const THead = styled(TableHead)`
  & th {
    border-bottom: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    border-bottom: 1px solid ${_variables.strokeColor};
    color: ${_variables.grayTextColor};
  }
`;

const StickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: white;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const TD = styled.td`
  color: ${_variables.grayTextColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const LoadWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const ActionButton = styled.div`
  display: flex;
  width: 32px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  height: 32px;
  background-color: ${_variables.backgroundColor};
  border-radius: 8px;
  & svg {
    color: black;
  }
`;

export const ClientFormListPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUserData);
  const { query, setPerPage, setPage } = useTableWorkspace({
    perPage: 25,
    page: 1,
  });

  const { data, isNotHaveContent, isLoad, isShowContent, countPage } =
    useShowFormsCollection();

  const [showData, setShowData] = useState(data);
  const [isDisableChangeActive, setDisableShowActive] = useState(false);

  const changePaginationHandler = setPage;
  const changePerPageHandler = setPerPage;

  const changeSwitchFormHandler = async (id: number, val: boolean) => {
    if (isDisableChangeActive) return;
    try {
      setDisableShowActive(true);
      const response = await userFormService.updateActive(id, val);
      const { id: returnId, is_active: returnVal } =
        response.data.data["subscribe-form"];
      setShowData(
        showData.map((item) => {
          if (item.id == returnId)
            return {
              ...item,
              is_active: returnVal,
            };
          return item;
        })
      );
      if (returnVal) {
        createStandardToast("Форма подписки включена")({
          toastId: "is_active_on",
        });
      } else {
        createStandardToast("Форма подписки отключена")({
          toastId: "is_active_off",
        });
      }
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setDisableShowActive(false);
    }
  };

  const clickDeleteHandler = ({ id }: { id: number }) => {
    dispatch(setModalData({ isNotShowClose: true, id: id }));
    dispatch(openModal("delete-client-form"));
  };

  const getActiveTab = () => {
    if (window.location.pathname.includes("forms")) return "forms";
    return "email-validation";
  };

  const items = [
    { name: "Мои формы", value: "forms" },
    {
      name: "Готовые шаблоны",
      value: "",
      disable: true,
      tooltip: "Скоро тут появятся шаблоны",
    },
  ];

  useEffect(() => {
    dispatch(createFormClientActions.clearData());
  }, []);

  useEffect(() => {
    setShowData(data);
  }, [data]);

  if (isNotHaveContent)
    return (
      <Content>
        <TableContainerScroll height={136}>
          <StickyTopContent>
            <HeadingRow>
              <Heading>Формы подписок</Heading>
              <Link link={"/forms/create"}>
                <Button>
                  <Icon
                    id={"plus-circle-icon"}
                    height={20}
                    width={20}
                    style={{ marginRight: 8 }}
                  />
                  Создать форму
                </Button>
              </Link>
            </HeadingRow>
            <RowTabs
              onClick={(val) => {
                if (val === "forms") {
                  history.push("/forms");
                  return;
                }
                if (val === "cards") {
                  history.push("/main/user/card-pay");
                  return;
                }
                history.push("/main/history-pay");
              }}
              active={getActiveTab()}
              items={items}
            />
          </StickyTopContent>

          <NotContentFormsPage marginTop={"180px"} />
        </TableContainerScroll>
      </Content>
    );
  return (
    <>
      <Content>
        <TableContainerScroll height={136}>
          <StickyTopContent>
            <HeadingRow>
              <Heading>Формы подписок</Heading>
              <Link link={"/forms/create"}>
                <Button>
                  <Icon
                    id={"plus-circle-icon"}
                    height={20}
                    width={20}
                    style={{ marginRight: 8 }}
                  />
                  Создать форму
                </Button>
              </Link>
            </HeadingRow>
            <RowTabs
              onClick={(val) => {
                if (val === "forms") {
                  history.push("/forms");
                  return;
                }
                if (val === "cards") {
                  history.push("/main/user/card-pay");
                  return;
                }
                history.push("/main/history-pay");
              }}
              active={getActiveTab()}
              items={items}
            />

            <TableTop>
              <THead>
                <th>Название</th>
                <th>Дата создания</th>
                <th>Список</th>
                <th>Статус</th>
                <th>Действие</th>
              </THead>
            </TableTop>
          </StickyTopContent>
          {isLoad && (
            <LoadWrapper>
              <LoadContent />
            </LoadWrapper>
          )}
          {isShowContent && (
            <Table>
              <TableBody>
                {showData.length > 0 &&
                  showData.map((item) => (
                    <tr>
                      <TD>{item.name}</TD>
                      <TD>{item.created_at}</TD>
                      <TD>{item.bookName}</TD>
                      <TD>
                        <SwitchWrapper>
                          <Switch
                            size={{
                              width: 32,
                              height: 20,
                              radius: 16,
                            }}
                            onChange={(val) => {
                              changeSwitchFormHandler(item.id, val);
                            }}
                            value={item.is_active}
                            isShowTextPreview={false}
                          />
                        </SwitchWrapper>
                      </TD>
                      <TD>
                        <ActionsWrapper>
                          <ActionButton
                            onClick={() => {
                              history.push(`/forms/create/${item.id}`);
                            }}
                          >
                            <ActionIcon
                              width={16}
                              height={16}
                              name={"pen-tiny"}
                              variant={"edit"}
                            />
                          </ActionButton>
                          <ActionButton
                            onClick={() => {
                              clickDeleteHandler({ id: item.id });
                            }}
                          >
                            <ActionIcon
                              width={18}
                              height={18}
                              name={"delete"}
                              variant={"edit"}
                            />
                          </ActionButton>
                          {userData?.is_admin && (
                            <a
                              target={"_blank"}
                              rel="noreferrer"
                              href={`https://kalinss.github.io/tmb-cheap-widget-test?id=${item.id}&uuid=${item.uuid}&token=${item.token}&url=${window.location.origin}/form/index.js`}
                            >
                              <ActionButton>
                                <ActionIcon
                                  width={18}
                                  height={18}
                                  name={"arrow-bottom"}
                                  variant={"download"}
                                />
                              </ActionButton>
                            </a>
                          )}
                        </ActionsWrapper>
                      </TD>
                    </tr>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainerScroll>
        <Footer>
          <ShowCount
            defaultValue={Number(query["per-page"])}
            onChange={changePerPageHandler}
          />
          {countPage > 1 && data.length > 0 && (
            <ContentPagination
              force={Number(query.page - 1)}
              onClick={changePaginationHandler}
              pageCount={countPage}
            />
          )}
        </Footer>
      </Content>
    </>
  );
};
