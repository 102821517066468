import styled from "styled-components";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import type { UserFormListType } from "domain/userForm";

import { useCreateClientFormPageState } from "app/createClientForm/useCreateClientFormPageState";
import { userFormCreateAdapter, userFormService } from "services/userForms";
import { createStandardToast, createToastResponseError } from "lib/toastify";

import { _variables } from "ui/styles/_variables";
import { CodeBlock } from "ui/components/CodeBlock";
import { Button } from "ui/components/Buttons/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const Box = styled.div`
  display: flex;
  min-width: 284px;
  flex-direction: column;
  height: fit-content;
  row-gap: 8px;
  padding: 16px;
  background-color: ${_variables.backgroundColor};
  border-radius: 12px;
`;
const TitleForm = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const ButtonRow = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const Btn = styled(Button)`
  width: 166px;
`;

export const FinalStep = () => {
  const {
    createWidgetScriptText,
    data: dataForm,
    createWidgetButtonText,
  } = useCreateClientFormPageState();
  const [codeData, setCodeData] = useState(
    createWidgetScriptText({
      id: 0,
      uuid: "",
      token: "",
    })
  );
  const [buttonData, setButtonData] = useState(createWidgetButtonText(0));
  const [isLoad, setLoad] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [savedData, setSavedData] = useState<UserFormListType | null>(null);

  const copyHandler = () => {
    window.myClipboard.copy(codeData);
    createStandardToast("Код успешно скопирован")({ toastId: "12" });
  };

  const copyButtonHandler = () => {
    window.myClipboard.copy(buttonData);
    createStandardToast("Код кнопки успешно скопирован")({ toastId: "13" });
  };

  const saveHandler = async () => {
    setLoad(true);
    try {
      const data = userFormCreateAdapter(dataForm);
      let response;

      if (!!Number(id)) {
        response = await userFormService.update(Number(id), data);
      } else {
        response = await userFormService.create(data);
      }
      setSavedData(response.data.data["subscribe-form"]);
      createStandardToast(response.data.message)();
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setLoad(false);
    }
  };

  const clickNextHandler = () => {
    history.push("/forms");
  };

  useEffect(() => {
    saveHandler();
  }, []);

  useEffect(() => {
    if (savedData) {
      setButtonData(createWidgetButtonText(savedData.id));
      setCodeData(
        createWidgetScriptText({
          id: savedData.id,
          uuid: savedData.uuid,
          token: savedData.token,
        })
      );
    }
  }, [savedData]);

  return (
    <Container>
      <Box>
        <TitleForm>Код формы</TitleForm>
        <CodeBlock
          onClickCopyButton={copyHandler}
          disabled={!Boolean(savedData) || isLoad}
          text={codeData}
          height={126}
        />
      </Box>
      {dataForm.setting.displayCondition === 2 && (
        <Box>
          <TitleForm>Код кнопки</TitleForm>
          <CodeBlock
            height={80}
            onClickCopyButton={copyButtonHandler}
            disabled={!Boolean(savedData) || isLoad}
            text={buttonData}
          />
        </Box>
      )}

      <ButtonRow>
        <Btn disabled={isLoad} onClick={clickNextHandler} variant={"primary"}>
          Готово
        </Btn>
      </ButtonRow>
    </Container>
  );
};
