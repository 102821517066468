import axios from "axios";

import type { TimezonesType } from "domain/timezones";

export const timezonesService = {
  async list() {
    return axios.get<ApiResponseBodyType<{ timezones: TimezonesType }>>(
      "default/timezone-list"
    );
  },
};
