import styled from "styled-components";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";

import { firstCharUpperCase } from "lib/string";
import { _variables } from "ui/styles/_variables";
import iconArrow from "ui/assets/media/icons/arrow-date-picker-icon.svg";

const Header = styled.div`
  display: flex;
  max-height: 44px;
  padding: 13px 16px;
  background-color: ${_variables.backgroundColor};
  border-radius: ${_variables.borderRadius};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  justify-content: space-between;
  align-items: center;
`;

const Date = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const Today = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: left;
  color: ${_variables.primaryColor};
  cursor: pointer;
  position: relative;
`;

const RightGroup = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  position: relative;
`;

const ControlLeft = styled.figure<{ disable: boolean }>`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disable ? "auto" : "pointer")};
  opacity: ${(props) => (props.disable ? "0.25" : "1")};
  & img {
    position: relative;
    top: 0px;
    height: 14px;
  }
`;

const ControlRight = styled(ControlLeft)`
  transform: rotate(180deg);
  & img {
    position: relative;
    top: 1px;
  }
`;

const ControlGroup = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;

const IconLeft: CT<{ onClick: VoidFunction; isDisable: boolean }> = ({
  onClick,
  isDisable = false,
}) => {
  return (
    <ControlLeft onClick={!isDisable ? onClick : () => {}} disable={isDisable}>
      <img src={iconArrow} alt="Предыдущий месяц" />
    </ControlLeft>
  );
};

const IconRight: CT<{ onClick: VoidFunction; isDisable: boolean }> = ({
  onClick,
  isDisable = false,
}) => {
  return (
    <ControlRight onClick={!isDisable ? onClick : () => {}} disable={isDisable}>
      <img src={iconArrow} alt="Следующий месяц" />
    </ControlRight>
  );
};

export const DatePickerHeader: CT<{
  month: Date;
  nextMonthHandler: VoidFunction;
  prevMonthHandler: VoidFunction;
  onClickTodayDate: VoidFunction;
}> = ({
  month,
  onClickTodayDate,
  nextMonthHandler,
  prevMonthHandler,
  className,
}) => {
  return (
    <Header className={className}>
      <Date>
        {firstCharUpperCase(format(month, "LLLL", { locale: ru }))}{" "}
        {month.getFullYear()}
      </Date>
      <RightGroup>
        <Today onClick={onClickTodayDate}>Сегодня</Today>
        <ControlGroup>
          <IconLeft onClick={prevMonthHandler} isDisable={false} />
          <IconRight onClick={nextMonthHandler} isDisable={false} />
        </ControlGroup>
      </RightGroup>
    </Header>
  );
};
