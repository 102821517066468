import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { UseShowDefaultTemplateCollection } from "app/tables/useShowDefaultTemplateCollection";
import { createStandardToast, createToastResponseError } from "lib/toastify";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { templatesService } from "services/templates";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";
import { selectUserData } from "services/store/userSlice";

import { useTableWorkspace } from "../../hooks/useTableWorkspace";
import { useCheckedTableData } from "../../hooks/useCheckedTableData";
import { TableContainerScroll } from "../../containers/TableContainerScroll";
import { ContactsHeaderRow } from "../../components/ContactsHeaderRow";
import { ButtonTable } from "../../components/Buttons/ButtonTable";
import { Icon } from "../../components/Icon";
import { TableHead } from "../../components/Table/TableHead";
import { TableHeadOnceFilter } from "../../components/Table/Filters/TableHeadOnceFilter";
import { LoadContent } from "../../components/LoadContent";
import { TableBody } from "../../components/Table/TableBody";
import { SearchNotFound } from "../../components/NotContent/SearchNotFound";
import { ShowCount } from "../../components/ShowCount";
import { ContentPagination } from "../../components/ContentPagination";
import { Button } from "../../components/Buttons/Button";
import { NotContentDefaultTemplate } from "../../components/NotContent/NotContentDefaultTemplate";
import { TemplateDefaultTableContent } from "../../components/Table/TemplateDefaultTableContent";
import { deepEqual } from "lib/other";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px 5px 12px 32px;
`;

const Table = styled.table`
  display: grid;
  grid-template-columns:
    minmax(280px, 100%) minmax(223px, 400px) minmax(145px, 140px)
    200px;
  grid-auto-rows: auto;
  position: relative;
  z-index: 9;

  & th {
    min-height: 48px;
  }

  & tr td {
    height: 74px;
  }
`;

const HeadingLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 31px;
  margin-bottom: 18px;
`;

const HeadingRightContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
`;

const Heading = styled.h1`
  font-family: "Inter";
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const TableTop = styled(Table)`
  padding-right: 0;
  row-gap: 0;
`;

const StickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: white;
`;

const FirstTH = styled(TableHeadOnceFilter)`
  padding-left: 4px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const LoadWrapper = styled.div`
  display: flex;

  height: 100%;
`;
const CreateButton = styled(Button)`
  padding: 0 18px;
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
`;

export const AdminDefaultTemplatesPage = () => {
  const { setPerPage, setPage, setSortBy } = useTableWorkspace({
    perPage: 25,
    page: 1,
    sortBy: "name",
  });
  const {
    data,
    countPage,
    query,
    isLoad,
    isNotFound,
    isNotHaveContent,
    loadData,
  } = UseShowDefaultTemplateCollection();
  const userData = useAppSelector(selectUserData, deepEqual);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { checkedArray, toggleCheck, setCheckedArray } = useCheckedTableData(
    "templatesDefaultChecked"
  );

  const changePaginationHandler = setPage;
  const changePerPageHandler = setPerPage;
  const changeSortByHandler = setSortBy;
  const checkedItemHandler = toggleCheck;

  const allCheckClickHandler = () => {
    if (checkedArray.length < data.length) {
      setCheckedArray(data.map((item) => item.id));
      return;
    }
    setCheckedArray([]);
  };

  const publishHandler = async (id: number) => {
    const answer = window.confirm("Вы готовы опубликовать шаблон?");
    if (!!answer) {
      try {
        const viewResponse = await templatesService.view(id);
        await templatesService.publishTemplate(
          id,
          JSON.stringify(viewResponse.data.data.template.scheme)
        );
        await loadData();
        await createStandardToast("Шаблон опубликован")();
      } catch (e) {
        createToastResponseError("Ошибка публикации шаблона")();
      }
    }
  };

  const deleteCollectionsHandler = () => {
    dispatch(setModalData({ ids: checkedArray }));
    dispatch(openModal("delete-templates"));
  };

  const checkedCount = checkedArray.length > 0 ? `${checkedArray.length} ` : "";
  useEffect(() => {
    if (!userData?.is_admin) history.push("/");
  }, [userData]);

  const openSelectEditorModal = () => {
    dispatch(openModal("select-editor-type"));
  };

  if (isNotHaveContent) {
    return <NotContentDefaultTemplate />;
  }

  return (
    <>
      <Content>
        <TableContainerScroll height={136}>
          <StickyTopContent>
            <ContactsHeaderRow>
              <Buttons>
                <ButtonTable
                  onClick={allCheckClickHandler}
                  iconId={"check-icon"}
                  isActive={checkedCount.length > 0}
                >{`${checkedCount}Выделить все`}</ButtonTable>
                {!!checkedArray.length && (
                  <ButtonTable
                    iconWidth={24}
                    iconHeight={24}
                    iconId={"delete-icon"}
                    onClick={deleteCollectionsHandler}
                  >
                    Удалить
                  </ButtonTable>
                )}
              </Buttons>
            </ContactsHeaderRow>
            <HeadingLine>
              <Heading>Список шаблонов</Heading>
              <HeadingRightContent>
                <CreateButton onClick={openSelectEditorModal}>
                  <Icon
                    id={"plus-circle-icon"}
                    width={20}
                    height={20}
                    style={{ marginRight: 12 }}
                  />
                  Создать шаблон
                </CreateButton>
              </HeadingRightContent>
            </HeadingLine>
            <TableTop>
              <TableHead>
                <FirstTH
                  name={"name"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("name")}
                >
                  Название
                </FirstTH>
                <TableHeadOnceFilter
                  name={"date"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("date")}
                >
                  Дата создания
                </TableHeadOnceFilter>
                <th />
                <TableHeadOnceFilter
                  name={"date"}
                  onClick={() => {}}
                  isActive={query.sortBy.includes("date")}
                >
                  Статус
                </TableHeadOnceFilter>
              </TableHead>
            </TableTop>
          </StickyTopContent>

          {isLoad && (
            <LoadWrapper>
              <LoadContent />
            </LoadWrapper>
          )}
          {!isLoad && (
            <Table>
              <TableBody>
                {data.map((item) => (
                  <TemplateDefaultTableContent
                    onClickPublicTemplate={publishHandler}
                    data={item}
                    key={item.id + item.name}
                    isActive={checkedArray.includes(item.id)}
                    onClickCheck={checkedItemHandler}
                  />
                ))}
              </TableBody>
            </Table>
          )}
          {isNotFound && <SearchNotFound />}
        </TableContainerScroll>
        <Footer>
          <ShowCount
            defaultValue={Number(query["per-page"])}
            onChange={changePerPageHandler}
          />
          {countPage > 1 && data.length > 0 && (
            <ContentPagination
              force={Number(query.page - 1)}
              onClick={changePaginationHandler}
              pageCount={countPage}
            />
          )}
        </Footer>
      </Content>
    </>
  );
};
