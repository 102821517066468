import styled from "styled-components";

import type { statusMailCatalog } from "domain/mailing";
import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";

import { Icon } from "./Icon";

export type StatusMailingChipType = keyof typeof statusMailCatalog;

const icons = {
  1: "note-icon",
  2: "menu-icon",
  3: "success-icon",
  4: "cross-bold-icon",
  5: "inModeration",
  6: "note-icon",
  7: "lock-icon",
};

const backgroundColorStatusCollection = {
  1: `rgba(${hexToRgba(_variables.statusColors.planing, 0.15)})`,
  2: `rgba(${hexToRgba(_variables.statusColors.continues, 0.15)})`,
  3: `rgba(${hexToRgba(_variables.statusColors.success, 0.15)})`,
  4: `rgba(${hexToRgba(_variables.backgroundColor, 1)})`,
  5: `rgba(${hexToRgba(_variables.statusColors.inModeration, 0.15)})`,
  6: `rgba(${hexToRgba(_variables.statusColors.planing, 0.15)})`,
  7: `rgba(${hexToRgba(_variables.statusColors.blocked, 0.15)})`,
};
const colorStatusCollection = {
  1: _variables.statusColors.planing,
  2: _variables.statusColors.continues,
  3: _variables.statusColors.success,
  4: _variables.grayTextColor,
  5: _variables.statusColors.inModeration,
  6: _variables.statusColors.planing,
  7: _variables.statusColors.blocked,
};

const Container = styled.div<{ status: StatusMailingChipType }>`
  width: auto;
  height: 28px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  column-gap: 8px;
  border-radius: 8px;
  background-color: ${(props) => backgroundColorStatusCollection[props.status]};
  color: ${(props) => colorStatusCollection[props.status]};
`;

export const StatusMailingChip: CT<{ status: StatusMailingChipType }> = ({
  status,
  children,
}) => {
  return (
    <Container status={status}>
      <span>{children}</span>
      <>
        <Icon id={icons[status]} width={20} height={20} />
      </>
    </Container>
  );
};
