import { useState } from "react";
import { useHistory } from "react-router-dom";

import { createStandardToast, createToastResponseError } from "lib/toastify";
import { updateQueryParams } from "lib/queryParams";
import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { contactsListService } from "services/list";

import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";
import { ModalTextContainer } from "../../components/Modals/ModalTextContainer";
import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";
import { Button } from "../../components/Buttons/Button";

export const DeleteUnValidateContacts: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const modalData = useAppSelector(selectModalData);
  const [isLoad, setLoad] = useState(false);

  const history = useHistory();

  const onDeleteData = async () => {
    try {
      setLoad(true);
      const response = await contactsListService.deleteNotValidateContacts({
        id: modalData!.id,
      });
      createStandardToast(response.data.message);
      updateQueryParams(history, {});
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      onClose();
    }
  };

  return (
    <ModalDefaultFormContainer>
      <ModalTextContainer>
        <p>
          Контакты со статусом “Недействительные” будут удалены из вашего списка
        </p>
      </ModalTextContainer>
      <ModalButtonsContainer>
        <Button
          disabled={isLoad}
          isLoad={isLoad}
          onClick={onDeleteData}
          type={"submit"}
        >
          Удалить
        </Button>
        <Button onClick={onClose} type={"submit"} variant={"secondary"}>
          Отмена
        </Button>
      </ModalButtonsContainer>
    </ModalDefaultFormContainer>
  );
};
