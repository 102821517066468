import styled from "styled-components";

import { helpLinks } from "domain/links";
import { _variables } from "ui/styles/_variables";
import help1Preview from "ui/assets/media/images/help-preview-1.svg";

import { DecorationCardButton } from "../../components/DecorationCardButton";
import { DecorationHelpCard } from "../../components/DecorationHelpCard";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 40px 5px 0 40px;
  overflow: scroll;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
`;
const Heading = styled.h1`
  color: ${_variables.blackColor};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const Grid2 = styled.div`
  display: grid;
  padding-right: 35px;
  grid-template-columns:
    minmax(545px, calc(50% - 16px))
    minmax(545px, calc(50% - 16px));
  column-gap: 32px;
  margin-top: 32px;
`;

const Grid3 = styled.div`
  display: grid;
  padding-right: 35px;
  grid-template-columns: repeat(auto-fill, 350px);
  column-gap: 32px;
  row-gap: 32px;
  margin-top: 32px;
`;

export const HelpMainPage = () => {
  return (
    <Content>
      <Top>
        <Heading>Помощь</Heading>
        <Grid2>
          <DecorationCardButton
            link={{
              isRedirect: true,
              blank: true,
              src: helpLinks.info,
            }}
            color={"orangeLight"}
            buttonTitle={"Перейти к чтению"}
            title={"База знаний"}
            message={
              <span>
                Руководство по созданию <br /> рассылок
              </span>
            }
            preview={{
              posY: "calc(100% - 180px)",
              posX: "calc(100% - 287px)",
              height: 170,
              width: 277,
              src: help1Preview,
            }}
          />
          {/*<DecorationCardButton*/}
          {/*  link={{*/}
          {/*    isRedirect: true,*/}
          {/*    blank: true,*/}
          {/*    src: helpLinks.help,*/}
          {/*  }}*/}
          {/*  color={"blueLight"}*/}
          {/*  buttonTitle={"Задать вопрос"}*/}
          {/*  title={"Служба поддержки"}*/}
          {/*  message={<span>По всем вопросам – пишите в наш Telegram</span>}*/}
          {/*  preview={{*/}
          {/*    posY: "calc(100% - 180px)",*/}
          {/*    posX: "calc(100% - 287px)",*/}
          {/*    height: 170,*/}
          {/*    width: 277,*/}
          {/*    src: help2Preview,*/}
          {/*  }}*/}
          {/*/>*/}
        </Grid2>
        <Grid3>
          <DecorationHelpCard
            decorationIcon={1}
            link={{
              title: "Подробнее",
              link: helpLinks.privacyPolicy,
              redirectMode: {
                blank: true,
              },
            }}
            title={"Политика конфиденциальности"}
          />
          <DecorationHelpCard
            decorationIcon={2}
            link={{
              title: "Подробнее",
              link: helpLinks.spamPolicy,
              redirectMode: {
                blank: true,
              },
            }}
            title={"Антиспам-политика"}
          />
          <DecorationHelpCard
            decorationIcon={3}
            link={{
              title: "Подробнее",
              link: helpLinks.refInformation,
              redirectMode: {
                blank: true,
              },
            }}
            title={"Справочная информация"}
          />
        </Grid3>
      </Top>
    </Content>
  );
};
