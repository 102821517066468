import styled from "styled-components";

import type { Idea } from "domain/idea";
import { _variables } from "ui/styles/_variables";

import { IdeaItem } from "./IdeaItem";

interface IdeaListProps {
  ideas: Idea[];
}

const EmptyText = styled.h2`
  font-weight: 500;
  font-size: 18px;
  margin: 200px auto;
`;

const Ul = styled.ul`
  width: 100%;
`;

const Li = styled.li`
  width: 100%;
`;

const Hr = styled.hr`
  color: ${_variables.grayColor};
`;

export const IdeaList = ({ ideas }: IdeaListProps) => {
  return (
    <>
      {ideas.length === 0 ? (
        <EmptyText>Ничего не найдено</EmptyText>
      ) : (
        <Ul>
          {ideas.map(
            (
              {
                id,
                likeCount,
                canLike,
                theme,
                content,
                commentCount,
                tags,
                status,
                userName,
                dateIn,
              },
              index
            ) => {
              return (
                <>
                  <Li>
                    <IdeaItem
                      key={id}
                      id={id}
                      likeCount={likeCount}
                      canLike={canLike}
                      dateIn={dateIn}
                      theme={theme}
                      content={content}
                      commentCount={commentCount}
                      tags={tags}
                      status={status}
                      userName={userName}
                    />
                  </Li>
                  {index !== ideas.length - 1 ? <Hr /> : null}
                </>
              );
            }
          )}
        </Ul>
      )}
    </>
  );
};
