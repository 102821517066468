import { Link } from "react-router-dom";
import styled from "styled-components";

import type { UnsubscribeUserType } from "domain/unsubscribe";
import { createDataForTable } from "lib/date";

import { _variables } from "ui/styles/_variables";
import { TableHead } from "ui/components/Table/TableHead";
import { TableBody } from "ui/components/Table/TableBody";
import { LoadContent } from "ui/components/LoadContent";
import { SearchNotFound } from "ui/components/NotContent/SearchNotFound";
import { NotContentTable } from "ui/components/NotContent/NotContentTable";
import { useShowDraftMailingCollection } from "../../../../../app/tables/useShowUnsubscribeMailingCollection";
import { TableHeadOnceFilter } from "ui/components/Table/Filters/TableHeadOnceFilter";

const ITable = styled.table`
  display: grid;
  grid-template-columns:
    minmax(322px, 100%) minmax(200px, 200px) 340px
    240px;
  grid-auto-rows: min-content;
  margin-top: 32px;
  position: relative;
  z-index: 9;

  & tr:first-child td {
    margin-top: 4px;
  }

  & tr td {
    margin-bottom: 16px;
  }

  & tr td {
    padding-bottom: 16px;
    height: auto;
    border-bottom: 1px solid ${_variables.strokeColor};
    display: flex;
    align-items: flex-start !important;
  }
`;

const THead = styled(TableHead)`
  & th {
    border-bottom: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${_variables.grayTextColor};
  }
`;

const TBody = styled(TableBody)`
  margin-top: 16px;
  position: relative;
  top: -10px;

  & td {
    border-bottom: none;
  }
`;

const IEmailTD = styled.td`
  display: flex;
  flex-direction: column;
`;

const IDate = styled.td`
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const LinkTable = styled(Link)`
  color: ${_variables.blackColor};
  &:hover {
    text-decoration: underline;
    color: ${_variables.blackColor};
  }
`;

const IListName = styled.td`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const IMailing = styled.td`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const IEmail = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const IMessage = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const TableRowContent: CT<{ data: UnsubscribeUserType }> = ({ data }) => {
  return (
    <tr>
      <IEmailTD>
        <IEmail>{data.email}</IEmail>
        <IMessage>{data.reason || ""}</IMessage>
      </IEmailTD>
      <IDate>{createDataForTable(data.date_in)}</IDate>
      <IListName>
        <LinkTable to={`/contacts/${data.book_id}`}>{data.book_name}</LinkTable>
      </IListName>
      <IMailing>{data.mailing_name}</IMailing>
    </tr>
  );
};

export const UnsubscribeContent: CT<{
  onSetCountPage: (val: number) => void;
  onChangeSortName: (val: string) => void;
}> = ({ onChangeSortName }) => {
  const { query, isLoad, isNotFound, isNotHaveContent, data } =
    useShowDraftMailingCollection();

  //eslint-disable-next-line

  if (isLoad) return <LoadContent top={120} />;

  return (
    <>
      <ITable>
        <THead>
          <TableHeadOnceFilter
            isActive={query.sortBy.includes("email")}
            name={"email"}
            onClick={onChangeSortName}
          >
            Email
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            isActive={query.sortBy.includes("date_in")}
            name={"date_in"}
            onClick={onChangeSortName}
          >
            Создано
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            isActive={query.sortBy.includes("book_name")}
            name={"book_name"}
            onClick={onChangeSortName}
          >
            Список
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            isActive={query.sortBy.includes("mailing_name")}
            name={"mailing_name"}
            onClick={onChangeSortName}
          >
            Рассылка
          </TableHeadOnceFilter>
        </THead>
        <TBody>
          {data.map((item) => (
            <TableRowContent key={item.id + item.date_in} data={item} />
          ))}
        </TBody>
      </ITable>
      {isNotHaveContent && (
        <NotContentTable>У вас пока нет отписок</NotContentTable>
      )}
      {isNotFound && <SearchNotFound />}
    </>
  );
};
