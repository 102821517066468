import styled from "styled-components";

import { Icon } from "./Icon";

const List = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const Row = styled.li`
  display: flex;
  column-gap: 10px;
  align-items: center;
  & p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    & a {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
`;

export const CheckList: CT<{ data: (string | JSX.Element)[] }> = ({ data }) => {
  return (
    <List>
      {data.map((item) => (
        <Row>
          <Icon id={"checklist-icon"} /> <p>{item}</p>
        </Row>
      ))}
    </List>
  );
};
