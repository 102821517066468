import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useUrlState from "@ahooksjs/use-url-state";
import styled from "styled-components";

import type { TemplateType } from "domain/templates";
import { useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import { templatesService } from "services/templates";

import { TemplateCreator } from "../components/TemplateEditor/TemplateCreator";
import { LoadContent } from "../components/LoadContent";
import { deepEqual } from "lib/other";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

export const CreatorTemplatePage = () => {
  const [isNewTemplate, setNewTemplate] = useState(true);
  const [isLoad, setLoad] = useState(false);
  const userData = useAppSelector(selectUserData, deepEqual);
  const history = useHistory();
  const [data, setData] = useState<TemplateType | null>(null);
  const [query] = useUrlState({
    id: "",
    publicTemplate: "false",
    isMailingCreationWay: "false",
  });

  const isPublicTemplate = query.publicTemplate === "true";
  const isMailingCreationWay = query.isMailingCreationWay === "true";

  const loadData = async () => {
    try {
      const request = await templatesService.view(query.id);
      if (!request.data.data.template.scheme) {
        setNewTemplate(true);
        setLoad(false);
        return;
      }
      setData(request.data.data.template);
    } catch (e) {
      console.log(e);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (query.id.length === 0) {
      setNewTemplate(true);
      return;
    }
    setNewTemplate(false);
    setLoad(true);
    loadData();
  }, [query]);

  // Если режим -> создаение публичного шаблона и пользователь не админ, редирект
  useEffect(() => {
    if (isPublicTemplate && !userData?.is_admin) history.push("/");
  }, [isPublicTemplate, userData]);

  // подгрузка шаблона с последующей передачей
  // При наличии id -> режим подгрузки, иначе новый шаблон
  if (isLoad)
    return (
      <Wrapper>
        <LoadContent />
      </Wrapper>
    );

  return (
    <TemplateCreator
      isPublicTemplate={isPublicTemplate}
      isNewTemplate={isNewTemplate}
      dataTemplate={data}
      isMailingCreationWay={isMailingCreationWay}
      templateId={query.id}
    />
  );
};
