import React, { useEffect } from "react";
import { useTitle } from "ahooks";
import styled from "styled-components";

import { useAppDispatch } from "services/hooks";
import { clearAppError } from "services/store/appSlice";

import { appConfig } from "../../../appConfig";
import { ContentContainer } from "../../components/ContentContainer";
import { Wrapper } from "../../components/Wrapper";
import { Button } from "../../components/Buttons/Button";

const Content = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
`;

export const ForbiddenPage = () => {
  const dispatch = useAppDispatch();

  useTitle("Недостаточно прав для просмотра" + appConfig.titleApp);

  useEffect(() => {
    return () => {
      dispatch(clearAppError());
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Wrapper>
        <Content>
          <h1>Недостаточно прав для просмотра</h1>
          <a href="/">
            <Button>Вернуться на главную</Button>
          </a>
        </Content>
      </Wrapper>
    </>
  );
};
