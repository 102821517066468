import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import styled from "styled-components";

import type { BookListType } from "domain/lists";
import { createToastResponseError } from "lib/toastify";
import { updateQueryParams } from "lib/queryParams";
import { contactsListService } from "services/list";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { clearViewContactAll } from "services/store/viewContantcsSlice";

import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";
import { Select } from "../../components/FormsElements/Select";
import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";
import { Button } from "../../components/Buttons/Button";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`;

export const MoveListMove: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const [isLoad, setLoad] = useState(false);
  const history = useHistory();
  const modalData = useAppSelector(selectModalData);
  const [data, setData] = useState<BookListType[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      setLoad(true);
      const dataAll = await contactsListService.getAllLists();
      setData(dataAll as BookListType[]);
      setLoad(false);
    })();
  }, []);

  const onSubmit = async (data: { checkId: string }) => {
    setLoad(true);
    try {
      const request = await contactsListService.moveContacts(
        modalData?.ids,
        Number(data.checkId)
      );
      toast(request.data.message, {
        autoClose: 3000,
      });
      updateQueryParams(history, {
        page: "1",
      });
      dispatch(clearViewContactAll());
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setLoad(false);
      onClose();
    }
  };

  const formik = useFormik({
    initialValues: { checkId: modalData?.id },
    validateOnChange: false,
    onSubmit: onSubmit,
  });

  return (
    <ModalDefaultFormContainer>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Select
          onChange={(val) => {
            formik.setFieldValue("checkId", val);
          }}
          values={data.map((item) => ({
            name: item.name,
            value: String(item.id),
            isDefaultChecked: Number(formik.values.checkId) == item.id,
          }))}
        />
        <ModalButtonsContainer style={{ marginTop: 12 }}>
          <Button size={"normal"} isLoad={isLoad} type={"submit"}>
            Переместить
          </Button>
          <Button
            onClick={onClose}
            size={"normal"}
            variant={"secondary"}
            type={"submit"}
          >
            Отменить
          </Button>
        </ModalButtonsContainer>
      </Form>
    </ModalDefaultFormContainer>
  );
};
