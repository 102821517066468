import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import Image from "../assets/media/images/import-counter-background.svg";

const IContainer = styled.div`
  width: 230px;
  height: 88px;
  padding: 16px 16px 0 16px;
  border-radius: 24px;
  background-color: ${_variables.backgroundColor};
  background-image: url(${Image});
  background-position: bottom 5px right;
  background-repeat: no-repeat;
`;

const IContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
const ITitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;

const ICounter = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;

export const ImportCounter: CT<{ title: string; counter: number }> = ({
  title = "",
  counter = 0,
}) => {
  return (
    <IContainer>
      <IContent>
        <ITitle>{title}</ITitle>
        <ICounter>{counter}</ICounter>
      </IContent>
    </IContainer>
  );
};
