import { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { createToastResponseError } from "lib/toastify";
import { updateQueryParams } from "lib/queryParams";
import { contactsListService } from "services/list";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectModalData, setModalData } from "services/store/modalDataSlice";
import { clearCheckPageState } from "services/store/pagesSlice";

import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";
import { ModalTextContainer } from "../../components/Modals/ModalTextContainer";
import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";
import { Button } from "../../components/Buttons/Button";
import { openModal } from "services/store/modal";

export const DeleteLists: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const modalData = useAppSelector(selectModalData);
  const [isLoad, setLoad] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const onDeleteData = async () => {
    if (!modalData?.ids?.length ?? null) {
      toast("id не найдены", {
        type: "warning",
        autoClose: 3000,
      });
      onClose();
    }
    try {
      setLoad(true);
      const response = await contactsListService.delete(modalData!.ids);
      toast(response.data.message, { autoClose: 3000 });
      dispatch(clearCheckPageState({ page: "listChecked" }));

      updateQueryParams(history, {
        page: "1",
      });
    } catch (e) {
      console.log(e.response?.data?.code);
      if (e.response?.data?.code == 1005) {
        onClose();
        setTimeout(() => {
          dispatch(
            setModalData({
              widthContent: "420px",
              title: e.response?.data?.message,
            })
          );
          dispatch(openModal("form-subscription"));
        }, 500);

        return;
      }
      createToastResponseError(e)();
    } finally {
      onClose();
    }
  };

  return (
    <ModalDefaultFormContainer>
      <ModalTextContainer>
        <p>Вы уверены, что хотите удалить списки?</p>
      </ModalTextContainer>
      <ModalButtonsContainer>
        <Button isLoad={isLoad} onClick={onDeleteData} type={"submit"}>
          Подтвердить
        </Button>
        <Button onClick={onClose} type={"submit"} variant={"secondary"}>
          Отмена
        </Button>
      </ModalButtonsContainer>
    </ModalDefaultFormContainer>
  );
};
