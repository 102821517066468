import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import type { BookListType } from "domain/lists";
import {
  createStandardToast,
  createToastResponseError,
  onlyOne,
} from "lib/toastify";
import { deepEqual } from "lib/other";
import { contactsListService } from "services/list";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";
import { selectUserData } from "services/store/userSlice";

import { ContactsHeaderRow } from "../../components/ContactsHeaderRow";
import { TableHead } from "../../components/Table/TableHead";
import { TableBody } from "../../components/Table/TableBody";
import { TableHeadOnceFilter } from "../../components/Table/Filters/TableHeadOnceFilter";
import { ListTableContent } from "../../components/Table/ListTableContent";
import { ButtonTable } from "../../components/Buttons/ButtonTable";
import { ContentPagination } from "../../components/ContentPagination";
import { TableContainerScroll } from "../../containers/TableContainerScroll";
import { ShowCount } from "../../components/ShowCount";
import { NotContentListsPage } from "../../components/NotContent/NotContentListsPage";
import { LoadContent } from "../../components/LoadContent";
import { useCheckedTableData } from "../../hooks/useCheckedTableData";
import { useTableWorkspace } from "../../hooks/useTableWorkspace";
import { useTableStateLoad } from "../../hooks/useTableStateLoad";
import { useNotification } from "../../containers/common/useNotification";
import { AdditionalMenuWord } from "ui/components/Menus/AdditionalMenuWord";
import { Icon } from "ui/components/Icon";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px 5px 12px 32px;
`;

const Table = styled.table`
  display: grid;
  grid-template-columns:
    minmax(500px, 50%) minmax(200px, 18%) minmax(200px, 18%)
    1fr;
  grid-auto-rows: auto;
  position: relative;
  z-index: 9;
`;

const Heading = styled.h1`
  font-family: "Inter";
  margin-top: 31px;
  margin-bottom: 18px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const TableTop = styled(Table)`
  padding-right: 0;
  row-gap: 0;
`;

const StickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: white;
`;

const FirstTH = styled(TableHeadOnceFilter)`
  padding-left: 4px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
`;

const LoadWrapper = styled.div`
  display: flex;

  height: 100%;
`;

export const ListsPage = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUserData, deepEqual);
  const [data, setData] = useState<BookListType[]>([]);
  const [countPage, setCountPage] = useState(0);
  const history = useHistory();
  const { query, setPerPage, setPage, setSortBy } = useTableWorkspace({
    perPage: 25,
    page: 1,
    sortBy: "-id",
  });
  const { removeById, addNotification } = useNotification();
  const { isNotHaveContent, isLoad, setOffLoad, setOnLoad, isShowContent } =
    useTableStateLoad(data);
  const { checkedArray, clearChecked, toggleCheck, setCheckedArray } =
    useCheckedTableData("listChecked");
  const [names, setNames] = useState<string[]>([]);

  const getNames = () => {
    return checkedArray.map((item) => {
      return data.find((dataItem) => dataItem.id === item)?.name ?? "";
    });
  };

  const Menu = () => {
    return (
      <>
        <li onClick={mergeCollectionsHandler}>
          <Icon id={"merge-icon"} width={24} height={24} />
          Обьединить списки
        </li>

        <li onClick={deleteCollectionsHandler}>
          <Icon id={"delete-icon"} width={24} height={24} />
          Удалить
        </li>
      </>
    );
  };

  const loadData = async () => {
    setData([]);
    setOnLoad();
    try {
      const list = await contactsListService.list({
        sortBy: query.sortBy,
        page: query.page,
        perPage: query["per-page"],
      });
      setCountPage(list.headers["x-pagination-page-count"]);
      setData(list.data.data.books);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  const changePaginationHandler = setPage;
  const changePerPageHandler = setPerPage;
  const changeSortByHandler = setSortBy;
  const checkedItemHandler = toggleCheck;
  const allCheckClickHandler = () => {
    if (checkedArray.length < data.length) {
      setCheckedArray(data.map((item) => item.id));
      return;
    }
    setCheckedArray([]);
  };

  const deleteCollectionsHandler = () => {
    dispatch(setModalData({ ids: checkedArray }));
    dispatch(openModal("delete-list"));
  };

  const mergeCollectionsHandler = () => {
    if (checkedArray.length === 1) {
      onlyOne(createStandardToast("Выберите два и более списков"));
      return;
    }

    dispatch(setModalData({ ids: checkedArray, names }));
    dispatch(openModal("merge-list"));
  };

  const clickCreateListHandler = () => {
    dispatch(openModal("create-list-import"));
  };

  const clickValidateEmailsHandler = async () => {
    if (checkedArray.length === 0) {
      onlyOne(
        createStandardToast("Выберите список для проверки отметив его галочкой")
      );
      return;
    }
    if (checkedArray.length !== 1) {
      onlyOne(
        createStandardToast(
          "Валидация проводится только по одному списку за раз"
        )
      );
      return;
    }
    try {
      const data = await contactsListService.view({ id: checkedArray[0] });
      if (data.data.data.book.count === 0) {
        onlyOne(createStandardToast("Список не должен быть пустой"));
        return;
      }
    } catch (e) {
      createToastResponseError(e)();
    }
    dispatch(setModalData({ id: checkedArray[0], isNotShowClose: true }));
    dispatch(openModal("pay-validate-list"));
  };

  useEffect(() => {
    return () => {
      clearChecked();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadData();
    //eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    setNames(getNames());
  }, [checkedArray]);

  useEffect(() => {
    if (userData?.mailing_limit) {
      addNotification(
        <span>
          На данный момент,&nbsp;
          <b>
            максимальное количество контактов в списке -{" "}
            {userData.mailing_limit}.
          </b>{" "}
          <br />
          Количество списков не ограничено.
        </span>,
        { id: "list" }
      );
    }
    return () => {
      removeById("list");
    };
  }, [userData?.mailing_limit]);

  const checkedCount = checkedArray.length > 0 ? `${checkedArray.length} ` : "";

  const isShowAdditionalMenu = checkedArray.length > 0;

  if (isNotHaveContent) {
    return <NotContentListsPage />;
  }

  return (
    <>
      <Content>
        <TableContainerScroll height={136}>
          <StickyTopContent>
            <ContactsHeaderRow>
              <Buttons>
                <ButtonTable
                  onClick={allCheckClickHandler}
                  iconId={"check-icon"}
                  isActive={checkedCount.length > 0}
                >{`${checkedCount}Выделить все`}</ButtonTable>
                <ButtonTable
                  onClick={clickCreateListHandler}
                  iconId={"add-circle-icon"}
                >
                  Создать список
                </ButtonTable>
                <ButtonTable
                  onClick={() => {
                    history.push("/contacts/import");
                  }}
                  iconId={"add-circle-icon"}
                >
                  Добавить контакты
                </ButtonTable>

                <ButtonTable
                  onClick={clickValidateEmailsHandler}
                  iconId={"check-validate-icon"}
                >
                  Проверка email
                </ButtonTable>
              </Buttons>
              {isShowAdditionalMenu && (
                <AdditionalMenuWord
                  offset={[-115, 45]}
                  contentMenu={<Menu />}
                />
              )}
            </ContactsHeaderRow>
            <Heading>Ваши списки</Heading>
            <TableTop>
              <TableHead>
                <FirstTH
                  name={"name"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("name")}
                >
                  Название
                </FirstTH>
                <TableHeadOnceFilter
                  name={"id"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("id")}
                >
                  Дата создания
                </TableHeadOnceFilter>
                <TableHeadOnceFilter
                  name={"count"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("count")}
                >
                  Кол-во контактов
                </TableHeadOnceFilter>
                <TableHeadOnceFilter
                  name={"count"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("status")}
                >
                  Статус
                </TableHeadOnceFilter>
              </TableHead>
            </TableTop>
          </StickyTopContent>
          {isLoad && (
            <LoadWrapper>
              <LoadContent />
            </LoadWrapper>
          )}
          {isShowContent && (
            <Table>
              <TableBody>
                {data.length > 0 &&
                  data.map((item) => (
                    <ListTableContent
                      key={item.id}
                      onClickCheck={checkedItemHandler}
                      id={item.id}
                      isActive={checkedArray.includes(item.id)}
                      count={item.count}
                      date={item.date_in}
                      name={item.name}
                      bookValidate={item.bookValidate}
                    />
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainerScroll>
        <Footer>
          <ShowCount
            defaultValue={Number(query["per-page"])}
            onChange={changePerPageHandler}
          />
          {countPage > 1 && data.length > 0 && (
            <ContentPagination
              force={Number(query.page - 1)}
              onClick={changePaginationHandler}
              pageCount={countPage}
            />
          )}
        </Footer>
      </Content>
    </>
  );
};
