import styled from "styled-components";

const IMainHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 44px;
`;
const IMainHeading = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

export const HistoryTopHeading: CT<unknown> = () => {
  return (
    <IMainHeadingWrapper>
      <IMainHeading>Топ лучших рассылок</IMainHeading>
    </IMainHeadingWrapper>
  );
};
