import axios from "axios";
import { format } from "date-fns";

import type {
  MailingContactsList,
  MailingType,
  MailItemViewType,
} from "domain/mailing";
import type { UnsubscribeUserType } from "domain/unsubscribe";
import type { ServiceListPropTypes } from "domain/api";
import { datePickerFormat, timePickerFormat } from "domain/time";

import type { TemplateViewStateType } from "./store/createMailingSlice";
import { dateDeSerialize } from "lib/date";

export type ApiMailingCreatePropType = {
  book_id: number; // айди листа контактов
  name: string; //тема письма
  template_id: number;
  from: string;
  email: string;
  status: number;
  date: string;
  time: string;
  timezone: string;
  withHolydays: boolean;
  /**
   * default -false
   * Запуск режима разбития рассылки
   */
  split?: boolean;
  /**
   * default - 5
   * max - 14
   * Количество дней разбития
   */
  days?: number;
  /**
   * type - float
   * default - 2
   * коэффициент роста рассылки
   */
  multiplier?: number;
  /**
   * Массив дат, замещает days
   * Нужен был для режима с календарем
   */
  dates?: string[];
};
export type ApiTestMailingPropType = {
  email: string;
  name: string;
  from: string;
  template_id: number;
  emails: string[];
};

export const portProMailing = (
  data: NonNullable<TemplateViewStateType["data"]>,
  time: string,
  date: Date,
  status: number,
  days: number,
  multiplier: number,
  withHoliday: boolean
): ApiMailingCreatePropType => {
  return {
    book_id: data.secondStep!.listId!,
    name: data.firstStep!.theme,
    date: format(date, datePickerFormat),
    email: data.firstStep!.address,
    from: data.firstStep!.name,
    template_id: data.templateStep!,
    time: time,
    timezone: data.params!.timeZone,
    status: status,
    split: true,
    days,
    multiplier,
    withHolydays: withHoliday,
  };
};

export const portAutoLightMailing = (
  data: NonNullable<TemplateViewStateType["data"]>,
  time: string,
  date: Date,
  status: number
): ApiMailingCreatePropType => {
  return {
    book_id: data.secondStep!.listId!,
    name: data.firstStep!.theme,
    date: format(date, datePickerFormat),
    email: data.firstStep!.address,
    from: data.firstStep!.name,
    template_id: data.templateStep!,
    time: time,
    timezone: data.params!.timeZone,
    status: status,
    split: true,
    withHolydays: true,
  };
};

export const portCreateMailing = (
  data: NonNullable<TemplateViewStateType>,
  status: number
): ApiMailingCreatePropType => {
  return {
    withHolydays: true,
    book_id: data.data!.secondStep!.listId!,
    name: data.data!.firstStep!.theme,
    date: data.data?.params?.isNow
      ? format(new Date(), datePickerFormat)
      : format(dateDeSerialize(data.data!.params!.date), datePickerFormat),
    email: data.data!.firstStep!.address,
    from: data.data!.firstStep!.name,
    template_id: data.data!.templateStep!,
    time: data.data?.params?.isNow
      ? format(new Date(), timePickerFormat)
      : format(dateDeSerialize(data.data!.params!.time), timePickerFormat),
    timezone: data.data!.params!.timeZone,
    status: status,
  };
};

export const portTestMailing = (
  data: NonNullable<TemplateViewStateType>,
  emails: string[]
): ApiTestMailingPropType => {
  return {
    email: data.data!.firstStep!.address,
    name: data.data!.firstStep!.theme,
    from: data.data!.firstStep!.name,
    template_id: data.data!.templateStep!,
    emails: emails,
  };
};

export const mailingService = {
  path: "mailing/",

  async history(
    {
      perPage,
      page,
      search = "",
      book_id,
      status,
      dateFrom,
      dateTo,
    }: ServiceListPropTypes<{ search?: string }>,
    onlyDraft?: boolean
  ) {
    const bookIdParam = book_id ? `&MailingSearch[book_id]=${book_id}` : "";
    const statusParam = status ? `&MailingSearch[status]=${status}` : "";
    const dateFromParam = dateFrom
      ? `&MailingSearch[date_start]=${dateFrom}`
      : "";
    const dateToParam = dateTo ? `&MailingSearch[date_end]=${dateTo}` : "";

    return axios.get<ApiResponseBodyType<{ history: MailingType[] }>>(
      `${
        this.path
      }history?page=${page}&per-page=${perPage}&MailingSearch[name]=${search}${statusParam}${bookIdParam}${dateToParam}${dateFromParam}&draft=${
        onlyDraft ? 1 : 0
      }`
    );
  },

  async top() {
    return axios.get<{
      data: {
        history: MailingType[];
      };
    }>(`${this.path}history?sort=-top_rated&per-page=5`);
  },
  async unsubscribeList({
    perPage,
    page,
    sortBy = "",
    search = "",
  }: ServiceListPropTypes<{ search?: string }>) {
    return axios.get<
      ApiResponseBodyType<{
        unsubscribes: UnsubscribeUserType[];
      }>
    >(
      `${this.path}unsubscribes?page=${page}&per-page=${perPage}&sort=${sortBy}&UnsubscribeContactSearch[email]=${search}`
    );
  },

  async unsubscribe({
    hash,
    token,
    reason,
  }: {
    hash: string;
    token: string;
    reason: string;
  }) {
    const body = new FormData();
    body.append("reason", reason ?? null);
    return axios.post<ApiResponseBodyType<unknown>>(
      `${this.path}unsubscribe-contact?token=${token}&hash=${hash}`,
      body
    );
  },
  async cancel({ id }: { id: number | string }) {
    return axios.get<ApiResponseBodyType<unknown>>(
      `${this.path}cancel?id=${id}`
    );
  },

  async testMailing(data: ApiTestMailingPropType) {
    const body = { MailingTestForm: data };
    return axios.post<ApiResponseBodyType<unknown>>(`${this.path}test`, body);
  },

  async createMailing(data: ApiMailingCreatePropType, draft?: number) {
    const body = { MailingForm: data };
    return axios.post<ApiResponseBodyType<{ mailing: MailingType }>>(
      `${this.path}create?draft=${draft || 0}`,
      body
    );
  },

  async getUnsubscribeCSV() {
    return axios.get(`${this.path}unsubscribes-csv`);
  },

  async viewMailing(id: string | number) {
    return axios.get<ApiResponseBodyType<{ mailing: MailItemViewType }>>(
      `${this.path}view?id=${id}`
    );
  },

  async getDefaultEmail() {
    return axios.get<ApiResponseBodyType<{ email: string }>>(
      `${this.path}default-mailing-email`
    );
  },

  getMailingContacts(
    params: Omit<
      ServiceListPropTypes<{ id: number; category: number }>,
      "sortBy"
    >
  ) {
    return axios.get<ApiResponseBodyType<MailingContactsList>>(
      `${this.path}get-mailing-contacts?id=${params.id}&MailingContactSearch[category]=${params.category}&page=${params.page}&per-page=${params.perPage}`
    );
  },

  getMailingCSVContacts({ id, category }: { id: number; category: number }) {
    return axios.get(
      `${this.path}get-mailing-contacts-csv?id=${id}&MailingContactSearch[category]=${category}`
    );
  },

  deleteMailingContacts({
    id,
    category,
    deleteBy,
  }: {
    id: number;
    category: number;
    deleteBy: "all" | "current";
  }) {
    return axios.get(
      `${this.path}delete-mailing-contacts?id=${id}&MailingContactSearch[category]=${category}&deleteBy=${deleteBy}`
    );
  },
};
