import { useCallback } from "react";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import { scroll } from "ui/styles/elements/scroll";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  z-index: 111;
  max-height: 174px;
  border: 1px solid ${_variables.strokeColor};
  box-shadow: 4px 4px 24px rgba(14, 16, 59, 0.07);
  border-radius: 12px;
  padding-right: 4px;
  overflow: hidden;

  & ul {
    padding: 0px 0;
    overflow-y: auto;
    position: relative;
    border-radius: 12px;
    width: 100%;

    height: 100%;
    display: flex;
    flex-direction: column;
    ${scroll};
  }

  & li {
    min-height: 32px;
    cursor: pointer;
    display: flex;
    padding-left: 12px;
    align-items: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    background-color: white;
    letter-spacing: 0px;
    text-align: left;

    &:hover {
      background-color: #f5f6f8;
    }
  }
`;
const FixedWrapper = styled.div`
  top: 9px;
  width: 100%;
  position: sticky;
`;
const Separator = styled.div`
  height: 9px;
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 9px;
  background-color: white;
`;
const SeparatorBottom = styled.div`
  height: 9px;
  position: sticky;
  bottom: 0;
  width: 100%;
  min-height: 9px;
  background-color: white;
`;
export const Nothing = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
`;

export const SelectBox: CT<{
  values: { name: string; id: number | string; data?: any }[];
  fixedElement?: JSX.Element;
  fixedElementHeight?: number;
  onClickFixedElement?: VoidFunction;
  onClickElement?: (data: {
    name: string;
    id: number | string;
    data?: any;
  }) => void;
}> = ({ fixedElement, className, values, onClickElement }) => {
  const FixedElement = useCallback(() => {
    return <FixedWrapper>{fixedElement}</FixedWrapper>;
  }, [fixedElement]);

  return (
    <Container className={className}>
      <ul>
        <Separator />
        {fixedElement && <FixedElement />}
        <p></p>
        {values?.map((item) => (
          <li
            key={item.name}
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopPropagation();
              onClickElement && onClickElement(item);
            }}
          >
            {item.name}
          </li>
        ))}
        {!values.length && <Nothing>Ничего не найдено</Nothing>}
        <SeparatorBottom />
      </ul>
    </Container>
  );
};
