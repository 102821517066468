import { useState } from "react";
import styled from "styled-components";

import type { useSettingFile } from "app/importContacts/useSettingFile";
import type { FilePreviewSettingUserType } from "domain/fileContacts";
import { fileDelimiterCatalog } from "domain/fileContacts";
import { _variables } from "ui/styles/_variables";

import { Checkbox } from "../FormsElements/Checkbox";
import { SelectCustom } from "../FormsElements/SelectCustom";
import { Icon } from "../Icon";

const Content = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: ${(props) => (props.isOpen ? "20px" : "0px")};
`;

const SettingsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 8px;
  padding: 15px;
  row-gap: 20px;

  & .open {
  }

  &.close {
    overflow: hidden;
    height: 0;
    padding: 0;
    border: none;
  }
`;

const OpenCheckbox = styled.div`
  display: flex;
  cursor: pointer;
  width: fit-content;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

export const FormatField: CT<{
  onSetCheckSkipRow: ReturnType<
    typeof useSettingFile
  >["actions"]["onSetCheckSkipRow"];
  onSetDelimiter: ReturnType<
    typeof useSettingFile
  >["actions"]["onSetDelimiter"];
  userSettings: ReturnType<typeof useSettingFile>["userSettings"];
  initialOpen?: boolean;
}> = ({
  onSetDelimiter,
  onSetCheckSkipRow,
  userSettings,
  initialOpen = false,
}) => {
  const [isOpen, setOpen] = useState(initialOpen);

  return (
    <Content isOpen={isOpen}>
      <OpenCheckbox
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        Настройки форматирования
        <Icon id={"arrow-down-icon"} width={24} height={24} />
      </OpenCheckbox>

      <SettingsBox className={isOpen ? "open" : "close"}>
        <Checkbox
          onChecked={(_, typeAction) => {
            onSetCheckSkipRow(typeAction);
          }}
          name={"123"}
          checked={!!userSettings.skipFirstRow}
        >
          Первая строка содержит имена полей
        </Checkbox>
        <SelectCustom
          onChange={(val) => {
            onSetDelimiter(
              val.value as FilePreviewSettingUserType["delimiter"]
            );
          }}
          label={"Разделители полей"}
          values={Object.entries(fileDelimiterCatalog).map(([val, title]) => ({
            value: val,
            name: title,
            isDefaultChecked: Number(val) === Number(userSettings.delimiter),
          }))}
        />
      </SettingsBox>
    </Content>
  );
};
