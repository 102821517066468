import { useState } from "react";
import { getTrackBackground, Range } from "react-range";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

const Wrapper = styled.div`
  min-height: 20px;
  display: flex;
  width: 100%;
  align-items: center;
`;

const Thumb = styled.div<{ showValue?: number | string }>`
  height: 20px;
  border-radius: 50%;
  width: 20px;
  background-color: ${_variables.primaryColor};
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: white;
  }
  &:focus-visible {
    border-color: ${_variables.primaryColor} !important;
    outline-color: ${_variables.primaryColor} !important;
  }

  &:focus {
    border-color: ${_variables.primaryColor} !important;
    outline-color: ${_variables.primaryColor} !important;
  }
`;

const ThumbCounter = styled.span`
  display: block;
  bottom: -22px;
  position: absolute;
  font-size: 12px !important;
  font-weight: 500 !important;
  left: 50%;
  transform: translateX(-50%);
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
`;

/**
 *
 * @param onChange
 * @param values
 * @param forceInitVal - Изначальное положение селекта на линии массива
 * @constructor
 */
export const LineSelect: CT<{
  onChange?: (value: number[]) => void;
  values: number[];
  min?: number;
  max?: number;
  step?: number;
}> = ({ onChange = () => {}, values, max = 10, min = 0, step }) => {
  const [valuesState, setValues] = useState(values);

  return (
    <Wrapper>
      <Range
        step={step}
        min={min}
        max={max}
        values={valuesState}
        onChange={(values) => {
          setValues(values);
          onChange(values);
        }}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "4px",
              width: "100%",
              backgroundColor: _variables.strokeColor,
              borderRadius: "6px",
              background: getTrackBackground({
                values: valuesState,
                colors: [_variables.primaryColor, _variables.strokeColor],
                min: min,
                max: max,
              }),
            }}
          >
            <>{children}</>
          </div>
        )}
        draggableTrack={false}
        rtl={false}
        renderThumb={({ props }) => (
          <Thumb
            {...props}
            style={{
              ...props.style,
            }}
          >
            <ThumbCounter>{valuesState[0]}</ThumbCounter>
          </Thumb>
        )}
      />
    </Wrapper>
  );
};
