import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import html2canvas from "html2canvas";
import styled from "styled-components";

import { useTemplatePreviewFrame } from "app/useTemplatePreviewFrame";
import { useGoBackLink } from "app/useGoBackLink";
import { previewTemplate } from "lib/previewTemplate";
import { blobToBase64 } from "lib/htmlToImage";
import { createToastResponseError } from "lib/toastify";
import { canvasToBlob } from "lib/imageOptimize";
import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { templatesService } from "services/templates";

import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";
import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";
import { appConfig } from "../../../appConfig";
import { Input } from "../../components/FormsElements/Input";
import { Button } from "../../components/Buttons/Button";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`;

export const CreateTemplate: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const [isLoad, setLoad] = useState(false);
  const { createPreviewFrame, destroyFrame } = useTemplatePreviewFrame();
  const { goBack } = useGoBackLink();
  const modalData = useAppSelector(selectModalData);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Поле обязательно для заполнения"),
  });

  const onSubmit = async (data: { name: string }) => {
    setLoad(true);
    try {
      const frame = await createPreviewFrame(modalData!.html);
      let base64;

      try {
        let resultCanvas = await html2canvas(frame!.contentDocument!.body, {
          useCORS: false,
          imageTimeout: 15000,
          proxy: appConfig.proxyImageUrl,
          width: 640,
          height: 780,
          x: 0,
          y: 0,
          allowTaint: true,
        });
        let image = await canvasToBlob(resultCanvas);
        base64 = await blobToBase64(image);
      } catch (e) {
        console.error("Ошибка формирования канваса");
        console.error(e);
        createToastResponseError(e, "Ошибка формирования превью")();
        base64 = previewTemplate;
      }

      await destroyFrame();
      const result = await templatesService.create({
        name: data.name,
        scheme: modalData?.scheme ?? null,
        preview: base64,
        content: modalData!.html,
      });
      toast(result.data.message, {
        autoClose: 3000,
      });
      goBack();
    } catch (e) {
      await destroyFrame();
      createToastResponseError(e, "Ошибка создания шаблона")();
    } finally {
      setLoad(false);
      onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <ModalDefaultFormContainer>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Input
          label={"Название шаблона"}
          type="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          name={"name"}
          placeholder={"Введите название шаблона"}
          error={formik.errors.name}
        />
        <ModalButtonsContainer style={{ marginTop: 12 }}>
          <Button size={"normal"} isLoad={isLoad} type={"submit"}>
            Создать
          </Button>
          <Button
            onClick={onClose}
            size={"normal"}
            variant={"secondary"}
            type={"submit"}
          >
            Отменить
          </Button>
        </ModalButtonsContainer>
      </Form>
    </ModalDefaultFormContainer>
  );
};
