import styled from "styled-components";

const Content = styled.h2`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 20px;
`;
export const HeadingImportContacts: CT<unknown> = ({ children }) => {
  return <Content>{children}</Content>;
};
