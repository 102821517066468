import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import type { TemplateType } from "domain/templates";
import { createDataForTable } from "lib/date";
import { useAppDispatch } from "services/hooks";
import { setDataTemplateView } from "services/store/templateViewSlice";
import { setTemplateMailing } from "services/store/createMailingSlice";
import { _variables } from "ui/styles/_variables";

import { Icon } from "../Icon";
import { ListCheckedIcon } from "../ListCheckedIcon";
import { Link } from "../Link";
import { Button } from "../Buttons/Button";
import { clearUserCJMMailingData } from "services/store/cjm";

const Container = styled.tr<{ isActive: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      & td {
        background: ${_variables.backgroundColor};
      }
    `}
  &:hover {
    & td {
      background: ${_variables.backgroundColor};
    }

    & .icon {
      opacity: 0;
    }

    & .checkedIcon {
      opacity: 1;
      z-index: 5;
    }
  }

  &.checked {
    & .icon {
      opacity: 0;
    }

    & .checkedIcon {
      opacity: 1;
      z-index: 5;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  position: relative;

  & .icon {
    transition: 0.1s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }

  & .checkedIcon {
    transition: 0.1s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    opacity: 0;
    transform: translate(-50%, -50%);
  }
`;

const NameWrapper = styled.td`
  padding-left: 12px;
  display: flex;
  column-gap: 16px;
  height: 100%;
  align-items: center;
`;

const Name = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: calc(100% - 80px);
  column-gap: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;

  & span {
    display: inline-block;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 95%;

    & a {
      max-width: 100%;
    }
  }
`;

const DateWrapper = styled.td`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center;
`;
const Date = styled.div`
  display: flex;
  column-gap: 24px;
  justify-content: flex-start;

  & span:nth-child(1) {
    display: block;
    min-width: 120px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  & span:nth-child(2) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${_variables.grayTextColor};
  }
`;

const Show = styled.td`
  display: flex;
  align-items: center;
`;
const ShowLink = styled(Link)`
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;

  align-items: center;
`;

const CreateMessages = styled.td`
  display: flex;
  align-items: center;
`;

const ButtonMessage = styled(Button)`
  width: 164px;
  height: 40px;
  padding: 0;

  &:hover {
    background-color: white;
  }
`;

export const TemplateTableContent: CT<{
  isActive: boolean;
  onClickCheck?: (id: number) => void;
  data: TemplateType;
}> = ({ onClickCheck, data, isActive }) => {
  const { id, name, date_edit: dateEdit, date_in: dateIn, has_scheme } = data;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const createHandler = () => {
    dispatch(setTemplateMailing(data.id));
    dispatch(clearUserCJMMailingData());
    history.push("/templates/create-mailing");
  };

  const linkEdit = () => {
    if (has_scheme) return `/templates/creator?id=${id}`;
    return `/templates/text-editor?id=${id}`;
  };

  const viewTemplateHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();
    e.stopPropagation();
    dispatch(setDataTemplateView({ mode: "desktop", id }));
  };

  return (
    <Container
      onClick={() => {
        onClickCheck && onClickCheck(id);
      }}
      className={isActive ? "checked" : ""}
      isActive={isActive}
    >
      <NameWrapper>
        <IconWrapper>
          <ListCheckedIcon
            className={"checkedIcon"}
            onClick={(e) => {
              e.stopPropagation();
              onClickCheck && onClickCheck(id);
            }}
            isActive={isActive}
          />
          <Icon
            className={"icon"}
            id={"template-file-icon"}
            width={32}
            height={32}
          />
        </IconWrapper>
        <Name>
          <span>
            <Link redirectMode={{ blank: false }} link={linkEdit()}>
              {name}
            </Link>
          </span>
        </Name>
      </NameWrapper>
      <DateWrapper className={"123"}>
        <Date>
          <span>Создано:</span>
          <span>{createDataForTable(dateIn)}</span>
        </Date>
        <Date>
          <span>Отредактирован:</span>
          <span>{createDataForTable(dateEdit)}</span>
        </Date>
      </DateWrapper>
      <Show>
        <ShowLink link={""} onClick={viewTemplateHandler}>
          <Icon
            id={"eye-icon"}
            style={{ marginRight: 12 }}
            width={24}
            height={24}
          />
          Посмотреть
        </ShowLink>
      </Show>
      <CreateMessages>
        <ButtonMessage onClick={createHandler} variant={"secondary"}>
          Создать рассылку
        </ButtonMessage>
      </CreateMessages>
    </Container>
  );
};
