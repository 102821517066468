import React from "react";
import { shallowEqual } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useTitle } from "ahooks";
import { isPossibleNumber } from "libphonenumber-js";
import styled from "styled-components";

import { helpLinks } from "domain/links";
import { createStandardToast, onlyOne } from "lib/toastify";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { serviceUser } from "services/user";
import { selectUserData, setUserData } from "services/store/userSlice";
import { openModal } from "services/store/modal";

import { TelephoneInput } from "../../containers/specialFormElements/TelephoneInput";
import { SelectTimeZone } from "../../containers/specialFormElements/SelectTimeZone";
import { Button } from "../../components/Buttons/Button";
import { Link } from "../../components/Link";
import { LoadImage } from "../../containers/specialFormElements/LoadImage";
import { appConfig } from "../../../appConfig";
import { InputEmailSetting } from "../../containers/specialFormElements/InputEmailSetting";
import { Input } from "../../components/FormsElements/Input";

const Content = styled.div`
  display: flex;
  padding-left: 40px;
  flex-direction: column;
  padding-bottom: 160px;
`;
const Row = styled.div`
  display: flex;
  column-gap: 40px;
`;
const HeadingBlock = styled.div`
  width: 17.42%;
  min-width: 196px;
  display: flex;
  justify-content: flex-start;
`;
const Heading = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const FormContent = styled.div`
  width: 53%;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
const MainHeading = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;
const Form = styled.form`
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  row-gap: 20px;
`;
const Buttons = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;

export const SettingPageHeading: CT<unknown> = React.memo(() => {
  return (
    <HeadingWrapper>
      <MainHeading>Настройки аккаунта</MainHeading>
    </HeadingWrapper>
  );
}, shallowEqual);

export const SettingPageContent: CT<unknown> = () => {
  const userData = useAppSelector(selectUserData, shallowEqual)!;
  const dispatch = useAppDispatch();
  useTitle(`Настройки аккаунта${appConfig.titleApp}`);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Необходимо заполнить поле")
      .max(50, "Максимум 50 символов"),
    surname: Yup.string().max(50, "Максимум 50 символов"),
    patronymic: Yup.string().max(50, "Максимум 50 символов"),
    tel: Yup.mixed().test(
      "tel",
      "Необходимо ввести корректный номер",
      function (val) {
        if (!val) return true;
        return isPossibleNumber(+7 + val, "RU");
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: userData.name ?? "",
      surname: userData.surname ?? "",
      patronymic: userData.patronymic ?? "",
      tel: userData.phone?.slice(2) ?? "",
      timezone: userData.timezone,
      photo: "",
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await serviceUser.update({
          ...values,
          phone: values.tel ? "+7" + values.tel : "",
          photo_upload: values.photo,
        });
        toast(response.data.message, {
          autoClose: 3000,
        });
        const user = await serviceUser.profile();
        dispatch(
          setUserData({
            ideaCount: user.data.data.ideaCount,
            newsCount: user.data.data.newsCount,
            ...user.data.data.user,
            ...user.data.data.profile,
          })
        );
      } catch (e) {
        toast(e.response.data.message, {
          type: "warning",
          autoClose: 3000,
        });
      }
    },
  });
  return (
    <Content>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Row>
          <HeadingBlock>
            <Heading>Основная информация</Heading>
          </HeadingBlock>

          <FormContent>
            <LoadImage
              defaultImage={userData.photo ?? ""}
              onChange={(file) => {
                formik.setFieldValue("photo", file);
              }}
            />
            <Input
              label={"Имя"}
              name={"name"}
              placeholder={"Введите ваше имя"}
              value={formik.values.name}
              error={formik.errors.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Input
              label={"Фамилия"}
              name={"surname"}
              placeholder={"Введите вашу фамилию"}
              value={formik.values.surname}
              onChange={formik.handleChange}
              error={formik.errors.surname}
              onBlur={formik.handleBlur}
            />
            <Input
              label={"Отчество"}
              name={"patronymic"}
              placeholder={"Введите ваше отчество"}
              value={formik.values.patronymic}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.patronymic}
            />
          </FormContent>
        </Row>
        <Row>
          <HeadingBlock>
            <Heading>Контактная информация</Heading>
          </HeadingBlock>
          <FormContent>
            <InputEmailSetting
              label={"Email аккаунта"}
              name={"email"}
              value={userData.email}
            />
            <TelephoneInput
              label={"Телефон"}
              error={formik.errors.tel}
              placeholder={"Номер телефона"}
              value={formik.values.tel}
              onBlur={(val) => {
                formik.setFieldValue("tel", val);
                setTimeout(() => {
                  formik.validateField("tel");
                }, 50);
              }}
              onChange={(val) => {
                formik.setFieldValue("tel", val);
              }}
            />

            <SelectTimeZone
              label={"Часовой пояс"}
              onChange={(val) => {
                formik.setFieldValue("timezone", val);
              }}
              initialTimezone={formik.values.timezone}
            />
            <Buttons>
              <Button type={"submit"}>Сохранить</Button>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(openModal("change-password"));
                }}
                color={"black"}
                link={"/"}
              >
                Изменить пароль
              </Link>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  onlyOne(
                    createStandardToast(
                      <span>
                        Для удаления аккаунта, обратитесь в{" "}
                        <Link
                          color={"onlyPrimary"}
                          link={helpLinks.help}
                          redirectMode={{ blank: true }}
                        >
                          службу поддержки
                        </Link>
                      </span>
                    )
                  );
                }}
                color={"black"}
                link={"/"}
              >
                Удалить аккаунт
              </Link>
            </Buttons>
          </FormContent>
        </Row>
      </Form>
    </Content>
  );
};
