import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { UserFullType } from "domain/user";

import type { RootState } from "./store";

export type UserStateType = null | UserFullType;

const initialState = null as UserStateType;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData(_, action: PayloadAction<UserFullType>) {
      return action.payload;
    },
    clearUserData() {
      return null;
    },
  },
});

export const { setUserData, clearUserData } = userSlice.actions;
export default userSlice.reducer;

export const selectUserData = (state: RootState) => state.user;
