import React, { useEffect } from "react";
import { useTitle } from "ahooks";
import styled from "styled-components";

import { helpLinks } from "domain/links";
import { useAppDispatch } from "services/hooks";
import { clearAppError } from "services/store/appSlice";
import { clearUserData } from "services/store/userSlice";
import { serviceUser } from "services/user";
import preview from "ui/assets/media/images/banned-preview.svg";

import { appConfig } from "../../../appConfig";
import { ShadowButton } from "../../components/Buttons/ShadowButton";
import { Logo } from "../../components/Logo";
import { Icon } from "../../components/Icon";
import { Copyright } from "../../components/Copyright";
import { Link } from "../../components/Link";

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  justify-content: space-between;
`;

const Content = styled.div`
  padding: 40px 0;
  display: flex;
  width: 580px;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Image = styled.div`
  display: flex;
  width: 297px;
  height: 260px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 38px;
`;

const Button = styled(ShadowButton)`
  margin-top: 24px;
`;
const LogoImage = styled(Logo)`
  margin-top: 40px;
`;

export const BanPage = () => {
  const dispatch = useAppDispatch();

  useTitle("Вы забанены" + appConfig.titleApp);

  useEffect(() => {
    dispatch(clearUserData());
    serviceUser.clearToken();
    return () => {
      dispatch(clearAppError());
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Wrapper>
        <LogoImage color={"orange"} />
        <Content>
          <Image>
            <img src={preview} alt="" />
          </Image>
          <Text>Ваш аккаунт заблокирован, обратитесь в службу поддержки</Text>
          <Link link={helpLinks.help} redirectMode={{ blank: true }}>
            <Button>
              <Icon
                id={"telegram-icon"}
                width={16}
                height={12}
                style={{ marginRight: 12 }}
              />
              Написать в поддержку
            </Button>
          </Link>
        </Content>
        <Copyright />
      </Wrapper>
    </>
  );
};
