import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { createToastResponseError } from "lib/toastify";
import { validateEmail } from "lib/email";
import { serviceUser } from "services/user";
import { _variables } from "ui/styles/_variables";

import { Icon } from "../../components/Icon";
import { BlackNotify } from "../../components/BlackNotify";
import { TooltipHover } from "../../components/TooltipHover";
import { Input } from "../../components/FormsElements/Input";

const Wrapper = styled.div`
  position: relative;
  z-index: 12;
`;
const Address = styled(Input)`
  padding-right: 120px;
`;
const Change = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  position: absolute;
  top: 34px;
  cursor: pointer;
  right: 48px;
  text-align: right;
  color: ${_variables.additionalColors.blue};
  transition: 0.2s ${_variables.transition};

  &:hover {
    color: ${_variables.blackColor};
  }
`;

const NotifyIcon = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 34px;

  & img {
    width: 100%;
    height: 100%;
  }
`;
const NotifyContainer = styled.div`
  cursor: pointer;
`;

export const InputEmailSetting: CT<
  Omit<
    React.ComponentProps<typeof Input>,
    "onChange" | "onBlur" | "error" | "value"
  > & {
    onChange?: (val: string) => void;
    onBlur?: (val: string) => void;
    value?: string;
    name?: string;
    error?: string;
  }
> = ({ onChange, name, error, value, onBlur, ...props }) => {
  // дизейбл поля ввода
  const [isDisable, setDisable] = useState(true);
  // проксирование значения инпута
  const [valueState, setValue] = useState(value);
  // проксирование ошибки
  const [errorState, setError] = useState(error);
  // были лы изменения
  const [hasBeenChange, setBeenChange] = useState(false);
  // сравнение с изначальным эмейлом
  const [prevEmail] = useState(value);
  // проверка того, был ли эмейл в списке селека
  const [isShowNotify, setShowNoify] = useState(false);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const load = async () => {
    try {
      await serviceUser.updateEmail(valueState!);
      setBeenChange(false);
      setShowNoify(true);
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  // клик по слову 'изменить'
  const clickChangeHandler = () => {
    setDisable(false);
    setTimeout(() => {
      (inputRef.current as unknown as HTMLInputElement).focus();
    }, 100);
  };

  // изменения ввода
  const changeHandler = (val: string) => {
    setValue(val);
  };

  // выход с фокуса
  const blurHandler = (val: string) => {
    setValue(val);
    setDisable(true);
    onBlur && onBlur(val);
    // изменения эмейла были
    setTimeout(() => {
      setBeenChange(true);
    }, 200);
  };

  // проксирование значения
  useEffect(() => {
    onChange && onChange(valueState!);
  }, [valueState]);

  useEffect(() => {
    if (!hasBeenChange) return;

    if (!validateEmail(valueState!)) {
      setError("Необходимо ввести корректный email");
      return;
    }
    setError("");
    // если email не изменился
    if (prevEmail === valueState) {
      setBeenChange(false);
      setDisable(true);
      return;
    }
    load();
  }, [hasBeenChange]);

  // проксирование ошибки
  useEffect(() => {
    setError(error);
    setDisable(true);
  }, [error]);

  return (
    <Wrapper ref={wrapperRef}>
      <Address
        ref={inputRef}
        disabled={isDisable}
        onKeyUp={(e) => {
          if (e.nativeEvent.key === "Enter") {
            //@ts-ignore
            blurHandler(e.target.value);
          }
        }}
        label={"Адрес отправителя"}
        value={valueState}
        onBlur={(e) => {
          blurHandler(e.target.value);
        }}
        onChange={(e) => {
          changeHandler(e.target.value);
        }}
        error={errorState}
        {...props}
      />

      <TooltipHover
        offset={[15, 75]}
        isShow={isShowNotify}
        placement={"top-end"}
        content={
          <NotifyContainer
            onClick={() => {
              setShowNoify(false);
            }}
          >
            <BlackNotify style={{ width: 420 }}>
              Для изменения данных отправителя необходимо{" "}
              <span>подтвердить код</span> отправленный на вашу почту
            </BlackNotify>
          </NotifyContainer>
        }
      >
        <div>
          <NotifyIcon>
            <Icon id={"notify-preview-icon"} width={24} height={24} />
          </NotifyIcon>
        </div>
      </TooltipHover>
      <Change onClick={clickChangeHandler}>Изменить</Change>
    </Wrapper>
  );
};
