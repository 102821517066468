import { useImportPageState } from "../../../../../app/importContacts/useImportPageState";
import { WriteFileContent } from "ui/pages/ImportContacts/Steps/File/WriteFileContent";
import { LoadFileContent } from "ui/pages/ImportContacts/Steps/File/LoadFileContent";

export const FileManagerContent: CT<unknown> = () => {
  const {
    state: {
      importData: { importMode },
    },
  } = useImportPageState();

  const isShowLoadFileContent = importMode === "load";

  if (isShowLoadFileContent) return <LoadFileContent />;
  return <WriteFileContent />;
};
