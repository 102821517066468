import { useRef, useState } from "react";
import { useFocusWithin } from "ahooks";
import { format } from "date-fns";
import styled from "styled-components";

import { timePickerFormat } from "domain/time";
import { _variables } from "ui/styles/_variables";
import { formElement, formWrapper } from "ui/styles/elements/formElements";

import { Icon } from "../../Icon";
import { TimePicker } from "../../Datepicker/TimePicker";

const Wrapper = styled.div`
  ${formWrapper}
`;

const Element = styled.label<{
  isError: boolean;
  haveIcon: boolean;
  backgroundColor?: string;
}>`
  ${formElement};
  position: relative;
  cursor: pointer;
  width: 100%;
  min-width: 240px;
  padding: 13px 16px;
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? _variables.backgroundColor};
  border: 2px solid transparent;

  &:focus-within {
    & .icon {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  & select {
    cursor: pointer;
    border: ${(props) =>
      props.isError
        ? `2px solid ${_variables.supportColors.red}`
        : "2px solid transparent"};
    transition: all ${_variables.transition};

    &:focus {
      border: 2px solid ${_variables.primaryColor} !important;
    }

    width: 100%;
    padding: 0 16px;
    height: 100%;
    background: transparent;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: ${_variables.borderRadius};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;

    margin: 0;
  }

  & select:focus {
    border: none;
    outline: none;
  }
`;
const Data = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;
const WrapperDatePickerWindow = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
`;

/**
 * Пикер времени с иконкой часов используемый в создании рассылки
 *
 * @param className
 * @param isTodayMode
 * @param label
 * @param onChange
 * @param value
 * @param error
 * @constructor
 */
export const InputTimePicker: CT<{
  error?: string;
  label?: string;
  onChange?: (date: Date | null) => void;
  value: Date;
  isTodayMode?: boolean;
  background?: string;
}> = ({
  className,
  isTodayMode = false,
  label = "",
  onChange,
  value,
  error = "",
  background,
}) => {
  const refWrapper = useRef(null);
  const elementRef = useRef(null);

  const [isOpen, setOpen] = useState(false);

  const clickHandler = () => {
    //@ts-ignore
    elementRef.current.focus();
    setOpen(true);
  };

  useFocusWithin(refWrapper, {
    onFocus: () => {
      setOpen(true);
    },
    onBlur: () => {
      setTimeout(() => {
        setOpen(false);
      }, 250);
    },
  });

  return (
    <Wrapper ref={refWrapper} className={className}>
      {label && <p className={"label"}>{label}</p>}
      <Element
        backgroundColor={background}
        tabIndex={0}
        ref={elementRef}
        onClick={clickHandler}
        htmlFor={"select"}
        haveIcon={false}
        isError={error?.length > 0}
      >
        <Data>
          <Icon id={"input-clock-icon"} width={24} height={24} />
          <p>{value ? format(value, timePickerFormat) : ""}</p>
        </Data>
        {isOpen && (
          <WrapperDatePickerWindow>
            <TimePicker
              value={value}
              todayMode={isTodayMode}
              onSelectTime={(date) => {
                setTimeout(() => {
                  //@ts-ignore
                  elementRef.current?.blur();
                }, 50);

                onChange && onChange(date);
                setOpen(false);
              }}
            />
          </WrapperDatePickerWindow>
        )}
      </Element>
    </Wrapper>
  );
};
