import React, { useEffect } from "react";
import { Prompt, useParams } from "react-router-dom";
import styled from "styled-components";

import { offExitConfirm, onExitConfirm } from "app/applicationExitHandler";
import { useInnerPromptRedirect } from "app/useInnerPromptRedirect";
import { useAppSelector } from "services/hooks";
import { selectFormClientState } from "services/store/createFormClientSlice";

import { _variables } from "ui/styles/_variables";
import { InputNameStep } from "ui/pages/ClientFormPage/CreateForm/Steps/InputNameStep";
import { useCreateFormCrumbs } from "ui/pages/ClientFormPage/CreateForm/useCreateFormCrumbs";
import { ConstructorStep } from "ui/pages/ClientFormPage/CreateForm/Steps/ConstructorStep";
import { ChooseFormatStep } from "ui/pages/ClientFormPage/CreateForm/Steps/ChooseFormatStep";
import { SettingStep } from "ui/pages/ClientFormPage/CreateForm/Steps/SettingStep";
import { TableContainerScroll } from "ui/containers/TableContainerScroll";
import { ChooseContactStep } from "ui/pages/ClientFormPage/CreateForm/Steps/ChooseContactStep";
import { FinalStep } from "ui/pages/ClientFormPage/CreateForm/Steps/FinalStep";

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 40px 5px 20px 40px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
`;
const Heading = styled.h1`
  color: ${_variables.blackColor};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const TopRow = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  column-gap: 16px;
`;
const Crumbs = styled.div`
  min-width: 296px;
`;
const ScrollContent = styled(TableContainerScroll)`
  padding-bottom: 150px;
`;

const acceptRoutes = ["/forms/", "/forms"];

export const CreateFormPageContent = () => {
  const { isShowConfirmRedirect } = useInnerPromptRedirect(acceptRoutes);

  const { id } = useParams<{ id?: string }>();

  const data = useAppSelector(selectFormClientState);
  const { step } = data;

  const CrumbsElement = useCreateFormCrumbs();

  useEffect(() => {
    onExitConfirm();
    return () => {
      offExitConfirm();
    };
  }, []);

  // useEffect(() => {
  //   dispatch(createFormClientActions.goToFinalStep());
  // }, []);
  return (
    <Content>
      <ScrollContent height={80}>
        <Prompt
          message={(location) => {
            return isShowConfirmRedirect(location as unknown as Location)
              ? true
              : `Вы покидаете создание формы. Внесенные изменения не сохранятся`;
          }}
        />
        <Top>
          <Heading>Создание формы подписки</Heading>
        </Top>
        <TopRow>
          {step === 1 && <InputNameStep />}
          {step === 2 && <ConstructorStep id={Number(id)} />}
          {step === 3 && <ChooseFormatStep />}
          {step === 4 && <SettingStep />}
          {step === 5 && <ChooseContactStep />}
          {step === 6 && <FinalStep />}
          <Crumbs>
            <CrumbsElement />
          </Crumbs>
        </TopRow>
      </ScrollContent>
    </Content>
  );
};
