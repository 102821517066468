import axios from "axios";

import type { ServiceListPropTypes } from "domain/api";
import type {
  BookListType,
  BookSplitListType,
  BookValidateHistoryType,
  BookValidateType,
  ListItemType,
} from "domain/lists";
import { format, parse } from "date-fns";
import { apiDateFormat } from "domain/time";

export const contactsListService = {
  path: "book/",

  async list({
    page = 0,
    perPage = 5,
    sortBy = "name",
    name = "",
  }: ServiceListPropTypes<{ name?: string }>) {
    return axios.get<{ data: { books: BookListType[] } }>(
      `${this.path}list?page=${page}&per-page=${perPage}&sort=${sortBy}&BookSearch[name]=${name}`
    );
  },

  merge(ids: number[], name: string) {
    return axios.post<ApiResponseBodyType<unknown>>(`${this.path}merge`, {
      ids,
      name,
    });
  },

  delete(ids: number[]) {
    const body = new FormData();
    ids.forEach((item) => {
      body.append("ids[]", item.toString());
    });
    return axios.post<ApiResponseBodyType<unknown>>(
      `${this.path}delete-list`,
      body
    );
  },

  view({
    page,
    id,
    perPage,
    sortBy,
    search,
  }: ServiceListPropTypes<{
    id: number;
  }>) {
    return axios.get<{
      data: {
        contacts: ListItemType[];
        book: BookListType;
        bookValidate: BookValidateType;
      };
    }>(
      `${this.path}view/${id}?page=${page}&per-page=${perPage}&sort=${sortBy}&ContactSearch[email]=${search}`
    );
  },

  create(name: string) {
    const body = new FormData();
    body.append("Book[name]", name);
    return axios.post<ApiResponseBodyType<{ book: ListItemType }>>(
      `${this.path}create-list`,
      body
    );
  },
  deleteContactsByList(bookId: string, ids: number[]) {
    const body = new FormData();
    ids.forEach((item) => {
      body.append("ids[]", item.toString());
    });
    return axios.post<ApiResponseBodyType<unknown>>(
      `${this.path}delete-contact?deleteBy=list&bookId=${bookId}`,
      body
    );
  },
  deleteContactsByAll(bookId: string, ids: number[]) {
    const body = new FormData();
    ids.forEach((item) => {
      body.append("ids[]", item.toString());
    });
    return axios.post<ApiResponseBodyType<unknown>>(
      `${this.path}delete-contact?deleteBy=all&bookId=${bookId}`,
      body
    );
  },
  renameList(newName: string, id: number) {
    const body = new FormData();
    body.append("Book[name]", newName);
    return axios.post(`${this.path}update-list/${id}`, body);
  },
  moveContacts(ids: number[], bookId: number) {
    const body = new FormData();
    ids.forEach((item) => {
      body.append("contactIds[]", item.toString());
    });
    body.append("bookId", String(bookId));
    return axios.post(`${this.path}move-contact`, body);
  },
  async getAllLists() {
    try {
      const data = await this.list({ perPage: 50 });
      const maxPages = Number(data.headers["x-pagination-page-count"]);
      const array = new Array(maxPages).fill(0);
      const allData = await Promise.all(
        array.map((_, i) => this.list({ page: i + 1, perPage: 50 }))
      ).then((result) => result.map((item) => item.data.data.books));
      return allData.flat(Infinity);
    } catch (e) {
      throw e;
    }
  },

  // апи валидиации

  async calculateValidation({ id }: { id: string | number }) {
    return axios.get<{ data: { price: number } }>(
      `${this.path}calculate-validation-price?id=${id}`
    );
  },

  async buyValidation(id: number | string, cardId?: number) {
    const card = !!cardId ? `&card_id=${cardId}` : "";

    return axios.get<{ data: { sale: { conformation_url: string } } }>(
      `${this.path}buy-validation?id=${id}${card}`
    );
  },

  async deleteNotValidateContacts({ id }: { id: string | number }) {
    return axios.get(`${this.path}delete-not-valid-contacts?id=${id}`);
  },

  async downloadValidateReport({ id }: { id: string | number }) {
    return axios.get(`${this.path}download-validation-report?id=${id}`);
  },

  async getHistoryValidates({
    perPage,
    page,
    sortBy = "",
  }: ServiceListPropTypes<unknown>) {
    return axios.get<{ data: { bookValidates: BookValidateHistoryType[] } }>(
      `${this.path}book-validate-list?expand=sale&page=${page}&per-page=${perPage}&sort=${sortBy}`
    );
  },

  // Получение разбития для разбития рассылки
  async getSplitBook({
    id,
    multiplier,
    days,
    fromDate,
    withHolydays = true,
  }: {
    id: number | string;
    fromDate: Date;
    days?: number;
    multiplier?: number;
    withHolydays?: boolean;
  }) {
    const daysProp = days ? `&days=${days}` : "";
    const multiplierProp = multiplier ? `&multiplier=${multiplier}` : "";
    const dateProp = fromDate ? `&date=${format(fromDate, apiDateFormat)}` : "";
    const holidayProp = withHolydays
      ? `&withHolydays=true`
      : "&withHolydays=false";
    try {
      const response = await axios.get<
        ApiResponseBodyType<{ parts: BookSplitListType }>
      >(
        `${this.path}split?id=${id}${daysProp}${dateProp}${multiplierProp}${holidayProp}`
      );

      let sortData = Object.entries(response.data.data.parts);
      sortData.sort((a, b) => {
        return parse(a[0], apiDateFormat, new Date()) >
          parse(b[0], apiDateFormat, new Date())
          ? 1
          : -1;
      });

      return sortData.map(([date, data]) => ({
        ...data,
        date: date,
      }));
    } catch (e) {
      throw e;
    }
  },

  async getContactsCount() {
    return await axios.get(`${this.path}count-contacts`);
  },
};
