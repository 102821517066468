import { shallowEqual } from "react-redux";
import styled from "styled-components";

import { useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";

import { _variables } from "ui/styles/_variables";
import { Logo } from "../components/Logo";
import { Link } from "../components/Link";
import { Avatar } from "../components/Avatar";
import { UserMenu } from "./UserMenu";
import { TooltipClicked } from "../components/TooltipClicked";
import { Icon } from "../components/Icon";

const Container = styled.header`
  display: flex;
  align-items: center;

  margin-bottom: 1px;
  background-color: white;
`;

const Wrapper = styled.div`
  padding: 0 24px;
  padding-right: 31px;
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  height: 68px;
  align-items: center;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const OverlayContent = styled(TooltipClicked)`
  z-index: 9999;
`;

const Email = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
`;
const AvatarWrapper = styled.div`
  cursor: pointer;
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

const Wrap = styled.div`
  display: flex;
  column-gap: 26px;
  align-items: center;
`;

const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;

const IdeaCount = styled.span`
  padding: 0 4px;
  font-weight: 500;
  color: white;
  background-color: ${_variables.primaryColor};
  border-radius: 4px;
`;

export const Header: CT<unknown> = ({ className }) => {
  const user = useAppSelector(selectUserData, shallowEqual);

  return (
    <Container className={className}>
      <Wrapper>
        <LogoLink link={"/"}>
          <Logo color={"orange"} />
        </LogoLink>
        <Wrap>
          <LinkItem link={"/news"}>
            Новости
            {user && user.newsCount > 0 ? (
              <IdeaCount>{user.newsCount}</IdeaCount>
            ) : null}
          </LinkItem>

          <LinkItem link={"/ideas"}>
            Есть идея
            {user && user.ideaCount > 0 ? (
              <IdeaCount>{user.ideaCount}</IdeaCount>
            ) : null}
          </LinkItem>
          <LinkItem link={"/help"}>
            <Icon id={"answer-circle"} width={24} height={24} />
            База знаний
          </LinkItem>
          {/*<Link link={"/packages/payment"}>*/}
          {/*  <PayButton variant={"secondary"}>*/}
          {/*    <Icon id={"money-icon"} width={24} height={16} />*/}
          {/*    Оплатить*/}
          {/*  </PayButton>*/}
          {/*</Link>*/}
          {/*<ChatButton*/}
          {/*  onClick={() => {*/}
          {/*    window.carrotquest.open();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <img src={IconChat} alt="" />*/}
          {/*</ChatButton>*/}
          <OverlayContent
            placement={"auto"}
            offset={[-85, 40]}
            content={<UserMenu />}
          >
            <AvatarWrapper>
              <Avatar
                src={user?.photo ?? ""}
                width={32}
                height={32}
                alt={user?.name ?? user?.email ?? "Пользователь"}
              />
              <Email>
                {user?.email ?? ""}&nbsp;
                <Icon id={"arrow-bottom-icon"} width={20} height={20} />
              </Email>
            </AvatarWrapper>
          </OverlayContent>
        </Wrap>
      </Wrapper>
    </Container>
  );
};
