import { useCallback, useEffect } from "react";

export const useExitApplicationHandler = () => {
  localStorage.removeItem("exitConfirm");
  sessionStorage.removeItem("exitConfirm");

  const handler = useCallback((e: BeforeUnloadEvent) => {
    if (sessionStorage.getItem("exitConfirm") === "true") {
      e.preventDefault();
      e.returnValue = "";
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", handler);
  }, []);
};
