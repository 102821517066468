import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useFocusWithin } from "ahooks";
import { debounce } from "lodash";
import styled from "styled-components";

import { useValidEmail } from "app/useValidEmail";
import { userEmailsService } from "services/userEmailsService";
import { _variables } from "ui/styles/_variables";

import { Input } from "../../components/FormsElements/Input";
import { SelectBox } from "../../components/SelectBox";
import { Icon } from "../../components/Icon";
import { BlackNotify } from "../../components/BlackNotify";
import { TooltipHover } from "../../components/TooltipHover";

const Wrapper = styled.div`
  position: relative;
  z-index: 12;
`;
const Address = styled(Input)`
  padding-right: 120px;
`;
const Change = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  position: absolute;
  top: 34px;
  cursor: pointer;
  right: 48px;
  text-align: right;
  color: ${_variables.additionalColors.blue};
  transition: 0.2s ${_variables.transition};

  &:hover {
    color: ${_variables.blackColor};
  }
`;

const Select = styled(SelectBox)`
  position: absolute;
  top: calc(100% + 10px);
  width: 100%;
  background-color: white;
`;

const NotifyIcon = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 34px;

  & img {
    width: 100%;
    height: 100%;
  }
`;
const NotifyContainer = styled.div`
  cursor: pointer;
`;

export const InputAddress: CT<
  Omit<
    React.ComponentProps<typeof Input>,
    "onChange" | "onBlur" | "error" | "value"
  > & {
    onChange?: (val: string) => void;
    onBlur?: (val: string) => void;
    value?: string;
    name?: string;
    error?: string;
  }
> = ({ onChange, name, error, value, onBlur, ...props }) => {
  // дизейбл поля ввода
  const [isDisable, setDisable] = useState(true);
  // проксирование значения инпута
  const [valueState, setValue] = useState(value);
  // проксирование ошибки
  const [errorState, setError] = useState(error);
  // были лы изменения
  const [hasBeenChange, setBeenChange] = useState(false);
  // показывать ли селект с выбором
  const [showSelect, setShowSelect] = useState(false);
  // проверка на валидность с бэка, нужно для создания нового или нет
  const validEmail = useValidEmail();
  //
  const [searchVal, setSearchVal] = useState(value);
  // значения селека
  const [values, setValues] = useState<
    React.ComponentProps<typeof SelectBox>["values"]
  >([]);
  // сравнение с изначальным эмейлом
  const [prevEmail, setPrevEmail] = useState(value);
  // проверка того, был ли эмейл в списке селека
  const [emailHaveInList, setEmailHaveInList] = useState(false);
  const [isShowNotify, setShowNoify] = useState(false);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const load = async () => {
    try {
      const response = await userEmailsService.list(searchVal!);
      setValues(
        response.data.data.emails.map((item) => ({
          name: item.email,
          id: item.id,
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  // клик по слову 'изменить'
  const clickChangeHandler = () => {
    setDisable(false);
    setTimeout(() => {
      (inputRef.current as unknown as HTMLInputElement).focus();
    }, 100);
  };

  // изменения ввода
  const changeHandler = (val: string) => {
    setValue(val);
  };
  const onChangeSearchVal = useCallback(
    debounce((val: string) => {
      setSearchVal(val);
    }, 300),
    []
  );

  // выход с фокуса
  const blurHandler = (val: string) => {
    setValue(val);
    onBlur && onBlur(val);
    // изменения эмейла были
    setTimeout(() => {
      setBeenChange(true);
    }, 200);
  };

  // клик по айтему селекта
  const onClickHandler = (val: string) => {
    setEmailHaveInList(true);
    setTimeout(() => {
      setValue(val);
    }, 100);
  };

  // Если существовала ошибка с отстуствием имени, то при изменении имени убрать ее
  useEffect(() => {
    if (
      hasBeenChange &&
      errorState === 'Необходимо ввести поле "От кого"' &&
      name
    ) {
      setError("");
    }
  }, [name]);

  // // проксирование значения
  useEffect(() => {
    onChange && onChange(valueState!);
  }, [valueState]);

  useEffect(() => {
    // если нету имени, то установить ошибку

    if (!name && hasBeenChange) {
      setError('Необходимо ввести поле "От кого"');
      return;
    }
    // если email не изменился

    if (prevEmail === valueState) {
      setBeenChange(false);
      setDisable(true);
      return;
    }

    if (!error && name && hasBeenChange) {
      // если эмейл был вызван с клика по селекту

      if (emailHaveInList) {
        setEmailHaveInList(false);
        setBeenChange(false);
        setDisable(true);
        setPrevEmail(valueState);
        return;
      }
      setBeenChange(false);
      setDisable(true);
      setPrevEmail(valueState);
      // проверка на существование
      (async () => {
        const isValid = await validEmail(valueState!);

        if (isValid) return;

        try {
          const response = await userEmailsService.create(name, valueState!);
          toast(response.data.message, { autoClose: 3000 });
          setShowNoify(true);
        } catch (e) {
          console.log(e);
          toast("Ошибка создания email", { type: "warning" });
        }
      })();
      // отправка на создание
    }
  }, [hasBeenChange, error, name]);

  // проксирование ошибки
  useEffect(() => {
    setError(error);
    setDisable(true);
  }, [error]);

  // скрытие селекта
  useFocusWithin(wrapperRef, {
    onFocus: () => {
      setShowSelect(true);
    },
    onBlur: () => {
      setTimeout(() => {
        setShowSelect(false);
        onBlur && onBlur(value!);
      }, 100);
    },
  });

  useEffect(() => {
    load();
  }, [searchVal]);

  return (
    <Wrapper ref={wrapperRef}>
      <Address
        ref={inputRef}
        disabled={isDisable}
        onKeyUp={(e) => {
          if (e.nativeEvent.key === "Enter") {
            //@ts-ignore
            blurHandler(e.target.value);
          }
        }}
        label={"Адрес отправителя"}
        value={valueState}
        onBlur={(e) => {
          blurHandler(e.target.value);
        }}
        onChange={(e) => {
          changeHandler(e.target.value);
          onChangeSearchVal(e.target.value);
        }}
        error={errorState}
        {...props}
      />

      <TooltipHover
        offset={[180, -100]}
        isShow={isShowNotify}
        content={
          <NotifyContainer
            onClick={() => {
              setShowNoify(false);
            }}
          >
            <BlackNotify style={{ width: 420 }}>
              Для изменения данных отправителя необходимо{" "}
              <span>подтвердить код</span> отправленный на вашу почту
            </BlackNotify>
          </NotifyContainer>
        }
      >
        <NotifyIcon>
          <Icon id={"notify-preview-icon"} width={24} height={24} />
        </NotifyIcon>
      </TooltipHover>
      <Change onClick={clickChangeHandler}>Изменить</Change>
      {showSelect && (
        <Select
          onClickElement={(e) => {
            onClickHandler(e.name);
          }}
          values={values}
        />
      )}
    </Wrapper>
  );
};
