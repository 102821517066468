import React, { useEffect } from "react";
import { shallowEqual } from "react-redux";
import styled from "styled-components";

import type { ProjectDomainType } from "domain/project";
import { useShowApiKeysCollection } from "app/tables/useShowApiKeysCollection";
import { _variables } from "ui/styles/_variables";

import { Icon } from "../../components/Icon";
import { LoadContent } from "../../components/LoadContent";
import { Button } from "../../components/Buttons/Button";
import { AlertBig } from "../../components/Alerts/AlertBig";
import { TableHeadOnceFilter } from "../../components/Table/Filters/TableHeadOnceFilter";
import { TableHead } from "../../components/Table/TableHead";
import { TableBody } from "../../components/Table/TableBody";
import { ChipDomainStatus } from "../../components/Chips/ChipDomainStatus";
import { CheckList } from "../../components/CheckList";
import { Link } from "../../components/Link";

const MainHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  z-index: 26;
`;

const MainHeading = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const Content = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  height: 100%;
`;

const KeysNothing = styled.p`
  font-family: Inter;
  margin-top: 275px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: ${_variables.grayTextColor};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

const ContentHeading = styled.h2`
  font-size: 24px;
  margin-top: 40px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const Table = styled.table`
  display: grid;
  grid-template-columns:
    minmax(230px, 100%) minmax(322px, 322px) minmax(180px, 320px)
    minmax(280px, 100%);
  grid-auto-rows: min-content;
  margin-top: 20px;
  position: relative;
  z-index: 11;

  & tr {
    min-height: 66px;
  }

  & tr td {
    word-break: break-all;
    border-bottom: 1px solid ${_variables.strokeColor};
    display: flex;
    min-height: 66px;
    height: inherit;
    padding: 10px 0;
    padding-right: 5px;
    align-items: center;
  }
`;

const THead = styled(TableHead)`
  & th {
    border-bottom: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    border-bottom: 1px solid ${_variables.strokeColor};
    color: ${_variables.grayTextColor};
  }
`;

const TBody = styled(TableBody)`
  margin-top: 16px;
  position: relative;
  top: -10px;

  & td {
    border-bottom: none;
  }
`;

const TableText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const ButtonApi = styled(Button)`
  display: flex;
  column-gap: 12px;
  padding-left: 18px;
  padding-right: 18px;
`;

export const KeysHeading = React.memo(() => {
  return (
    <MainHeadingWrapper>
      <MainHeading>API рассылки</MainHeading>
      <a
        href="https://api.cheapsender.email/"
        rel="noreferrer"
        target={"_blank"}
      >
        <ButtonApi variant={"secondary"}>
          <Icon id={"document-icon"} />
          <span>Документация API</span>
        </ButtonApi>
      </a>
    </MainHeadingWrapper>
  );
}, shallowEqual);

const RowContent: CT<{ data: ProjectDomainType }> = ({ data }) => {
  return (
    <tr>
      <td>
        <TableText>{data.name}</TableText>
      </td>
      <td>
        <TableText>{data.domain}</TableText>
      </td>
      <td>
        {data.status && (
          <span>
            <ChipDomainStatus status={data.status} />
          </span>
        )}
      </td>
      <td>
        <TableText>{data.token}</TableText>
      </td>
    </tr>
  );
};

export const KeysContent: CT<{
  query: {
    perPage?: any;
    page?: any;
    name?: any;
    sortBy?: any;
  };
  onSetCountPage: (val: number) => void;
}> = ({ onSetCountPage }) => {
  const { data, countPage, isLoad } = useShowApiKeysCollection();

  useEffect(() => {
    onSetCountPage(countPage);
  }, [countPage]);

  if (isLoad) return <LoadContent top={120} />;
  if (!isLoad && data.length === 0) {
    return (
      <Content>
        <KeysNothing>
          Скоро здесь появится ваш первый ключ API. <br /> Дождитесь модерации
          вашей анкеты.
        </KeysNothing>
      </Content>
    );
  }

  return (
    <ContentWrapper>
      <ContentHeading>
        Перед тем как проводить рассылки, следует проверить:
      </ContentHeading>
      <AlertBig
        text={
          <CheckList
            data={[
              <span>
                <Link
                  link={
                    "/main/user/setting?page=1&per-page=25&sortBy=emails&tab=emails"
                  }
                >
                  Чтобы ваш email отправителя был добавлен и верифицирован
                </Link>
              </span>,
              <span>
                <Link
                  link={
                    "/main/user/setting?name=&page=1&per-page=25&sortBy=domain&tab=domains"
                  }
                >
                  Проверьте настроены ли для домена отправителя DNS-записи
                </Link>
              </span>,
              <span>
                <Link link={"/packages/payment"}>
                  Приобретите необходимый пакет в разделе “Пакеты писем”
                </Link>
              </span>,
            ]}
          />
        }
      />
      <Table>
        <THead>
          <TableHeadOnceFilter
            name={"domain"}
            onClick={() => {}}
            isActive={false}
          >
            Проект
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            name={"added"}
            onClick={() => {}}
            isActive={false}
          >
            Домены отправки
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            name={"status"}
            onClick={() => {}}
            isActive={false}
          >
            Статус домена
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            name={"count"}
            onClick={() => {}}
            isActive={false}
          >
            Токены
          </TableHeadOnceFilter>
        </THead>
        <TBody>
          {data.map((item) => (
            <RowContent key={item.token} data={item} />
          ))}
        </TBody>
      </Table>
    </ContentWrapper>
  );
};
