import styled, { keyframes } from "styled-components";

import { _variables } from "ui/styles/_variables";

const load = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Dot1 = styled.circle`
  animation: ${load} 1.5s infinite ease-in;
`;
const Dot2 = styled.circle`
  animation: ${load} 1.5s infinite ease-in;
  animation-delay: 0.2s;
`;
const Dot3 = styled.circle`
  animation: ${load} 1.5s infinite ease-in;
  animation-delay: 0.4s;
`;

export const LoadDots: CT<{ color?: "white" | "black" }> = ({
  color = "black",
}) => {
  const fill = `${
    color === "black" ? _variables.blackColor : _variables.whiteColor
  }`;
  return (
    <svg
      width="30px"
      height="6px"
      viewBox="0 0 36 12"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs />
      <g
        id="Page-1"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="dots" fill={fill}>
          <Dot1 id="dot1" cx={-3} cy={6} r={6} />
          <Dot2 id="dot2" cx={17} cy={6} r={6} />
          <Dot3 id="dot3" cx={38} cy={6} r={6} />
        </g>
      </g>
    </svg>
  );
};
