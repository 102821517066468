import { useHistory } from "react-router-dom";

/**
 * Хук необходимый для случаев сохранения url пути пользователя с последующим
 * возвращением на эту url. Используется в конструкторах.
 * Реализуется через внутренние переходы SPA
 */
export const useGoBackLink = () => {
  const history = useHistory();

  /**
   * Создает ссылку возврата в SS
   * @param link
   */
  const create = (link: string) => {
    sessionStorage.setItem("goBackLink", link);
  };

  /**
   * Удаляет ссылку возврата в SS
   */
  const remove = () => {
    if (sessionStorage.getItem("goBackLink")) {
      sessionStorage.removeItem("goBackLink");
    }
  };

  const get = () => sessionStorage.getItem("goBackLink");

  /**
   * Производит переход на ссылку возврата и удаляет ее из SS.
   * В случае, если ссылки не было, то берет последний url
   */
  const goBack = () => {
    setTimeout(() => {
      remove();
    }, 400);
    if (get()) {
      history.push(get()!);
    } else {
      window.history.back();
    }
  };

  return {
    create,
    remove,
    get,
    goBack,
  };
};
