import { css } from "styled-components";
import { _variables } from "../../styles/_variables";

export const counterShowStyle = (invertedCount: boolean) => css`
  .statistic__count {
    display: none;
  }
  ${() => {
    if (invertedCount)
      return css`
        .statistic__count {
          display: block;
        }
        .statistic__percent {
          display: none;
        }
      `;
  }}
  &:hover {
    background-color: ${_variables.strokeColor};
    .statistic__percent {
      display: none;
    }
    .statistic__count {
      display: block;
    }

    ${() => {
      if (invertedCount)
        return css`
          .statistic__percent {
            display: block;
          }
          .statistic__count {
            display: none;
          }
        `;
    }}
  }
`;
