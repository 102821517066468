/**
 * ui хук, который возвращает текущую компонент хлебных крошек,
 * в зависимости от состояния шагов
 */
import { useCallback } from "react";

import { CrumbsVertialUpd } from "ui/components/Crumbs/CrumbsVertical";
import styled from "styled-components";
import { useCreateClientFormPageState } from "app/createClientForm/useCreateClientFormPageState";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 21px;
`;
const Title = styled.h5`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;

export const useCreateFormCrumbs = () => {
  const {
    data: { step },
  } = useCreateClientFormPageState();

  const getCrumbsData = useCallback(() => {
    return [
      {
        title: "Название",
        isActive: step === 1,
        isSuccess: step > 1,
      },
      {
        title: "Конструктор",
        isActive: step === 2,
        isSuccess: step > 2,
      },
      {
        title: "Формат",
        isActive: step === 3,
        isSuccess: step > 3,
      },
      {
        title: "Настройки",
        isActive: step === 4,
        isSuccess: step > 4,
      },
      {
        title: "Выбор списка",
        isActive: step === 5,
        isSuccess: step > 5,
      },
      {
        title: "Сохранение",
        isActive: step === 6,
        isSuccess: step > 6,
      },
    ];
  }, [step]);

  const CrumbComponent = useCallback(() => {
    return (
      <Wrapper>
        <Title>Этапы создания формы</Title>{" "}
        <CrumbsVertialUpd values={getCrumbsData()} />
      </Wrapper>
    );
  }, [step]);

  return CrumbComponent;
};
