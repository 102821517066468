import styled from "styled-components";

const Container = styled.div`
  display: flex;
  column-gap: 8px;
  margin-top: 24px;
`;
export const ModalButtonsContainer: CT<{ style?: React.CSSProperties }> = ({
  children,
  style,
}) => {
  return <Container style={style}>{children}</Container>;
};
