import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import useUrlState from "@ahooksjs/use-url-state";

import type { UserPlan } from "domain/userPlan";
import { userPlanService } from "services/userPlan";
import { useTableStateLoad } from "ui/hooks/useTableStateLoad";

export const useShowPayHistoryCollection = () => {
  const [values, setValues] = useState<UserPlan[]>([]);
  const [countPage, setCountPage] = useState(0);
  const [query] = useUrlState({
    "per-page": "",
    page: "",
    sortBy: "",
  });
  const [queryState, setQueryState] = useState({});
  const { setOffLoad, setOnLoad, ...tableState } = useTableStateLoad(values);

  const loadData = async () => {
    setOnLoad();
    try {
      const list = await userPlanService.index({
        ...query,
        perPage: query["per-page"],
      });

      setCountPage(list.headers["x-pagination-page-count"]);
      setValues(list.data.data.userPlans);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (shallowEqual(queryState, query)) return;
    setValues([]);
    loadData();
    setQueryState(query);
  }, [query]);

  return {
    data: values,
    countPage,
    loadData,
    query,
    ...tableState,
  };
};
