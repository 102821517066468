const variables = {
  primaryColor: "#E86E4D",
  secondaryColor: "#FF7F5D",
  blackColor: "#050C26",

  grayTextColor: "#898D9A",
  strokeColor: "#E4E5E8",
  strokeColor2: "#e8e8e8",

  grayColor: "#777991",
  grayColor2: "#8C95A4",

  backgroundColor: "#F5F6F8",
  whiteColor: "#FFFFFF",

  defaultTemplates: {
    hover: "#113D57",
    labelHTML: "#4690FF",
  },

  additionalColors: {
    purple: "#CCD7E3",
    blue: "#00629C",
    yellow: "#FFB81C",
    orange: "#E86E4D",
  },

  supportColors: {
    red: "#EA4335",
    green: "#39CD7D",
    yellow: "#FFB81C",
  },

  supportColors2: {
    red: "#E22446",
    green: "#00BA96",
    yellow: "#FFB81C",
  },

  statusColors: {
    continues: "#2B79ED",
    planing: "#B880FF",
    success: "#00BA96",
    blocked: "#EA4335",
    inModeration: "#FFB81C",
  },

  borderRadius: "8px",
  transition: "0.2s ease-in",
  fontFamily: "Inter",

  wrapperWidth: 1156,
  tabletWidth: 640,
  mobileWidth: 380,

  // newsStyle
  newsPrimaryFontSize: "16px",

  newsTextPrimaryColor: "#777991",
  newsHeadingPrimaryColor: "#212529",

  decorMacOSToolbar: {
    close: "#CE5347",
    fold: "#D6A243",
    fullScreen: "#58A942",
  },
};

export const _variables = variables;
export default variables;
