import styled from "styled-components";

const Preloader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: transparent;
  & svg {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 102;
    transform: translate(-50%, -50%);
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
    z-index: 101;
  }
`;

export const SectionPreloader: CT<unknown> = () => {
  return (
    <Preloader>
      <svg
        id="svg1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={28}
        height={28}
        viewBox="0 0 120 120"
      >
        {/* Путь заполнения линии прогрессбара     */}
        <circle
          cx={60}
          cy={60}
          r={50}
          fill="none"
          stroke="#293ACC"
          opacity={0.25}
          strokeWidth={16}
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
        />
        <circle
          cx={60}
          cy={60}
          r={50}
          transform="rotate(-90 60 60)"
          fill="none"
          strokeDashoffset={314}
          strokeDasharray={314}
          stroke="#293ACC"
          strokeWidth={16}
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
        >
          {/*Анимация  изменение отступа линии stroke-dashoffset от 314 до 0 */}
          <animate
            repeatCount={"indefinite"}
            attributeName="stroke-dashoffset"
            dur="2s"
            values="618;0"
          />
        </circle>
      </svg>
    </Preloader>
  );
};
