import React, { useLayoutEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useUserNotify } from "ui/containers/common/useUserNotify";
import { usePayValidationRedirect } from "app/usePayValidationRedirect";
import { useUseYandexMetrika } from "app/analytics/useUseYandexMetrika";
import { ContentLayout } from "ui/containers/ContentLayout";
import { routes } from "ui/routes/routes";
import "ui/assets/media/icons/logo-color.svg";
import { ModeRoute } from "ui/routes/ModeRoute";
import { useUserPlanList } from "app/user/useUserPlanList";

function App() {
  useUserNotify();
  useUseYandexMetrika();
  useUserPlanList();
  const { callAlertInfoPaymentData } = usePayValidationRedirect();

  useLayoutEffect(() => {
    callAlertInfoPaymentData();
    //@eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <ContentLayout routes={routes}>
        <Switch>
          <Route path={"/"} exact>
            <Redirect to={"/main/"} />
          </Route>

          {routes.map((route) => {
            const Elem = route.component;
            return (
              <Route key={route.path} path={route.path} exact={route.exact}>
                <ModeRoute mode={route.mode}>
                  <Elem />
                </ModeRoute>
              </Route>
            );
          })}

          <Route path={"*"}>
            <Redirect to={"/status/404"} />
          </Route>
        </Switch>
      </ContentLayout>
    </Switch>
  );
}

export default App;
