import React from "react";
import { shallowEqual } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import styled from "styled-components";

import { projectService } from "services/project";
import { _variables } from "ui/styles/_variables";

import { Input } from "../../components/FormsElements/Input";
import { Button } from "../../components/Buttons/Button";
import { AlertBig } from "../../components/Alerts/AlertBig";

const MainHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MainHeading = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const Content = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

const HeadingContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const RowContent = styled.div`
  display: flex;
  column-gap: 20px;
`;

const FormWrapper = styled.div`
  display: flex;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 12px;
  width: 676px;
  padding: 32px;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  row-gap: 20px;
`;

const FormCell = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const FormsHeading = React.memo(() => {
  return (
    <MainHeadingWrapper>
      <MainHeading>API рассылки</MainHeading>
    </MainHeadingWrapper>
  );
}, shallowEqual);

export const FormsContent: CT<unknown> = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Необходимо заполнить поле"),
    domain: Yup.string().required("Необходимо заполнить поле"),
    howToUse: Yup.string().required("Необходимо заполнить поле"),
    howToCollectEmails: Yup.string().required("Необходимо заполнить поле"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      domain: "",
      howToUse: "",
      howToCollectEmails: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await projectService.create(values);
        toast(response.data.message, {
          autoClose: 3000,
          toastId: 1,
        });
        formik.resetForm();
      } catch (e) {
        toast(e.response.data.message, {
          type: "warning",
          autoClose: 3000,
        });
      }
    },
  });

  return (
    <Content>
      <HeadingContent>
        <Heading>Заполните анкету для получения ключа API</Heading>
        <Subtitle>Все поля обязательны</Subtitle>
      </HeadingContent>
      <RowContent>
        <FormCell
          onSubmit={(e) => {
            e.preventDefault();
            formik.submitForm();
          }}
        >
          <FormWrapper>
            <FormContent>
              <Input
                label={"Название проекта"}
                name={"name"}
                placeholder={"Как будет называться ваш проект"}
                value={formik.values.name}
                error={formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label={"Домен отправителя"}
                name={"domain"}
                placeholder={"С какого домена будет производиться отправка"}
                value={formik.values.domain}
                error={formik.errors.domain}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label={"Как планируете использовать API"}
                name={"howToUse"}
                placeholder={"Укажите сферу использования"}
                value={formik.values.howToUse}
                error={formik.errors.howToUse}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label={"Как были собраны адреса для рассылки"}
                name={"howToCollectEmails"}
                placeholder={"Укажите как была получена база контактов"}
                value={formik.values.howToCollectEmails}
                error={formik.errors.howToCollectEmails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormContent>
          </FormWrapper>
          <Button type={"submit"}>Отправить анкету</Button>
        </FormCell>
        <AlertBig
          maxWidth={420}
          text={
            "Для рассылки необходим e-mail адрес на корпоративном домене. Отправка с общедоступных e-mail заблокирована из-за невозможности изменить DNS-записи."
          }
        />
      </RowContent>
    </Content>
  );
};
