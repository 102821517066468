import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import img from "ui/assets/media/images/mailing-success-previw.svg";

import { Button } from "../Buttons/Button";
import { Link } from "../Link";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  width: 100%;
`;
const Preview = styled.div`
  width: 300px;
  height: 260px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const TextContent = styled.div`
  row-gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Heading = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.25px;
  text-align: left;
  color: ${_variables.grayTextColor};
  text-align: center;
`;
const Buttons = styled.div`
  display: flex;

  column-gap: 8px;
`;

export const MailingSendSuccess: CT<{
  onClose: VoidFunction;
  onCloseLink: VoidFunction;
}> = ({ onClose, onCloseLink }) => {
  const history = useHistory();
  return (
    <Container>
      <Preview>
        <img src={img} alt="" />
      </Preview>
      <TextContent>
        <Heading>Рассылка успешно отправлена</Heading>
        <Text>
          Вы можете проверить статус <br /> в разделе{" "}
          <Link
            color={"primary"}
            onClick={(e) => {
              e.nativeEvent.preventDefault();
              e.preventDefault();
              onCloseLink();
              history.push("/mailing/");
            }}
            link={"/mailing/"}
          >
            Мои рассылки
          </Link>
        </Text>
      </TextContent>
      <Buttons>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          На главную
        </Button>
      </Buttons>
    </Container>
  );
};
