import axios from "axios";

import type { BuyPlanDataType, PayPlanType } from "domain/payPlan";
import type { UserPlan } from "domain/userPlan";

export const planService = {
  path: "plan/",
  async list() {
    return axios.get<ApiResponseBodyType<{ ab: string; plans: PayPlanType[] }>>(
      `${this.path}list`
    );
  },
  async buy(planId: number | string, cardId?: number) {
    const card = !!cardId ? `&card_id=${cardId}` : "";

    return axios.get<ApiResponseBodyType<BuyPlanDataType>>(
      `${this.path}buy?id=${planId}${card}`
    );
  },
  async update(id: number | string) {
    return axios.get<
      ApiResponseBodyType<{
        sale: BuyPlanDataType["sale"];
        userPlan: UserPlan | null; // null если sale.complete == false
      } | null>
    >(`sale/update?id=${id}`);
  },
};
