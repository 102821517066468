import { useState, useEffect } from "react";
import { useTitle } from "ahooks";
import useUrlState from "@ahooksjs/use-url-state";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

import { mailingService } from "services/mailing";
import { _variables } from "ui/styles/_variables";
import { mxm } from "ui/styles/_mixin";
import image from "ui/assets/media/images/success-icon.svg";

import { Logo } from "../components/Logo";
import { appConfig } from "../../appConfig";
import { Copyright } from "../components/Copyright";
import { TextArea } from "../components/FormsElements/TextArea";
import { Button } from "../components/Buttons/Button";
import { Link } from "../components/Link";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;
const Content = styled.div`
  min-height: 100vh;
  width: 365px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40px;
  position: relative;
  padding-bottom: 50px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  height: 100%;
  align-items: center;
`;

const FormBox = styled.div`
  margin-top: 52px;
  display: flex;
  border-radius: 12px;
  border: 1px solid ${_variables.strokeColor};
  flex-direction: column;
  padding: 48px;
  width: 532px;
  ${mxm(
    1200,
    css`
      width: 90vw;
    `
  )}
  ${mxm(
    500,
    css`
      margin-top: 0px;
      margin-bottom: 8px;
      padding: 24px;
    `
  )}
`;

const Heading = styled.h3`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  ${mxm(
    400,
    css`
      font-size: 20px;
    `
  )}
`;
const Address = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  column-gap: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  & span:first-child {
    min-width: 121px;
  }

  & span:last-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const FormDescription = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  margin-top: 20px;
  text-align: left;

  & span {
    color: ${_variables.grayTextColor};
  }

  ${mxm(
    500,
    css`
      margin-bottom: 8px;
    `
  )}
`;

const Field = styled(TextArea)`
  margin-top: 12px;
  margin-bottom: 20px;
`;
const SuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 600px;
`;
const SuccessImage = styled.div`
  width: 104px;
  height: 104px;
  margin-bottom: 20px;

  & img {
    width: 100%;
    height: 100%;
  }
`;
const SuccessText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;

  & a {
    font-size: inherit;
  }
`;

const removeMinWidth = () => {
  document.body.style.minWidth = "200px";

  const metaViewport = document.querySelector('meta[name="viewport"]');

  if (metaViewport) {
    metaViewport.setAttribute(
      "content",
      "width=device-width, initial-scale=1.0"
    );
  }
};

export const UnsubscribePage: CT<unknown> = () => {
  useTitle("Отмена подписки" + appConfig.titleApp);
  const [isSuccessSubmit, setSuccessSubmit] = useState(false);
  const [reason, setReason] = useState("");
  const [query] = useUrlState({
    hash: "",
    token: "",
    email: "",
  });
  const { hash, token, email } = query;

  useEffect(() => {
    removeMinWidth();
  }, []);

  const SuccessBlock = () => {
    return (
      <SuccessWrapper>
        <SuccessImage>
          <img src={image} alt="" />
        </SuccessImage>
        <SuccessText>
          Готово! Письма для контакта{" "}
          <Link
            color={"gray"}
            link={`mailto:${email}`}
            redirectMode={{ blank: false }}
          >
            {email}
          </Link>
          <br />
          больше не будут приходить
        </SuccessText>
      </SuccessWrapper>
    );
  };

  const submitHandler = async () => {
    try {
      await mailingService.unsubscribe({
        hash,
        token,
        reason,
      });
      setSuccessSubmit(true);
    } catch (e) {
      toast(e.response.data.message, { autoClose: 5000, type: "warning" });
    }
  };

  return (
    <Wrapper>
      <Content>
        <Box>
          <Logo color={"orange"} />
          {isSuccessSubmit ? (
            <SuccessBlock />
          ) : (
            <FormBox>
              <Heading>Отписка от рассылки</Heading>
              <Address>
                <span>Для контакта:</span> <span>{email}</span>
              </Address>
              <FormDescription>
                Пожалуйста, укажите причину отписки{" "}
                <span>(не обязательно)</span>:
              </FormDescription>
              <Field
                value={reason}
                onChange={(e: any) => {
                  setReason(e.target.value);
                }}
                placeholder={"Напишите причину"}
              />
              <Button onClick={submitHandler}>Отписаться</Button>
            </FormBox>
          )}
        </Box>
        <Copyright />
      </Content>
    </Wrapper>
  );
};
