import styled from "styled-components";
import { _variables } from "ui/styles/_variables";
import { mailPlural } from "lib/plural";

const IContent = styled.div`
  min-height: 44px;
  max-width: 100px;
  min-width: 79px;
  padding: 5px 7px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  border-bottom: 3px solid ${_variables.supportColors.green};
`;

const ITitle = styled.h5`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const ICounter = styled.h5`
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: -0.25px;
  text-align: left;
`;

export const MailScheduleCounter: CT<{ title: string; counter: number }> = ({
  title,
  counter,
}) => {
  return (
    <IContent>
      <ITitle>{title}</ITitle>
      <ICounter>{mailPlural(counter)}</ICounter>
    </IContent>
  );
};
