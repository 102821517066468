import type { RowTabsPropsType } from "../../components/RowTabs";

export const defaultPageQueryParams = {
  tab: "history",
  perPage: 25,
  page: 1,
  sortBy: "",
};

// Параметры используемые при смене таба на странице
export const resetPageQueryParams: {
  [key: string]: {
    name: string | undefined;
    "per-page": number;
    page: number;
    sortBy: string;
  };
} = {
  draft: {
    name: undefined,
    "per-page": 25,
    page: 1,
    sortBy: "name",
  },
  history: {
    name: undefined,
    "per-page": 25,
    page: 1,
    sortBy: "",
  },
  top: {
    name: undefined,
    "per-page": 25,
    page: 1,
    sortBy: "",
  },
  unsubscribe: {
    name: undefined,
    "per-page": 25,
    page: 1,
    sortBy: "-date_in",
  },
};

export const pageTabsData: RowTabsPropsType["items"] = [
  { name: "История", value: "history" },
  { name: "Текущие черновики", value: "draft" },
  { name: "Топ рассылок", value: "top" },
  { name: "Отписки", value: "unsubscribe" },
];
