import { useEffect, useState } from "react";
import { debounce } from "lodash";
import styled from "styled-components";

import type { TemplateType } from "domain/templates";
import { createStandardToast, onlyOne } from "lib/toastify";
import { useAppDispatch } from "services/hooks";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";
import { templatesService } from "services/templates";

import { ContactsHeaderRow } from "../components/ContactsHeaderRow";
import { TableHead } from "../components/Table/TableHead";
import { TableBody } from "../components/Table/TableBody";
import { TableHeadOnceFilter } from "../components/Table/Filters/TableHeadOnceFilter";
import { ButtonTable } from "../components/Buttons/ButtonTable";
import { ContentPagination } from "../components/ContentPagination";
import { TableContainerScroll } from "../containers/TableContainerScroll";
import { ShowCount } from "../components/ShowCount";
import { Icon } from "../components/Icon";
import { LoadContent } from "../components/LoadContent";
import { TemplateTableContent } from "../components/Table/TemplateTableContent";
import { Input } from "../components/FormsElements/Input";
import { Button } from "../components/Buttons/Button";
import { useTableWorkspace } from "../hooks/useTableWorkspace";
import { useCheckedTableData } from "../hooks/useCheckedTableData";
import { useTableStateLoad } from "../hooks/useTableStateLoad";
import { NotContentTemplatesPage } from "../components/NotContent/NotContentTemplatePage";
import { Link } from "../components/Link";
import { SearchNotFound } from "../components/NotContent/SearchNotFound";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px 5px 12px 32px;
`;

const Table = styled.table`
  display: grid;
  grid-template-columns:
    minmax(280px, 100%) minmax(223px, 400px) minmax(145px, 140px)
    170px;
  grid-auto-rows: auto;
  position: relative;
  z-index: 9;

  & th {
    min-height: 48px;
  }

  & tr td {
    height: 74px;
  }
`;

const HeadingLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 31px;
  margin-bottom: 18px;
`;

const HeadingRightContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
`;

const Heading = styled.h1`
  font-family: "Inter";
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const TableTop = styled(Table)`
  padding-right: 0;
  row-gap: 0;
`;

const StickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: white;
`;

const FirstTH = styled(TableHeadOnceFilter)`
  padding-left: 4px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
`;

const InputSearch = styled(Input)`
  width: 308px;
`;

const LoadWrapper = styled.div`
  display: flex;

  height: 100%;
`;
const CreateButton = styled(Button)`
  padding: 0 18px;
`;

export const TemplatesPage = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<TemplateType[]>([]);
  const [countPage, setCountPage] = useState(0);
  const { query, setPerPage, setPage, setSortBy, setName } = useTableWorkspace({
    perPage: 25,
    page: 1,
    sortBy: "name",
    name: "",
  });
  const { isNotHaveContent, isNotFound, isLoad, setOffLoad, setOnLoad } =
    useTableStateLoad(data, query.name);
  const { checkedArray, clearChecked, toggleCheck, setCheckedArray } =
    useCheckedTableData("templatesChecked");
  const loadData = async () => {
    setOnLoad();
    setData([]);
    try {
      const list = await templatesService.list({
        sortBy: query.sortBy,
        page: query.page,
        perPage: query["per-page"],
        name: query.name,
      });

      setCountPage(list.headers["x-pagination-page-count"]);
      setData(list.data.data.templates);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  const changePaginationHandler = setPage;
  const changePerPageHandler = setPerPage;
  const changeSortByHandler = setSortBy;
  const checkedItemHandler = toggleCheck;

  const allCheckClickHandler = () => {
    if (checkedArray.length < data.length) {
      setCheckedArray(data.map((item) => item.id));
      return;
    }
    setCheckedArray([]);
  };

  const changeSearchString = debounce((e: string) => {
    setName(e);
  }, 300);

  const deleteCollectionsHandler = () => {
    dispatch(setModalData({ ids: checkedArray }));
    dispatch(openModal("delete-templates"));
  };

  const duplicateHandler = () => {
    if (checkedArray.length > 1) {
      onlyOne(createStandardToast("Дублировать можно один шаблон за раз"));
      return;
    }
    dispatch(setModalData({ id: checkedArray[0] }));
    dispatch(openModal("duplicate-template"));
  };

  useEffect(() => {
    return () => {
      clearChecked();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadData();
    //eslint-disable-next-line
  }, [query]);

  const checkedCount = checkedArray.length > 0 ? `${checkedArray.length} ` : "";

  if (isNotHaveContent) {
    return <NotContentTemplatesPage />;
  }

  return (
    <>
      <Content>
        <TableContainerScroll height={136}>
          <StickyTopContent>
            <ContactsHeaderRow>
              <Buttons>
                <ButtonTable
                  onClick={allCheckClickHandler}
                  iconId={"check-icon"}
                  isActive={checkedCount.length > 0}
                >{`${checkedCount}Выделить все`}</ButtonTable>
                {!!checkedArray.length && (
                  <ButtonTable
                    iconWidth={24}
                    iconHeight={24}
                    iconId={"delete-icon"}
                    onClick={deleteCollectionsHandler}
                  >
                    Удалить
                  </ButtonTable>
                )}
                {checkedArray.length > 0 && (
                  <ButtonTable
                    iconWidth={24}
                    iconHeight={24}
                    iconId={"duplicate-icon"}
                    onClick={duplicateHandler}
                  >
                    Дублировать шаблон
                  </ButtonTable>
                )}
              </Buttons>
            </ContactsHeaderRow>
            <HeadingLine>
              <Heading>Список шаблонов</Heading>
              <HeadingRightContent>
                <InputSearch
                  startIcon={{
                    id: "search-glass-icon",
                    width: 24,
                    height: 24,
                  }}
                  key={"searchTemplate"}
                  value={query.name}
                  placeholder={"Найти шаблон"}
                  onChange={(e) => {
                    changeSearchString(e.target.value);
                  }}
                />
                <Link link={"/templates/cards?tab=new"}>
                  <CreateButton>
                    <Icon
                      id={"plus-circle-icon"}
                      width={20}
                      height={20}
                      style={{ marginRight: 12 }}
                    />
                    Создать шаблон
                  </CreateButton>
                </Link>
              </HeadingRightContent>
            </HeadingLine>
            <TableTop>
              <TableHead>
                <FirstTH
                  name={"name"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("name")}
                >
                  Название
                </FirstTH>
                <TableHeadOnceFilter
                  name={"date"}
                  onClick={changeSortByHandler}
                  isActive={query.sortBy.includes("date")}
                >
                  Дата создания
                </TableHeadOnceFilter>
                <th />
                <th />
              </TableHead>
            </TableTop>
          </StickyTopContent>
          {isLoad && (
            <LoadWrapper>
              <LoadContent />
            </LoadWrapper>
          )}
          {!isLoad && (
            <Table>
              <TableBody>
                {data.map((item) => (
                  <TemplateTableContent
                    data={item}
                    key={item.id + item.name}
                    isActive={checkedArray.includes(item.id)}
                    onClickCheck={checkedItemHandler}
                  />
                ))}
              </TableBody>
            </Table>
          )}
          {isNotFound && <SearchNotFound />}
        </TableContainerScroll>
        <Footer>
          <ShowCount
            defaultValue={Number(query["per-page"])}
            onChange={changePerPageHandler}
          />
          {countPage > 1 && data.length > 0 && (
            <ContentPagination
              force={Number(query.page - 1)}
              onClick={changePaginationHandler}
              pageCount={countPage}
            />
          )}
        </Footer>
      </Content>
    </>
  );
};
