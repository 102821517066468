import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import type { MailingType } from "domain/mailing";
import { createDataForTable } from "lib/date";
import { useAppDispatch } from "services/hooks";
import { setDataTemplateView } from "services/store/templateViewSlice";
import { setModalData } from "services/store/modalDataSlice";
import { openModal } from "services/store/modal";
import { _variables } from "ui/styles/_variables";

import { Icon } from "../Icon";
import { Link } from "../Link";
import { Button } from "../Buttons/Button";

const Container = styled.tr<{ isActive: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      & td {
        background: ${_variables.backgroundColor};
      }
    `}
  &:hover {
    & td {
      background: ${_variables.backgroundColor};
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  position: relative;

  & .icon {
    transition: 0.1s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }
`;

const NameWrapper = styled.td`
  padding-left: 12px;
  display: flex;
  column-gap: 16px;
  height: 100%;
  align-items: center;
`;

const Name = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: calc(100% - 80px);
  column-gap: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;

  & span {
    display: inline-block;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 95%;

    & a {
      max-width: 100%;
    }
  }
`;

const DateWrapper = styled.td`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center;
`;
const Date = styled.div`
  display: flex;
  column-gap: 24px;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const Show = styled.td`
  display: flex;
  align-items: center;
`;
const ShowLink = styled(Link)`
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;

  align-items: center;
`;

const CreateMessages = styled.td`
  display: flex;
  align-items: center;
  column-gap: 24px;
`;

const Delete = styled.div`
  width: 24px;
  cursor: pointer;
  height: 24px;
`;

const ButtonMessage = styled(Button)`
  width: 164px;
  height: 40px;
  padding: 0;
`;

export const DraftTableContent: CT<{
  isActive: boolean;
  onClickCheck?: (id: number) => void;
  data: MailingType;
}> = ({ data, isActive }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const createHandler = () => {
    history.push(`/mailing/create-mailing?id=${data.id}`);
  };

  const viewTemplateHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();
    dispatch(setDataTemplateView({ mode: "desktop", id: data.template_id }));
  };

  const cancelHandler = () => {
    dispatch(setModalData({ id: data.id }));
    dispatch(openModal("delete-draft"));
  };

  return (
    <Container className={isActive ? "checked" : ""} isActive={isActive}>
      <NameWrapper>
        <IconWrapper>
          <Icon
            className={"icon"}
            id={"template-file-icon"}
            width={32}
            height={32}
          />
        </IconWrapper>
        <Name>
          <span>{data.name}</span>
        </Name>
      </NameWrapper>
      <DateWrapper className={"123"}>
        <Date>
          <span>{createDataForTable(data.date_start)}</span>
        </Date>
      </DateWrapper>
      <Show>
        <ShowLink link={""} onClick={viewTemplateHandler}>
          <Icon
            id={"eye-icon"}
            style={{ marginRight: 12 }}
            width={24}
            height={24}
          />
          Посмотреть
        </ShowLink>
      </Show>
      <CreateMessages>
        {data.can_repeat ? (
          <ButtonMessage onClick={createHandler} variant={"secondary"}>
            Продолжить
          </ButtonMessage>
        ) : (
          <div style={{ width: 112 }} />
        )}
        <Delete onClick={cancelHandler}>
          <Icon id={"cross-bold-icon"} height={24} width={24}></Icon>
        </Delete>
      </CreateMessages>
    </Container>
  );
};
