import axios from "axios";

import type { ServiceListPropTypes } from "domain/api";
import type { UserFormDomainType, UserFormListType } from "domain/userForm";
import type { CreateFormClientType } from "services/store/createFormClientSlice";

export const userFormCreateAdapter = (
  stateData: CreateFormClientType
): UserFormDomainType => {
  return {
    name: stateData?.name ?? "",
    book_id: stateData.bookId,
    is_active: true,
    type: stateData.format,
    settings: {
      setting: { ...stateData.setting },
      version: stateData.version,
      data: stateData.data,
    },
  };
};

export const userDomainDataStateAdapter = (
  data: UserFormDomainType
): CreateFormClientType => {
  return {
    name: data.name,
    data: data.settings.data,
    format: data.type,
    version: data.settings.version,
    bookId: data.book_id,
    step: 1,
    setting: data.settings.setting,
    bookName: data.bookName ?? "",
  };
};

export const userFormService = {
  path: "subscribe-form/",

  list({ page = 0, perPage = 25 }: ServiceListPropTypes<unknown>) {
    return axios.get<
      ApiResponseBodyType<{ "subscribe-forms": UserFormListType[] }>
    >(`${this.path}list?page=${page}&per-page=${perPage}`);
  },

  view(id: number) {
    return axios.get<
      ApiResponseBodyType<{ "subscribe-form": UserFormDomainType }>
    >(`${this.path}view?id=${id}&expand=settings`);
  },

  updateActive(id: number, val: boolean) {
    return axios.post<
      ApiResponseBodyType<{ "subscribe-form": UserFormListType }>
    >(`${this.path}update?id=${id}`, { is_active: val });
  },

  create(data: UserFormDomainType) {
    return axios.post<
      ApiResponseBodyType<{ "subscribe-form": UserFormListType }>
    >(this.path + "create", data);
  },

  update(id: number, data: UserFormDomainType) {
    return axios.post<
      ApiResponseBodyType<{ "subscribe-form": UserFormListType }>
    >(`${this.path}update?id=${id}`, data);
  },

  delete(id: number) {
    return axios.get(`${this.path}delete?id=${id}`);
  },
};
