import { css } from "styled-components";

import { _variables } from "../_variables";
import imgExit from "../../assets/media/icons/close-notify-icon.svg";

export const notifications = css`
  .notification {
    min-width: 470px;
  }
  .rnc__notification-item {
    border-left: 0px solid ${_variables.primaryColor};
    position: relative;
    background-color: #fdf1ed;
    min-width: 470px;
    border-radius: 8px;
    &:before {
      width: 4px;
      height: 100%;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      position: absolute;
      background: #e86e4d;
      border-radius: 8px 0px 0px 8px;
    }
  }
  .rnc__notification-content {
    padding: 16px;
    padding-left: 20px;
    padding-right: 37px;
  }
  .rnc__notification-close-mark {
    background-color: transparent !important;
    width: 12px !important;
    height: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 16px !important;
    right: 16px !important;
    &:after {
      content: "";
      background-repeat: no-repeat;
      width: 12px !important;
      height: 12px !important;
      background-size: contain;
      background-position: center center;
      background-image: url(${imgExit});
    }
  }
  .rnc__notification-message {
    font-family: "Inter";
    max-width: 650px;
    color: ${_variables.blackColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    & b {
      font-weight: 600;
    }
    & a {
      font-size: inherit !important;
    }
  }
`;
