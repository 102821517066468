import styled from "styled-components";

import { Link } from "../components/Link";
import { Icon } from "../components/Icon";

const Heading = styled.h1`
  font-family: "Inter";
  display: flex;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  column-gap: 8px;
  text-align: left;

  & a {
    font-size: inherit;
  }
`;

export const HeadingCrumbs: CT<{
  items: { title: string; link?: string }[];
}> = ({ items, className }) => {
  return (
    <Heading className={className}>
      {items.map((item, i, arr) => {
        return (
          <>
            {item.link ? (
              <Link link={item.link}>{item.title}</Link>
            ) : (
              <span>{item.title}</span>
            )}
            {i !== arr.length - 1 && (
              <Icon id={"arrow-right-icon"} width={24} height={24} />
            )}
          </>
        );
      })}
    </Heading>
  );
};
