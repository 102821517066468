import React from "react";
import { shallowEqual } from "react-redux";
import styled from "styled-components";

import { useImportStatus } from "app/importContacts/useImportStatus";
import { useLoadFile } from "app/useLoadFile";
import { contactService } from "services/contact";

import { ImportCounter } from "ui/components/ImportCounter";
import { CheckIcon } from "ui/components/Icons/CheckIcon";
import { ActionIcon } from "ui/components/Icons/ActionIcon";
import { ImportLineStatus } from "ui/components/ImportLineStatus";
import { Link } from "ui/components/Link";
import { _variables } from "ui/styles/_variables";
import { ButtonInvert } from "ui/components/Buttons/ButtonInvert";
import { Button } from "ui/components/Buttons/Button";
import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  saveUserMailingData,
  selectCJMSavedMailingUserData,
} from "services/store/cjm";
import { useImportPageState } from "app/importContacts/useImportPageState";
import { useHistory } from "react-router-dom";

const ButtonSubText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  color: ${_variables.grayColor};
`;

const ButtonDownload = styled(ButtonInvert)`
  display: flex;
  column-gap: 10px;
  width: 180px;
  height: 44px;
  & svg {
    width: 24px;
    height: 24px;
  }
`;

const IBottomRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 24px;
  margin-top: 32px;
`;

const ICounters = styled.div`
  display: flex;
  width: 100%;
  column-gap: 24px;
`;

const IButtonRow = styled.div`
  display: flex;
  column-gap: 16px;
  margin-top: 24px;
  align-items: center;
`;

const ButtonNext = styled(Button)`
  display: flex;
  column-gap: 10px;

  & svg {
    width: 24px;
    height: 24px;
  }
`;

export const MonitoringImportContent: CT<{ step: number; id: number }> =
  React.memo(({ step, id }) => {
    const dispatch = useAppDispatch();
    // Если пользователь попал на страницу с создания рассылки
    const { state } = useImportPageState();
    const history = useHistory();
    const mailingData = useAppSelector(selectCJMSavedMailingUserData);
    const { isDisableLoad, loadFile } = useLoadFile(
      contactService.errorImportCSV(Number(id)),
      "Отчет об ошибках.csv"
    );

    const { data } = useImportStatus(id);

    const createMailingHandler = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      e.preventDefault();
      e.stopPropagation();

      const { id: listId, count, name } = state.importData.list!;

      // Заполняем промежуточные данные между рассылкой и импортом
      // чтобы автоматически вставить поля
      dispatch(
        saveUserMailingData({
          firstStep: mailingData?.firstStep ?? null,
          secondStep: {
            listId,
            name,
            count: count + data.count_added,
          },
        })
      );
      if (!!mailingData?.firstStep) {
        history.push("/mailing/create-mailing?by=import");
        return;
      }
      history.push("/templates/cards?by=import");
    };

    return (
      <>
        {step > 3 && (
          <IBottomRow>
            <ImportLineStatus
              serverStatusText={data.statusName}
              status={data.status}
              percent={Number(data.percent)}
            />
            <ICounters>
              <ImportCounter
                counter={data.count_processed}
                title={"Обработали"}
              />
              <ImportCounter counter={data.count_added} title={"Добавили"} />
              <ImportCounter counter={data.count_updated} title={"Обновили"} />
              <ImportCounter counter={data.count_errors} title={"Ошибки"} />
            </ICounters>
          </IBottomRow>
        )}
        {step >= 5 && (
          <IButtonRow>
            <Link
              onClick={createMailingHandler}
              link={"/templates/cards?by=import"}
            >
              <ButtonNext>
                <CheckIcon variant={"glyph"} name={"check-mark"} />
                Создать рассылку
              </ButtonNext>
            </Link>
            <ButtonDownload disabled={isDisableLoad} onClick={loadFile}>
              <ActionIcon variant={"download"} name={"arrow-bottom"} />
              Скачать отчет
            </ButtonDownload>
            <ButtonSubText>
              В отчете можно увидеть все
              <br />
              незагруженные контакты и причину
            </ButtonSubText>
          </IButtonRow>
        )}
      </>
    );
  }, shallowEqual);
