const statusCatalog = {
  1: "Проверка",
  2: "Включен в отправку",
  3: "Ошибка",
};

export type DKIMType = {
  dkim: string;
  errors: string[];
  spf: string;
  dmarc: string;
};

export type DomainType = {
  domain: string;
  date_in: date_in;
  status: keyof typeof statusCatalog;
  count: number;
};

export const userDomainIsSuccess = (status: DomainType["status"]) =>
  status === 2;
export const userDomainInProgress = (status: DomainType["status"]) =>
  status === 1;
export const userDomainInError = (status: DomainType["status"]) => status === 3;
