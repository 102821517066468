import styled from "styled-components";

import { offExitConfirm } from "app/applicationExitHandler";
import type { TemplateType } from "domain/templates";
import { useAppDispatch } from "services/hooks";
import { setDataTemplateView } from "services/store/templateViewSlice";
import { _variables } from "ui/styles/_variables";

import { Icon } from "./Icon";
import { Link } from "./Link";

const Content = styled.div`
  display: flex;
  width: 426px;
  height: 356px;
  background-color: ${_variables.strokeColor};
  border: 1px solid ${_variables.strokeColor};
  border-radius: 12px;
  overflow-y: hidden;
  position: relative;
`;

const Image = styled.div`
  height: 332px;
  border-radius: 12px;
  overflow: hidden;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 378px;
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: 10;

  & img {
    z-index: 2;
    width: calc(100% + 2px);
    position: relative;
    left: -1px;
    height: auto;
    object-fit: contain;
  }
`;

const ContentShow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 13;
  display: flex;
  align-items: center;
  opacity: 1;
  justify-content: center;
  background-color: transparent;
  transition: opacity ${_variables.transition};

  &:hover {
    opacity: 1;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  height: 40px;
  width: 81px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${_variables.blackColor};
  align-items: center;
`;
const IconItem = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${_variables.whiteColor};
`;
const IconSeparator = styled.div`
  display: flex;
  width: 1px;
  height: 24px;
  background-color: ${_variables.blackColor};
`;

export const TemplateCardBigPreview: CT<{
  src: string;
  data: TemplateType;
  onSave: VoidFunction;
}> = ({ data, src, onSave }) => {
  const { id } = data;
  const dispath = useAppDispatch();

  const linkEdit = () => {
    if (data?.has_scheme) return `/templates/creator?id=${id}`;
    return `/templates/text-editor?id=${id}`;
  };

  const onClickShow = () => {
    dispath(setDataTemplateView({ mode: "desktop", id }));
  };

  return (
    <Content>
      <Image>
        <img src={src} alt="" />
      </Image>
      <ContentShow className={"content"}>
        <IconsWrapper>
          <Link
            onClick={(e) => {
              offExitConfirm();
              e.preventDefault();
              onSave();
              setTimeout(() => {
                document.location.href = linkEdit();
              }, 100);
            }}
            redirectMode={{ blank: false }}
            link={linkEdit()}
          >
            <IconItem>
              <Icon id={"edit-icon"} width={24} height={24} />
            </IconItem>
          </Link>
          <IconSeparator />
          <IconItem onClick={onClickShow}>
            <Icon id={"eye-icon"} width={24} height={24} />
          </IconItem>
        </IconsWrapper>
      </ContentShow>
    </Content>
  );
};
