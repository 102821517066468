import { useParams } from "react-router-dom";
import { useTitle } from "ahooks";
import styled from "styled-components";

import { ConfirmEmailForm } from "../containers/forms/ConfirmEmail";
import { appConfig } from "../../appConfig";
import { BackgroundPreview } from "../components/BackgroudPreview";

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ConfirmEmailPage = () => {
  const params = useParams<{ token: string }>();

  useTitle("Подтверждение email" + appConfig.titleApp);
  return (
    <BackgroundPreview>
      <FormWrapper>
        <ConfirmEmailForm token={params.token} />
      </FormWrapper>
    </BackgroundPreview>
  );
};
