import { format, parse } from "date-fns";
import { ru } from "date-fns/locale";

import {
  apiTimeFormat,
  buyPackageFormat,
  ideaFormat,
  ideaFormatComment,
  mailFormat1,
  newsFormat,
  tableTimeFormat1,
  tableTimeFormat2,
} from "domain/time";

export const removeTimezone = (str: string) => {
  if (str.split(" ").length < 3) return str;
  return [str.split(" ")[0], str.split(" ")[1]].join(" ");
};

export const createFormatDate = (
  str: string,
  inputFormat: string,
  outputFormat: string
) => {
  const dataString = removeTimezone(str);
  const parsedDate = parse(dataString, inputFormat, new Date());
  return format(parsedDate, outputFormat, { locale: ru });
};

export const dateSerialize = (date: Date) => {
  return JSON.stringify(date);
};

export const dateDeSerialize = (str: string) => {
  return new Date(JSON.parse(str));
};

export const createDataForTable = (str: string) => {
  return createFormatDate(str, apiTimeFormat, tableTimeFormat1);
};

export const createDataForTable2 = (str: string) => {
  return createFormatDate(str, apiTimeFormat, tableTimeFormat2);
};

export const createDateForMail = (str: string) => {
  return createFormatDate(str, apiTimeFormat, mailFormat1);
};

export const createDateForIdea = (str: string) => {
  return createFormatDate(str, apiTimeFormat, ideaFormat);
};

export const createDateForIdeaComment = (str: string) => {
  return createFormatDate(str, apiTimeFormat, ideaFormatComment);
};

export const createDateForNews = (str: string) => {
  return createFormatDate(str, apiTimeFormat, newsFormat);
};

export const createDateForBuyPackage = (str: string) => {
  return createFormatDate(str, apiTimeFormat, buyPackageFormat);
};
