import { planService } from "services/plan";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import { deepEqual } from "lib/other";
import { packagePlanActions } from "services/store/packagePlan";

export const useUserPlanList = () => {
  const userData = useAppSelector(selectUserData, deepEqual);
  const [hasBeenRequest, setRequest] = useState(false);
  const dispatch = useAppDispatch();
  // Получение планов пакетов для оплаты
  // Запись планов в стор
  // Запись в аналитику в зависимости от AB теста
  const getPackageList = async () => {
    try {
      const planData = await planService.list();
      dispatch(packagePlanActions.setData(planData.data.data));

      if (planData.data.data.ab === "cat_a") {
        window.dataLayer.push({ event: "optmz_cp_paywall_a" });
      }
      if (planData.data.data.ab === "cat_b") {
        window.dataLayer.push({ event: "optmz_cp_paywall_b" });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!userData || hasBeenRequest) return;
    setRequest(true);
    getPackageList();
  }, [userData]);
};
