import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import img from "ui/assets/media/images/moderation-preview.svg";

import { Button } from "../Buttons/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  align-self: center;
  width: 375px;
`;
const Preview = styled.div`
  width: 270px;
  height: 200px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const TextContent = styled.div`
  row-gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Heading = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.25px;
  color: ${_variables.grayTextColor};
  text-align: center;
`;
const Buttons = styled.div`
  display: flex;

  column-gap: 8px;
`;

export const MailingModeration: CT<{
  onClose: VoidFunction;
}> = ({ onClose }) => {
  return (
    <Container>
      <Preview>
        <img src={img} alt="" />
      </Preview>
      <TextContent>
        <Heading>Рассылка на проверке</Heading>
        <Text>
          Модерация осуществляется с 9 до 18 по МСК в будние дни, с 10 до 18 по
          МСК - в выходные. <br />
          Модерация в будние дни занимает 15-30 минут. <br />
          В выходные и праздничные дни модерация может занимать большее время.{" "}
          <br />
        </Text>
      </TextContent>
      <Buttons>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          На главную
        </Button>
      </Buttons>
    </Container>
  );
};
