import type { PagesType } from "services/store/pagesSlice";
import {
  clearCheckPageState,
  setCheckPageState,
  toggleCheckPageState,
} from "services/store/pagesSlice";
import { useAppDispatch, useAppSelector } from "services/hooks";

export const useCheckedTableData = (name: keyof PagesType) => {
  const checkedArray = useAppSelector((state) => state.pagesState[name]);
  const dispatch = useAppDispatch();

  const toggleCheck = (id: number | string) => {
    dispatch(
      toggleCheckPageState({
        id: Number(id),
        page: name,
      })
    );
  };
  const clearChecked = () => {
    dispatch(clearCheckPageState({ page: name }));
  };
  const setCheckedArray = (ids: (string | number)[]) => {
    dispatch(
      setCheckPageState({ page: name, ids: ids.map((item) => Number(item)) })
    );
  };

  return {
    checkedArray,
    toggleCheck,
    setCheckedArray,
    clearChecked,
  };
};
