import { useState } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import styled from "styled-components";

import { useMailingFilters } from "app/useMailingFilters";
import { RowTabs } from "ui/components/RowTabs";
import { TableContainerScroll } from "ui/containers/TableContainerScroll";
import { ShowCount } from "ui/components/ShowCount";
import { ContentPagination } from "ui/components/ContentPagination";
import { useTableWorkspace } from "ui/hooks/useTableWorkspace";
import {
  DraftContent,
  DraftHeading,
  HistoryContent,
  HistoryHeading,
  HistoryTopContent,
  HistoryTopHeading,
  UnsubscribeContent,
  UnsubscribeHeading,
} from "ui/pages/MailingPage/PageContents";
import {
  defaultPageQueryParams,
  pageTabsData,
  resetPageQueryParams,
} from "ui/pages/MailingPage/contants";

const IContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 40px 5px 12px 40px;
`;

const IStickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 16;
  background-color: white;
`;

const IFooter = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const ITop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
`;

export const MailingPage = () => {
  const [tabQuery, setTabQuery] = useUrlState({
    tab: defaultPageQueryParams.tab,
  });
  const [showTab, setShowTab] = useState(tabQuery.tab);

  const [countPage, setCountPage] = useState(0);

  const {
    query,
    setPerPage,
    setQuery,
    setName,
    setPage,
    setSortBy,
    resetFilters,
  } = useTableWorkspace({
    ...defaultPageQueryParams,
  });

  const { isFiltered, onResetFilters } = useMailingFilters({
    resetFilters,
  });

  const changePaginationHandler = setPage;
  const changePerPageHandler = setPerPage;
  const changeFindField = setName;
  const changeSortByHandler = setSortBy;

  const changeTabHandler = (valTab: string) => {
    setShowTab(valTab);
    setTabQuery({ tab: valTab });
    setCountPage(1);
    setQuery({ ...resetPageQueryParams[valTab] });
  };

  return (
    <IContent>
      <TableContainerScroll showScroll={true} height={150}>
        <IStickyTopContent>
          <ITop>
            {showTab === "history" && (
              <HistoryHeading
                value={query.name}
                onChangeSearch={changeFindField}
                isFiltered={isFiltered}
                onResetFilters={onResetFilters}
              />
            )}
            {showTab === "draft" && (
              <DraftHeading
                value={query.name}
                onChangeSearch={changeFindField}
              />
            )}
            {showTab === "top" && <HistoryTopHeading />}
            {showTab === "unsubscribe" && (
              <UnsubscribeHeading
                value={query.name}
                onChangeSearch={changeFindField}
              />
            )}
          </ITop>
          <RowTabs
            onClick={changeTabHandler}
            active={showTab}
            items={pageTabsData}
          />
        </IStickyTopContent>

        <>
          {showTab === "history" && (
            <HistoryContent
              onSetCountPage={setCountPage}
              query={{ ...query, perPage: query["per-page"] }}
              isFiltered={isFiltered}
            />
          )}
          {showTab === "draft" && (
            <DraftContent
              onChangeSortName={changeSortByHandler}
              onSetCountPage={setCountPage}
            />
          )}
          {showTab === "top" && <HistoryTopContent />}
          {showTab === "unsubscribe" && (
            <UnsubscribeContent
              onSetCountPage={setCountPage}
              onChangeSortName={changeSortByHandler}
            />
          )}
        </>
      </TableContainerScroll>
      {showTab !== "top" && (
        <IFooter>
          <ShowCount
            defaultValue={Number(query["per-page"])}
            onChange={changePerPageHandler}
          />
          {countPage > 1 && (
            <ContentPagination
              force={Number(query.page - 1)}
              onClick={changePaginationHandler}
              pageCount={countPage}
            />
          )}
        </IFooter>
      )}
    </IContent>
  );
};
