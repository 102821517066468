import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { setReadyPushMail } from "services/store/createMailingSlice";

import { Button } from "../Buttons/Button";
import img from "ui/assets/media/images/mailing-final-preview.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 432px;
`;
const Preview = styled.div`
  width: 432px;
  height: 211px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const TextContent = styled.div`
  row-gap: 12px;
  display: flex;
  flex-direction: column;
`;
const Heading = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.25px;
  text-align: left;
  opacity: 0.5;
`;
const Buttons = styled.div`
  display: flex;

  column-gap: 8px;
`;

export const MailingFinishSend: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector(selectModalData);
  return (
    <Container>
      <Preview>
        {" "}
        <img src={img} alt="" />
      </Preview>
      <TextContent>
        <Heading>Вы почти на финише!</Heading>
        <Text>
          Осталось только нажать на кнопку и отправить рассылку. Количество
          контактов, которые получат письмо: {modalData?.count ?? ""}
        </Text>
      </TextContent>
      <Buttons>
        <Button
          onClick={() => {
            dispatch(setReadyPushMail());
            onClose();
          }}
        >
          Отправить рассылку
        </Button>
        <Button onClick={onClose} variant={"secondary"}>
          Отменить
        </Button>
      </Buttons>
    </Container>
  );
};
