import { useCallback, useMemo } from "react";
import * as Yup from "yup";
import { format } from "date-fns";
import styled from "styled-components";
import { useFormik } from "formik";

import { datePickerFormat } from "domain/time";
import { Button } from "ui/components/Buttons/Button";
import { SortDateField } from "ui/components/FormsElements/SortDateField";
import { SelectCustom } from "ui/components/FormsElements/SelectCustom";
import _variables from "ui/styles/_variables";
import { ImportEmailChooseUserList } from "../specialFormElements/ImportEmailChooseUserList";
import { useHistory, useLocation } from "react-router";

type FormValuesType = {
  book_id?: string;
  status?: string;
  dateFrom?: string;
  dateTo?: string;
};

const statusValues = [
  {
    value: "4",
    name: "Отменено",
  },
  {
    value: "3",
    name: "Отправлено",
  },
  {
    value: "6",
    name: "Планируется",
  },
  {
    value: "2",
    name: "Выполняется",
  },
  {
    value: "7",
    name: "Не пройдена модерация",
  },
];

const newDate = new Date();

const intervals = {
  today: format(newDate, datePickerFormat),
  week: format(new Date(Date.now() - 604800000), datePickerFormat),
  month: format(new Date().setMonth(newDate.getMonth() - 1), datePickerFormat),
  quarter: format(
    new Date().setMonth(newDate.getMonth() - 3),
    datePickerFormat
  ),
};

const Root = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const Field = styled.div`
  background-color: ${_variables.backgroundColor};
  padding: 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const FieldName = styled.span`
  font-weight: 600;
  font-size: 14px;
`;

const IntervalWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const Interval = styled.div<{ isActive: boolean }>`
  padding: 8px 15px;
  margin: 4px 8px;
  border-radius: 58px;
  font-weight: 500;
  border: 1px solid ${_variables.blackColor};
  cursor: pointer;
  background-color: ${(props) =>
    props.isActive ? _variables.blackColor : "white"};
  color: ${(props) => (props.isActive ? "white" : _variables.blackColor)};
  &:hover {
    background-color: ${_variables.blackColor};
    color: white;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & button {
    width: 47%;
  }
`;

const MailingFilter = ({ onClose }: { onClose: () => void }) => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const queryParam = new URLSearchParams(search);

  const validationSchema = Yup.object().shape({
    book_id: Yup.string().optional(),
    status: Yup.string().optional(),
    dateFrom: Yup.string().optional(),
    dateTo: Yup.string().optional(),
  });

  const onSubmit = useCallback(
    (value: FormValuesType) => {
      Object.entries(value).forEach(([key, value]) => {
        if (value) queryParam.set(key, value);
      });
      history.replace(`${pathname}?${queryParam.toString()}`);
      onClose();
    },
    [history, pathname, queryParam, onClose]
  );

  const initialValues: FormValuesType = useMemo(
    () => ({
      book_id: queryParam.get("book_id") ?? undefined,
      status: queryParam.get("status") ?? undefined,
      dateFrom: queryParam.get("dateFrom") ?? undefined,
      dateTo: queryParam.get("dateTo") ?? intervals.today,
    }),
    [queryParam]
  );

  const setFromToday = () => {
    formik.setFieldValue("dateTo", intervals.today);
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema,
    validateOnMount: true,
    onSubmit,
  });

  return (
    <Root
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Field>
        <FieldName>По дате</FieldName>
        <SortDateField
          placeholder="От"
          onChangeDate={(date) =>
            formik.setFieldValue(
              "dateFrom",
              date ? format(date, datePickerFormat) : undefined
            )
          }
          value={formik.values.dateFrom}
        />
        <SortDateField
          placeholder="До"
          onChangeDate={(date) =>
            formik.setFieldValue(
              "dateTo",
              date ? format(date, datePickerFormat) : undefined
            )
          }
          value={formik.values.dateTo}
        />
      </Field>
      <Field>
        <FieldName>За промежуток</FieldName>
        <IntervalWrapper>
          <Interval
            isActive={formik.values.dateFrom === intervals.week}
            onClick={() => {
              formik.setFieldValue("dateFrom", intervals.week);
              setFromToday();
            }}
          >
            За 7 дней
          </Interval>
          <Interval
            isActive={formik.values.dateFrom === intervals.month}
            onClick={() => {
              formik.setFieldValue("dateFrom", intervals.month);
              setFromToday();
            }}
          >
            За последние 30 дней
          </Interval>
          <Interval
            isActive={formik.values.dateFrom === intervals.quarter}
            onClick={() => {
              formik.setFieldValue("dateFrom", intervals.quarter);
              setFromToday();
            }}
          >
            За квартал
          </Interval>
          <Interval
            isActive={!formik.values.dateFrom}
            onClick={() => {
              formik.setFieldValue("dateFrom", null);
              setFromToday();
            }}
          >
            С&nbsp;самого&nbsp;начала
          </Interval>
        </IntervalWrapper>
      </Field>
      <Field>
        <FieldName>По статусу рассылки</FieldName>
        <SelectCustom
          placeholder="Например: Выполняется"
          background="white"
          values={statusValues}
          onChange={(status) => {
            if (status.value !== "-1")
              formik.setFieldValue("status", status.value);
          }}
        />
      </Field>
      <Field>
        <FieldName>По списку</FieldName>
        <ImportEmailChooseUserList
          canAdd={false}
          initialData={{ count: 0, id: 0, name: "" }}
          onChange={(book) => {
            if (book.id !== 0) formik.setFieldValue("book_id", String(book.id));
          }}
          onBlur={() => {}}
          style={{ backgroundColor: "white" }}
        />
      </Field>
      <ButtonWrapper>
        <Button
          onClick={() => {
            history.replace(pathname);
            onClose();
          }}
          variant="secondary"
        >
          Сбросить
        </Button>
        <Button type="submit" variant="primary">
          Применить
        </Button>
      </ButtonWrapper>
    </Root>
  );
};

export default MailingFilter;
