import { useRef, useState } from "react";
import { Overlay } from "react-overlays";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import type { OverlayProps } from "react-overlays/Overlay";

import { animateHideShowTooltip, animateTransition } from "lib/animations";

const Wrapper = styled.div<{ isActive: boolean }>`
  ${(props) => {
    if (props.isActive)
      return css`
        & .rotate_active {
          transform: rotate(180deg);
        }
      `;
  }}
`;

const Container = styled.div`
  position: absolute;
  z-index: 99999;
`;

const AnimationBlok = styled(motion.div)`
  position: absolute;
  z-index: 99999;
`;

export const TooltipClicked: CT<{
  content: JSX.Element;
  placement?: OverlayProps["placement"];
  offset?: OverlayProps["offset"];
}> = ({
  content,
  className,
  placement = "auto",
  offset = [0, 0],
  children,
}) => {
  const [isOpen, setOpen] = useState(false);

  const triggerRef = useRef(null);
  const containerRef = useRef(null);

  return (
    <Wrapper isActive={isOpen} ref={containerRef}>
      <div ref={triggerRef} onClick={() => setOpen(true)}>
        {children}
      </div>
      <Overlay
        placement={placement}
        rootClose
        container={containerRef}
        show={isOpen}
        offset={offset}
        target={triggerRef}
        onHide={() => setOpen(false)}
      >
        {({ props }) => (
          <>
            {isOpen && (
              // @ts-ignore
              <Container className={className} {...props}>
                <AnimationBlok
                  {...animateTransition}
                  {...animateHideShowTooltip}
                  key="modal"
                >
                  <div>{content}</div>
                </AnimationBlok>
              </Container>
            )}
          </>
        )}
      </Overlay>
    </Wrapper>
  );
};
