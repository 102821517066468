import styled from "styled-components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  row-gap: 32px;
`;
export const LeftColumnContent: CT<unknown> = ({ children, className }) => {
  return <Content className={className}>{children}</Content>;
};
