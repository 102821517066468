import { useMemo } from "react";
import { useHistory, useLocation } from "react-router";

const filtersQueryName = ["book_id", "status", "dateFrom", "dateTo"];

type Props = {
  resetFilters: () => void;
};

export const useMailingFilters = ({ resetFilters }: Props) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);

  const filters = useMemo(
    () =>
      filtersQueryName.reduce((acum, param) => {
        const value = searchParams.get(param);
        if (!value) return acum;
        return {
          ...acum,
          [param]: searchParams.get(param),
        };
      }, {}),
    [searchParams]
  );

  const onResetFilters = () => {
    resetFilters();
    filtersQueryName.forEach((param) => searchParams.delete(param));
    history.replace(`${pathname}${searchParams.toString()}`);
  };

  return {
    isFiltered: !!Object.keys(filters).length,
    onResetFilters,
  };
};
