import { useState } from "react";
import DatePicker from "react-datepicker";
import { getHours, getMinutes, setHours, setMinutes } from "date-fns";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";
import { scroll } from "ui/styles/elements/scroll";

const Wrapper = styled.div<{
  isShowTimeCaption: boolean;
  isHideDisableSelect: boolean;
}>`
  width: fit-content;

  & .react-datepicker--time-only {
    border: 1px solid ${_variables.strokeColor};
    border-radius: 12px;
    overflow: hidden;
  }

  & .react-datepicker__time-list {
    margin-top: 0px !important;
    ${scroll}
  }

  & .react-datepicker__time-container {
    border: none;
    border-radius: 0;
  }

  & .react-datepicker__header {
    background-color: ${_variables.backgroundColor};
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    display: ${(props) =>
      props.isShowTimeCaption ? "block" : "none !important"};
  }

  & .react-datepicker-time__header {
    font-weight: 400;
  }

  & .react-datepicker__time-list-item {
    font-family: ${_variables.fontFamily};
    font-weight: 500;

    &:hover {
      background-color: ${_variables.strokeColor} !important;
    }
  }

  & .react-datepicker__time-list-item--selected {
    &:hover {
      background-color: ${_variables.primaryColor} !important;
    }

    background-color: ${_variables.primaryColor} !important;
  }

  & .react-datepicker__time-list-item--disabled {
    display: ${(props) => (props.isHideDisableSelect ? "none" : "block")};
  }
`;

/**
 *
 * @param todayMode - Взятие времени от текущей даты и от текущего времени
 * @param onSelectTime
 * @param value
 * @param timeCaption
 * @param intervalMinutes
 * @param minTime - Минимальное время в выборке, замещает todayMode
 * @param maxTime - Максимальное время в выборке
 * @param isHideDisableSelect - Скрывать те даты, что невозможно выбрать
 */
export const TimePicker: CT<{
  value: Date;
  todayMode?: boolean;
  onSelectTime?: (date: Date | null) => void;
  timeCaption?: string;
  intervalMinutes?: number;
  minTime?: Date;
  maxTime?: Date;
  isHideDisableSelect?: boolean;
}> = ({
  todayMode = false,
  onSelectTime,
  value,
  timeCaption = "Время",
  intervalMinutes = 15,
  minTime,
  maxTime,
  isHideDisableSelect = false,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date(value));

  const currentHours = todayMode ? getHours(new Date()) : 24;
  const currentMinutes = todayMode ? getMinutes(new Date()) : 0;

  return (
    <Wrapper
      isShowTimeCaption={false}
      isHideDisableSelect={isHideDisableSelect}
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();
      }}
    >
      <DatePicker
        inline={true}
        onChange={(date) => {
          setStartDate(date);
          onSelectTime && onSelectTime(date);
        }}
        selected={startDate}
        showTimeSelect
        minTime={
          minTime ??
          setHours(setMinutes(new Date(), currentMinutes), currentHours)
        }
        maxTime={maxTime ?? setHours(setMinutes(new Date(), 45), 23)}
        showTimeSelectOnly
        timeIntervals={intervalMinutes}
        timeCaption={timeCaption}
        dateFormat="HH:mm"
        timeFormat={"HH:mm"}
      />
    </Wrapper>
  );
};
