import styled from "styled-components";

import { ShadowButton } from "../Buttons/ShadowButton";
import { Icon } from "../Icon";
import { Link } from "../Link";

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 24px;
`;

export const NotContentTemplateCardPage: CT<unknown> = ({ className }) => {
  return (
    <Content className={className}>
      <Text>У вас пока нет шаблонов</Text>
      <Link link={"/templates/cards?tab=new"}>
        <ShadowButton>
          <Icon
            id={"plus-icon"}
            height={16}
            width={16}
            style={{ marginRight: 8 }}
          />
          Создать шаблон
        </ShadowButton>
      </Link>
    </Content>
  );
};
