import { useEffect } from "react";
import styled from "styled-components";

import { useAppDispatch } from "services/hooks";
import { serviceUser } from "services/user";
import { clearAppError } from "services/store/appSlice";

import { LoadDots } from "../../components/LoadDots";

const Content = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoutForm = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearAppError());
    serviceUser.clearToken();
    dispatch(clearAppError());
    setTimeout(() => {
      document.location.pathname = "/login";
    }, 250);
  }, []);
  return (
    <Content>
      <LoadDots />
    </Content>
  );
};
