import { useEffect, useState } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import styled from "styled-components";

import { RowTabs } from "../../components/RowTabs";
import { TableContainerScroll } from "../../containers/TableContainerScroll";
import { ShowCount } from "../../components/ShowCount";
import { ContentPagination } from "../../components/ContentPagination";
import { useTableWorkspace } from "../../hooks/useTableWorkspace";
import { NotContentMailingPage } from "../../components/NotContent/NotContentMailingPage";
import { SettingPageContent, SettingPageHeading } from "./SettingPage";
import { EmailPageContent, EmailsPageHeader } from "./EmailsPage";
import { DomainsPageContent, DomainsPageHeader } from "./DomainsPage";
import { Link } from "../../components/Link";

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 40px 5px 12px 0px;
`;
const StickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  padding-left: 40px;
  z-index: 26;
  background-color: white;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding-left: 40px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
`;

const initialTab = {
  tab: "history",
  perPage: 25,
  page: 1,
  sortBy: "email",
};

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
`;
const LinkDomain = styled(Link)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`;

export const AccountPage = () => {
  const [tabQuery, setTabQuery] = useUrlState({
    tab: initialTab.tab,
  });
  const [countPage, setCountPage] = useState(0);
  const { query, setPerPage, setQuery, setSortBy, setName, setPage } =
    useTableWorkspace({
      perPage: initialTab.perPage,
      page: initialTab.page,
      sortBy: initialTab.sortBy,
      name: "",
    });
  const [showTab, setShowTab] = useState(tabQuery.tab);
  const [notContent] = useState(false);
  const changePaginationHandler = setPage;
  const changePerPageHandler = setPerPage;
  const changeFindField = setName;

  useEffect(() => {
    setQuery({
      name: undefined,
      "per-page": 25,
      page: 1,
    });
    setCountPage(0);
    setShowTab(tabQuery.tab);
  }, [tabQuery.tab]);

  if (notContent) return <NotContentMailingPage />;

  return (
    <Content>
      <TableContainerScroll showScroll={true} height={150}>
        <StickyTopContent>
          <Top>
            {showTab === "account" && <SettingPageHeading />}
            {showTab === "emails" && (
              <EmailsPageHeader
                value={query.name}
                onChangeSearch={changeFindField}
              />
            )}
            {showTab === "domains" && (
              <DomainsPageHeader
                value={query.name}
                onChangeSearch={changeFindField}
              />
            )}
          </Top>
          <Row>
            <RowTabs
              onClick={(val) => {
                setTabQuery({ tab: val });
              }}
              active={showTab}
              items={[
                { name: "Настройки аккаунта", value: "account" },
                { name: "Подтвержденные email", value: "emails" },
                { name: "Проверка домена", value: "domains" },
              ]}
            />
            {showTab === "domains" && (
              <LinkDomain
                link={
                  "https://cheapsender.notion.site/036bfaa129ed46f38feb1df56d66b4fe"
                }
                color={"gray"}
                redirectMode={{ blank: true }}
              >
                Как настроить домен?
              </LinkDomain>
            )}
          </Row>
        </StickyTopContent>

        <>
          {showTab === "account" && <SettingPageContent />}
          {showTab === "emails" && (
            <EmailPageContent
              onChangeSortBy={setSortBy}
              onSetCountPage={setCountPage}
              query={{ ...query, perPage: query["per-page"] }}
            />
          )}
          {showTab === "domains" && (
            <DomainsPageContent
              onChangeSortBy={setSortBy}
              onSetCountPage={setCountPage}
              query={{ ...query, perPage: query["per-page"] }}
            />
          )}
        </>
      </TableContainerScroll>
      {showTab !== "account" && (
        <Footer>
          <ShowCount
            defaultValue={Number(query["per-page"])}
            onChange={changePerPageHandler}
          />
          {countPage > 1 && (
            <ContentPagination
              force={Number(query.page - 1)}
              onClick={changePaginationHandler}
              pageCount={countPage}
            />
          )}
        </Footer>
      )}
    </Content>
  );
};
