import { Redirect } from "react-router-dom";

import { useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import { deepEqual } from "lib/other";

export const ProtectedRoute: CT<unknown> = ({ children }) => {
  const user = useAppSelector(selectUserData, deepEqual);
  return user ? <>{children}</> : <Redirect to={"/login"} />;
};
