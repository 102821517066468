import styled from "styled-components";

import { getFormatNum } from "lib/numbers";
import { _variables } from "ui/styles/_variables";
import apiIcon from "ui/assets/media/icons/statistic-api-icon.svg";

const Container = styled.div<{ haveCount: boolean }>`
  display: flex;
  column-gap: 12px;
  min-width: 145px;
`;
const Image = styled.img`
  width: 52px;
  height: 52px;
`;
const TextContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const SubHeading = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;
const Percent = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;
const Count = styled(Percent)``;

type variantType = "api";

const icons = {
  api: apiIcon,
};

const subHeadings = {
  api: "Вызовы API",
};

export const StatisticApiCount: CT<{
  variant: variantType;
  count: number;
}> = ({ variant, children, count }) => {
  return (
    <Container haveCount={!!count}>
      <Image src={icons[variant]}>{children}</Image>
      <TextContent>
        <SubHeading>{subHeadings[variant]}</SubHeading>
        {/*<Percent className={"statistic__percent"}>{percent}%</Percent>*/}
        <Count className={"statistic__count"}>{getFormatNum(count)}</Count>
      </TextContent>
    </Container>
  );
};
