import useUrlState from "@ahooksjs/use-url-state";

import type { MailingFilters } from "domain/api";

import { getToggledNameFilter } from "lib/sorting";

export const useTableWorkspace = ({
  perPage = 25,
  page = 25,
  sortBy,
  name = "",
  book_id,
  status,
  dateFrom,
  dateTo,
}: {
  perPage: number;
  page: number;
  sortBy?: string;
  name?: string;
} & MailingFilters) => {
  const [query, setQuery] = useUrlState({
    sortBy,
    page,
    "per-page": perPage,
    name,
    ...(book_id ? { book_id } : null),
    ...(status ? { status } : null),
    ...(dateFrom ? { dateFrom } : null),
    ...(dateTo ? { dateTo } : null),
  });

  const setPage = (page: string | number) => {
    setQuery({ ...query, page: page });
  };

  const setPerPage = (num: number) => {
    setQuery({ ...query, "per-page": num, page: 1 });
  };

  const setSortBy = (name: string) => {
    if (query.sortBy.includes(name)) {
      setQuery({ sortBy: getToggledNameFilter(query.sortBy) });
      return;
    }
    setQuery({ sortBy: name });
  };
  //@ts-ignore
  const resetQuery = (sortBy? = "name", pepPage? = 25, page? = 1) => {
    setQuery({ sortBy, "per-page": pepPage, page });
  };

  const setName = (name: string) => {
    setQuery({ ...query, name: name });
  };

  const resetName = () => {
    setQuery({ ...query, name: "" });
  };

  const resetFilters = () => {
    const { book_id, status, dateFrom, dateTo, ...rest } = query;
    setQuery({ ...rest });
  };

  return {
    query,
    setPerPage,
    setQuery,
    resetQuery,
    setPage,
    setSortBy,
    setName,
    resetName,
    resetFilters,
  };
};
