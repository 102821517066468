import { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { createToastResponseError } from "lib/toastify";
import { updateQueryParams } from "lib/queryParams";
import { contactsListService } from "services/list";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { clearCheckPageState } from "services/store/pagesSlice";

import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";
import { ModalTextContainer } from "../../components/Modals/ModalTextContainer";
import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";
import { Button } from "../../components/Buttons/Button";
import styled from "styled-components";
import { Input } from "ui/components/FormsElements/Input";

const Lists = styled.p`
  overflow-wrap: break-word;
`;

const Form = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const MergeLists: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const modalData = useAppSelector(selectModalData);
  const [isLoad, setLoad] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [name, setName] = useState("");

  const mergeHandler = async () => {
    if (!modalData?.ids?.length ?? null) {
      toast("id не найдены", {
        type: "warning",
        autoClose: 3000,
      });
      onClose();
    }
    try {
      setLoad(true);
      const response = await contactsListService.merge(modalData!.ids, name);
      toast(response.data.message, { autoClose: 3000 });
      dispatch(clearCheckPageState({ page: "listChecked" }));
      updateQueryParams(history, {
        page: "1",
      });
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      onClose();
    }
  };

  return (
    <ModalDefaultFormContainer>
      <ModalTextContainer>
        <Lists>{modalData?.names?.join(", ") ?? ""}</Lists>
      </ModalTextContainer>
      <Form>
        <Input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          label={"Название списка"}
          placeholder={"Введите название списка"}
        />
      </Form>
      <ModalButtonsContainer>
        <Button
          disabled={name.length === 0}
          onClick={mergeHandler}
          isLoad={isLoad}
          type={"submit"}
        >
          Подтвердить
        </Button>
        <Button onClick={onClose} type={"submit"} variant={"secondary"}>
          Отмена
        </Button>
      </ModalButtonsContainer>
    </ModalDefaultFormContainer>
  );
};
