import { useCallback, useEffect, useState } from "react";

import { serviceIdea } from "services/idea";

type useLikeProps = {
  isLikedInitial: boolean;
  initialLikeCount: number;
};

type useLikeResult = {
  isLiked: boolean;
  currentLikeCount: number;
  onLike: (id: number) => void;
};

export const useLike = ({
  isLikedInitial,
  initialLikeCount,
}: useLikeProps): useLikeResult => {
  const [isLiked, setIsLiked] = useState<boolean>(isLikedInitial);
  const [currentLikeCount, setCurrentLikeCount] =
    useState<number>(initialLikeCount);
  useEffect(() => {
    setCurrentLikeCount(initialLikeCount);
    setIsLiked(isLikedInitial);
  }, [initialLikeCount, isLikedInitial]);

  const onLike = useCallback(
    (id: number) => {
      if (isLiked) {
        serviceIdea.unLike(id).then(() => {
          setIsLiked(false);
          setCurrentLikeCount((prev) => prev - 1);
        });
      } else {
        serviceIdea.like(id).then(() => {
          setIsLiked(true);
          setCurrentLikeCount((prev) => prev + 1);
        });
      }
    },
    [isLiked, currentLikeCount]
  );

  return {
    isLiked,
    currentLikeCount,
    onLike,
  };
};
