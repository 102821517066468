import type { ButtonHTMLAttributes } from "react";
import React from "react";
import styled, { css } from "styled-components";

import $ from "ui/styles/_variables";
import _variables from "ui/styles/_variables";

import { LoadDots } from "../LoadDots";

type ColorType = "primary" | "secondary" | "black" | "grey" | "white";
type SizeType = "normal" | "small";

const buttonColor = {
  white: {
    backgroundColor: "white",
    color: $.blackColor,
    hoverBgc: "white",
    hoverColor: "white",
    borderColor: "white",
    load: "white",
  },
  primary: {
    backgroundColor: $.primaryColor,
    color: $.whiteColor,
    hoverBgc: $.secondaryColor,
    hoverColor: $.whiteColor,
    borderColor: $.primaryColor,
    load: "white",
  },
  secondary: {
    backgroundColor: $.whiteColor,
    color: $.blackColor,
    hoverBgc: $.backgroundColor,
    hoverColor: $.blackColor,
    borderColor: $.strokeColor,
    load: "black",
  },
  black: {
    backgroundColor: $.blackColor,
    color: $.whiteColor,
    hoverBgc: $.blackColor,
    hoverColor: $.whiteColor,
    borderColor: $.blackColor,
    load: "white",
  },
  grey: {
    backgroundColor: $.backgroundColor,
    color: $.blackColor,
    hoverBgc: $.strokeColor,
    hoverColor: $.blackColor,
    borderColor: $.backgroundColor,
    load: "white",
  },
};

const size = {
  normal: {
    fz: "13px",
    padding: "14px 23px",
    fontWeight: 600,
    height: "44px",
    minWidth: "auto",
    letterSpacing: "-0.25px",
  },
  small: {
    fz: "13px",
    padding: "12px 23px",
    fontWeight: 600,
    height: "40px",
    minWidth: "auto",
    letterSpacing: "-0.25px",
  },
};
const ButtonComponent = styled.button<{
  typeColor: ColorType;
  size: SizeType;
  widthMode?: "full" | "content";
  isLoad?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  padding: ${(props) => size[props.size]["padding"]};
  ${(props) => props.widthMode === "full" && "padding-left:0"};
  ${(props) => props.widthMode === "full" && "padding-right:0"};
  border-radius: ${$.borderRadius};
  outline: none;
  font-family: ${_variables.fontFamily};
  cursor: pointer;
  line-height: 18px;
  min-width: ${(props) =>
    props.widthMode === "content" ? size[props.size]["minWidth"] : "auto"};
  width: ${(props) => (props.widthMode === "full" ? "100%" : "fit-content")};
  border: none;
  height: ${(props) => size[props.size]["height"]};
  font-size: ${(props) => size[props.size]["fz"]};
  background-color: ${(props) =>
    buttonColor[props.typeColor]["backgroundColor"]};
  font-weight: ${(props) => size[props.size]["fontWeight"]};
  color: ${(props) => buttonColor[props.typeColor]["color"]};
  border: ${(props) =>
    `1px solid ${buttonColor[props.typeColor]["borderColor"]}`};
  transition: all ${$.transition};

  &:hover {
    background-color: ${(props) => buttonColor[props.typeColor]["hoverBgc"]};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: ${(props) =>
        buttonColor[props.typeColor]["backgroundColor"]};
    }
  }

  ${(props) => {
    if (props.isLoad) {
      return css`
        &:disabled {
          opacity: 1;
        }
      `;
    }
  }}
`;

export const Button: CT<
  {
    variant?: ColorType;
    size?: SizeType;
    widthMode?: "full" | "content";
    isLoad?: boolean;
    disabled?: boolean;
  } & ButtonHTMLAttributes<unknown>
> = React.memo(
  ({
    widthMode = "content",
    variant = "primary",
    className = "",
    children,
    disabled = false,
    isLoad = false,
    size = "normal",

    ...rest
  }) => {
    return (
      <ButtonComponent
        size={size}
        className={className}
        typeColor={variant}
        disabled={disabled || isLoad}
        widthMode={widthMode}
        isLoad={isLoad}
        {...rest}
      >
        {isLoad ? (
          // @ts-ignore
          <LoadDots color={buttonColor![variant]!["load"]!} />
        ) : (
          <>{children}</>
        )}
      </ButtonComponent>
    );
  }
);
