import styled from "styled-components";

import { getTwoFirstChar } from "lib/string";
import { _variables } from "ui/styles/_variables";

const Figure = styled.figure<{ width: number; height: number }>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const NotImage = styled.div<{ width: number; height: number }>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  border-radius: 50%;
  overflow: hidden;
  background-color: ${_variables.blackColor};
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const Avatar: CT<{
  src: string;
  height?: number;
  width?: number;
  alt?: string;
}> = ({ src, height = 24, width = 24, alt = undefined, className }) => {
  if (!src || src.length === 0)
    return (
      <NotImage title={alt} width={width} height={height}>
        {getTwoFirstChar(alt ?? "Аноним")}
      </NotImage>
    );
  return (
    <Figure width={width} height={height} className={className}>
      <img src={src} alt={alt} />
    </Figure>
  );
};
