import type { SortValue } from "./types";

export const sortValues: SortValue[] = [
  {
    name: "Сначала новые",
    value: "-id",
    isDefaultChecked: true,
  },
  {
    name: "Сначала старые",
    value: "id",
    isDefaultChecked: false,
  },
  {
    name: "Самые популярные",
    value: "-like_count",
    isDefaultChecked: false,
  },
  {
    name: "Самые обсуждаемые",
    value: "-comment_count",
    isDefaultChecked: false,
  },
];
