import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { isBadBodyStatusResponse } from "domain/api";
import styled from "styled-components";

import { createToastResponseError } from "lib/toastify";
import { serviceUser } from "services/user";
import { useAppDispatch } from "services/hooks";
import { setUserData } from "services/store/userSlice";

import { Input } from "../../components/FormsElements/Input";
import { Link } from "../../components/Link";
import { Button } from "../../components/Buttons/Button";
import { isDev } from "../../../appConfig";
import { InputPassword } from "ui/components/FormsElements/InputPassword";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 320px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: flex-start;
`;

const ForgotPasswordText = styled.p`
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: left;
`;

export const LoginForm = () => {
  const [isLoad, setLoad] = useState(false);
  const [apiStatus, setApiStatus] =
    useState<ApiResponseBodyType<unknown> | null>(null);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Поле обязательно для заполнения")
      .email("Необходимо ввести корректный email"),
    password: Yup.string().required("Поле обязательно для заполнения"),
  });

  const submitHandler = async (data: { email: string; password: string }) => {
    setLoad(true);
    try {
      const request = await serviceUser.login(data);
      setApiStatus(request.data);
      serviceUser.setToken(request.data.data.user.jwt);
      const user = await serviceUser.profile();
      if (!isDev()) {
        window.carrotquest.track("$authorized", {
          $email: user.data.data.user.email,
          $name: user.data.data.user.name,
        });
      }
      dispatch(
        setUserData({
          ideaCount: user.data.data.ideaCount,
          newsCount: user.data.data.newsCount,
          ...user.data.data.profile,
          ...user.data.data.user,
        })
      );
      history.push("/main/");
    } catch (e) {
      setApiStatus(e.response.data);
      createToastResponseError(e)();
      setLoad(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnChange: false,
    onSubmit: submitHandler,
    validationSchema: validationSchema,
  });

  const setFormError = () => {
    formik.dirty = true;
    formik.setFieldError("email", " ");
    formik.setFieldError("password", " ");
  };

  useEffect(() => {
    if (!apiStatus) return;
    if (isBadBodyStatusResponse(apiStatus)) setFormError();
    //eslint-disable-next-line
  }, [apiStatus]);

  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Input
          type={"text"}
          onChange={formik.handleChange}
          value={formik.values.email}
          name={"email"}
          placeholder={"Email"}
          error={formik.errors.email}
        />

        <InputPassword
          handleChange={formik.handleChange}
          error={formik.errors.password}
          value={formik.values.password}
          name="password"
          placeholder="Пароль"
        />

        <ButtonWrapper>
          <Button
            style={{ marginTop: 8 }}
            size={"normal"}
            widthMode={"full"}
            isLoad={isLoad}
            type={"submit"}
          >
            Вход
          </Button>
          <ForgotPasswordText>
            Забыли пароль?{" "}
            <Link
              redirectMode={{ blank: false }}
              color={"primary"}
              link="/password/restore"
            >
              Восстановить
            </Link>
          </ForgotPasswordText>
        </ButtonWrapper>
      </Form>
    </>
  );
};
