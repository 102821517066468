import axios from "axios";

import type { NewsDomainType } from "domain/news";
import type { ServiceListPropTypes } from "domain/api";

export const newsService = {
  path: "news/",

  view(id: number | string) {
    return axios.get<ApiResponseBodyType<{ news: NewsDomainType }>>(
      `${this.path}view?id=${id}`
    );
  },

  listNews({ page, perPage }: ServiceListPropTypes<unknown>) {
    return axios.get<ApiResponseBodyType<{ news: NewsDomainType[] }>>(
      `${this.path}index?per-page=${perPage}&page=${page}`
    );
  },

  getNotViewCount() {
    return axios.get(`${this.path}not-view-count`);
  },

  like(id: number) {
    return axios.post(`${this.path}like?id=${id}`);
  },

  unLike(id: number) {
    return axios.post(`${this.path}unlike?id=${id}`);
  },
};
