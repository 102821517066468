import styled from "styled-components";
import { Button } from "ui/components/Buttons/Button";

const Container = styled.div`
  display: flex;
  min-width: 550px;
  height: 116px;
  overflow-y: hidden;
  background-color: #06112f;
  border-radius: 12px;
  padding: 16px 16px;
  color: white;
  white-space: pre-line;
  position: relative;
  z-index: 3;
  & p {
    z-index: 2;
  }
  &:before {
    content: "";
    display: block;
    height: 10px;
    bottom: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    background-color: #06112f;
    border-radius: 12px;
    position: absolute;
  }
`;

const CopyButton = styled(Button)`
  display: flex;
  position: absolute;
  bottom: 8px;
  z-index: 4;
  right: 10px;
  height: 36px;
  width: 133px;
  font-weight: 500;
`;

export const CodeBlock: CT<{
  text: string;
  disabled?: boolean;
  onClickCopyButton: VoidFunction;
  height?: number;
}> = ({ text, onClickCopyButton, disabled = false, height = 116 }) => {
  return (
    <Container style={{ height: height }}>
      <p>{text}</p>
      <CopyButton
        onClick={onClickCopyButton}
        disabled={disabled}
        variant={"white"}
      >
        Скопировать код
      </CopyButton>
    </Container>
  );
};
