import type { UnlayerOptions } from "./types";

type createOptionsProps = Partial<UnlayerOptions>;

export const createOptions = (options?: createOptionsProps): UnlayerOptions => {
  return {
    ...options,
    id: options?.id ?? "id-wrapper",
    projectId: options?.projectId ?? 74964,
    safeHtml: true,
    displayMode: options?.displayMode ?? "popup",
    features: options?.features ?? {
      preview: false,
      imageEditor: true,
      stockImages: true,
      undoRedo: true,
    },
    locale: "ru",
  };
};

export const defaultTools = {
  text: {
    properties: {
      text: {
        value:
          '<span style="font-size: 14px; line-height: 19.6px;">Текст</span>',
      },
    },
  },
  button: {
    properties: {
      text: {
        value:
          '<span style="font-size: 14px; line-height: 19.6px;">Кнопка</span>',
      },
    },
  },
  html: {
    properties: {
      html: {
        value: "<b>Hello world</b>",
      },
    },
  },
};
