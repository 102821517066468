import { useEffect } from "react";
import axios from "axios";
import { shallowEqual } from "react-redux";

import { useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import { serviceUser } from "services/user";

import { appConfig } from "../../../appConfig";

export const useGlobalRequestConfiguration = () => {
  const userData = useAppSelector(selectUserData, shallowEqual);

  useEffect(() => {
    axios.defaults.baseURL = appConfig.apiUrl;
    if (userData) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${serviceUser.getToken()}`;
    }
    //eslint-disable-next-line
  });
};
