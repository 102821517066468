import { css } from "styled-components";

export const scroll = css`
  &::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #e9ebef; /* color of the tracking area */
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #898d9a; /* creates padding around scroll thumb */
  }

  &.hidden {
    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
`;
