import { useState } from "react";
import styled from "styled-components";
import { serviceUser } from "services/user";
import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import {
  createStandardResponseToast,
  createToastResponseError,
} from "lib/toastify";
import { Button } from "ui/components/Buttons/Button";
import { ModalDefaultFormContainer } from "ui/components/Modals/ModalDefaultFormContainer";
import { updateQueryParams } from "lib/queryParams";
import { useHistory } from "react-router-dom";

const Container = styled(ModalDefaultFormContainer)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 10px;
  column-gap: 8px;
`;

const ButtonPay = styled(Button)`
  width: 101px;
`;

export const UnsubscribeCardForm: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const modalData = useAppSelector(selectModalData);
  const [isLoad, setLoad] = useState(false);
  const history = useHistory();

  const deleteCardHandler = async () => {
    setLoad(true);
    try {
      const response = await serviceUser.deletePaymentCard(modalData!.idCard);
      createStandardResponseToast(response.data)();
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setTimeout(() => {
        setLoad(false);
        updateQueryParams(history, {});
        onClose();
      }, 100);
    }
  };

  return (
    <Container>
      <ButtonRow>
        <ButtonPay
          disabled={isLoad}
          isLoad={isLoad}
          onClick={deleteCardHandler}
        >
          Отвязать
        </ButtonPay>
        <Button onClick={onClose} variant={"secondary"}>
          Отмена
        </Button>
      </ButtonRow>
    </Container>
  );
};
