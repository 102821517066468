import { CKEditor } from "ckeditor4-react";
import { appConfig } from "../../../../appConfig";

const config = {
  fullPage: true,
};

export const CK: CT<{
  value: string;
  setValue: (value: string) => void;
}> = ({ value, setValue }) => {
  return (
    <CKEditor
      initData={value}
      editorUrl={appConfig.staticCKEditorPath}
      config={config}
      onChange={(payload) => setValue(payload.editor.getData())}
      onBlur={(payload) => setValue(payload.editor.getData())}
    />
  );
};
