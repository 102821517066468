import { useEffect } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import styled from "styled-components";

import { serviceUser } from "services/user";
import catGif from "ui/assets/media/images/cat-space.gif";

import { BackgroundPreview } from "../components/BackgroudPreview";
import { createToastResponseError } from "lib/toastify";

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
`;

export const AdminLoginPage = () => {
  const [urlState] = useUrlState({
    token: "",
  });

  useEffect(() => {
    (async () => {
      if (!urlState.token) document.location.pathname = "/";
      try {
        serviceUser.setToken(urlState.token);
        setTimeout(async () => {
          document.location.pathname = "/";
        }, 1000);
      } catch (e) {
        createToastResponseError(e)();
      }
    })();
  }, []);

  return (
    <BackgroundPreview>
      <FormWrapper>
        <img src={catGif} alt="" />
      </FormWrapper>
    </BackgroundPreview>
  );
};
