import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

import { Input } from "../FormsElements/Input";
import { InputAddress } from "../../containers/specialFormElements/InputAddress";
import { Icon } from "../Icon";

const Container = styled.div`
  background-color: ${_variables.backgroundColor};
  border-radius: 12px;
  padding: 24px 32px 32px 32px;
  width: 100%;
  position: relative;
  z-index: 115;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
const TextData = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: flex-start;
`;

const Name = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  width: 135px;
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  max-width: 70%;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
`;
const EditData = styled.form`
  display: flex;
  row-gap: 20px;
  flex-direction: column;
`;

const HeadingWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
`;

const EditButton = styled.button`
  display: flex;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;

  border: none;
  column-gap: 8px;
  outline: none;
  height: 40px;
  transition: background-color ${_variables.transition};
  border-radius: 8px;
  padding: 0 8px;
  align-items: center;
  &:hover {
    background-color: ${_variables.backgroundColor};
  }
  & svg {
    color: ${_variables.grayTextColor};
  }
`;

const validationSchema = Yup.object().shape({
  address: Yup.string()
    .email("Необходимо ввести валидный email")
    .required("Поле обязательно для заполнения"),
  theme: Yup.string().required("Поле обязательно для заполнения"),
  name: Yup.string().required("Поле обязательно для заполнения"),
});

export const CreateMailingInfoForm: CT<{
  initialData: {
    theme: string;
    name: string;
    address: string;
  };
  onChangeData: (data: {
    theme: string;
    name: string;
    address: string;
    isValid: boolean;
  }) => void;
}> = ({ onChangeData, initialData }) => {
  const [mode, setMode] = useState<"show" | "edit">("show");

  const values = {
    theme: initialData?.theme ?? "",
    name: initialData.name,
    address: initialData.address,
  };

  const formik = useFormik({
    initialValues: values,
    onSubmit: () => {},
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema,
  });

  useEffect(() => {
    onChangeData({
      theme: formik.values.theme,
      name: formik.values.name,
      isValid: formik.isValid,
      address: formik.values.address,
    });
  }, [formik.values, formik.errors]);

  return (
    <Container>
      <HeadingWrapper>
        <Heading>Название темы и email</Heading>
        {mode === "show" && (
          <EditButton
            onClick={() => {
              setMode("edit");
            }}
          >
            <Icon id={"edit-table-icon"} height={24} width={24} />
            Редактировать
          </EditButton>
        )}
      </HeadingWrapper>
      {mode === "show" && (
        <Data>
          <TextData>
            <Name>Тема письма</Name>
            <Text>{formik.values.theme}</Text>
          </TextData>
          <TextData>
            <Name>От кого</Name>
            <Text> {formik.values.name}</Text>
          </TextData>
          <TextData>
            <Name>Адрес отправителя</Name>
            <Text> {formik.values.address}</Text>
          </TextData>
        </Data>
      )}
      {mode === "edit" && (
        <EditData
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Input
            label={"Тема письма"}
            error={formik.errors.theme}
            value={formik.values.theme}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name={"theme"}
          />
          <Input
            label={"От кого"}
            error={formik.errors.name}
            value={formik.values.name}
            onBlur={(e) => {
              formik.handleChange(e);
              setTimeout(() => {
                formik.validateField("name");
              }, 200);
            }}
            name={"name"}
          />
          <InputAddress
            value={formik.values.address}
            onBlur={(val) => {
              formik.setFieldValue("address", val);
              setTimeout(() => {
                formik.validateField("address");
              }, 200);
            }}
            name={formik.values.name}
            onChange={(val) => {
              formik.setFieldValue("address", val);
            }}
            error={formik.errors.address}
          />
        </EditData>
      )}
    </Container>
  );
};
