import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";

import { useImportPageState } from "app/importContacts/useImportPageState";
import { useInnerPromptRedirect } from "app/useInnerPromptRedirect";
import { offExitConfirm, onExitConfirm } from "app/applicationExitHandler";
import { useAppDispatch } from "services/hooks";
import { importStoreAction } from "services/store/importSlice";

import { mxm } from "ui/styles/_mixin";
import { RowTabs } from "ui/components/RowTabs";
import { importModeValues } from "ui/pages/ImportContacts/contants";
import { FileManagerContent } from "ui/pages/ImportContacts/Steps/File/FileManagerContent";
import { useImportCrumbs } from "ui/pages/ImportContacts/useImportCrumbs";
import { SettingFileContent } from "ui/pages/ImportContacts/Steps/SettingFileContent";
import { TableContainerScroll } from "ui/containers/TableContainerScroll";
import { ImportFileContent } from "ui/pages/ImportContacts/Steps/ImportFileContent";
import { MonitoringImportContent } from "ui/pages/ImportContacts/Steps/MonitoringImportContent";

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 40px 5px 12px 40px;
`;

const TopRow = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  column-gap: 16px;
`;

const MainData = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Crumbs = styled.div`
  min-width: 296px;
`;

const TabWrapper = styled.div`
  margin-bottom: 24px;
`;

const ScrollContent = styled(TableContainerScroll)`
  padding-bottom: 150px;
`;

const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 18px;
`;
const StickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 26;
  background-color: white;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
  ${mxm(
    1300,
    css`
      font-size: 20px;
    `
  )}
`;

/**
 * Страница импорта контактов, является оберткой для шагов импорта,
 * так же отвечает за консистентность стейта
 */
export const ImportContactsPage = () => {
  const { state, action } = useImportPageState();
  const dispatch = useAppDispatch();
  const [isSendConfirmExit, setSendConfirmExit] = useState(false);
  const { isShowConfirmRedirect } = useInnerPromptRedirect([
    "/templates/cards?by=import",
    "/contacts/import",
    "/mailing/create-mailing?by=import",
  ]);

  const CrumbsElement = useImportCrumbs();

  const {
    importData: { importMode, fileId },
    step,
  } = state;

  const isImported = step === 5;

  // *** Костыльная логика предупреждения о выходе
  useEffect(() => {
    setSendConfirmExit(
      state.importData.hasBeenChangeWriteFile ||
        state.step == 2 ||
        state.step == 3 ||
        state.step == 4 ||
        Boolean(state.importData.file)
    );
  }, [state.importData, state.importData.step]);

  useEffect(() => {
    if (isSendConfirmExit && !isImported) {
      onExitConfirm();
      return;
    }
    offExitConfirm();
  }, [isSendConfirmExit, isImported]);
  // *** Конец костыля

  useEffect(() => {
    return () => {
      dispatch(importStoreAction.clearAllForm());
    };
  }, []);

  return (
    <Content>
      <Prompt
        when={!isImported}
        message={(location) => {
          return isShowConfirmRedirect(
            location as unknown as Location,
            () => !isSendConfirmExit
          )
            ? true
            : `Вы покидаете форму импорта. Внесенные изменения не сохранятся`;
        }}
      />
      <ScrollContent height={80}>
        <StickyTopContent>
          <HeadingRow>
            <Heading>Импорт контактов</Heading>
          </HeadingRow>
        </StickyTopContent>
        <TopRow>
          <MainData>
            <TabWrapper>
              <RowTabs
                onClick={action.onChangeModeImport}
                active={importMode}
                items={importModeValues}
              />
            </TabWrapper>
            {step < 2 && <FileManagerContent />}
            {step >= 2 && <SettingFileContent isComplete={step > 2} />}
            {step >= 3 && <ImportFileContent isComplete={step > 3} />}
          </MainData>
          <Crumbs>
            <CrumbsElement />
          </Crumbs>
        </TopRow>
        {step > 3 && <MonitoringImportContent id={fileId!} step={step} />}
      </ScrollContent>
    </Content>
  );
};
