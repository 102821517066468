import { css } from "styled-components";

import { _variables } from "../_variables";

export const toastsCustomStyles = css`
  .Toastify {
    position: relative;
    z-index: 9999999;
  }
  .Toastify__toast {
    padding: 8px 0;
    min-height: 48px;
    min-width: 320px;
    max-width: 1200px;
    display: flex;
    align-items: center;
    cursor: default;
    border-radius: ${_variables.borderRadius};
  }
  .Toastify__toast-container {
    max-width: 100%;
    width: auto;
    min-width: 320px;
    //padding-right: 100px;
  }
  .Toastify__close-button {
    position: absolute;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    color: white;
    & > svg {
      width: 16px;
      height: 16px;
    }
  }
  .Toastify__toast-body {
    padding: 0;
    padding-right: 42px;
    padding-left: 20px;
    margin: 0;
    text-wrap: none;

    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    line-height: 20px;
    letter-spacing: -0.25px;
    text-align: left;
    & * {
      height: 20px;
      height: auto;
    }
    & a {
      font-size: inherit !important;
    }
  }
  .Toastify__toast-icon {
    display: none;
  }
  .Toastify__toast--default {
    background-color: ${_variables.blackColor};
  }
  .Toastify__toast--warning {
    background-color: ${_variables.supportColors.red};
  }
`;
