import type { OverlayProps } from "react-overlays/Overlay";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

import { Icon } from "../Icon";
import { TooltipClicked } from "../TooltipClicked";
import { ContentMenu } from "../ContentMenu";

const Container = styled.div``;
const IconMenu = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  transition: all ${_variables.transition};
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
  &:hover {
    background-color: ${_variables.backgroundColor};
  }
  & svg {
    color: ${_variables.grayTextColor};
  }
`;

export const AdditionalMenuTable: CT<{
  contentMenu: JSX.Element;
  offset?: OverlayProps["offset"];
}> = ({ contentMenu, offset = [0, 0] }) => {
  return (
    <Container>
      <TooltipClicked
        offset={offset}
        content={<ContentMenu>{contentMenu}</ContentMenu>}
      >
        <IconMenu>
          <Icon id={"dots-menu-icon"} width={24} height={24} />
        </IconMenu>
      </TooltipClicked>
    </Container>
  );
};
