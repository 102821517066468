import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { serviceUser } from "services/user";
import { _variables } from "ui/styles/_variables";

import { Button } from "../../components/Buttons/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 432px;
`;

const Text = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  opacity: 0.5;
  color: ${_variables.blackColor};
`;
const Buttons = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const DomainSettingNotify: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const modalData = useAppSelector(selectModalData);
  const history = useHistory();
  const onSuccess = () => {
    onClose();
    setTimeout(async () => {
      await serviceUser.deleteNotify(modalData!.id);
      history.push(
        "/main/user/setting?page=1&per-page=25&sortBy=emails&tab=emails"
      );
    }, 50);
  };

  const onNext = () => {
    onClose();
    setTimeout(async () => {
      await serviceUser.deleteNotify(modalData!.id);
    }, 50);
  };
  return (
    <Container>
      <Text>
        Перед подготовкой вашей первой рассылки, рекомендуем начать с добавления
        email отправителя и настройки DNS-записей для домена. Добавить email и
        настроить домен можно в настройках аккаунта.
      </Text>
      <Buttons>
        <Button onClick={onSuccess}>Перейти в настройки</Button>
        <Button onClick={onNext} variant={"secondary"}>
          {" "}
          Настроить позже
        </Button>
      </Buttons>
    </Container>
  );
};
