import { useTitle } from "ahooks";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

import { appConfig } from "../../appConfig";
import { RestorePasswordForm } from "../containers/forms/RestorePasswordForm";
import { BackgroundPreview } from "../components/BackgroudPreview";
import { Link } from "../components/Link";

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.25px;
  color: ${_variables.blackColor};
  text-align: center;
`;

const Text = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  line-height: 21px;
  letter-spacing: -0.25px;
  color: ${_variables.grayColor};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-bottom: 32px;
`;

export const RestorePasswordPage = () => {
  useTitle("Восстановление пароля" + appConfig.titleApp);
  return (
    <BackgroundPreview>
      <FormWrapper>
        <TextWrapper>
          <Title>Восстановить доступ</Title>
          <Text>
            Уже есть аккаунт?&nbsp;
            <Link color={"blue"} link={"/login"}>
              Войдите
            </Link>
          </Text>
        </TextWrapper>
        <RestorePasswordForm />
      </FormWrapper>
    </BackgroundPreview>
  );
};
