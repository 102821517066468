import styled from "styled-components";

const TextBlock = styled.div`
  margin-top: 120px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
`;
export const SearchNotFound: CT<unknown> = () => {
  return <TextBlock>Ничего не найдено</TextBlock>;
};
