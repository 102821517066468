import { useHistory } from "react-router-dom";
import styled from "styled-components";

import type { TemplateType } from "domain/templates";
import { useAppDispatch } from "services/hooks";
import { setDataTemplateView } from "services/store/templateViewSlice";
import { setTemplateMailing } from "services/store/createMailingSlice";
import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";

const ButtonWrapper = styled.div`
  position: absolute;
  top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
`;

const Button = styled.button<{ hasBackground?: boolean }>`
  padding: 0 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  border-radius: 8px;
  color: white;
  border: none;
  background-color: ${({ hasBackground }) =>
    hasBackground ? _variables.blackColor : "rgb(0,0,0,0)"};
  height: 36px;
  align-items: center;
  display: flex;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 25px;
  overflow-y: hidden;
  position: relative;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  z-index: 10;

  & img {
    z-index: 2;
    width: calc(100% + 2px);
    position: relative;
    left: -1px;
    object-position: right top;
    height: auto;
    max-height: 300px;
    object-fit: cover;
  }
`;

const Label = styled.div<{ statusColor: string }>`
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 22px;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 12;
  border-radius: 8px;
  color: white;
  background-color: ${({ statusColor }) => statusColor};
`;

const ContentShow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 13;
  display: flex;
  align-items: center;
  opacity: 0;
  justify-content: center;
  background-color: rgba(${hexToRgba(_variables.defaultTemplates.hover, 0.4)});
  transition: opacity ${_variables.transition};

  &:hover {
    opacity: 1;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  padding: 25px 15px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: none;
  align-items: center;
  justify-content: flex-end;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${_variables.strokeColor};
  padding: 16px;
`;

const NameField = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: black;
`;

const DateField = styled.p`
  color: ${_variables.grayTextColor};
  font-weight: 500;
  font-size: 12px;
`;

const removeMilliseconds = (stringDate: string) => stringDate.slice(0, -3);

export const TemplateItem: CT<{
  src: string;
  data: TemplateType;
}> = ({ data, src }) => {
  const { id } = data;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onClickShow = () => {
    dispatch(
      setDataTemplateView({
        mode: "desktop",
        id,
        isDefaultTemplate: false,
        rating: data.rating,
        has_scheme: data.has_scheme,
        isMailingCreationWay: true,
      })
    );
  };

  return (
    <Content>
      <Image>
        <img src={src} alt="" />
      </Image>
      <Info>
        <NameField>{data.name}</NameField>
        <DateField>
          {removeMilliseconds(data.date_edit || data.date_in)}
        </DateField>
      </Info>
      <ContentShow className={"content"}>
        <IconsWrapper>
          <Label
            statusColor={
              data.has_scheme
                ? _variables.additionalColors.yellow
                : _variables.defaultTemplates.labelHTML
            }
          >
            {data.has_scheme ? "Конструктор" : "HTML"}
          </Label>
        </IconsWrapper>

        <ButtonWrapper>
          <Button
            onClick={() => {
              dispatch(setTemplateMailing(data.id));
              history.push("/templates/create-mailing");
            }}
            hasBackground={true}
          >
            Использовать
          </Button>

          <Button onClick={onClickShow}>Просмотреть</Button>
        </ButtonWrapper>
      </ContentShow>
    </Content>
  );
};
