import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";

import { Button } from "../../components/Buttons/Button";
import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";
import { ModalTextContainer } from "../../components/Modals/ModalTextContainer";
import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";

export const BackToLinkForm: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const modalData = useAppSelector(selectModalData);

  const onPushHistory = () => {
    onClose();
    setTimeout(() => {
      window.history.back();
    }, 500);
  };

  return (
    <ModalDefaultFormContainer>
      <ModalTextContainer>
        <p>{modalData!.message ?? ""}</p>
      </ModalTextContainer>
      <ModalButtonsContainer>
        <Button onClick={onPushHistory} type={"submit"}>
          Подтвердить
        </Button>
        <Button onClick={onClose} type={"submit"} variant={"secondary"}>
          Отмена
        </Button>
      </ModalButtonsContainer>
    </ModalDefaultFormContainer>
  );
};
