export const firstCharUpperCase = (str: string) => {
  const strCopy = str.split("");
  return [strCopy[0].toUpperCase(), ...strCopy.slice(1)].join("");
};

export const getTwoFirstChar = (str: string) => {
  const arr = str.split(" ");
  if (arr.length === 1) return arr[0][0].toUpperCase();
  return `${arr?.[0].toUpperCase()[0]}${arr?.[1].toUpperCase()[0]}`;
};

export const strToHash = (str: string) => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const strRemoveSpace = (str: string) => {
  return str.replace(/\s/g, "");
};
