import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";

import { createToastResponseError } from "lib/toastify";
import { useAppSelector } from "services/hooks";
import { selectCreateMailingData } from "services/store/createMailingSlice";
import { mailingService, portTestMailing } from "services/mailing";
import { selectUserData } from "services/store/userSlice";
import { _variables } from "ui/styles/_variables";

import { Button } from "../../components/Buttons/Button";
import { InputMailsTextArea } from "../specialFormElements/InputMailsTextArea";

const SubText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.25px;
  text-align: left;
  color: ${_variables.blackColor};
  opacity: 0.5;
  margin-top: 24px;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 24px;
  column-gap: 8px;
`;

export const CreateTestMailing: CT<{ onClose: VoidFunction }> = ({
  onClose,
}) => {
  const userData = useAppSelector(selectUserData, shallowEqual);
  const mailData = useAppSelector(selectCreateMailingData);
  const [isLoad, setLoad] = useState(false);
  const [values, setValues] = useState<
    React.ComponentProps<typeof InputMailsTextArea>["mails"]
  >([]);

  const removeHandler = (id: number) => {
    setValues(values.filter((item) => item.id !== id));
  };
  const addHandler = (name: string) => {
    setValues([...values, { email: name, id: values.length + 1 }]);
  };

  const submitHandler = async () => {
    const data = portTestMailing(
      mailData,
      values.map((item) => item.email)
    );
    try {
      setLoad(true);
      const response = await mailingService.testMailing(data);
      toast(response.data.message, { autoClose: 3000 });
      onClose();
    } catch (e) {
      createToastResponseError(e)();
      console.log(e);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    setValues([{ id: 0, email: userData!.email }]);
  }, []);

  return (
    <div>
      <InputMailsTextArea
        onAdd={addHandler}
        onRemove={removeHandler}
        mails={values}
      />
      <SubText>Впишите новый адрес и нажмите enter</SubText>
      <Buttons>
        <Button
          onClick={submitHandler}
          disabled={!values.length || isLoad}
          isLoad={isLoad}
        >
          Отправить письмо
        </Button>
        <Button onClick={onClose} variant={"secondary"}>
          Отменить
        </Button>
      </Buttons>
    </div>
  );
};
