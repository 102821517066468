import { useEffect, useState } from "react";

import type { ImportPreviewType } from "services/contact";
import { contactService } from "services/contact";
import { useAppDispatch } from "services/hooks";
import { importStoreAction } from "services/store/importSlice";
import { createToastResponseError, onlyOne } from "lib/toastify";

import useInterval from "../useInterval";

export const useImportStatus = (id: number) => {
  const [data, setData] = useState<ImportPreviewType>({
    status: 2,
    count_added: 0,
    count_errors: 0,
    id: id,
    statusName: "В очереди на загрузку",
    count_processed: 0,
    count_updated: 0,
    percent: 0,
  });
  const { stopInterval, startInterval } = useInterval(async () => {
    await loadPreviewData();
  }, 1000);
  const dispatch = useAppDispatch();
  const loadPreviewData = async () => {
    try {
      const data = await contactService.importPreview(id);
      setData(data.data.data["import-preview"]);
    } catch (e) {
      onlyOne(createToastResponseError(e));
      stopInterval();
    }
  };

  useEffect(() => {
    if (data.status == 4 || data.status == 5) {
      stopInterval();
      dispatch(importStoreAction.goToFinalStep());
    }
  }, [data]);

  useEffect(() => {
    startInterval();
  }, []);

  return {
    data,
  };
};
