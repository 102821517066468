import { MainPage } from "../pages/MainPage";
import { ContactPage } from "../pages/Contacts/ContactPage";
import { ListsPage } from "../pages/Contacts/ListsPage";
import { CreateMailing } from "../pages/CreateMailing";
import { MailingPage } from "../pages/MailingPage";
import { PackagesPage } from "../pages/Payment/PackagesPage";
import { HistoryPayPage } from "../pages/AccoutPage/HistoryPayPage";
import { SettingPage } from "../pages/SettingPage";
import { TemplatesPage } from "../pages/TemplatesPage";
import { TemplateCardsPage } from "../pages/TemplateCardPage/TemplateCardsPage";
import { CreatorTemplatePage } from "../pages/CreatorTemplatePage";
import { TextEditorTemplatePage } from "../pages/TextEditorTemplatePage";
import { LoginPage } from "../pages/LoginPage";
import { RegisterPage } from "../pages/RegisterPage";
import { RestorePasswordPage } from "../pages/RestorePasswordPage";
import { LogoutPage } from "../pages/LogoutPage";
import { ErrorPage } from "../pages/ErrorPages/ErrorPage";
import { ForbiddenPage } from "../pages/ErrorPages/ForbiddenPage";
import { NotFoundPage } from "../pages/ErrorPages/NotFoundPage";
import { BanPage } from "../pages/ErrorPages/BanPage";
import { ConfirmEmailPage } from "../pages/ConfirmEmailPage";
import { RecoveryPasswordPage } from "../pages/RecoveryPasswordPage";
import { SuccessSubscribe } from "../pages/SuccessSubscribe";
import { HelpMainPage } from "../pages/Help/HelpMainPage";
import { HelpInfoPage } from "../pages/Help/HelpInfoPage";
import { UnsubscribePage } from "../pages/UnsubscribePage";
import { FirstStepCreateMailingPage } from "../pages/CreateMailing/FirstStepCreateMailingPage";
import { SecondStepCreateMailingPage } from "../pages/CreateMailing/SecondStepCreateMailingPage";
import { CreateMailingMainFormPage } from "../pages/CreateMailing/CreateMailingMainFormPage";
import { ConfirmUserEmailsPage } from "../pages/ConfirmUserEmailsPage";
import { AccountPage } from "../pages/AccoutPage/AccoutPage";
import { PaymentSuccess } from "../pages/Payment/PaymentSuccess";
import { ApiPage } from "../pages/ApiPage/ApiPage";
import { AdminDefaultTemplatesPage } from "../pages/PublicTemplatePages/AdminDefaultTemplatesPage";
import { ValidationsPayPage } from "../pages/AccoutPage/ValidationsPayPage";
import { AdminLoginPage } from "../pages/AdminLoginPage";
import { IdeaListPage, IdeaPage, NewIdeasPage } from "../pages/Ideas";
import { AnalyticMailingPage } from "../pages/AnalyticMailingPage";
import { ImportContactsPage } from "ui/pages/ImportContacts/ImportContactsPage";
import { UserCardPayPage } from "ui/pages/AccoutPage/UserCardPayPage";
import { NewsPage } from "ui/pages/News/NewsPage";
import { PopupCreator } from "ui/components/TemplateEditor/PopupBuilder";

import { ClientFormListPage } from "ui/pages/ClientFormPage/ClientFormListPage";
import { ClientFormPage } from "ui/pages/ClientFormPage/ClientFormPage";

export type RouteType = {
  path: string;
  haveLayout?: boolean;
  mode?: "protected" | "unAuth";
  exact?: boolean;
  title?: string;
  component: any;
};

export const routes: RouteType[] = [
  {
    exact: true,
    path: "/forms/create",
    haveLayout: true,
    component: ClientFormPage,
    title: "Создание формы подписок",
  },
  {
    exact: true,
    path: "/forms/create/:id",
    haveLayout: true,
    component: ClientFormPage,
    title: "Создание формы подписок",
  },
  {
    exact: true,
    path: "/forms/constructor/:id",
    haveLayout: false,
    component: PopupCreator,
    title: "Конструктор форм",
  },
  {
    exact: true,
    path: "/forms/constructor",
    haveLayout: false,
    component: PopupCreator,
    title: "Конструктор форм",
  },
  {
    exact: true,
    path: "/forms/",
    haveLayout: true,
    component: ClientFormListPage,
    title: "Формы подписок",
  },
  {
    mode: "protected",
    exact: true,
    path: "/main/",
    haveLayout: true,
    component: MainPage,
    title: "Главная страница",
  },
  {
    mode: "protected",
    exact: true,
    path: "/main/user/setting",
    haveLayout: true,
    component: AccountPage,
    title: "Настройки пользователя",
  },
  {
    mode: "protected",
    exact: true,
    path: "/contacts/import",
    haveLayout: true,
    component: ImportContactsPage,
    title: "Импорт контактов",
  },
  {
    mode: "protected",
    exact: false,
    path: "/contacts/:id",
    haveLayout: true,
    component: ContactPage,
    title: "Список",
  },
  {
    mode: "protected",
    exact: true,
    path: "/contacts",
    haveLayout: true,
    component: ListsPage,
    title: "Контакты",
  },
  {
    mode: "protected",
    exact: true,
    path: "/mailing/create-mailing",
    haveLayout: true,
    component: CreateMailingMainFormPage,
    title: "Создание рассылки",
  },
  {
    mode: "protected",
    exact: false,
    path: "/mailing/create",
    haveLayout: true,
    component: CreateMailing,
    title: "Создание рассылки",
  },
  {
    mode: "protected",
    exact: false,
    path: "/mailing/analytic/:id",
    haveLayout: true,
    component: AnalyticMailingPage,
    title: "Аналитика рассылки",
  },
  {
    mode: "protected",
    exact: false,
    path: "/mailing/",
    haveLayout: true,
    component: MailingPage,
    title: "Мои рассылки",
  },

  {
    mode: "protected",
    exact: false,
    path: "/packages/payment",
    haveLayout: true,
    component: PackagesPage,
    title: "Пакеты писем",
  },
  {
    mode: "protected",
    exact: false,
    path: "/main/history-pay-validation",
    haveLayout: true,
    component: ValidationsPayPage,
    title: "История покупок",
  },
  {
    mode: "protected",
    exact: false,
    path: "/main/history-pay",
    haveLayout: true,
    component: HistoryPayPage,
    title: "История покупок",
  },
  {
    mode: "protected",
    exact: false,
    path: "/main/user/card-pay",
    haveLayout: true,
    component: UserCardPayPage,
    title: "Привязанные карты",
  },
  {
    mode: "protected",
    exact: false,
    path: "/main/user/setting",
    haveLayout: true,
    component: SettingPage,
    title: "Настройки пользователя",
  },
  {
    mode: "protected",
    exact: true,
    path: "/templates/create-mailing",
    haveLayout: true,
    component: FirstStepCreateMailingPage,
    title: "Создание шаблона",
  },
  {
    mode: "protected",
    exact: true,
    path: "/templates/choose-list",
    haveLayout: true,
    component: SecondStepCreateMailingPage,
    title: "Выбор списка",
  },
  {
    mode: "protected",
    exact: false,
    path: "/templates/cards",
    haveLayout: true,
    component: TemplateCardsPage,
    title: "Создайте письмо",
  },
  {
    mode: "protected",
    exact: false,
    path: "/templates/creator",
    haveLayout: false,
    component: CreatorTemplatePage,
    title: "Создание шаблона",
  },
  {
    mode: "protected",
    exact: false,
    path: "/templates/text-editor",
    haveLayout: false,
    component: TextEditorTemplatePage,
    title: "Создание шаблона",
  },
  {
    mode: "protected",
    exact: true,
    path: "/templates/",
    haveLayout: true,
    component: TemplatesPage,
    title: "Шаблоны",
  },
  {
    mode: "protected",
    exact: false,
    path: "/rest",
    haveLayout: true,
    component: ApiPage,
    title: "API рассылки",
  },
  {
    mode: "unAuth",
    exact: false,
    path: "/login",
    haveLayout: false,
    component: LoginPage,
    title: "Авторизация",
  },
  {
    mode: "unAuth",
    exact: false,
    path: "/registration",
    haveLayout: false,
    component: RegisterPage,
    title: "Регистрация",
  },
  {
    exact: false,
    path: "/email/confirm/:token",
    haveLayout: false,
    component: ConfirmEmailPage,
    title: "Подтверждение email",
  },
  {
    mode: "protected",
    exact: false,
    path: "/user-email/confirm/:token",
    haveLayout: false,
    component: ConfirmUserEmailsPage,
    title: "Подтверждение пользователя",
  },
  {
    exact: false,
    path: "/login-as-user",
    haveLayout: false,
    component: AdminLoginPage,
    title: "Восстановление пароя",
  },
  {
    exact: false,
    path: "/email/recovery/:token",
    haveLayout: false,
    component: RecoveryPasswordPage,
    title: "Восстановление пароля",
  },
  {
    exact: false,
    path: "/subscribe/success",
    haveLayout: false,
    component: SuccessSubscribe,
    title: "Потверждение подписки",
  },
  {
    exact: false,
    path: "/password/restore",
    haveLayout: false,
    component: RestorePasswordPage,
    title: "Восстановление пароя",
  },
  {
    exact: false,
    path: "/logout",
    haveLayout: false,
    component: LogoutPage,
    title: "Выход",
  },
  {
    exact: false,
    path: "/status/error",
    haveLayout: false,
    component: ErrorPage,
    title: "Ошибка",
  },
  {
    exact: false,
    path: "/status/403",
    haveLayout: false,
    component: ForbiddenPage,
    title: "Недостаточно прав для просмотра",
  },
  {
    exact: false,
    path: "/status/404",
    haveLayout: false,
    component: NotFoundPage,
    title: "Страница не существует",
  },

  {
    exact: false,
    path: "/status/1000",
    haveLayout: false,
    component: BanPage,
    title: "Вы забанены",
  },
  {
    exact: false,
    path: "/help/info/",
    haveLayout: true,
    component: HelpInfoPage,
    title: "Помощь",
  },
  {
    exact: true,
    path: "/help/",
    haveLayout: true,
    component: HelpMainPage,
    title: "Помощь",
  },
  {
    exact: false,
    path: "/ideas/new",
    haveLayout: true,
    component: NewIdeasPage,
    title: "Предложить идею",
  },
  {
    exact: true,
    path: "/ideas/:id",
    haveLayout: true,
    component: IdeaPage,
    title: "Есть идея",
  },
  {
    exact: true,
    path: "/ideas/",
    haveLayout: true,
    component: IdeaListPage,
    title: "У вас есть идея?",
  },
  {
    mode: "protected",
    exact: false,
    path: "/news/:id",
    haveLayout: true,
    component: NewsPage,
    title: "Новости",
  },
  {
    mode: "protected",
    exact: false,
    path: "/news",
    haveLayout: true,
    component: NewsPage,
    title: "Новости",
  },
  {
    exact: true,
    path: "/payment/response/",
    title: "Успешная оплата",
    component: PaymentSuccess,
  },
  {
    exact: true,
    path: "/unsubscribe/",
    haveLayout: false,
    component: UnsubscribePage,
    title: "Отписка",
  },
  {
    exact: true,
    path: "/default-templates/",
    haveLayout: true,
    component: AdminDefaultTemplatesPage,
    title: "Публичные шаблоны",
  },
];
