import styled from "styled-components";

const Container = styled.div`
  width: 360px;
`;
export const ModalDefaultFormContainer: CT<unknown> = ({
  children,
  className,
}) => {
  return <Container className={className}>{children}</Container>;
};
