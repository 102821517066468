import styled from "styled-components";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { createToastResponseError } from "lib/toastify";
import { updateQueryParams } from "lib/queryParams";
import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { userEmailsService } from "services/userEmailsService";

import { Button } from "../../components/Buttons/Button";
import img from "ui/assets/media/images/delete-preview.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 432px;
`;
const Preview = styled.div`
  width: 432px;
  height: 211px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const TextContent = styled.div`
  row-gap: 12px;
  display: flex;
  flex-direction: column;
`;
const Heading = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const Buttons = styled.div`
  display: flex;

  column-gap: 8px;
`;

export const DeleteEmail: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const modalData = useAppSelector(selectModalData);
  const history = useHistory();
  const onDelete = async () => {
    try {
      await userEmailsService.delete(modalData!.id);
      toast("Email успешно удален", { autoClose: 3000 });
      onClose();
      updateQueryParams(history, {});
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  return (
    <Container>
      <Preview>
        <img src={img} alt="" />
      </Preview>
      <TextContent>
        <Heading>
          Вы действительно хотите удалить <br /> {modalData?.email ?? "email"}
        </Heading>
      </TextContent>
      <Buttons>
        <Button onClick={onDelete}>Удалить email</Button>
        <Button onClick={onClose} variant={"secondary"}>
          Отменить
        </Button>
      </Buttons>
    </Container>
  );
};
