import axios from "axios";

import type { UserPlan } from "domain/userPlan";
import type { ServiceListPropTypes } from "domain/api";

export const userPlanService = {
  path: "user-plan/",

  index({ perPage, page, sortBy = "" }: ServiceListPropTypes<unknown>) {
    return axios.get<ApiResponseBodyType<{ userPlans: UserPlan[] }>>(
      `${this.path}index?page=${page}&per-page=${perPage}&sort=${sortBy}`
    );
  },
};
