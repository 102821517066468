import styled from "styled-components";

import { LoadContent } from "./LoadContent";

const Load = styled(LoadContent)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
export const LoadCenterAbsolute: CT<{ top?: number }> = ({ top = 120 }) => {
  return <Load style={{ top: top }} />;
};
