import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "./store";

export type modalDataType = string;

const initialState = "" as modalDataType;

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal(_, action: PayloadAction<string>) {
      return action.payload;
    },
    clearModal() {
      return "";
    },
  },
});

export const { openModal, clearModal } = modalSlice.actions;
export default modalSlice.reducer;

export const selectOpenModal = (state: RootState) => state.modal;
