import { useEffect, useState } from "react";
import { getTrackBackground, Range } from "react-range";
import styled from "styled-components";

import { usePayEntity } from "app/usePayEntity";
import { _variables } from "ui/styles/_variables";
import { hexToRgba } from "ui/styles/_mixin";

const Wrapper = styled.div`
  min-height: 20px;
  display: flex;
  align-items: center;
`;

const Thumb = styled.div`
  height: 20px;
  border-radius: 50%;
  width: 20px;
  background-color: ${_variables.primaryColor};

  &:focus-visible {
    border-color: ${_variables.primaryColor} !important;
    outline-color: ${_variables.primaryColor} !important;
  }

  &:focus {
    border-color: ${_variables.primaryColor} !important;
    outline-color: ${_variables.primaryColor} !important;
  }
`;

/**
 *
 * @param onChange
 * @param values
 * @param forceInitVal - Изначальное положение селекта на линии массива
 * @constructor
 */
export const InputPackage: CT<{
  onChange?: (value: { count: number; price: number; id: number }) => void;
  forceInitVal?: number;
  values: { count: number; price: number; step: number; id: number }[];
}> = ({ onChange, values, forceInitVal = 1 }) => {
  const { isLoadPriceData } = usePayEntity();
  const [valuesState, setValues] = useState<number[] | null>([
    values![forceInitVal]!.step,
  ]);

  useEffect(() => {
    if (isLoadPriceData) return;
    onChange && onChange(values![valuesState![0]]);
  }, [valuesState]);

  useEffect(() => {
    if (isLoadPriceData) return;
    if (valuesState) return;
    setValues([values![forceInitVal]!.step]);
  }, [values]);

  const stepLength = values.length - 1;

  return (
    <Wrapper>
      <Range
        step={1}
        min={0}
        max={stepLength}
        values={valuesState!}
        onChange={(values) => setValues(values)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "4px",
              width: "100%",
              backgroundColor: _variables.backgroundColor,
              borderRadius: "6px",
              background: getTrackBackground({
                values: valuesState!,
                colors: [
                  _variables.primaryColor,
                  `rgba(${hexToRgba("#FF9F85", 0.2)})`,
                ],
                min: 0,
                max: stepLength,
              }),
            }}
          >
            <>{children}</>
          </div>
        )}
        draggableTrack={false}
        rtl={false}
        renderThumb={({ props }) => (
          <Thumb
            {...props}
            style={{
              ...props.style,
            }}
          />
        )}
      />
    </Wrapper>
  );
};
