import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "ahooks";
import { toast } from "react-toastify";
import styled from "styled-components";

import { createToastResponseError } from "lib/toastify";
import { serviceUser } from "services/user";
import { _variables } from "ui/styles/_variables";

import { appConfig } from "../../../appConfig";
import { LoadDots } from "../../components/LoadDots";

const Title = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.25px;
  color: ${_variables.blackColor};
  text-align: center;
`;

const Text = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 20px;
  text-align: center;
  line-height: 21px;
  letter-spacing: -0.25px;
  color: ${_variables.grayColor};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-bottom: 32px;
`;

export const ConfirmEmailForm: CT<{ token: string }> = ({ token }) => {
  const [status, setStatus] = useState("");
  const history = useHistory();

  useTitle("Подтверждение email" + appConfig.titleApp);

  useEffect(() => {
    (async () => {
      try {
        await serviceUser.confirm(token);
        toast("Email успешно подтвержден", { autoClose: 3000 });
        setStatus("");
      } catch (e) {
        console.log(e);
        createToastResponseError('Ошибка подтверждения"')();
      } finally {
        setTimeout(() => {
          history.push({
            pathname: "/login",
          });
        }, 2000);
      }
    })();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <TextWrapper>
        <Title>Ожидайте подтверждения Email</Title>
        <Text>{status ? <p /> : <LoadDots />}</Text>
      </TextWrapper>
    </>
  );
};
