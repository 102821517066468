import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

const Container = styled.div`
  display: flex;
  background-color: #fdf1ed;
  position: relative;
  height: fit-content;
  border-radius: 8px;
  overflow: hidden;
  &:before {
    position: relative;
    left: 0;
    top: 0;
    content: "";
    width: 8px;
    background-color: ${_variables.primaryColor};
  }
`;
const InnerContainer = styled.div`
  padding: 32px 36px;
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

export const AlertBig: CT<{
  text: string | JSX.Element;
  maxWidth?: number;
}> = ({ text, className, maxWidth }) => {
  return (
    <Container style={{ maxWidth: maxWidth ?? "auto" }} className={className}>
      <InnerContainer>
        <Text>{text}</Text>
      </InnerContainer>
    </Container>
  );
};
