import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { validateEmail } from "lib/email";
import { formWrapper } from "ui/styles/elements/formElements";
import { _variables } from "ui/styles/_variables";
import { scroll } from "ui/styles/elements/scroll";

import { Icon } from "../../components/Icon";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${formWrapper}
`;
const Container = styled.div`
  display: flex;
  cursor: pointer;
  padding: 12px 16px;
  flex-wrap: wrap;
  min-width: 620px;
  height: 124px;
  border-radius: 12px;
  background-color: ${_variables.backgroundColor};
`;
const ContainerScroll = styled.div`
  overflow-y: auto;
  row-gap: 8px;
  column-gap: 8px;
  width: 100%;
  height: 100%;

  ${scroll}
`;

const Chip = styled.div`
  display: flex;
  height: 28px;
  column-gap: 8px;
  padding: 4px 16px;
  font-size: 14px;
  float: left;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  width: fit-content;

  & svg {
    cursor: pointer;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputChip = styled.input`
  border: none;
  width: 100%;
  cursor: pointer;

  float: left;
  background-color: transparent;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  height: 100%;
  letter-spacing: -0.25px;
  text-align: left;
`;
const InputWrapper = styled.div`
  height: 28px;
  cursor: pointer;
  align-self: flex-start;
  width: 100% !important;
`;

export const InputMailsTextArea: CT<{
  mails: { id: number; email: string }[];
  onRemove: (id: number) => void;
  onAdd: (email: string) => void;
}> = ({ mails, onRemove, className, onAdd }) => {
  const scrollRef = useRef(null);
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  const createInputHandler = () => {
    setTimeout(() => {
      (scrollRef.current as unknown as HTMLDivElement).scrollTo({
        top: 10000,
      });
      (inputRef.current as unknown as HTMLInputElement).focus();
    }, 100);
  };

  useEffect(() => {
    setTimeout(() => {
      (inputRef.current as unknown as HTMLInputElement).focus();
    }, 200);
  }, []);

  return (
    <Wrapper className={className}>
      <label className={"label"}>Кому</label>
      <Container onClick={createInputHandler}>
        <ContainerScroll ref={scrollRef}>
          {mails.map((item) => {
            return (
              <Chip>
                <span>{item.email}</span>
                <IconWrapper
                  onClick={() => {
                    onRemove(item.id);
                  }}
                >
                  <Icon id={"cross-bold-icon"} width={16} height={16} />
                </IconWrapper>
              </Chip>
            );
          })}
          <InputWrapper>
            <InputChip
              value={inputValue}
              maxLength={200}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              onKeyUp={async (ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  setInputValue("");
                  if (inputValue === "") return;
                  if (!validateEmail(inputValue.trim())) return;
                  onAdd(inputValue.trim());
                  setTimeout(() => {
                    (scrollRef.current as unknown as HTMLDivElement).scrollTo({
                      top: 10000,
                    });
                  }, 100);
                }
              }}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                }
              }}
              ref={inputRef}
            />
          </InputWrapper>
        </ContainerScroll>
      </Container>
    </Wrapper>
  );
};
