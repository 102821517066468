import styled from "styled-components";

import { useSettingFile } from "app/importContacts/useSettingFile";

import { CounterBox } from "ui/components/ImportContacts/CounterBox";
import { SettingFields } from "ui/components/ImportContacts/SettingFields";
import { FormatField } from "ui/components/ImportContacts/FormatField";
import { Button } from "ui/components/Buttons/Button";
import { HeadingImportContacts } from "ui/components/HeadingImportContacts";
import { WrapperContent } from "ui/components/ImportContacts/WrapperContent";
import { LeftColumnContent } from "ui/components/ImportContacts/LeftColumnContent";
import { Link } from "ui/components/Link";
import { LoadContent } from "ui/components/LoadContent";

const LeftContent = styled(LeftColumnContent)`
  row-gap: 32px;
`;

const CenterContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormatFields = styled.div`
  max-width: 50.33333%;
  min-width: 50.33333%;
`;

const HowLink = styled(Link)`
  padding-top: 41px;
`;

export const SettingFileContent: CT<{ isComplete: boolean }> = ({
  isComplete,
}) => {
  const {
    previewData,
    actions,
    isValidData,
    userSettings,
    importData,
    isLoad,
  } = useSettingFile();

  if (isComplete) return <></>;

  if (!previewData) return <LoadContent />;

  return (
    <WrapperContent>
      <LeftContent>
        <CounterBox
          fileName={importData.file}
          count={previewData.data.count.contacts}
        />
        <CenterContent>
          <FormatFields>
            <HeadingImportContacts>Настройте поля</HeadingImportContacts>
            <SettingFields isLoad={isLoad} data={previewData.data} />
          </FormatFields>
          <HowLink
            link={
              "https://cheapsender.notion.site/aa4e2ef903bc4d2ebad74e6149a96776"
            }
            color={"gray"}
            redirectMode={{ blank: true }}
          >
            Как импортировать контакты?
          </HowLink>
        </CenterContent>
        <div>
          <FormatField
            onSetCheckSkipRow={actions.onSetCheckSkipRow}
            onSetDelimiter={actions.onSetDelimiter}
            userSettings={userSettings}
            initialOpen={isValidData}
          />

          <Button onClick={actions.onGoToNextStep} disabled={!isValidData}>
            Продолжить
          </Button>
        </div>
      </LeftContent>
    </WrapperContent>
  );
};
