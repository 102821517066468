import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { createToastResponseError } from "lib/toastify";
import { updateQueryParams } from "lib/queryParams";
import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { mailingService } from "services/mailing";

import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";
import { ModalTextContainer } from "../../components/Modals/ModalTextContainer";
import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";
import { Button } from "../../components/Buttons/Button";

export const DeleteDraft: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const modalData = useAppSelector(selectModalData);
  const [isLoad, setLoad] = useState(false);

  const history = useHistory();
  const onDeleteData = async () => {
    try {
      setLoad(true);
      const response = await mailingService.cancel({ id: modalData!.id });
      toast(response.data.message, { autoClose: 3000 });
      updateQueryParams(history, {});
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      onClose();
    }
  };

  return (
    <ModalDefaultFormContainer>
      <ModalTextContainer>
        <p>Вы уверены, что хотите удалить черновик?</p>
      </ModalTextContainer>
      <ModalButtonsContainer>
        <Button isLoad={isLoad} onClick={onDeleteData} type={"submit"}>
          Подтвердить
        </Button>
        <Button onClick={onClose} type={"submit"} variant={"secondary"}>
          Отмена
        </Button>
      </ModalButtonsContainer>
    </ModalDefaultFormContainer>
  );
};
