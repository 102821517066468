import { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { debounce } from "lodash";
import styled from "styled-components";

import { offExitConfirm, onExitConfirm } from "app/applicationExitHandler";
import { useInnerPromptRedirect } from "app/useInnerPromptRedirect";
import type { BookListType } from "domain/lists";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { contactsListService } from "services/list";
import {
  selectCreateMailingData,
  setChooseMailing,
  setSecondStepData,
} from "services/store/createMailingSlice";
import {
  saveUserMailingData,
  selectCJMSavedMailingUserData,
} from "services/store/cjm";

import { ContactsHeaderRow } from "../../components/ContactsHeaderRow";
import { TableHead } from "../../components/Table/TableHead";
import { TableBody } from "../../components/Table/TableBody";
import { ButtonTable } from "../../components/Buttons/ButtonTable";
import { ContentPagination } from "../../components/ContentPagination";
import { TableContainerScroll } from "../../containers/TableContainerScroll";
import { ShowCount } from "../../components/ShowCount";
import { ShadowButton } from "../../components/Buttons/ShadowButton";
import { NotContentListsPage } from "../../components/NotContent/NotContentListsPage";
import { LoadContent } from "../../components/LoadContent";
import { useTableWorkspace } from "../../hooks/useTableWorkspace";
import { useTableStateLoad } from "../../hooks/useTableStateLoad";
import { RowContact } from "../../components/Table/RowContant";
import { Input } from "../../components/FormsElements/Input";
import { createStandardToast, onlyOne } from "lib/toastify";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px 5px 12px 32px;
`;

const Table = styled.table`
  display: grid;
  grid-template-columns: minmax(500px, 100%) minmax(248px, 248px);
  grid-auto-rows: auto;
  position: relative;
  z-index: 9;
`;

const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 31px;
  margin-bottom: 18px;
`;

const Heading = styled.h1`
  font-family: "Inter";

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const TableTop = styled(Table)`
  padding-right: 0;
  row-gap: 0;
`;

const StickyTopContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: white;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
`;

const TopRow = styled(ContactsHeaderRow)`
  border-bottom: none;
`;

const ButtonTop = styled(ButtonTable)`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
`;

const AbsoluteButton = styled(ShadowButton)`
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 11;
`;
const HeadingRightContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
`;

const InputSearch = styled(Input)`
  width: 308px;
`;

const LoadWrapper = styled.div`
  display: flex;

  height: 100%;
`;

const acceptRoutes = [
  "/templates/create-mailing",
  "/mailing/create-mailing",
  "/templates/cards?by=import",
  "/contacts/import",
  "/templates/choose-list",
];

export const SecondStepCreateMailingPage = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<BookListType[]>([]);
  const [countPage, setCountPage] = useState(0);
  const { isShowConfirmRedirect } = useInnerPromptRedirect(acceptRoutes);
  const cjmData = useAppSelector(selectCJMSavedMailingUserData);

  const [checked, setChecked] = useState<{
    id: number;
    name: string;
    count: number;
  }>({
    id: cjmData?.secondStep?.listId ?? 0,
    name: cjmData?.secondStep?.name ?? "",
    count: cjmData?.secondStep?.count ?? 1,
  });
  const history = useHistory();
  const createMailingData = useAppSelector(selectCreateMailingData);
  const { query, setPerPage, setPage, setName } = useTableWorkspace({
    perPage: 25,
    page: 1,
    sortBy: "",
    name: "",
  });

  // Если пользователь решил добавить новые контакты во время создания рассылки
  const createNewBookHandler = () => {
    dispatch(
      saveUserMailingData({
        firstStep: createMailingData.data!.firstStep,
        secondStep: null,
      })
    );
    history.push("/contacts/import?by=mailing");
  };

  useEffect(() => {
    onExitConfirm();
    return () => {
      offExitConfirm();
    };
  }, []);

  useEffect(() => {
    if (
      createMailingData?.data?.isChooseMailing &&
      createMailingData?.data?.secondStep?.listId
    ) {
      setChecked({
        id: createMailingData?.data?.secondStep?.listId,
        name: createMailingData?.data?.secondStep?.name,
        count: createMailingData?.data?.secondStep?.count,
      });
    }
  }, []);

  const { isNotHaveContent, isLoad, setOffLoad, setOnLoad, isShowContent } =
    useTableStateLoad(data);

  const loadData = async () => {
    setData([]);
    setOnLoad();
    try {
      const list = await contactsListService.list({
        sortBy: query.sortBy,
        page: query.page,
        perPage: query["per-page"],
        name: query.name,
      });
      setCountPage(list.headers["x-pagination-page-count"]);
      setData(list.data.data.books);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  const changePaginationHandler = setPage;
  const changePerPageHandler = setPerPage;

  const submitHandler = () => {
    dispatch(
      setSecondStepData({
        listId: checked.id,
        name: checked.name,
        count: checked.count,
      })
    );
    dispatch(setChooseMailing(false));
    sessionStorage.removeItem("goToChooseList");
    history.push("/mailing/create-mailing");
  };

  const changeInputHandler = debounce((e: string) => {
    setName(e);
  }, 300);
  const checkedItemHandler = (id: number, name: string, count: number) =>
    setChecked({
      id,
      name,
      count,
    });

  useEffect(() => {
    if (checked.count === 0) {
      onlyOne(
        createStandardToast("Пожалуйста, выберите список, в котором есть email")
      );
    }
  }, [checked]);

  useEffect(() => {
    loadData();
    //eslint-disable-next-line
  }, [query]);

  return (
    <>
      <Content>
        <Prompt
          message={(location) => {
            return isShowConfirmRedirect(location as unknown as Location)
              ? true
              : `Вы покидаете форму импорта. Внесенные изменения не сохранятся`;
          }}
        />
        <TableContainerScroll height={136}>
          <StickyTopContent>
            <TopRow>
              <Buttons>
                <ButtonTop
                  onClick={createNewBookHandler}
                  iconId={"add-circle-icon"}
                >
                  Загрузить новые контакты
                </ButtonTop>
              </Buttons>
            </TopRow>
            <HeadingRow>
              <Heading>Ваши списки</Heading>
              <HeadingRightContent>
                <InputSearch
                  startIcon={{
                    id: "search-glass-icon",
                    width: 24,
                    height: 24,
                  }}
                  key={"searchTemplate"}
                  value={query.name}
                  placeholder={"Поиск списка"}
                  onChange={(e) => {
                    changeInputHandler(e.target.value);
                  }}
                />
              </HeadingRightContent>
            </HeadingRow>
            <TableTop>
              <TableHead>
                <th>Название</th>
                <th>Кол-во контактов</th>
              </TableHead>
            </TableTop>
          </StickyTopContent>
          {isLoad && (
            <LoadWrapper>
              <LoadContent />
            </LoadWrapper>
          )}
          {isNotHaveContent && <NotContentListsPage marginTop="200px" />}
          {isShowContent && (
            <Table>
              <TableBody>
                {!!cjmData?.secondStep && query.page <= 1 && (
                  <RowContact
                    key={"saved"}
                    onClickCheck={checkedItemHandler}
                    id={cjmData.secondStep.listId}
                    isActive={cjmData.secondStep.listId === checked.id}
                    count={cjmData.secondStep.count}
                    name={cjmData.secondStep.name}
                  />
                )}
                {data.length > 0 &&
                  data
                    .filter(
                      (item) => item.id !== cjmData?.secondStep?.listId ?? 0
                    )
                    .map((item) => (
                      <RowContact
                        key={item.id}
                        onClickCheck={checkedItemHandler}
                        id={item.id}
                        isActive={item.id === checked.id}
                        count={item.count}
                        name={item.name}
                      />
                    ))}
              </TableBody>
            </Table>
          )}
        </TableContainerScroll>
        <Footer>
          <ShowCount
            defaultValue={Number(query["per-page"])}
            onChange={changePerPageHandler}
          />
          {countPage > 1 && data.length > 0 && (
            <ContentPagination
              force={Number(query.page - 1)}
              onClick={changePaginationHandler}
              pageCount={countPage}
            />
          )}
          {!!checked.id && checked.count > 0 && (
            <AbsoluteButton onClick={submitHandler}>Продолжить</AbsoluteButton>
          )}
        </Footer>
      </Content>
    </>
  );
};
