import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import useUrlState from "@ahooksjs/use-url-state";

import { userFormService } from "services/userForms";
import type { UserFormListType } from "domain/userForm";

import { useTableStateLoad } from "ui/hooks/useTableStateLoad";

export const useShowFormsCollection = () => {
  const [values, setValues] = useState<UserFormListType[]>([]);
  const [countPage, setCountPage] = useState(0);
  const [query] = useUrlState({
    "per-page": 25,
    page: 1,
  });
  const [queryState, setQueryState] = useState({});
  const { setOffLoad, setOnLoad, ...tableState } = useTableStateLoad(values);

  const loadData = async () => {
    setOnLoad();
    try {
      const list = await userFormService.list({
        ...query,
        perPage: query["per-page"],
      });

      setCountPage(list.headers["x-pagination-page-count"]);
      setValues(list.data.data["subscribe-forms"]);
    } catch (e) {
      console.log(e);
    } finally {
      setOffLoad();
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (shallowEqual(queryState, query)) return;
    setValues([]);
    loadData();
    setQueryState(query);
  }, [query]);

  return {
    data: values,
    countPage,
    loadData,
    query,
    ...tableState,
  };
};
