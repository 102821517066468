import styled from "styled-components";

const Container = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.25px;
  text-align: left;
  opacity: 0.5;
  & span,
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.25px;
    text-align: left;
  }
`;
export const ModalTextContainer: CT<unknown> = ({ children }) => {
  return <Container>{children}</Container>;
};
