import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { importInterfaceStatusCatalog, maxSizeByte } from "domain/fileContacts";
import { contactService } from "services/contact";
import { useAppSelector } from "services/hooks";
import {
  importStoreAction,
  selectImportState,
  selectWriteContent,
} from "services/store/importSlice";
import { createToastResponseError } from "lib/toastify";

export const useLoadContactImport = () => {
  const [binFile, setBinFile] = useState<File | null>(null);
  const [file] = useState("");
  const dispatch = useDispatch();
  const importStoreData = useAppSelector(selectImportState);
  const writeContent = useAppSelector(selectWriteContent);
  const [isLoadFile, setLoadFile] = useState(false);

  const onChangeFile = (file: File | undefined, content?: string) => {
    if (!file) return;
    if (file.size > maxSizeByte) {
      dispatch(importStoreAction.setStatus(6));
      return;
    }
    if (content) dispatch(importStoreAction.setWriteFile(content));
    setBinFile(file);
  };

  const onClearWriteFile = () => {
    dispatch(importStoreAction.setWriteFile(null));
  };
  const onReset = () => {
    dispatch(importStoreAction.clearAllWithoutWrite());
  };
  const onGoToSettingStep = () => {
    dispatch(importStoreAction.goToSettingStep());
  };
  const setHasBeenChangeWriteFile = () => {
    dispatch(importStoreAction.setHasBeenChange());
  };
  const clearHasBeenChangeWriteFile = () => {
    dispatch(importStoreAction.clearHasBeenChange());
  };

  const onLoad = async (file: File) => {
    dispatch(importStoreAction.setStatus(2));
    setLoadFile(true);
    try {
      const response = await contactService.upload(file);
      dispatch(importStoreAction.setFile(file.name));
      dispatch(importStoreAction.setId(response.data.data.id));
      dispatch(importStoreAction.setStatus(3));
    } catch (e) {
      dispatch(importStoreAction.setStatus(4));
      createToastResponseError(e)();
    } finally {
      setLoadFile(false);
    }
  };

  useEffect(() => {
    dispatch(importStoreAction.setStatus(1));
  }, []);

  useEffect(() => {
    if (!binFile) return;
    onLoad(binFile!);
  }, [binFile]);

  return {
    onLoad,
    status: importStoreData.status,
    onChangeFile,
    statusCatalog: importInterfaceStatusCatalog,
    file,
    isLoadFile,
    onClearWriteFile,
    onReset,
    onGoToNextStep: onGoToSettingStep,
    writeContent,
    setHasBeenChangeWriteFile,
    clearHasBeenChangeWriteFile,
  };
};
