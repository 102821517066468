export const everyConfirmedRoutes = [
  "/status/error",
  "/status/403",
  "/status/404",
  "/status/1000",
];

export const routeIsEveryConfirm = (location: Location) => {
  return everyConfirmedRoutes.some((item) =>
    location.pathname.startsWith(item)
  );
};
