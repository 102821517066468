import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import type { DKIMType } from "domain/domain";
import { createToastResponseError } from "lib/toastify";
import { updateQueryParams } from "lib/queryParams";
import { selectModalData } from "services/store/modalDataSlice";
import { useAppSelector } from "services/hooks";
import { userEmailsService } from "services/userEmailsService";

import { _variables } from "ui/styles/_variables";
import { scroll } from "ui/styles/elements/scroll";

import { LoadDots } from "../LoadDots";
import { AlertSmall } from "../Alerts/AlertSmall";
import { Button } from "../Buttons/Button";
import { Icon } from "../Icon";
import { TooltipHover } from "../TooltipHover";
import { BlackNotify } from "../BlackNotify";

const Content = styled.div`
  width: 850px;
  max-height: calc(100vh - 50px);
  padding: 6px;
  padding-top: 0;
  min-height: 560px;
  overflow: hidden;
`;
const ContentScroll = styled.div`
  width: 100%;
  padding: 0px 24px 30px 30px;
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow-y: auto;
  row-gap: 24px;

  max-height: calc(100vh - 150px);

  ${scroll};
`;

const DomainWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 8px;
`;

const LoadContent = styled.div`
  width: 850px;
  min-height: 560px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const DomainName = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const NeedHelp = styled.div`
  display: flex;
  color: ${_variables.supportColors2.green};
  cursor: default;
`;

const StatusBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const StatusLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Status = styled.span<{ isBad: boolean }>`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  color: ${(props) =>
    props.isBad
      ? _variables.statusColors.blocked
      : _variables.statusColors.success};
`;
const ListStatus = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: disc;
  list-style-position: inside;

  & li {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
    text-align: left;
  }
`;
const StatusText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: ${_variables.grayTextColor};
`;

const RepeatButton = styled(Button)`
  height: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
  margin-left: 12px;
  color: ${_variables.grayTextColor};
  border-radius: 4px;
  padding: 4px 8px;
`;

const Table = styled.table`
  width: 100%;
  & th {
    height: 37px;
    border-bottom: 1px solid ${_variables.backgroundColor};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
  }
  & td {
    word-break: break-all;
    padding: 6px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
    text-align: left;
  }
  & tr:first-child {
    & td {
      padding-top: 12px;
    }
  }
  & tr {
    &:first-child {
      padding-top: 12px;
    }
    & td:nth-child(1) {
      width: 332px;
    }
    & td:nth-child(2) {
      width: 150px;
    }
  }
`;

export const DKIMDomain: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const modalData = useAppSelector(selectModalData);
  const [isLoad, setLoad] = useState(true);
  const [data, setData] = useState<DKIMType | null>(null);
  const [isBadStatus, setBadStatus] = useState(false);
  const history = useHistory();

  const loadData = async () => {
    try {
      setLoad(true);
      const response = await userEmailsService.domainInfo(modalData!.domain);
      setData(response.data.data);
      setBadStatus(
        response.data.data.errors && response.data.data.errors.length > 0
      );
      updateQueryParams(history, {});
      setLoad(false);
    } catch (e) {
      createToastResponseError(e, "Ошибка загрузки информации о DKIM")();
      onClose();
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoad)
    return (
      <LoadContent>
        <LoadDots />
      </LoadContent>
    );
  return (
    <Content>
      <ContentScroll>
        <StatusBlock>
          <StatusLine>
            <DomainWrapper>
              <Text>Домен:</Text>
              <DomainName>{modalData!.domain}</DomainName>
              <RepeatButton onClick={loadData} variant={"secondary"}>
                <Icon
                  id={"repeat-icon"}
                  style={{ marginRight: 4 }}
                  width={16}
                  height={16}
                />
                Обновить статус
              </RepeatButton>
            </DomainWrapper>
            <NeedHelp>
              <TooltipHover
                offset={[-85, -60]}
                content={
                  <BlackNotify
                    right={240}
                    style={{ padding: "6px 12px", left: 50 }}
                  >
                    Для помощи с настройкой напишите <br /> в нашу службу
                    поддержки
                  </BlackNotify>
                }
              >
                <Icon id={"info"} width={18} height={18} />
                Нужна помощь с настройкой?
              </TooltipHover>
            </NeedHelp>
          </StatusLine>
          <StatusLine>
            <Text>Статус:</Text>
            <Status isBad={isBadStatus}>
              {isBadStatus
                ? "Ресурсные записи не обнаружены"
                : "Ошибок не обнаружено"}
            </Status>
          </StatusLine>
          {isBadStatus && (
            <ListStatus>
              {data!.errors.map((item) => (
                <li>{item}</li>
              ))}
            </ListStatus>
          )}
        </StatusBlock>
        <Table>
          <thead>
            <tr>
              <th>Хост</th>
              <th>Тип записи</th>
              <th>Значение</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>@</td>
              <td>TXT</td>
              <td>{data!.spf}</td>
            </tr>
            <tr>
              <td>cheapsender._domainkey</td>
              <td>TXT</td>
              <td>{data!.dkim}</td>
            </tr>
            <tr>
              <td>_dmarc</td>
              <td>TXT</td>
              <td>{data!.dmarc}</td>
            </tr>
          </tbody>
        </Table>
        <StatusText>
          Для добавления TXT-записи вы должны иметь доступ к редактированию
          DNS-записей домена. Обычно это делается через веб-интерфейс
          хостинг-провайдера или регистратора доменного имени. Добавьте в DNS
          зону домена три записи со следующими значениями:
        </StatusText>

        <AlertSmall
          text={
            <p>
              Иногда интерфейс управления DNS не позволяет использовать символ @
              в качестве хоста. В этом случае оставьте поле "Хост" пустым.
            </p>
          }
        />
      </ContentScroll>
    </Content>
  );
};
