import axios from "axios";

export type CreateIdea = {
  theme: string;
  content: string;
};

export type Tag = {
  id: number;
  name: string;
  count: number;
};

export type SortType = "id" | "-id" | "-like_count" | "-comment_count";

type GetAllParams = {
  sort: SortType;
  theme?: string;
  statusId?: number;
  tagId?: number;
  currentPage: number;
};

export const serviceIdea = {
  path: "idea/",

  getAll({ sort, theme, statusId, tagId, currentPage }: GetAllParams) {
    const search = theme ? `IdeaSearch[theme]=${theme}&` : "";
    const status = statusId ? `IdeaSearch[status]=${statusId}&` : "";
    const tag = tagId ? `IdeaSearch[tagId]=${tagId}&` : "";
    const page = `page=${currentPage}`;
    return axios.get(
      `${this.path}?sort=${sort}&${search}${status}${tag}${page}`
    );
  },

  get(id: string | number) {
    return axios.get(`${this.path}view?id=${id}`);
  },

  getComments(id: number | string, page: number) {
    return axios.get(`${this.path}comments?idea_id=${id}&page=${page}`);
  },

  getTags() {
    return axios.get(`${this.path}tags`);
  },

  getStatuses() {
    return axios.get(`${this.path}statuses`);
  },

  createIdea(body: CreateIdea) {
    return axios.post(`${this.path}create`, body);
  },

  like(id: number) {
    return axios.post(`${this.path}like?id=${id}`);
  },

  unLike(id: number) {
    return axios.post(`${this.path}unlike?id=${id}`);
  },

  addComment(id: number | string, content: string, reply_to?: number) {
    return axios.post(`${this.path}comment`, {
      idea_id: id,
      content,
      reply_to,
    });
  },

  getNotViewCount() {
    return axios.get(`${this.path}not-view-count`);
  },
};
