import styled from "styled-components";

const Content = styled.div`
  display: flex;
  column-gap: 124px;
  justify-content: space-between;
  width: 100%;
`;
export const WrapperContent: CT<unknown> = ({ children }) => {
  return <Content>{children}</Content>;
};
