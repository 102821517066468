import { serviceUser } from "services/user";
import { useAppDispatch } from "services/hooks";
import { setUserData } from "services/store/userSlice";
import { createToastResponseError } from "lib/toastify";

export const useUserWorkflow = () => {
  const dispatch = useAppDispatch();

  const profile = async () => {
    try {
      const user = await serviceUser.profile();
      dispatch(
        setUserData({
          ideaCount: user.data.data.ideaCount,
          newsCount: user.data.data.newsCount,
          ...user.data.data.user,
          ...user.data.data.profile,
        })
      );
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  return {
    profile,
  };
};
