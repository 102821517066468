import styled, { css } from "styled-components";

import { _variables } from "ui/styles/_variables";
import { mxm } from "ui/styles/_mixin";

import imagePreviewCreateTemplate from "../../assets/media/images/create-template-preview.svg";
import imagePreviewHTML from "../../assets/media/images/create-html-preview.svg";
import { useHistory } from "react-router";

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 15px;
  & a {
    display: flex;
    width: calc(50% - 16px);
    ${mxm(
      1400,
      css`
        width: 100%;
        margin: 16px 0;
      `
    )}
  }
`;
const Item = styled.div`
  border: 1px solid ${_variables.strokeColor};
  min-width: 545px;
  width: 100%;
  padding: 24px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  height: 210px;
  transition: all ${_variables.transition};
  color: ${_variables.blackColor};
  &:hover {
    border: 1px solid ${_variables.primaryColor};
    color: ${_variables.blackColor};
  }
`;

const Heading = styled.h3`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 12px;
`;

const Subheading = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const CreateTemplatePreview = styled.div`
  width: 308px;
  height: 172px;
  position: absolute;
  bottom: -20px;
  right: 10px;
  & img {
    width: 100%;
    height: 100%;
  }
`;
const CreateTemplateHTML = styled.div`
  width: 179px;
  height: 193px;
  position: absolute;
  bottom: 0px;
  right: 10px;
  & img {
    width: 100%;
    height: 100%;
  }
`;

export const SelectEditor: CT<{ onClose: () => void }> = ({ onClose }) => {
  const history = useHistory();

  return (
    <Content>
      <Item
        onClick={() => {
          history.push("/templates/creator?publicTemplate=true");
          onClose();
        }}
      >
        <Heading>Создать шаблон в конструкторе</Heading>
        <Subheading>Собирайте письма из блоков с нуля</Subheading>
        <CreateTemplatePreview>
          <img src={imagePreviewCreateTemplate} alt="" />
        </CreateTemplatePreview>
      </Item>
      <Item
        onClick={() => {
          history.push("/templates/text-editor?publicTemplate=true");
          onClose();
        }}
      >
        <Heading>
          Создать шаблон <br /> в HTML-редакторе
        </Heading>
        <Subheading>Редактируйте готовые шаблоны или свои</Subheading>
        <CreateTemplateHTML>
          <img src={imagePreviewHTML} alt="" />
        </CreateTemplateHTML>
      </Item>
    </Content>
  );
};
