import { useEffect, useState } from "react";
import { addDays, format, parse, setHours, setMinutes } from "date-fns";
import { ru } from "date-fns/locale";

import { apiDateFormat } from "domain/time";
import type { BookSplitListType } from "domain/lists";
import { useCreateMailig } from "app/createMailing/useCreateMailig";
import { contactsListService } from "services/list";
import { portAutoLightMailing } from "services/mailing";
import type { TemplateViewStateType } from "services/store/createMailingSlice";

import { ActionIcon } from "ui/components/Icons/ActionIcon";
import { MailScheduleCounter } from "ui/components/MailScheduleCounter";
import { Button } from "ui/components/Buttons/Button";
import { SelectTimePicker } from "ui/components/FormsElements/TimePickers/SelectTimePicker";
import { Icon } from "ui/components/Icon";
import { AutoMailingStyled } from "./styles";

const {
  IBoxTitle,
  IBoxTopLine,
  IInfoBox,
  IContent,
  ICount,
  FromToWrapper,
  IIconWrapper,
  Wrapper,
  IRow,
  IRowButtons,
  IDate,
  IRowTimeTable,
  ITimetable,
  LinkSetting,
  DatePicker,
} = AutoMailingStyled;

const createShowMailScheduleData = (
  data: BookSplitListType
): { title: string; counter: number }[] =>
  data.map((item) => ({
    counter: item.limit,
    title: format(parse(item.date, apiDateFormat, new Date()), "d MMM", {
      locale: ru,
    }),
  }));

const createTitleDate = (data: BookSplitListType) => {
  if (data.length === 0) return "";
  const lastDate = parse(data[data.length - 1].date, apiDateFormat, new Date());
  const startDate = parse(data[0].date, apiDateFormat, new Date());

  const startDay = format(startDate, "d");
  const lastDay = format(lastDate, "d");
  return `${startDay} - ${lastDay} ${format(lastDate, "MMMM yyyy", {
    locale: ru,
  })} года`;
};

export const AutoMailingForm: CT<{
  onClose: VoidFunction;
  mailingData: NonNullable<TemplateViewStateType["data"]>;
  onOpenProMode: VoidFunction;
}> = ({ onClose, mailingData, onOpenProMode }) => {
  const { submitMailing, isLoad: isSubmiting } = useCreateMailig();

  const [isOpenEditDate, setOpenEditDate] = useState(false);
  const [isOpenEditTime, setIsOpenEditTime] = useState(false);
  const [mailingDatesSplit, setMailingDatesSplit] = useState<BookSplitListType>(
    []
  );

  const [fromDate, setFromDate] = useState(addDays(new Date(), 1));
  const [fromDateTime, setFromDateTime] = useState(
    setHours(setMinutes(new Date(), 0), 18)
  );

  const currentBookId = mailingData.secondStep?.listId;
  const totalCount = mailingData.secondStep?.count;

  const submitHandler = async () => {
    const time = format(fromDateTime, "HH:mm");
    const prepareData = portAutoLightMailing(mailingData, time, fromDate, 1);
    await submitMailing(prepareData);
  };

  const editTimeToggleHandler = () => {
    setIsOpenEditTime(!isOpenEditTime);
  };

  const editDateToggleHandler = () => {
    setOpenEditDate(!isOpenEditDate);
  };

  const changeTimeHandler = (val: Date) => {
    setFromDateTime(val);
    setIsOpenEditTime(false);
  };

  const changeDateHandler = (val: Date | null) => {
    setFromDate(val ?? fromDate);
    setOpenEditDate(false);
  };

  useEffect(() => {
    if (!currentBookId) return;
    if (!fromDate) return;
    (async () => {
      try {
        const data = await contactsListService.getSplitBook({
          id: currentBookId,
          fromDate: fromDate,
          withHolydays: true,
        });
        setMailingDatesSplit(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [currentBookId, fromDate]);

  return (
    <Wrapper>
      <IContent>
        <IRow>
          <IInfoBox>
            <IBoxTopLine>
              <IBoxTitle>Даты рассылки</IBoxTitle>
              <IIconWrapper onClick={editDateToggleHandler}>
                <ActionIcon
                  variant={"edit"}
                  height={16}
                  width={16}
                  name={"pen"}
                />
              </IIconWrapper>
            </IBoxTopLine>
            {isOpenEditDate ? (
              <FromToWrapper>
                <DatePicker
                  isMinDayNow={true}
                  initialDate={fromDate}
                  onChangeDate={changeDateHandler}
                />
              </FromToWrapper>
            ) : (
              <IDate>{createTitleDate(mailingDatesSplit)}</IDate>
            )}
          </IInfoBox>
          <IInfoBox>
            <IBoxTopLine>
              <IBoxTitle>Время начала отправки (по мск)</IBoxTitle>
              <IIconWrapper onClick={editTimeToggleHandler}>
                <ActionIcon
                  variant={"edit"}
                  height={16}
                  width={16}
                  name={"pen"}
                />
              </IIconWrapper>
            </IBoxTopLine>
            {isOpenEditTime ? (
              <FromToWrapper>
                <SelectTimePicker
                  dateFrom={fromDateTime}
                  onChangeFromDateHandler={changeTimeHandler}
                />
              </FromToWrapper>
            ) : (
              <IDate>C {format(fromDateTime, "HH:mm")}</IDate>
            )}
          </IInfoBox>
        </IRow>
        <ITimetable>
          <IBoxTopLine>
            <IBoxTitle>Количество писем в каждой рассылке</IBoxTitle>
            <ICount>Всего писем: {totalCount}</ICount>
          </IBoxTopLine>
          <IRowTimeTable count={mailingDatesSplit.length + 1}>
            {createShowMailScheduleData(mailingDatesSplit).map((item) => (
              <MailScheduleCounter key={item.title} {...item} />
            ))}
          </IRowTimeTable>
        </ITimetable>

        <LinkSetting
          color={"primary"}
          link={"/"}
          onClick={(e) => {
            e.preventDefault();
            onOpenProMode();
          }}
        >
          <Icon id={"info-icon"} width={16} height={16} />
          <span>Настройки вручную</span>
        </LinkSetting>

        <IRowButtons>
          <Button widthMode={"full"} onClick={onClose} variant={"grey"}>
            Отменить
          </Button>

          <Button
            onClick={submitHandler}
            disabled={isSubmiting}
            widthMode={"full"}
          >
            Запустить автопрогрев
          </Button>
        </IRowButtons>
      </IContent>
    </Wrapper>
  );
};
