import type { PropsWithChildren } from "react";
import React from "react";
import { connect } from "react-redux";

import { setAppError } from "services/store/appSlice";

type Props = PropsWithChildren<{
  setAppError: (err: AppError) => void;
}>;

type State = {
  error: AppError | null;
};

class ComponentBoundary extends React.Component<Props, State> {
  setError: (err: AppError) => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
    };
    this.setError = props.setAppError.bind(this);
  }

  static getDerivedStateFromError(err: Error) {
    const log =
      err.message +
        "\n" +
        err?.stack?.toString().replaceAll(/\san\s/gm, "\n an") ?? "";
    return {
      error: {
        message: "Error boundary",
        status: 0,
        log,
      },
    };
  }

  componentDidCatch() {
    if (this.state.error) {
      this.setError(this.state.error);
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export const ErrorBoundary = connect(null, (dispatch) => {
  return {
    setAppError: (err: AppError) => {
      dispatch(setAppError({ ...err }));
    },
  };
})(ComponentBoundary);
