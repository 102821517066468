import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";

import { Input } from "../../components/FormsElements/Input";
import { Button } from "../../components/Buttons/Button";
import { LoadImage } from "../../components/FormsElements/LoadImage";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
`;
export const UserSettings: CT<{ onClose: VoidFunction }> = () => {
  const [isLoad, setLoad] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Поле обязательно для заполнения")
      .max(50, "Максимум 50 символов"),
    link: Yup.string().required("Поле обязательно для заполнения"),
  });

  const initialValues = {
    name: "",
    link: "",
    timeZone: "",
  };

  const submitHandler = async () => {
    setLoad(true);
    try {
      // const request = await serviceUser.joinByEmail({
      //   ...data,
      //   password_confirm: data.password,
      //   phone: data.tel,
      // });
      // setApiStatus(request.data);
    } catch (e) {
      // setApiStatus(e.response.data);
    } finally {
      setLoad(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    onSubmit: submitHandler,
    validationSchema: validationSchema,
  });

  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <LoadImage />
        <Input
          label={"Ваше имя"}
          type={"text"}
          onChange={formik.handleChange}
          name={"name"}
          error={formik.errors.name}
        />
        <Input
          label={"Персональная ссылка"}
          type={"text"}
          onChange={formik.handleChange}
          name={"name"}
          error={formik.errors.name}
        />

        <Button isLoad={isLoad} type={"submit"}>
          Сохранить
        </Button>
      </Form>
    </>
  );
};
