import axios from "axios";

import type {
  UserNotification,
  UserPayCardDataType,
  UserProfileType,
  UserType,
} from "domain/user";
import type { PublicDomainsType } from "domain/userEmail";
import type { ServiceListPropTypes } from "domain/api";

export type ApiJoinByEmailData = {
  name: string;
  surname: string;
  email: string;
  password: string;
  password_confirm: string;
};

export type ApiLoginDataType = {
  email: string;
  password: string;
};

export type ApiUserForgotPasswordDataType = {
  password: string;
  password_confirm: string;
  email: string;
  token: string;
};

export type ApiPublicDomainType = {
  id: number;
  name: string;
};

export type APIUserChangePassword = {
  password: string;
  passwordConfirm: string;
  oldPassword: string;
};

export const serviceUser = {
  path: "user/",
  joinByEmail(data: ApiJoinByEmailData) {
    const body = new FormData();
    body.append("RegistrationForm[name]", data.name);
    body.append("RegistrationForm[surname]", data.surname);
    body.append("RegistrationForm[email]", data.email);
    body.append("RegistrationForm[password]", data.password);
    body.append("RegistrationForm[password_confirm]", data.password_confirm);
    return axios.post<ApiResponseBodyType<unknown>>(
      this.path + "join-by-email",
      body
    );
  },

  update(data: {
    name?: string;
    surname?: string;
    patronymic?: string;
    phone?: string;
    photo_upload?: string;
    timezone?: string;
  }) {
    const body = new FormData();
    if (data.name) body.append("ProfileForm[name]", data.name);
    if (data.surname) body.append("ProfileForm[surname]", data.surname);
    if (data.patronymic)
      body.append("ProfileForm[patronymic]", data.patronymic);
    if (data.phone) body.append("ProfileForm[phone]", data.phone);
    if (data.photo_upload)
      body.append("ProfileForm[photo_upload]", data.photo_upload);
    if (data.timezone) body.append("ProfileForm[timezone]", data.timezone);

    return axios.post<ApiResponseBodyType<unknown>>(
      `${this.path}profile-update`,
      body
    );
  },
  login(data: ApiLoginDataType) {
    const body = new FormData();
    body.append("LoginForm[email]", data.email);
    body.append("LoginForm[password]", data.password);
    return axios.post<ApiResponseBodyType<{ user: { jwt: string } }>>(
      this.path + "login",
      body
    );
  },
  confirm(token: string) {
    return axios.get<ApiResponseBodyType<unknown>>(
      this.path + `verify-email?token=${token}`
    );
  },

  recoveryEmail(email: string) {
    return axios.get<ApiResponseBodyType<unknown>>(
      this.path + `forgot-password?email=${email}`
    );
  },

  changePassword({
    password,
    oldPassword,
    passwordConfirm,
  }: APIUserChangePassword) {
    return axios.post<ApiResponseBodyType<unknown>>(
      this.path + "update-password",
      {
        UpdatePasswordForm: {
          old_password: oldPassword,
          password,
          password_confirm: passwordConfirm,
        },
      }
    );
  },

  resendConfirm(email: string) {
    return axios.get<ApiResponseBodyType<unknown>>(
      this.path + `resend-email-confirm?email=${email}`
    );
  },

  forgotPassword(data: ApiUserForgotPasswordDataType) {
    const body = new FormData();
    body.append("ForgotPasswordForm[password]", data.password);
    body.append("ForgotPasswordForm[password_confirm]", data.password_confirm);
    body.append("ForgotPasswordForm[token]", data.token);
    body.append("ForgotPasswordForm[email]", data.email);
    return axios.post<ApiResponseBodyType<UserType>>(
      this.path + `forgot-password?email=${data.email}`,
      body
    );
  },
  profile() {
    return axios.get<
      ApiResponseBodyType<
        { profile: UserProfileType; user: UserType } & UserNotification
      >
    >(this.path + "profile", {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  },
  setToken(token: string) {
    localStorage.setItem("token", token);
  },

  getToken() {
    return localStorage.getItem("token");
  },

  isAuth() {
    return !!localStorage.getItem("token");
  },

  clearToken() {
    localStorage.removeItem("token");
  },

  getPublicDomains(): Promise<PublicDomainsType> {
    return axios
      .get("user-email/public-domains")
      .then((response) =>
        response.data.data.domains.map(
          (domain: ApiPublicDomainType) => domain.name
        )
      );
  },

  confirmNewEmail(token: string) {
    return axios.get(`user-email/confirm?token=${token}`);
  },

  updateEmail(email: string) {
    const body = new FormData();
    body.append("UpdateEmailForm[email]", email);
    return axios.post<ApiResponseBodyType<unknown>>(
      `${this.path}update-email`,
      body
    );
  },

  notifyNewRegistration() {
    return axios.get<
      ApiResponseBodyType<{
        notification: {
          id: number;
          date_in: string;
          params: {
            title: string;
            scenario?: string;
            message: string;
          };
        };
      }>
    >(`${this.path}get-notification`);
  },

  deleteNotify(id: number | string) {
    return axios.get(`${this.path}delete-notification?id=${id}`);
  },

  getPaymentCards() {
    return axios.get<
      ApiResponseBodyType<{ "user-payment-cards": UserPayCardDataType[] }>
    >(`${this.path}get-payment-cards`);
  },

  getUserCardsCollection({ perPage, page }: ServiceListPropTypes<unknown>) {
    return axios.get<
      ApiResponseBodyType<{ "user-payment-cards": UserPayCardDataType[] }>
    >(`${this.path}get-payment-cards?page=${page}&per-page=${perPage}`);
  },

  deletePaymentCard(id: number) {
    return axios.get<ApiResponseBodyType<unknown>>(
      `${this.path}delete-payment-card?id=${id}`
    );
  },
};
