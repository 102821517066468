import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  importStoreAction,
  selectImportMode,
} from "services/store/importSlice";
import { getFormatNum } from "lib/numbers";

import { _variables } from "ui/styles/_variables";
import img from "ui/assets/media/images/import-badge.svg";

import { Link } from "../Link";

const Content = styled.div`
  display: flex;
  column-gap: 12px;
  height: 80px;
  align-items: center;
  padding: 16px;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 8px;
  width: 100%;
  position: relative;

  &:before {
    height: 24px;
    position: absolute;
    left: -0.5px;
    top: 50%;
    content: "";
    width: 1.5px;
    background-color: ${_variables.primaryColor};
    transform: translateY(-50%);
  }
`;
const Preview = styled.div`
  display: flex;
  width: 48px;
  height: 48px;

  & img {
    width: 100%;
    height: 100%;
  }
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;
const Count = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

export const CounterBox: CT<{
  count: number;
  isDisable?: boolean;
  fileName: string | null;
  isHideBackLink?: boolean;
}> = ({ count, isHideBackLink = false, isDisable = false, fileName }) => {
  const dispatch = useAppDispatch();
  const modeImport = useAppSelector(selectImportMode);
  return (
    <Content>
      <Preview>
        <img src={img} alt="" />
      </Preview>
      <TextBox>
        <Count>
          {modeImport === "load" ? (
            <span>{!!fileName ? `${fileName}` : "контакты-из-файла.txt"}</span>
          ) : (
            <span>Всего контактов: {getFormatNum(count)}</span>
          )}
        </Count>
        {!isHideBackLink && (
          <Link
            onClick={(e) => {
              e.preventDefault();
              dispatch(importStoreAction.goBackToFileLoad(modeImport));
            }}
            link={"/"}
            color={"gray"}
            disabled={isDisable}
          >
            Изменить
          </Link>
        )}
      </TextBox>
    </Content>
  );
};
