import type {
  FlattenInterpolation,
  ThemedStyledProps,
} from "styled-components";
import { css } from "styled-components";

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )}`
    : null;
}

export function hexToRgba(hex: string, opacity: string | number) {
  const result = hexToRgb(hex);
  if (!result) return null;
  return `${result},${opacity}`;
}

export const mxm = (
  width: number,
  rules: FlattenInterpolation<ThemedStyledProps<object, string>>
) => css`
  @media screen and (max-width: ${width}px) {
    ${rules}
  }
`;

export const mxmHeight = (
  width: number,
  rules: FlattenInterpolation<ThemedStyledProps<object, string>>
) => css`
  @media screen and (max-height: ${width}px) {
    ${rules}
  }
`;
