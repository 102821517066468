import type { OverlayProps } from "react-overlays/Overlay";
import styled from "styled-components";

import { TooltipClicked } from "../TooltipClicked";
import { ContentMenu } from "../ContentMenu";
import { ArrowsIcon } from "ui/components/Icons/ArrowsIcon";

const Container = styled.div``;

const InnerContent = styled.div`
  display: flex;
  column-gap: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  cursor: pointer;
  text-align: center;
`;

export const AdditionalMenuWord: CT<{
  contentMenu: JSX.Element;
  offset?: OverlayProps["offset"];
}> = ({ contentMenu, offset = [0, 0] }) => {
  return (
    <Container>
      <TooltipClicked
        offset={offset}
        content={<ContentMenu>{contentMenu}</ContentMenu>}
      >
        <InnerContent>
          Действия
          <ArrowsIcon
            className={"rotate_active"}
            variant={"select"}
            name={"arrow"}
          />
        </InnerContent>
      </TooltipClicked>
    </Container>
  );
};
