import styled from "styled-components";
import SwitchPlugin from "react-switch";

import { _variables } from "ui/styles/_variables";
import { Icon } from "ui/components/Icon";

const IContainer = styled.div`
  width: 100%;
  height: 110px;
  border-radius: 12px;
  background-color: ${_variables.backgroundColor};
  padding: 16px;
  row-gap: 12px;
  display: flex;
  flex-direction: column;
`;

const ITop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const IHeading = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const ITextContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 13px;
`;

const IIconWrapper = styled.div`
  min-width: 26px;
  min-height: 26px;
`;

const IText = styled.p`
  color: ${_variables.grayColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

export const AutoMailingPreview: CT<{
  onCallAutoMailing: VoidFunction;
  isActiveSwitch: boolean;
}> = ({ onCallAutoMailing, isActiveSwitch }) => {
  return (
    <IContainer>
      <ITop>
        <IHeading>Автоматический прогрев</IHeading>
        <SwitchPlugin
          draggable={false}
          uncheckedIcon={false}
          checkedIcon={false}
          onChange={() => {
            onCallAutoMailing();
          }}
          checked={isActiveSwitch}
          onColor={_variables.primaryColor}
          offColor={_variables.grayColor}
          width={32}
          height={20}
          handleDiameter={16}
          borderRadius={12}
        />
      </ITop>
      <ITextContent>
        <IIconWrapper>
          <Icon id={"info-icon"} width={26} height={26} />
        </IIconWrapper>
        <IText>
          Для улучшения доставляемости мы разделим рассылку на несколько дней и
          будем постепенно увеличивать число отправляемых писем
        </IText>
      </ITextContent>
    </IContainer>
  );
};
