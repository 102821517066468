import { useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import styled from "styled-components";

import { updateQueryParams } from "lib/queryParams";
import { createToastResponseError } from "lib/toastify";
import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { contactsListService } from "services/list";

import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";
import { Input } from "../../components/FormsElements/Input";
import { Button } from "../../components/Buttons/Button";
import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`;

export const RenameListForm: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const [isLoad, setLoad] = useState(false);
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Поле обязательно для заполнения"),
  });

  const modalData = useAppSelector(selectModalData);

  const onSubmit = async (data: { name: string }) => {
    setLoad(true);
    try {
      const request = await contactsListService.renameList(
        data.name,
        modalData!.id
      );
      toast(request.data.message, {
        autoClose: 3000,
      });
      updateQueryParams(history, {
        page: "1",
        name: data.name,
      });
    } catch (e) {
      createToastResponseError(e)();
    } finally {
      setLoad(false);
      onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: modalData?.name ?? "",
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <ModalDefaultFormContainer>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Input
          label={"Название списка"}
          type="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          name={"name"}
          placeholder={"Переименование списка"}
          error={formik.errors.name}
        />
        <ModalButtonsContainer style={{ marginTop: 12 }}>
          <Button size={"normal"} isLoad={isLoad} type={"submit"}>
            Переименовать
          </Button>
          <Button
            onClick={onClose}
            size={"normal"}
            variant={"secondary"}
            type={"submit"}
          >
            Отменить
          </Button>
        </ModalButtonsContainer>
      </Form>
    </ModalDefaultFormContainer>
  );
};
