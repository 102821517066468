import type { ChangeEvent, TextareaHTMLAttributes } from "react";
import { useState } from "react";
import styled from "styled-components";

import { formElement, formWrapper } from "ui/styles/elements/formElements";
import { _variables } from "ui/styles/_variables";

const InputElement = styled.textarea<{ isError: boolean; haveIcon: boolean }>`
  ${formElement};
  width: 100%;
  resize: none;
  height: 120px;
  font-family: ${_variables.fontFamily};
`;

const Wrapper = styled.div`
  ${formWrapper};
  width: 100%;
`;

export const TextArea: CT<
  {
    error?: string | boolean;
    label?: string;
    description?: string;
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  } & TextareaHTMLAttributes<unknown>
> = ({
  value = "",
  error = "",
  label = "",
  description = "",
  onChange = () => {},
  ...rest
}) => {
  const [innerValue, setValue] = useState(value);

  return (
    <Wrapper>
      {label && <p className={"label"}>{label}</p>}
      {description && <p className={"description"}>{description}</p>}
      <div className={"form"}>
        <InputElement
          value={innerValue}
          onChange={(e) => {
            onChange(e);
            setValue(e.target.value);
          }}
          haveIcon={false}
          isError={!!error}
          maxLength={5000}
          {...rest}
        />
      </div>
      {error !== undefined && <span className={"error"}>{error}</span>}
    </Wrapper>
  );
};
