import cheerio from "cheerio";

export const usePrepareHTMLTemplate = () => {
  // фикс бага блочного редактора с удалением текстового тега при вводе текста
  // воиспроизвести баг можно так
  // создать кнопку => полностью удалить весь текст
  // подметить что высота уменьшилась на 1-2px, это значит, что редактор удалил внутренний span
  // фикс его восстанавливает
  // https://unlayer.canny.io/bug-reports/p/button-text-changes-to-letter-b

  const fixEmptyButtonTag = (html: string) => {
    try {
      const $ = cheerio.load(html);
      const linkSpans = $(
        // своеобразная выборка для редактора
        "body[class^='clean-body'] a[style*='mso-border-alt'] > span"
      );
      linkSpans.each(function () {
        if ($(this).text().length > 0) {
          // выбираем только те спаны, что содержат необернутые текстовые ноды
          const textNodes = $(this)[0].children.filter(
            (node: any) => node.type === "text"
          );
          const text = $(textNodes).text();
          // удаляем текстовые ноды и подставляем вместо них спаны
          $(textNodes).remove();
          $(this).prepend(
            `<span style="font-size: 14px;line-height: 19.2px">${text}</span>`
          );
        }
      });

      return $.html();
    } catch (e) {
      console.log(e);
      return html;
    }
  };
  const fixParagraphTagMarginMailRu = (html: string) => {
    try {
      const $ = cheerio.load(html);
      $("head").append(`
        <style>
          p{
            margin: 0 !important;
          }
        </style>
      `);
      return $.html();
    } catch (e) {
      console.log(e);
      return html;
    }
  };
  return { fixParagraphTagMarginMailRu, fixEmptyButtonTag };
};
