import { useEffect, useRef, useState } from "react";
import { useFocusWithin } from "ahooks";
import styled from "styled-components";

import { formElement, formWrapper } from "ui/styles/elements/formElements";
import { _variables } from "ui/styles/_variables";
import IconSelectSVG from "ui/assets/media/icons/arrow-select-icon.svg";

import { SelectBox } from "../SelectBox";

const Wrapper = styled.div`
  ${formWrapper}
`;

const Element = styled.label<{ isError: boolean; haveIcon: boolean }>`
  ${formElement};
  position: relative;
  cursor: pointer;
  width: 100%;
  min-width: 240px;
  padding: 13px 16px;
  display: flex;
  align-items: center;

  border: 2px solid transparent;

  &:focus-within {
    & .icon {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  & select {
    cursor: pointer;
    border: ${(props) =>
      props.isError
        ? `2px solid ${_variables.supportColors.red}`
        : "2px solid transparent"};
    transition: all ${_variables.transition};

    &:focus {
      border: 2px solid ${_variables.primaryColor} !important;
    }

    width: 100%;
    padding: 0 16px;
    height: 100%;
    background: transparent;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: ${_variables.borderRadius};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;

    margin: 0;
  }

  & select:focus {
    border: none;
    outline: none;
  }
`;

const IconSelect = styled.div`
  background-image: url(${IconSelectSVG});
  background-position: center center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
`;

const ListBoxWrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
`;

const NameWrapper = styled.div<{ isPlaceholder: boolean }>`
  color: ${(props) =>
    props.isPlaceholder ? _variables.grayColor : _variables.blackColor};
`;

export const SelectCustom: CT<{
  error?: string;
  label?: string;
  placeholder?: string;
  values: {
    value: string | React.ReactText;
    name: string | number;
    isDefaultChecked?: boolean;
  }[];
  onChange?: (val: {
    value: string | React.ReactText;
    name: string | number;
  }) => void;
  background?: string;
}> = ({
  placeholder,
  values,
  onChange = () => {},
  label = "",
  error = "",
  className,
  background,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [hasBeenOpen, setHasBeenOpen] = useState(false);
  const [valueState, setValue] = useState<{
    value: string | React.ReactText;
    name: string | number;
    isDefaultChecked?: boolean;
  }>(
    values.find((item) => item.isDefaultChecked) ??
      (!!placeholder
        ? {
            isDefaultChecked: false,
            value: "-1",
            name: placeholder,
          }
        : values?.[0] ?? [])
  );
  const refWrapper = useRef(null);
  const elementRef = useRef(null);
  const clickHandler = () => {
    //@ts-ignore
    elementRef.current.focus();
  };

  // values.find((item) => item.isDefaultChecked) ?? values?.[0] ?? []
  const onChangeHandler = (data: {
    name: string;
    id: number | string;
    data?: any;
  }) => {
    setValue({ name: data.name, value: data.id });
    setOpen(false);

    setTimeout(() => {
      //@ts-ignore
      elementRef.current.blur();
    }, 50);
  };

  useFocusWithin(refWrapper, {
    onFocus: () => {
      setOpen(true);
      setTimeout(() => {
        setHasBeenOpen(true);
      }, 250);
    },
    onBlur: () => {
      setTimeout(() => {
        setOpen(false);
        setHasBeenOpen(false);
      }, 100);
    },
  });

  useEffect(() => {
    onChange(valueState);
  }, [valueState]);

  return (
    <Wrapper ref={refWrapper} className={className}>
      {label && <p className={"label"}>{label}</p>}
      <Element
        tabIndex={0}
        ref={elementRef}
        onClick={clickHandler}
        htmlFor={"select"}
        haveIcon={false}
        isError={error?.length > 0}
        style={{ backgroundColor: background }}
      >
        <IconSelect
          className={"icon"}
          onClick={() => {
            if (!hasBeenOpen) return;
            setOpen(false);
            (elementRef.current as unknown as HTMLDivElement).focus();
            setTimeout(() => {
              (elementRef.current as unknown as HTMLDivElement).blur();
            }, 50);
          }}
        />
        <NameWrapper isPlaceholder={valueState.value === "-1"}>
          {valueState.name}
        </NameWrapper>
        {isOpen && (
          <ListBoxWrapper>
            <SelectBox
              onClickElement={onChangeHandler}
              //@ts-ignore
              values={values.map((item) => ({
                name: item.name,
                id: item.value,
                data: {},
              }))}
            />
          </ListBoxWrapper>
        )}
      </Element>
    </Wrapper>
  );
};
