import { useEffect, useRef, useState } from "react";

const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>(() => {});
  const [isActive, setIsActive] = useState(false);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (!isActive) return;

    const tick = () => {
      savedCallback.current();
    };
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [isActive, delay]);

  const start = () => setIsActive(true);
  const stop = () => setIsActive(false);

  return {
    startInterval: start,
    stopInterval: stop,
  };
};

export default useInterval;
