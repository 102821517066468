import type { ChangeEvent } from "react";
import { useEffect } from "react";
import { debounce } from "react-ace/lib/editorOptions";
import { useTitle } from "ahooks";
import styled from "styled-components";

import { useShowDomainCollection } from "app/tables/useShowDomainCollection";
import type { DomainType } from "domain/domain";
import { appConfig } from "appConfig";
import { createDataForTable2 } from "lib/date";
import { setModalData } from "services/store/modalDataSlice";
import { useAppDispatch } from "services/hooks";
import { openModal } from "services/store/modal";
import { _variables } from "ui/styles/_variables";

import { TableHead } from "../../components/Table/TableHead";
import { TableBody } from "../../components/Table/TableBody";
import { Input } from "../../components/FormsElements/Input";
import { LoadContent } from "../../components/LoadContent";
import { SearchNotFound } from "../../components/NotContent/SearchNotFound";
import { TableHeadOnceFilter } from "../../components/Table/Filters/TableHeadOnceFilter";
import { Icon } from "../../components/Icon";
import { Button } from "../../components/Buttons/Button";
import { ChipDomainStatus } from "../../components/Chips/ChipDomainStatus";

const Table = styled.table`
  padding-left: 40px;
  display: grid;
  grid-template-columns:
    minmax(322px, 100%) minmax(322px, 322px) 180px
    240px;
  grid-auto-rows: min-content;
  margin-top: 20px;
  position: relative;
  z-index: 25;

  & tr {
    min-height: 66px;
  }

  & tr td {
    word-break: break-all;
    border-bottom: 1px solid ${_variables.strokeColor};
    display: flex;
    min-height: 66px;
    height: inherit;
    padding: 10px 0;
    padding-right: 5px;
    align-items: center;
  }
`;

const THead = styled(TableHead)`
  & th {
    border-bottom: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    border-bottom: 1px solid ${_variables.strokeColor};
    color: ${_variables.grayTextColor};
  }
`;

const TBody = styled(TableBody)`
  margin-top: 16px;
  position: relative;
  top: -10px;

  & td {
    border-bottom: none;
  }
`;

const MainHeadingWrapper = styled.div`
  display: flex;

  justify-content: space-between;
  width: 100%;
`;
const MainHeading = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;
const InputSearch = styled(Input)`
  width: 308px;
`;
const HeadingLeftContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 32px;
`;

const Domain = styled.div`
  display: flex;
  column-gap: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const Date = styled.span`
  color: ${_variables.grayTextColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const IconCrossWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CountWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
const Count = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;
const HeadingWrapperButton = styled.div`
  column-gap: 24px;
  display: flex;
  align-items: center;
`;

export const DomainsPageHeader: CT<{
  onChangeSearch: (str: string) => void;
  value: string;
}> = ({ onChangeSearch, value }) => {
  const dispatch = useAppDispatch();
  const changeHandler = debounce((e: ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(e.target.value);
  }, 300);
  useTitle(`Проверка домена${appConfig.titleApp}`);
  return (
    <MainHeadingWrapper>
      <HeadingLeftContent>
        <MainHeading>Настройки аккаунта</MainHeading>
        <HeadingWrapperButton>
          <InputSearch
            value={value}
            onChange={changeHandler}
            name={"email"}
            startIcon={{
              id: "search-glass-icon",
              width: 24,
              height: 24,
            }}
            placeholder={"yandex.ru"}
          />
          <Button
            onClick={() => {
              dispatch(openModal("add-email"));
            }}
          >
            <Icon
              style={{ marginRight: 8 }}
              id={"plus-circle-icon"}
              width={24}
              height={24}
            />{" "}
            <span>Добавить</span>
          </Button>
        </HeadingWrapperButton>
      </HeadingLeftContent>
    </MainHeadingWrapper>
  );
};

const RowContent: CT<{ data: DomainType }> = ({ data }) => {
  const dispatch = useAppDispatch();

  const clickSettingHandler = () => {
    dispatch(setModalData({ domain: data.domain, noPaddingContent: true }));
    dispatch(openModal("dkim"));
  };

  return (
    <tr>
      <td>
        <Domain>{data.domain}</Domain>
      </td>
      <td>
        <Date>{createDataForTable2(data.date_in)}</Date>
      </td>
      <td>
        <span>
          <ChipDomainStatus status={data.status} />
        </span>
      </td>
      <td>
        <CountWrapper>
          <Count>{data.count}</Count>
          <IconCrossWrapper onClick={clickSettingHandler}>
            <Icon id={"setting-icon"} width={24} height={24} />
          </IconCrossWrapper>
        </CountWrapper>
      </td>
    </tr>
  );
};

export const DomainsPageContent: CT<{
  query: {
    perPage?: any;
    page?: any;
    name?: any;
    sortBy?: any;
  };
  onSetCountPage: (val: number) => void;
  onChangeSortBy: (val: string) => void;
}> = ({ onSetCountPage, onChangeSortBy }) => {
  const { data, countPage, query, isLoad, isNotFound } =
    useShowDomainCollection();

  useEffect(() => {
    onSetCountPage(countPage);
  }, [countPage]);

  if (isLoad) return <LoadContent top={120} />;
  return (
    <>
      <Table>
        <THead>
          <TableHeadOnceFilter
            name={"domain"}
            onClick={onChangeSortBy}
            isActive={query.sortBy.includes("domain")}
          >
            Домен
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            name={"added"}
            onClick={onChangeSortBy}
            isActive={query.sortBy.includes("added")}
          >
            Добавлен
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            name={"status"}
            onClick={onChangeSortBy}
            isActive={query.sortBy.includes("status")}
          >
            Статус
          </TableHeadOnceFilter>
          <TableHeadOnceFilter
            name={"count"}
            onClick={onChangeSortBy}
            isActive={query.sortBy.includes("count")}
          >
            Кол-во писем
          </TableHeadOnceFilter>
        </THead>
        <TBody>
          {data.map((item) => (
            <RowContent key={item.domain + item.date_in} data={item} />
          ))}
        </TBody>
      </Table>
      {isNotFound && <SearchNotFound />}
    </>
  );
};
