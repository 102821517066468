import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useUrlState from "@ahooksjs/use-url-state";
import styled from "styled-components";

import { usePayValidationRedirect } from "app/usePayValidationRedirect";
import { useAppDispatch } from "services/hooks";
import { planService } from "services/plan";
import { openModal } from "services/store/modal";
import { setModalData } from "services/store/modalDataSlice";
import { createToastResponseError } from "lib/toastify";

import { LoadDots } from "ui/components/LoadDots";

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const PaymentSuccess = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const payValidation = usePayValidationRedirect();
  const [query] = useUrlState({
    id: "",
  });

  const updatePlan = async (id: number | string) => {
    try {
      const response = await planService.update(id);

      // случай успешной оплаты и наличия информации об оплате на валидацию
      if (
        payValidation.checkInfoPaymentData() &&
        response.data.data?.sale.complete
      ) {
        history.push("/contacts");
        payValidation.callAlert();
        return;
      }

      if (!response.data?.data?.sale) history.push("/");

      // Оплата на пакет
      if (response.data.data?.sale.complete) {
        history.push("/packages/payment");
        const { date_end, limit } = response.data.data.userPlan!;
        dispatch(setModalData({ date_end, limit }));
        dispatch(openModal("payment-success"));
      } else {
        history.push("/packages/payment");
      }
    } catch (e) {
      createToastResponseError(e)();
      history.push("/");
    }
  };

  useEffect(() => {
    if (!query.id) {
      history.push("/");
    }
    if (query.id) {
      updatePlan(query.id);
    }
  }, []);

  return (
    <Content>
      <LoadDots />
    </Content>
  );
};
