import { useHistory } from "react-router-dom";
import html2canvas from "html2canvas";

import { canvasToBlob } from "lib/imageOptimize";
import { blobToBase64 } from "lib/htmlToImage";
import { createToastResponseError } from "lib/toastify";
import { previewTemplate } from "lib/previewTemplate";

import { appConfig } from "../../appConfig";
import { useTemplatePreviewFrame } from "../useTemplatePreviewFrame";
import { useGoBackLink } from "../useGoBackLink";

export const useCreateTemplate = () => {
  const { create } = useGoBackLink();
  const history = useHistory();
  const { createPreviewFrame } = useTemplatePreviewFrame();

  const createPreview = async (html: string) => {
    try {
      const frame = await createPreviewFrame(html);
      let base64;
      try {
        let resultCanvas = await html2canvas(frame!.contentDocument!.body, {
          useCORS: false,
          imageTimeout: 15000,
          proxy: appConfig.proxyImageUrl,
          width: frame!.contentDocument!.body.offsetWidth,
          height:
            frame!.contentDocument!.body!.offsetHeight > 3000
              ? 3000
              : frame?.contentDocument?.body.offsetHeight,
          x: 0,
          y: 0,
          allowTaint: true,
        });
        let image = await canvasToBlob(resultCanvas);
        base64 = await blobToBase64(image);
      } catch (e) {
        console.error("Ошибка формирования канваса");
        console.error(e);
        createToastResponseError(e, "Ошибка формирования превью")();
        base64 = previewTemplate;
      }
      return base64;
    } catch (e) {
      throw e;
    }
  };

  const redirectToCreate = (
    redactor: "html" | "block",
    linkBack?: string,
    id?: number
  ) => {
    if (linkBack) create(linkBack);
    if (redactor === "block") {
      window.document.location.href = id
        ? `/templates/creator?id=${id}`
        : `/templates/creator`;
    }
    if (redactor === "html") {
      history.push(
        id ? `/templates/text-editor?id=${id}` : "/templates/text-editor"
      );
    }
  };

  return {
    redirectToCreate,
    createPreview,
  };
};
