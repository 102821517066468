import styled from "styled-components";

import imagePreview from "ui/assets/media/images/help-info-preview.svg";

import { HeadingCrumbs } from "../HeadingCrumbs";

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 40px 40px 0px 40px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Preview = styled.div`
  display: flex;
  width: 265px;
  height: 265px;
  margin-top: 120px;
  & img {
    width: 100%;
    height: 100%;
  }
`;

export const HelpInfoPage = () => {
  return (
    <Content>
      <Top>
        <HeadingCrumbs
          items={[
            { title: "Помощь", link: "/help/" },
            { title: "База знаний" },
          ]}
        />
      </Top>
      <Preview>
        <img src={imagePreview} alt="" />
      </Preview>
    </Content>
  );
};
