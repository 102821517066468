import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import styled from "styled-components";

import { createToastResponseError } from "lib/toastify";
import { serviceUser } from "services/user";

import { Button } from "../../components/Buttons/Button";
import { Input } from "../../components/FormsElements/Input";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 320px;
`;

export const RestorePasswordForm = () => {
  const [isLoad, setLoad] = useState(false);
  const [finallyForm, setFinally] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Поле обязательно для заполнения")
      .email("Необходимо ввести корректный email"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validateOnChange: false,
    onSubmit: async ({ email }) => {
      setLoad(true);
      try {
        formik.setErrors({ email: "" });
        await serviceUser.recoveryEmail(email);
        toast("Ссылка на восстановление отправлена вам на почту", {
          autoClose: 3000,
        });
        localStorage.setItem("email", email);
        setFinally(true);
        formik.resetForm();
      } catch (err) {
        createToastResponseError(err)();
        formik.resetForm();
        formik.setErrors({ email: " " });
      } finally {
        setLoad(false);
      }
    },
    validationSchema: validationSchema,
  });
  return (
    <>
      <Form
        action=""
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Input
          type="email"
          placeholder={"Email"}
          name={"email"}
          onChange={formik.handleChange}
          error={formik.errors.email}
        />
        <Button
          widthMode={"full"}
          disabled={finallyForm}
          isLoad={isLoad}
          type={"submit"}
        >
          Отправить ссылку
        </Button>
      </Form>
    </>
  );
};
