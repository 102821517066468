import { useEffect, useRef, useState } from "react";
import { Overlay } from "react-overlays";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import type { OverlayProps } from "react-overlays/Overlay";

import { animateHideShowTooltip, animateTransition } from "lib/animations";
import { _variables } from "ui/styles/_variables";

const Container = styled.div`
  position: absolute;
  z-index: 99999;
`;

const AnimationBlok = styled(motion.div)`
  // background-color: ${_variables.blackColor};
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: white;
  border-radius: 8px;
`;

export const TooltipHover: CT<{
  content: JSX.Element;
  placement?: OverlayProps["placement"];
  offset?: OverlayProps["offset"];
  isShow?: boolean;
}> = ({
  content,
  className,
  placement = "auto",
  offset = [0, 0],
  children,
  isShow,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isAnimate, setAnimate] = useState(false);
  const triggerRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (isShow) {
      setOpen(true);
      setAnimate(true);
    }
    if (!isShow) {
      setAnimate(false);
      setTimeout(() => {
        setOpen(false);
      }, 200);
    }
  }, [isShow]);

  return (
    <div className={"tooltip__container"} ref={containerRef}>
      <div
        className={"tooltip__innerContent"}
        ref={triggerRef}
        onMouseEnter={() => {
          setOpen(true);
          setAnimate(true);
        }}
        onMouseLeave={() => {
          if (isShow) return;
          setAnimate(false);
          setTimeout(() => {
            setOpen(false);
          }, 200);
        }}
      >
        {children}
      </div>
      <Overlay
        placement={placement}
        container={containerRef}
        show={isOpen}
        offset={offset}
        target={triggerRef}
      >
        {({ props }) => (
          <>
            <AnimatePresence>
              {isAnimate && (
                <Container className={className} {...props}>
                  <AnimationBlok
                    as={motion.div}
                    {...animateTransition}
                    {...animateHideShowTooltip}
                  >
                    <div>{content}</div>
                  </AnimationBlok>
                </Container>
              )}
            </AnimatePresence>
          </>
        )}
      </Overlay>
    </div>
  );
};
