import { useMemo, useState } from "react";
import styled from "styled-components";

import { _variables } from "ui/styles/_variables";

import { Icon } from "./Icon";
import { Button } from "./Buttons/Button";

interface Option {
  name: string;
  id: number;
  count: number;
  iconId?: string;
}

interface Props {
  options: Option[];
  onChange: (value: Option) => void;
  selectedOption: Option | null;
  heading?: string;
  reverseColors?: boolean;
  elementsToShow?: number;
}

const Container = styled.div`
  background-color: ${_variables.backgroundColor};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 8px 0;
  & .showMore {
    width: 80%;
    margin: 0 auto;
    background: none;
    color: ${_variables.blackColor};
    font-size: 14px;
  }
`;

const Heading = styled.h4`
  font-size: 16px;
  padding: 4px 16px;
  font-weight: 500;
`;
interface ItemProps {
  isActive: boolean;
  colors: {
    active: string;
    secondary: string;
  };
  canShowBorder: boolean;
}
const Item = styled.li<ItemProps>`
  display: flex;
  cursor: pointer;
  margin: 8px 0;
  padding: 8px 20px;
  color: ${({ isActive, colors }) =>
    isActive ? colors.active : colors.secondary};
  border-left: ${({ isActive, canShowBorder, colors }) =>
    isActive && canShowBorder ? `${colors.active} 2px solid` : "none"};
  padding-left: ${({ isActive, canShowBorder }) =>
    isActive && canShowBorder ? "14px" : "16px"};
  &:hover {
    color: ${({ colors }) => colors.active};
  }
`;

const Text = styled.p`
  padding: 0 10px;
`;

const Count = styled.p`
  margin-left: auto;
  color: ${_variables.grayColor};
`;

export const CategorySelect = ({
  options,
  selectedOption,
  onChange,
  heading,
  reverseColors,
  elementsToShow,
}: Props) => {
  const [visibleElCount, setVisibleElCount] = useState<number>(
    elementsToShow || options.length
  );

  const hideElCount = options.length - visibleElCount;

  const visibleElements = useMemo(
    () => options.slice(0, visibleElCount),
    [visibleElCount, options]
  );

  const colors = useMemo(
    () => ({
      active: reverseColors ? _variables.blackColor : _variables.primaryColor,
      secondary: reverseColors
        ? _variables.primaryColor
        : _variables.blackColor,
    }),
    [reverseColors]
  );
  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      <ul>
        {visibleElements.map((category) => {
          const isActive = category.id === selectedOption?.id;
          return (
            <Item
              key={category.id}
              isActive={isActive}
              colors={colors}
              canShowBorder={!!category.iconId}
              onClick={() => onChange(category)}
            >
              {category.iconId && (
                <Icon id={category.iconId} width={25} height={25} />
              )}
              <Text>{category.name}</Text>
              <Count>{category.count}</Count>
            </Item>
          );
        })}
      </ul>
      {hideElCount ? (
        <Button
          className="showMore"
          onClick={() => setVisibleElCount(options.length)}
        >
          Показать еще {hideElCount} тегов
        </Button>
      ) : null}
    </Container>
  );
};
