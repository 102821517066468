import { useRef, useState } from "react";
import { useClickAway, useFocusWithin } from "ahooks";
import { format } from "date-fns";
import styled, { css } from "styled-components";

import { datePickerFormat } from "domain/time";
import { _variables } from "ui/styles/_variables";
import { formElement, formWrapper } from "ui/styles/elements/formElements";

import { Icon } from "../Icon";
import { DatePickerCreateMailing } from "../Datepicker/DatePickerCreateMailing";

const Wrapper = styled.div`
  ${formWrapper}
`;

const Element = styled.label<{
  isError: boolean;
  haveIcon: boolean;
  backgroundColor?: string;
}>`
  ${formElement};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? _variables.backgroundColor};
  position: relative;
  cursor: pointer;
  width: 100%;
  min-width: 240px;
  padding: 13px 16px;
  display: flex;
  align-items: center;

  border: 2px solid transparent;

  &:focus-within {
    & .icon {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  & select {
    cursor: pointer;
    border: ${(props) =>
      props.isError
        ? `2px solid ${_variables.supportColors.red}`
        : "2px solid transparent"};
    transition: all ${_variables.transition};

    &:focus {
      border: 2px solid ${_variables.primaryColor} !important;
    }

    width: 100%;
    padding: 0 16px;
    height: 100%;
    background: transparent;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: ${_variables.borderRadius};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;

    margin: 0;
  }

  & select:focus {
    border: none;
    outline: none;
  }
`;
const Data = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;
const WrapperDatePickerWindow = styled.div<{ isTop: boolean }>`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  z-index: 1;
  ${(props) =>
    props.isTop &&
    css`
      top: auto;
      bottom: calc(100% + 10px);
    `}
`;

export const InputDatePicker: CT<{
  error?: string;
  label?: string;
  onChangeDate?: (date: Date | null) => void;
  initialDate?: Date;
  placeholder?: string;
  hasInitialValue?: boolean;
  isMinDayNow?: boolean;
  positionTopPicker?: boolean;
  background?: string;
}> = ({
  className,
  initialDate = new Date(),
  onChangeDate,
  label = "",
  error = "",
  placeholder = "",
  hasInitialValue = true,
  isMinDayNow = true,
  positionTopPicker = false,
  background,
}) => {
  const refWrapper = useRef(null);
  const elementRef = useRef(null);

  const [value, setValue] = useState<Date | null>(
    hasInitialValue ? initialDate : null
  );

  const [isOpen, setOpen] = useState(false);

  const clickHandler = () => {
    //@ts-ignore
    elementRef.current.focus();
    setOpen(true);
  };

  useFocusWithin(refWrapper, {
    onFocus: () => {
      setOpen(true);
    },
  });
  useClickAway(() => {
    setOpen(false);
  }, refWrapper);

  return (
    <Wrapper ref={refWrapper} className={className}>
      {label && <p className={"label"}>{label}</p>}
      <Element
        backgroundColor={background}
        tabIndex={0}
        ref={elementRef}
        onClick={clickHandler}
        htmlFor={"select"}
        haveIcon={false}
        isError={error?.length > 0}
      >
        <Data>
          <Icon id={"calendar-icon"} width={24} height={24} />
          <p>{value ? format(value, datePickerFormat) : placeholder}</p>
        </Data>
        {isOpen && (
          <WrapperDatePickerWindow isTop={positionTopPicker}>
            <DatePickerCreateMailing
              isMinDayNow={isMinDayNow}
              datePick={value}
              onPickDate={(date) => {
                setValue(date);
                setOpen(false);
                onChangeDate && onChangeDate(date);
              }}
            />
          </WrapperDatePickerWindow>
        )}
      </Element>
    </Wrapper>
  );
};
