export type ListItemType = {
  id: number;
  name: string;
  subscribe: boolean;
  date_in: string;
  email: string;
  statusName: string;
};
export type BookListType = {
  id: number;
  name: string;
  date_in: string;
  count: number;
  bookValidate: BookValidateType | null;
};
export type BookValidateType = {
  book_id: number;
  countNotValidContacts: number;
  date_in: string;
  id: number;
  saleComplete: boolean;
  sale_id: number;
  status: number; // 2 - проверка // 3 - успех валидации // 4 - ошибка // 1 - ожидает оплаты
  statusName: string;
};
export type BookValidateHistoryType = {
  book_id: number;
  countNotValidContacts: number;
  date_in: string;
  countCompleteContacts: number;
  sale: {
    id: number;
    user_id: number;
    plan_id: null;
    price: string;
    date_in: string;
    complete: boolean;
    payment_id: string;
    conformation_url: string;
  };
  id: number;
  saleComplete: boolean;
  sale_id: number;
  status: number; // 2 - проверка // 3 - успех валидации // 4 - ошибка // 1 - ожидает оплаты
  statusName: string;
};

export type BookSplitListType = {
  offset: number; // системное
  limit: number; // Сколько писем пойдет на каждый день
  date: string;
}[];

export const bookNowIsValidated = (status: BookValidateType["status"]) => {
  return status === 2;
};

export const bookValidateIsSuccess = (status: BookValidateType["status"]) => {
  return status === 3;
};

export const bookValidateIsError = (status: BookValidateType["status"]) => {
  return status === 4;
};

export const bookValidateWaitPay = (status: BookValidateType["status"]) => {
  return status === 1;
};
