import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import styled from "styled-components";

import { useTemplatePreviewFrame } from "app/useTemplatePreviewFrame";
import { createStandardToast, createToastResponseError } from "lib/toastify";
import { blobToBase64 } from "lib/htmlToImage";
import { previewTemplate } from "lib/previewTemplate";
import { canvasToBlob } from "lib/imageOptimize";
import { useAppSelector } from "services/hooks";
import { selectModalData } from "services/store/modalDataSlice";
import { templatesService } from "services/templates";

import { Input } from "../../components/FormsElements/Input";
import { Button } from "../../components/Buttons/Button";
import { ModalButtonsContainer } from "../../components/Modals/ModalButtonsContainer";
import { ModalDefaultFormContainer } from "../../components/Modals/ModalDefaultFormContainer";
import { appConfig } from "../../../appConfig";
import { Link } from "../../components/Link";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`;

export const EditTemplate: CT<{ onClose: VoidFunction }> = ({ onClose }) => {
  const [isLoad, setLoad] = useState(false);
  const { createPreviewFrame, destroyFrame } = useTemplatePreviewFrame();
  const modalData = useAppSelector(selectModalData);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Поле обязательно для заполнения"),
  });

  const onSubmit = async (data: { name: string }) => {
    setLoad(true);
    try {
      const frame = await createPreviewFrame(modalData!.html);
      let base64;

      try {
        let resultCanvas = await html2canvas(frame!.contentDocument!.body, {
          useCORS: false,
          imageTimeout: 15000,
          proxy: appConfig.proxyImageUrl,
          allowTaint: true,
          width: 640,
          height: 780,
          x: 0,
          y: 0,
        });

        let image = await canvasToBlob(resultCanvas);
        base64 = await blobToBase64(image);
      } catch (e) {
        console.error("Ошибка формирования канваса");
        console.error(e);
        createToastResponseError(e, "Ошибка формирования превью")();
        base64 = previewTemplate;
      }

      await destroyFrame();
      await destroyFrame();
      const result = await templatesService.edit({
        id: modalData!.id,
        name: data.name,
        scheme: modalData!.scheme,
        //@ts-ignore
        preview: base64,
        content: modalData!.html,
      });
      toast(result.data.message, {
        autoClose: 3000,
      });
      onClose();
      setTimeout(() => {
        window.history.back();
      }, 500);
    } catch (e) {
      if (e.response?.data?.code === 1003) {
        createStandardToast(
          <p>
            Прежде чем редактировать/удалить шаблон - необходимо{" "}
            <Link
              color={"onlyPrimary"}
              link={"/mailing/?page=1&per-page=25&sortBy=name&tab=draft"}
            >
              завершить/удалить рассылки
            </Link>
          </p>
        )();
        return;
      }
      createToastResponseError(e)();
    } finally {
      setLoad(false);
      onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: modalData?.name ?? "",
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <ModalDefaultFormContainer>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Input
          label={"Название шаблона"}
          type="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          name={"name"}
          placeholder={"Введите название шаблона"}
          error={formik.errors.name}
        />
        <ModalButtonsContainer style={{ marginTop: 12 }}>
          <Button size={"normal"} isLoad={isLoad} type={"submit"}>
            Сохранить
          </Button>
          <Button
            onClick={onClose}
            size={"normal"}
            variant={"secondary"}
            type={"submit"}
          >
            Отменить
          </Button>
        </ModalButtonsContainer>
      </Form>
    </ModalDefaultFormContainer>
  );
};
