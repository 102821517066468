import styled from "styled-components";
import { ModalDefaultFormContainer } from "ui/components/Modals/ModalDefaultFormContainer";
import { _variables } from "ui/styles/_variables";
import { InputDatePicker } from "ui/components/FormsElements/InputDatePicker";
import { Link } from "ui/components/Link";

const Wrapper = styled(ModalDefaultFormContainer)`
  width: 640px;
`;
const IContent = styled.div`
  display: flex;
  min-width: 572px;
  flex-direction: column;
  row-gap: 16px;
`;
const IRow = styled.div`
  column-gap: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const IInfoBox = styled.div`
  width: 48.8%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${_variables.backgroundColor};
  border-radius: 12px;
  height: 102px;
  justify-content: space-between;
`;
const ISelectCountBox = styled(IInfoBox)`
  padding: 12px;
  height: 114px;
  width: 100%;
  padding-bottom: 28px;
`;
const ITimetable = styled(IInfoBox)`
  width: 100%;
  row-gap: 12px;
  min-height: 117px;
  height: auto;
`;
const IBoxTitle = styled.h4`
  color: ${_variables.grayColor};
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
`;
const IBoxTopLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ITitleIconMessage = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;

  & span {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: ${_variables.grayColor};
  }
`;
const ICount = styled.h5`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: right;
`;
const IIconWrapper = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;
const FromToWrapper = styled.div`
  width: 100px;
`;
const IDate = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
`;
const IRowTimeTable = styled.div<{ count: number }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.count > 7 ? "flex-start" : "space-between"};
  column-gap: 4px;
  row-gap: 4px;
  flex-wrap: wrap;
`;
const DatePicker = styled(InputDatePicker)`
  & label {
    font-size: 12px;
    min-width: 120px;
    padding: 0 16px;
    height: 28px;
    background-color: white;
    border: none;
    &:focus-within {
      border: none;
    }
    &:hover {
      border: none;
      background-color: white;
    }
  }
  & svg {
    width: 16px !important;
    height: 16px !important;
  }
`;
const IRowButtons = styled.div`
  display: flex;
  width: 100%;
  column-gap: 17px;
`;
const LinkSetting = styled(Link)`
  display: flex;
  column-gap: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: left;
  height: 26px;
  align-items: center;
`;

const ISelectLineTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  color: ${_variables.grayColor};
`;

const ISelectLineBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 0px;
  & span {
    color: ${_variables.blackColor};
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.25px;
    text-align: left;
  }
`;

const ICheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const ICheckboxTitle = styled(ITitleIconMessage)`
  justify-content: flex-start;
  & span {
    line-height: 16px;
  }
  & svg {
    position: relative;
    top: 0;
  }
`;

const ISwitchWrapper = styled.div`
  display: flex;
  width: 205px;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;

  text-align: right;
`;

export const AutoMailingStyled = {
  Wrapper,
  IContent,
  IRow,
  IInfoBox,
  ISelectCountBox,
  ITimetable,
  IBoxTitle,
  IBoxTopLine,
  ITitleIconMessage,
  ICount,
  IIconWrapper,
  FromToWrapper,
  IDate,
  IRowTimeTable,
  DatePicker,
  IRowButtons,
  LinkSetting,
  ISelectLineTitle,
  ISelectLineBox,
  ICheckboxWrapper,
  ICheckboxTitle,
  ISwitchWrapper,
};
