import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  clearTemplateView,
  selectTemplateViewData,
} from "services/store/templateViewSlice";

import { TemplateViewer } from "../../components/TemplateEditor/TemplateViewer";

export const TemplateViewLayer: CT<unknown> = ({ children }) => {
  const data = useAppSelector(selectTemplateViewData);
  const dispatch = useAppDispatch();

  const onExit = () => {
    dispatch(clearTemplateView());
  };

  return (
    <>
      {children}
      {data && (
        <TemplateViewer
          id={data.id}
          needUpload={true}
          onClickExit={onExit}
          rating={data.rating}
          has_scheme={data.has_scheme}
          isDefaultTemplate={data.isDefaultTemplate}
          isMailingCreationWay={data.isMailingCreationWay}
        />
      )}
    </>
  );
};
