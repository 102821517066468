import styled, { css } from "styled-components";

import type { BookValidateType } from "domain/lists";
import { createDataForTable } from "lib/date";
import { _variables } from "ui/styles/_variables";

import { Icon } from "../Icon";
import { ListCheckedIcon } from "../ListCheckedIcon";
import { Link } from "../Link";

const Container = styled.tr<{ isActive: boolean }>`
  cursor: pointer;
  &:hover {
    & td {
      background: ${_variables.backgroundColor};
    }
  }
  ${(props) =>
    props.isActive &&
    css`
      & td {
        background: ${_variables.backgroundColor};
      }
    `}
`;

const NameWrapper = styled.td`
  padding-left: 12px;
  display: flex;
  column-gap: 16px;
  height: 100%;
  align-items: center;
`;

const Name = styled.div`
  align-items: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: calc(100% - 80px);
  column-gap: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;

  & span {
    display: inline-block;
    white-space: nowrap;
    width: 95%;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`;

const Text = styled.td`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;

const getStatusValidateType = (bookValidate: BookValidateType | null) => {
  if (!bookValidate) return "";
  if (bookValidate.status === 2) return "Идет проверка";
  if (bookValidate.status === 3) return "Проверен";
  if (bookValidate.status === 4) return "Ошибка проверки";
  if (bookValidate.status === 1) return "Ожидает оплаты";
};

export const ListTableContent: CT<{
  isActive: boolean;
  onClickCheck?: (id: number) => void;
  id: number;
  name: string;
  date: string;
  count: number;
  bookValidate: BookValidateType | null;
}> = ({ onClickCheck, id, name, count, date, isActive, bookValidate }) => {
  createDataForTable(date);
  return (
    <Container
      onClick={() => {
        onClickCheck && onClickCheck(id);
      }}
      isActive={isActive}
    >
      <NameWrapper>
        <ListCheckedIcon
          onClick={(e) => {
            e.stopPropagation();
            onClickCheck && onClickCheck(id);
          }}
          isActive={isActive}
        />
        <Icon id={"book"} width={26} height={26} />
        <Name>
          <span>
            <Link link={`/contacts/${id}?name=${name}`}>{name}</Link>
          </span>
        </Name>
      </NameWrapper>
      <Text>{createDataForTable(date)}</Text>
      <Text>{count}</Text>
      <Text>{getStatusValidateType(bookValidate)}</Text>
    </Container>
  );
};
