import React, { useEffect, useState } from "react";
import axios from "axios";

import { setAppError } from "services/store/appSlice";
import { useAppDispatch } from "services/hooks";

export const RequestInterceptor: React.FC<unknown> = ({ children }) => {
  const [showContent, setShowContent] = useState(false);
  const dispatch = useAppDispatch();

  /*
          Установка интерсептора для глобального отлова ошибок
          Логика в том, что при 500,403,401 произойдет редирект на соотв. страницу
          Вне зависимости от вида запроса
         */
  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        if (err.message === "Network Error") return;
        if (err.response) {
          if (err.response.status === 403) {
            if (err.response?.data?.code === 1000) {
              const { message } = err.response.data;
              dispatch(setAppError({ status: 1000, message }));
              return;
            }
          }
          if (err?.response?.status === 500 || err?.response?.status === 401) {
            const { message } = err.response.data;
            dispatch(
              setAppError({
                status: err.response.status,
                message,
                log: JSON.stringify(err.response),
              })
            );
          }
        }
        if (err.response === undefined) {
          dispatch(
            setAppError({
              status: 0,
              message: "Неизвестная ошибка",
              log: JSON.stringify(err.response),
            })
          );
        }
        return Promise.reject(err);
      }
    );
    setShowContent(true);
    //eslint-disable-next-line
  }, []);

  return <>{showContent && children}</>;
};
