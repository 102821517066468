import { useEffect, useState } from "react";
import styled from "styled-components";

import { useUserBuyPackage } from "app/useUserBuyPackage";
import { usePayEntity } from "app/usePayEntity";
import { helpLinks } from "domain/links";
import { getFormatNum } from "lib/numbers";
import {
  createStandardToast,
  createToastResponseError,
  onlyOne,
} from "lib/toastify";
import { contactsListService } from "services/list";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { openModal } from "services/store/modal";
import { setModalData } from "services/store/modalDataSlice";
import { selectUserData } from "services/store/userSlice";
import { Icon } from "ui/components/Icon";
import { _variables } from "ui/styles/_variables";
import previewOneImage from "ui/assets/media/images/package-preview-1.svg";
import previewTwoImage from "ui/assets/media/images/package-preview-2.svg";

import { Button } from "../../components/Buttons/Button";
import { LoadDots } from "../../components/LoadDots";
import { InputPackage } from "../../components/FormsElements/LineInputs/InputPackage";
import { Link } from "../../components/Link";
import { deepEqual } from "lib/other";

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 40px 40px 0px 40px;
`;
const Heading = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 32px;
`;

const Element = styled.div`
  display: flex;
  max-width: 1130px;
  column-gap: 22px;

  background-color: ${_variables.backgroundColor};
  min-height: 320px;
  height: auto;
  border-radius: 12px;
  padding: 24px;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 524px;
  width: 100%;
`;

const ContactsCount = styled.div`
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${_variables.grayColor};
  padding: 18px 13px;
  border: 1px solid ${_variables.strokeColor};
  border-radius: 12px;
  margin-bottom: 15px;
  svg {
    margin-right: 8px;
    position: relative;
    top: -2px;
  }
  span {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${_variables.blackColor};
  }
`;

const CountBox = styled.div`
  margin-bottom: 12px;

  & span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 4px;
    color: ${_variables.grayTextColor};
  }

  & p {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
const Price = styled.div`
  margin-top: 25px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
`;

const Additional = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${_variables.grayTextColor};
  margin-top: 12px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 24px;
`;

const Preview = styled.div`
  width: 100%;
  min-height: 250px;
  min-width: 202px;
  height: auto;
`;

const PreviewOne = styled.div`
  display: flex;
  width: 220px;
  height: 154px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  & img {
    width: 100%;
    height: 100%;
  }
`;

const PreviewTwo = styled.div`
  display: flex;
  width: 136px;
  height: 90px;
  position: absolute;
  top: 16px;
  right: 16px;
  & img {
    width: 100%;
    height: 100%;
  }
`;

const LoadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 128px;
`;

const Load = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const BoldOrange = styled.span`
  font-weight: 700;
  color: ${_variables.primaryColor};
`;

const PreviewImage = styled.div`
  display: flex;
  background: rgba(0, 94, 206, 0.07);
  border-radius: 12px;
  position: relative;
  height: auto;
  min-height: 202px;
`;

export const PackagesPage = () => {
  const [contactsCount, setContactsCount] = useState<number>(0);
  const { values, isLoadPriceData } = usePayEntity();
  const { onPay, isLoadPayData } = useUserBuyPackage();
  const [isShow, setShow] = useState(false);
  const [isLoad] = useState(false);
  const user = useAppSelector(selectUserData, deepEqual);
  const dispatch = useAppDispatch();

  // Изначальный паке при подгрузке даты
  const forcePackage = 2;

  const [value, setValue] = useState<{
    count: number;
    price: number;
    id: number;
  } | null>(null);

  const onChange = (val: { count: number; price: number; id: number }) => {
    setValue(val);
  };

  const loadContactsCount = async () => {
    try {
      const response = await contactsListService.getContactsCount();
      setContactsCount(response.data.data.count);
    } catch (e) {
      createToastResponseError(e)();
    }
  };

  useEffect(() => {
    loadContactsCount();
  }, []);

  useEffect(() => {
    if (value) return;
    if (!isLoadPriceData) {
      setValue(values[forcePackage]);
    }
  }, [values]);

  const moreMailClickHandler = () => {
    onlyOne(
      createStandardToast(
        <span>
          Чтобы выставить счет на организацию, обратитесь в{" "}
          <Link
            color={"onlyPrimary"}
            link={helpLinks.helpBot}
            redirectMode={{ blank: true }}
          >
            службу поддержки
          </Link>
        </span>
      )
    );
  };

  const isDisablePay = value?.count === 0 ?? true;
  const isShowData = !isLoadPriceData && !!value;

  // Если пользователь имеет карту, то показать попап выбора
  const buyHandler = async () => {
    if (!Boolean(user?.count_payment_cards)) {
      await onPay(value!.id, undefined);
      return;
    }
    dispatch(setModalData({ planId: value!.id, isNotShowClose: true }));
    dispatch(openModal("user-pay-plan"));
  };

  useEffect(() => {
    if (!isShowData) return;
    setTimeout(() => {
      setShow(true);
    }, 200);
  }, [isShowData]);

  return (
    <Content>
      <Heading>Пакеты писем</Heading>
      <Element>
        <InputBox>
          <LoadWrapper>
            {!isShow && (
              <Load>
                <LoadDots />
              </Load>
            )}
            {isShow && (
              <>
                <CountBox>
                  <span>Количество писем</span>
                  {isShowData && <p>{getFormatNum(value!.count)}</p>}
                </CountBox>
                <InputPackage
                  forceInitVal={forcePackage}
                  values={values}
                  onChange={onChange}
                />
                <Price>
                  Итого: {isShowData && <>{getFormatNum(value!.price)}</>} ₽
                </Price>
                <Additional>
                  Срок действия пакета – <BoldOrange>30 дней</BoldOrange>
                </Additional>
              </>
            )}
          </LoadWrapper>
          <Buttons>
            <Button
              onClick={buyHandler}
              disabled={isDisablePay || !isShow}
              isLoad={isLoad || isLoadPayData}
              widthMode={"full"}
            >
              Оплатить
            </Button>
            <Button
              onClick={moreMailClickHandler}
              variant={"secondary"}
              widthMode={"full"}
            >
              Выставить счет на организацию
            </Button>
          </Buttons>
        </InputBox>
        <Preview>
          <ContactsCount>
            <Icon id="groupContacts" width={22} height={16} />
            Общее количество загруженных контактов:
            <span> {getFormatNum(contactsCount)}</span>
          </ContactsCount>

          <PreviewImage>
            <PreviewOne>
              <img src={previewOneImage} alt="" />
            </PreviewOne>
            <PreviewTwo>
              {" "}
              <img src={previewTwoImage} alt="" />
            </PreviewTwo>
          </PreviewImage>
        </Preview>
      </Element>
    </Content>
  );
};
