import styled from "styled-components";

import logoImage from "ui/assets/media/icons/Logo.svg";
import logoColor from "ui/assets/media/icons/logo-color.svg";

const LogoComponents = styled.img`
  width: 194px;
  height: 40px;
`;
export const Logo: CT<{ color?: "orange" | "black" }> = ({
  className,
  color = "black",
}) => {
  const colorIcon = {
    orange: logoColor,
    black: logoImage,
  };

  return <LogoComponents className={className} src={colorIcon[color]} alt="" />;
};
