import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "./store";

export type PagesType = {
  templatesChecked: number[];
  listChecked: number[];
  contactsChecked: number[];
  templatesDefaultChecked: number[];
};

const initialState = {
  templatesChecked: [],
  listChecked: [],
  contactsChecked: [],
  templatesDefaultChecked: [],
} as PagesType;

export const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    addCheckPageState(
      state,
      action: PayloadAction<{ page: keyof PagesType; id: number }>
    ) {
      state[action.payload.page] = [
        ...state[action.payload.page],
        action.payload.id,
      ];
    },

    removeCheckPageState(
      state,
      action: PayloadAction<{ page: keyof PagesType; id: number }>
    ) {
      state[action.payload.page] = state[action.payload.page].filter(
        (item) => item !== action.payload.id
      );
    },

    toggleCheckPageState(
      state,
      action: PayloadAction<{ page: keyof PagesType; id: number }>
    ) {
      if (state[action.payload.page].includes(action.payload.id)) {
        state[action.payload.page] = state[action.payload.page].filter(
          (item) => item !== action.payload.id
        );
        return;
      }
      state[action.payload.page] = [
        ...state[action.payload.page],
        action.payload.id,
      ];
    },

    setCheckPageState(
      state,
      action: PayloadAction<{ page: keyof PagesType; ids: number[] }>
    ) {
      state[action.payload.page] = action.payload.ids;
    },

    clearCheckPageState(
      state,
      action: PayloadAction<{ page: keyof PagesType }>
    ) {
      state[action.payload.page] = [];
    },

    clearAllPagesState() {
      return initialState;
    },
  },
});

export const {
  addCheckPageState,
  clearAllPagesState,
  clearCheckPageState,
  removeCheckPageState,
  setCheckPageState,
  toggleCheckPageState,
} = pagesSlice.actions;
export default pagesSlice.reducer;

export const selectTemplatesChecked = (state: RootState) =>
  state.pagesState.templatesChecked;
