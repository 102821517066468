import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";

import { isBadBodyStatusResponse } from "domain/api";
import { serviceUser } from "services/user";
import { _variables } from "ui/styles/_variables";

import { Input } from "../../components/FormsElements/Input";
import { InputPassword } from "../../components/FormsElements/InputPassword";
import { Link } from "../../components/Link";
import { CheckboxCircle } from "../../components/FormsElements/CheckboxCircle";
import { Button } from "../../components/Buttons/Button";
import { appConfig, isDev } from "../../../appConfig";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 320px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: flex-start;
`;

const ForgotPasswordText = styled.p`
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: left;
`;

const ValidMessage = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: ${_variables.supportColors.green};
`;
const InvalidMessage = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: ${_variables.supportColors.red};
`;

const CheckboxBox = styled.div`
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  row-gap: 12px;
`;

export const RegisterForm: CT<{
  onSuccessRegister: (email: string) => void;
}> = ({ onSuccessRegister }) => {
  const [isLoad, setLoad] = useState(false);
  const [apiStatus, setApiStatus] =
    useState<ApiResponseBodyType<unknown> | null>(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^\S*$/gm, "Имя не должно содержать пробелов")
      .required("Поле обязательно для заполнения")
      .max(50, "Максимум 50 символов"),
    email: Yup.string()
      .email("Необходимо ввести корректный email")
      .required("Поле обязательно для заполнения"),
    password: Yup.string()
      .required("Поле обязательно для заполнения")
      .min(6, "Минимум 6 символов"),
    firstCheckbox: Yup.boolean().required(""),
    secondCheckbox: Yup.boolean().required(""),
  });

  const initialValues = {
    name: "",
    surname: "Нет фамилии",
    email: "",
    password: "",
    firstCheckbox: false,
    secondCheckbox: false,
  };

  const submitHandler = async (data: typeof initialValues) => {
    setLoad(true);
    try {
      const request = await serviceUser.joinByEmail({
        name: data.name,
        email: data.email,
        surname: data.surname,
        password: data.password,
        password_confirm: data.password,
      });
      setApiStatus(request.data);
      window.dataLayer.push({ event: "cp_registration_success" });
      if (!isDev()) {
        window.carrotquest.track("$registered", {
          $email: data.email,
          $name: data.name,
        });
      }
      onSuccessRegister(data.email);
    } catch (e) {
      setApiStatus(e.response.data);
    } finally {
      setLoad(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    onSubmit: submitHandler,
    validationSchema: validationSchema,
  });

  const setFormError = () => {
    formik.dirty = true;
  };

  useEffect(() => {
    if (!apiStatus) return;
    if (isBadBodyStatusResponse(apiStatus)) setFormError();
    //eslint-disable-next-line
  }, [apiStatus]);

  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Input
          placeholder={"Имя"}
          autoFocus={false}
          type={"text"}
          value={formik.values.name}
          onChange={formik.handleChange}
          name={"name"}
          error={formik.errors.name}
        />
        <Input
          placeholder={"Email"}
          type={"email"}
          name={"email"}
          onChange={formik.handleChange}
          error={formik.errors.email}
        />

        <InputPassword
          name={"password"}
          placeholder={"Пароль"}
          handleChange={formik.handleChange}
          error={formik.errors.password}
          value={formik.values.password}
        />

        {/*<input*/}
        {/*  type="tel"*/}
        {/*  onChange={formik.handleChange}*/}
        {/*  name={"tel"}*/}
        {/*  value={formik.values.tel}*/}
        {/*  placeholder={"tel"}*/}
        {/*/>*/}
        <CheckboxBox>
          <CheckboxCircle
            name={"firstCheckbox"}
            onChecked={() => {
              formik.setFieldValue(
                "firstCheckbox",
                !formik.values.firstCheckbox
              );
            }}
            checked={formik.values.firstCheckbox}
          >
            <span>
              Я&nbsp;согласен(на)&nbsp;с&nbsp;
              <Link
                underline={true}
                link={`${appConfig.staticUrl}docs/oferta.pdf`}
                color={"onlyPrimary"}
                redirectMode={{ blank: true }}
              >
                офертой
              </Link>
              &nbsp;и&nbsp;
              <Link
                underline={true}
                link={"https://cheapsender.email/antispam-politika"}
                color={"onlyPrimary"}
                redirectMode={{ blank: true }}
              >
                антиспам&nbsp;политикой
              </Link>
            </span>
          </CheckboxCircle>
          <CheckboxCircle
            name={"secondCheckbox"}
            checked={formik.values.secondCheckbox}
            onChecked={() => {
              formik.setFieldValue(
                "secondCheckbox",
                !formik.values.secondCheckbox
              );
            }}
          >
            <span>
              Я&nbsp;ознакомлен(на)&nbsp;с&nbsp;
              <Link
                underline={true}
                link={
                  "https://documents.timeweb.ru/files/policy/personal_data.pdf"
                }
                color={"onlyPrimary"}
                redirectMode={{ blank: true }}
              >
                политикой
              </Link>
              &nbsp;и&nbsp;даю&nbsp;согласие&nbsp;
              <Link
                underline={true}
                link={"https://timeweb.com/tw-static/docs/user_agreement.pdf"}
                color={"onlyPrimary"}
                redirectMode={{ blank: true }}
              >
                <span>
                  на
                  <br />
                  обработку&nbsp;персональных&nbsp;данных
                </span>
              </Link>
            </span>
          </CheckboxCircle>
        </CheckboxBox>
        <ButtonWrapper>
          <Button
            widthMode={"full"}
            style={{ marginTop: 8 }}
            isLoad={isLoad}
            type={"submit"}
            disabled={
              !formik.values.firstCheckbox || !formik.values.secondCheckbox
            }
          >
            Зарегистрироваться
          </Button>
          <ForgotPasswordText>
            Забыли пароль?{" "}
            <Link
              redirectMode={{ blank: false }}
              color={"primary"}
              link="/password/restore"
            >
              Восстановить
            </Link>
          </ForgotPasswordText>
        </ButtonWrapper>
        {apiStatus && apiStatus?.status >= 400 && (
          <InvalidMessage> {apiStatus.message}</InvalidMessage>
        )}
        {apiStatus && apiStatus?.status === 200 && (
          <ValidMessage> {apiStatus.message}</ValidMessage>
        )}
      </Form>
    </>
  );
};
