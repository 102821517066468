import { useEffect } from "react";
import { useTitle } from "ahooks";
import styled from "styled-components";

import { clearAppError } from "services/store/appSlice";
import { useAppDispatch } from "services/hooks";
import { _variables } from "ui/styles/_variables";
import imagePreview from "ui/assets/media/images/404-preview.svg";

import { Logo } from "../../components/Logo";
import { appConfig } from "../../../appConfig";
import { Copyright } from "../../components/Copyright";
import { Link } from "../../components/Link";
import { Icon } from "../../components/Icon";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;
const Content = styled.div`
  min-height: 100vh;
  width: 365px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 50px;
`;
const Preview = styled.div`
  width: 352px;
  margin-top: 96px;
  height: 280px;
  & img {
    width: 100%;
    height: 100%;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;
`;

const Heading = styled.h3`
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-top: 40px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
`;

const LinkToMain = styled(Link)`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  column-gap: 12px;
  align-items: center;
  color: ${_variables.primaryColor};
  transition: ${_variables.transition};
  &:hover {
    transform: translateX(-5px);
  }
`;

export const NotFoundPage: CT<unknown> = () => {
  const dispatch = useAppDispatch();
  useTitle("Страница не найдена" + appConfig.titleApp);

  useEffect(() => {
    return () => {
      dispatch(clearAppError());
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Content>
        <Box>
          <Logo color={"orange"} />

          <Preview>
            <img src={imagePreview} alt="" />
          </Preview>
          <Heading>Похоже, этой страницы не существует...</Heading>
          <LinkToMain link={"/main/"}>
            <Icon id={"arrow-to-left"} width={20} height={13} />
            На главную страницу
          </LinkToMain>
        </Box>
        <Copyright />
      </Content>
    </Wrapper>
  );
};
