import { toast } from "react-toastify";

const autoClose = 3000;

export const createToastResponseError =
  (e: any, message?: string | JSX.Element) => (addConfig?: any) => {
    toast(message ?? e.response.data.message, {
      autoClose,
      type: "warning",
      ...addConfig,
    });
  };

export const onlyOne = (toastFn: any) => {
  toastFn({ toastId: "onlyOne" });
};

export const createStandardResponseToast =
  (res: ApiResponseBodyType<unknown>, message?: string | JSX.Element) =>
  (addConfig?: any) => {
    toast(message ?? res.message, { autoClose, ...addConfig });
  };

export const createStandardToast =
  (message: string | JSX.Element) => (addConfig?: any) => {
    toast(message, { autoClose, ...addConfig });
  };
