import type { InputHTMLAttributes } from "react";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import cn from "classnames";

import { formElement, formWrapper } from "ui/styles/elements/formElements";
import { _variables } from "ui/styles/_variables";
import IconErrorSVG from "ui/assets/media/icons/error-icon-form.svg";
import IconSuccessSVG from "ui/assets/media/icons/success-icon-form.svg";

import { Icon } from "../Icon";

const InputElement = styled.input<{
  isError: boolean;
  haveIcon: boolean;
  haveStartIcon: boolean;
  haveEndIcon: boolean;
  color: "white" | "standard";
}>`
  ${formElement}
  ${(props) =>
    props.haveStartIcon &&
    css`
      padding-left: 54px;
    `}
  ${(props) =>
    props.haveEndIcon &&
    css`
      padding-right: 54px;
    `}
  &.focus {
    background-color: ${_variables.whiteColor};
    border: 2px solid ${_variables.blackColor};
  }
  &:focus-within {
    background-color: ${_variables.whiteColor};
    border: 2px solid ${_variables.blackColor};
  }

  ${({ color }) => {
    if (color == "white")
      return css`
        background-color: ${_variables.whiteColor};
        &:hover {
          background-color: ${_variables.whiteColor};
        }
        &.focus {
          background-color: ${_variables.whiteColor};
          border: 2px solid ${_variables.blackColor};
        }
        &:focus-within {
          background-color: ${_variables.whiteColor};
          border: 2px solid ${_variables.blackColor};
        }
      `;
  }}
`;

const Wrapper = styled.div`
  ${formWrapper};
  width: 100%;
`;

const ErrorIcon = () => (
  <img className={"verify"} width={16} height={16} src={IconErrorSVG} alt="" />
);

const SuccessIcon = () => (
  <img
    className={"verify"}
    width={16}
    height={16}
    src={IconSuccessSVG}
    alt=""
  />
);

const StartIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
`;

const RightContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

type InputType = {
  error?: string | boolean;
  label?: string;
  description?: string;
  verifyIcon?: {
    state: "error" | "success";
  };
  color?: "white" | "standard";
  isFocus?: boolean;
  startIcon?: {
    id: string;
    width: number;
    height: number;
  };
  value?: string;
  rightIconContent?: JSX.Element;
  ref?: React.RefObject<HTMLInputElement>;
  EndComponent?: JSX.Element;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input: CT<InputType> = React.memo(
  React.forwardRef<HTMLInputElement, InputType>(
    (
      {
        rightIconContent,
        verifyIcon,
        value = "",
        error = "",
        label = "",
        color = "standard",
        isFocus = false,
        description = "",
        onChange = () => {},
        startIcon,
        EndComponent,
        ...rest
      },
      ref
    ) => {
      const [innerValue, setValue] = useState(value);

      useEffect(() => {
        setValue(value);
      }, [value]);

      return (
        <Wrapper>
          {label && <p className={"label"}>{label}</p>}
          {description && <p className={"description"}>{description}</p>}

          <div className={"form"}>
            <InputElement
              //@ts-ignore
              ref={ref}
              color={color}
              value={innerValue}
              className={cn(isFocus ? "focus" : "", rest.className)}
              onChange={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopPropagation();
                onChange(e);
                setValue(e.target.value);
              }}
              haveStartIcon={!!startIcon}
              haveEndIcon={!!EndComponent}
              haveIcon={!!verifyIcon}
              isError={!!error}
              maxLength={200}
              {...rest}
            />
            {rightIconContent && (
              <RightContainer>{rightIconContent}</RightContainer>
            )}
            {startIcon && (
              <StartIconWrapper>
                <Icon
                  id={startIcon.id}
                  height={startIcon.height}
                  width={startIcon.width}
                />
              </StartIconWrapper>
            )}
            {EndComponent}
            {verifyIcon && verifyIcon.state === "error" && <ErrorIcon />}
            {verifyIcon && verifyIcon.state === "success" && <SuccessIcon />}
          </div>
          {error !== undefined && <span className={"error"}>{error}</span>}
        </Wrapper>
      );
    }
  ),
  () => {
    return false;
  }
);
