import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "./store";

export type ViewContactsType = {
  checked: number[];
};

const initialState = {
  checked: [],
} as ViewContactsType;

export const viewContactsSlice = createSlice({
  name: "viewContact",
  initialState,
  reducers: {
    addViewContactCheck(state, action: PayloadAction<number>) {
      state.checked = [...state.checked, action.payload];
    },
    removeViewContactCheck(state, action: PayloadAction<number>) {
      state.checked = state.checked.filter((item) => item !== action.payload);
    },
    toggleViewContactCheck(state, action: PayloadAction<number>) {
      if (state.checked.includes(action.payload)) {
        state.checked = state.checked.filter((item) => item !== action.payload);
        return;
      }
      state.checked = [...state.checked, action.payload];
    },
    setViewContactCheckCollection(state, action: PayloadAction<number[]>) {
      state.checked = action.payload;
    },
    clearViewContactAll(state) {
      state.checked = [];
    },
  },
});

export const {
  addViewContactCheck,
  setViewContactCheckCollection,
  clearViewContactAll,
  removeViewContactCheck,
  toggleViewContactCheck,
} = viewContactsSlice.actions;
export default viewContactsSlice.reducer;

export const selectCheckedViewContact = (state: RootState) =>
  state.viewContact.checked;
