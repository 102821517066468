import styled from "styled-components";

import preview from "ui/assets/media/images/mailing-not-content-preview.svg";

import { ShadowButton } from "../Buttons/ShadowButton";
import { Icon } from "../Icon";
import { Link } from "../Link";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const Preview = styled.div`
  width: 485px;
  height: 212px;
  margin-bottom: 24px;
  & img {
    width: 100%;
    height: 100%;
  }
`;
export const NotContentMailingPage = () => {
  return (
    <Content>
      <Preview>
        <img src={preview} alt="" />
      </Preview>
      <Link link={"/templates/cards?tab=default-templates"}>
        <ShadowButton>
          <Icon
            id={"plus-icon"}
            height={16}
            width={16}
            style={{ marginRight: 8 }}
          />
          Создать рассылку
        </ShadowButton>
      </Link>
    </Content>
  );
};
