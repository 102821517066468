import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { getFormatNum } from "lib/numbers";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectUserData } from "services/store/userSlice";
import { _variables } from "ui/styles/_variables";
import { scroll } from "ui/styles/elements/scroll";

import { Icon } from "./Icon";
import { Copyright } from "./Copyright";
import { Button } from "./Buttons/Button";
import { ButtonOpacity } from "./Buttons/ButtonOpacity";
import { Link as AppLink } from "./Link";
import { clearUserCJMMailingData } from "services/store/cjm";
import { deepEqual } from "lib/other";

const Menu = styled.nav`
  width: 238px;
  min-width: 238px;
  border-right: 1px solid ${_variables.strokeColor2};
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  ${scroll}
  &::-webkit-scrollbar {
    width: 3px; /* width of the entire scrollbar */
  }
`;

const List = styled.ul`
  width: 100%;
  row-gap: 6px;
  padding-right: 24px;
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
`;

const Item = styled.li`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;

  transition: all ${_variables.transition};
  & .active {
    background-color: ${_variables.backgroundColor};
  }
  &:hover {
    border-radius: 0px 8px 8px 0px;
    background-color: ${_variables.backgroundColor};
    color: ${_variables.blackColor};
    & a {
      color: ${_variables.blackColor};
    }
  }
`;

const IconMenu = styled(Icon)`
  margin-right: 12px;
`;

const Link = styled(NavLink)`
  display: flex;
  width: 100%;
  align-items: center;
  color: inherit;
  border-radius: 0px 8px 8px 0px;
  padding-left: 24px;
  height: 100%;
  transition: all ${_variables.transition};
`;

const Copy = styled(Copyright)`
  margin-left: 24px;
  margin-top: auto;
  margin-bottom: 24px;
`;

const ButtonWrapper = styled.div`
  padding-right: 24px;
  margin-bottom: 24px;
  width: 100%;
  padding-left: 24px;
  align-self: flex-end;
  margin-left: 10px;
  & a {
    padding-left: 0;
  }
`;

const AdditionalBlock = styled.div`
  padding: 24px 24px;
  border-top: 1px solid ${_variables.backgroundColor};
  border-bottom: 1px solid ${_variables.backgroundColor};
`;

const PayButton = styled(Button)`
  column-gap: 12px;
`;

const CountMails = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-top: 12px;
  position: relative;
  padding-left: 15px;

  &:before {
    width: 3px;
    background-color: ${_variables.primaryColor};
    left: 0;
    top: 6px;
    border-radius: 8px;
    height: calc(100% - 8px);
    content: "";
    position: absolute;
  }
  & span {
    color: ${_variables.grayTextColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  & p {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

export const ColumnMenu: CT<unknown> = React.memo(() => {
  const userData = useAppSelector(selectUserData, deepEqual);
  const dispatch = useAppDispatch();
  const showTemplatesRoute = userData?.["is_admin"];
  return (
    <Menu>
      <ButtonWrapper>
        <Link to={"/templates/cards?tab=default-templates"}>
          <ButtonOpacity
            onClick={() => {
              dispatch(clearUserCJMMailingData());
            }}
            widthMode={"full"}
          >
            Создать рассылку
          </ButtonOpacity>
        </Link>
      </ButtonWrapper>
      <List>
        <Item>
          <Link to={"/main"} activeClassName={"active"}>
            <IconMenu id={"home-icon"} width={24} height={24} />
            <span>Главная</span>
          </Link>
        </Item>
        <Item>
          <Link to={"/contacts"} activeClassName={"active"}>
            <IconMenu id={"2user"} width={24} height={24} />
            <span>Контакты</span>
          </Link>
        </Item>
        <Item>
          <Link to={"/templates/"} activeClassName={"active"}>
            <IconMenu id={"grid-icon"} width={24} height={24} />
            <span>Шаблоны</span>
          </Link>
        </Item>
        <Item>
          <Link to={"/mailing/"} activeClassName={"active"}>
            <IconMenu id={"send-icon"} width={24} height={24} />
            <span>Мои рассылки</span>
          </Link>
        </Item>
        <Item>
          <Link to={"/packages/payment"} activeClassName={"active"}>
            <IconMenu id={"folder-open"} width={24} height={24} />
            <span>Пакеты писем</span>
          </Link>
        </Item>
        <Item>
          <Link to={"/rest/"} activeClassName={"active"}>
            <IconMenu id={"api-icon"} width={24} height={24} />
            <span>API рассылки</span>
          </Link>
        </Item>
        {showTemplatesRoute && (
          <Item>
            <Link to={"/forms/"} activeClassName={"active"}>
              <IconMenu id={"forms-subscribe"} width={24} height={24} />
              <span>Формы подписки</span>
            </Link>
          </Item>
        )}
        {showTemplatesRoute && (
          <Item>
            <Link to={"/default-templates/"} activeClassName={"active"}>
              <IconMenu id={"templates-default-icon"} width={24} height={24} />
              <span>Публичные шаблоны</span>
            </Link>
          </Item>
        )}
      </List>
      <AdditionalBlock>
        <AppLink link={"/packages/payment"}>
          <PayButton widthMode={"full"}>
            <Icon width={25} height={24} id={"card-pay-icon"} />
            Пополнить
          </PayButton>
        </AppLink>
        <CountMails>
          <span>Доступно писем</span>
          <p>{getFormatNum(userData?.balance ?? 0)}</p>
        </CountMails>
      </AdditionalBlock>

      <Copy />
    </Menu>
  );
});
