import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useAppSelector } from "services/hooks";
import { selectAppError } from "services/store/appSlice";

export const ErrorBoundaryApi: React.FC<unknown> = ({ children }) => {
  const appError = useAppSelector(selectAppError);
  const history = useHistory();
  const appErrors: { [key: number]: VoidFunction } = {
    "0": () => {
      history.push("/status/error");
    },
    "404": () => {
      history.push("/status/404");
    },
    "401": () => {
      history.push("/logout");
    },
    "500": () => {
      history.push("/status/error");
    },
    "403": () => {
      history.push("/status/403");
    },
    "1000": () => {
      history.push("/status/1000");
    },
  };

  useEffect(() => {
    if (!appError) return;
    appErrors[appError.status]();
    //eslint-disable-next-line
  }, [appError]);

  return <>{children}</>;
};
