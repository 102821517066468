export const animateTransition = {
  transition: {
    duration: 0.2,
    ease: "linear",
  },
};

export const animateHideShowTooltip = {
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  initial: { opacity: 0, transition: animateTransition["transition"] },
};
