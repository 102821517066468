import type { StatisticBlockProps } from "ui/components/StatisticBlocks/types";

import styled from "styled-components";

import { getFormatNum } from "lib/numbers";

import { _variables } from "ui/styles/_variables";
import eyeIcon from "ui/assets/media/icons/statistic-eye-icon.svg";
import mailIcon from "ui/assets/media/icons/statistic-mail-icon.svg";
import successIcon from "ui/assets/media/icons/statistic-success-icon.svg";
import clickIcon from "ui/assets/media/icons/statistic-click-icon.svg";
import apiIcon from "ui/assets/media/icons/statistic-api-icon.svg";

const Container = styled.div<{ haveCount: boolean }>`
  display: flex;
  column-gap: 12px;
  cursor: ${(props) => (props.haveCount ? "pointer" : "default")};
  &:hover {
    .statistic__percent {
      display: none;
    }
    .statistic__count {
      display: block;
    }
  }
`;
const Image = styled.img`
  width: 52px;
  height: 52px;
`;
const TextContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const SubHeading = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${_variables.grayTextColor};
`;
const Percent = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;
const Count = styled(Percent)`
  display: none;
`;

type variantType = "success" | "mail" | "eye" | "click" | "api";

const icons = {
  success: successIcon,
  mail: mailIcon,
  eye: eyeIcon,
  click: clickIcon,
  api: apiIcon,
};

const subHeadings = {
  success: "Отправлено",
  mail: "Доставлено",
  eye: "Открыто",
  click: "Клики",
  api: "Вызовы API",
};

export const StatisticCircle: CT<
  StatisticBlockProps<{
    variant: variantType;
  }>
> = ({ variant, category, children, onClick, percent, count }) => {
  return (
    <Container
      haveCount={!!count}
      onClick={() => {
        if (count === 0) return;
        onClick(category);
      }}
    >
      <Image src={icons[variant]}>{children}</Image>
      <TextContent>
        <SubHeading>{subHeadings[variant]}</SubHeading>
        <Percent className={"statistic__percent"}>{percent}%</Percent>
        <Count className={"statistic__count"}>{getFormatNum(count)}</Count>
      </TextContent>
    </Container>
  );
};
