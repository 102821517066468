import { useState } from "react";
import styled from "styled-components";

import type { CommentAnswerType } from "domain/idea";
import { createDateForIdeaComment } from "lib/date";
import { _variables } from "ui/styles/_variables";
import defaultAvatar from "ui/assets/media/images/defaultAvatar.png";

import { Avatar } from "../Avatar";
import { CommentField } from "./CommentField";

type CommentProps = CommentAnswerType & {
  addComment?: (content: string, replyTo?: number) => Promise<void>;
};

const Container = styled.div<{ isAnswer: boolean }>`
  margin-left: ${(props) => (props.isAnswer ? "20px" : 0)};
`;

const Header = styled.div`
  display: flex;
  margin: 8px 0;
`;

const AvatarWrapper = styled.div`
  margin-right: 10px;
`;

const CommentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Name = styled.span`
  font-size: 15px;
  font-weight: 500;
`;

const Date = styled.span`
  color: ${_variables.grayColor};
  font-size: 13px;
`;

const Content = styled.p`
  width: 100%;
  color: ${_variables.grayColor};
  font-size: 13px;
  margin: 8px 0;
`;

const ReplyButton = styled.button`
  border: none;
  background: none;
  color: ${_variables.primaryColor};
  font-size: 15px;
  font-weight: 500;
  padding: 0;
`;

export const Comment = ({
  userPhoto,
  userName,
  dateIn,
  content,
  isAnswer,
  addComment,
  id,
}: CommentProps) => {
  const [canWrite, setCanWrite] = useState<boolean>(false);

  return (
    <Container isAnswer={isAnswer}>
      <Header>
        <AvatarWrapper>
          <Avatar src={userPhoto ?? defaultAvatar} height={48} width={48} />
        </AvatarWrapper>
        <CommentInfo>
          <Name>{userName}</Name>
          <Date>{createDateForIdeaComment(dateIn)}</Date>
        </CommentInfo>
      </Header>
      <Content>{content}</Content>
      {isAnswer || canWrite ? null : (
        <ReplyButton onClick={() => setCanWrite(true)}>Ответить</ReplyButton>
      )}
      {canWrite && addComment ? (
        <CommentField
          addComment={(comment, replyTo) =>
            addComment(comment, replyTo).then(() => setCanWrite(false))
          }
          parentCommentId={id}
        />
      ) : null}
    </Container>
  );
};
