import FileSaver from "file-saver";
import styled, { css } from "styled-components";

import {
  bookNowIsValidated,
  bookValidateIsError,
  bookValidateWaitPay,
} from "domain/lists";
import { useBlockTimeout } from "app/useBlockTimeout";
import { contactsListService } from "services/list";
import { useAppDispatch } from "services/hooks";
import { openModal } from "services/store/modal";
import { setModalData } from "services/store/modalDataSlice";
import {
  createStandardToast,
  createToastResponseError,
  onlyOne,
} from "lib/toastify";

import { mxm } from "ui/styles/_mixin";
import { AlertTable } from "ui/components/DecorativeBlocks/AlertTable";
import { Button } from "ui/components/Buttons/Button";
import { Icon } from "ui/components/Icon";

const Container = styled.div`
  display: flex;
  width: calc(100%);
  align-items: center;
`;

const Alert = styled(AlertTable)`
  width: 100%;
  max-width: 100%;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 65px;
  justify-content: space-between;
`;

const Statistic = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
`;

const DeleteButton = styled(Button)`
  max-width: 255px;
  & svg {
    opacity: 0.5;
  }
`;

const DownloadButton = styled(Button)``;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: flex-start;

  & h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
  }

  & span {
    font-size: 14px;
    max-width: 150px;
    color: #898d9a;
    display: flex;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    justify-content: center;
    text-align: center;
  }

  .maxWidth {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 32px;
  ${mxm(
    1300,
    css`
      flex-direction: column;
      row-gap: 5px;
    `
  )}
`;

export const AlertListPageContent: CT<{
  name: string;
  date: string;
  countNotValide: string | number;
  status: number;
  maxCount: string | null;
  saleComplete: boolean;
  bookId: number;
}> = ({
  status,
  maxCount,
  countNotValide,
  name,
  className,
  date,
  saleComplete,
  bookId,
}) => {
  const bookIsValidatedNow = bookNowIsValidated(status);
  const { timeout, setTimer } = useBlockTimeout();
  const dispatch = useAppDispatch();

  const getStatus = () => {
    if (bookIsValidatedNow) return "Идет проверка...";
    if (bookValidateWaitPay(status)) return "Ожидает оплаты";
    if (bookValidateIsError(status)) return "Ошибка валидации";
    if (maxCount) {
      return `${countNotValide} из ${maxCount}`;
    }
    return `${countNotValide}`;
  };

  const getPayStatus = () => {
    if (!saleComplete) return "Ожидает оплаты";
    return "Оплачено";
  };

  const checkHandler = () => {
    if (bookIsValidatedNow) {
      onlyOne(createStandardToast("Дождитесь конца проверки"));
      return null;
    }
    if (!saleComplete) {
      onlyOne(createStandardToast("Ожидание оплаты"));
      return null;
    }
    if (bookValidateIsError(status)) {
      onlyOne(
        createStandardToast(
          "Возникла ошибка валидации, обратитесь в службу поддержки"
        )
      );
      return null;
    }
    return 1;
  };

  const clickDeleteHandler = async () => {
    if (!checkHandler()) return;
    if (!bookId) return;
    dispatch(setModalData({ id: bookId }));
    dispatch(openModal("delete-unValidate-contacts"));
  };

  const clickDownloadHandler = async () => {
    if (!checkHandler()) return;
    try {
      setTimer(3);
      const response = await contactsListService.downloadValidateReport({
        id: bookId,
      });
      let blob = new Blob([response.data]);
      FileSaver.saveAs(blob, `${name}.csv`);
    } catch (e) {
      createToastResponseError(null, "Ошибка создания отчета")();
    }
  };

  return (
    <Container className={className}>
      <Alert>
        <Content>
          <Statistic>
            <Item>
              <h6>Название списка</h6>
              <span className={"maxWidth"}>{name}</span>
            </Item>
            <Item>
              <h6>Дата проверки</h6>
              <span>{date.split(" ")[0]}</span>
            </Item>
            <Item>
              <h6>Недействительные</h6>
              <span>{getStatus()}</span>
            </Item>
            <Item>
              <h6>Статус оплаты</h6>
              <span>{getPayStatus()}</span>
            </Item>
          </Statistic>
          <ButtonWrapper>
            <DeleteButton onClick={clickDeleteHandler} variant={"secondary"}>
              <Icon
                id={"trash-icon"}
                height={16}
                width={16}
                style={{ marginRight: 8 }}
              />
              Удалить недействительные
            </DeleteButton>
            <DownloadButton
              disabled={timeout > 0}
              onClick={clickDownloadHandler}
              variant={"secondary"}
            >
              <Icon
                width={16}
                height={16}
                id={"document-statistic-icon"}
                style={{ marginRight: 8 }}
              />
              Скачать отчет
            </DownloadButton>
          </ButtonWrapper>
        </Content>
      </Alert>
    </Container>
  );
};
