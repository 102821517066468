import styled from "styled-components";

import notContentImage from "ui/assets/media/images/no-contant-preview.svg";

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
`;

const Image = styled.div`
  width: 302px;
  height: 249px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

export const NotContentContactPage: CT<unknown> = ({ className }) => {
  return (
    <Content className={className}>
      <Image>
        <img src={notContentImage} alt="" />
      </Image>
    </Content>
  );
};
