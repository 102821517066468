import FileSaver from "file-saver";
import type { AxiosResponse } from "axios";

import { createToastResponseError } from "lib/toastify";

import { useBlockTimeout } from "./useBlockTimeout";

export const useLoadFile = (
  serviceMethod: () => Promise<AxiosResponse<any>>,
  titleFile: string,
  errorMessage = "Ошибка загрузки файла"
) => {
  const { setTimer, timeout } = useBlockTimeout();

  const load = async () => {
    try {
      setTimer(3);
      const response = await serviceMethod();
      let blob = new Blob([response.data]);
      FileSaver.saveAs(blob, titleFile);
    } catch (e) {
      createToastResponseError(null, errorMessage)();
    }
  };

  return {
    loadFile: load,
    isDisableLoad: timeout > 0,
  };
};
