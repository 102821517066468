import React, { useEffect } from "react";
import { Prompt, useHistory } from "react-router-dom";
import styled from "styled-components";

import { offExitConfirm, onExitConfirm } from "app/applicationExitHandler";
import { useInnerPromptRedirect } from "app/useInnerPromptRedirect";
import { useAppSelector } from "services/hooks";
import { selectCreateMailingData } from "services/store/createMailingSlice";
import { _variables } from "ui/styles/_variables";

import { FirstCreateMailing } from "../../containers/forms/FirstCreateMailing";

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 40px 40px 0px 40px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
`;
const Heading = styled.h1`
  color: ${_variables.blackColor};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;
const SubText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 32px;
  color: ${_variables.grayTextColor};
`;
const Row = styled.div`
  display: flex;
  column-gap: 20px;
`;

const acceptRoutes = [
  "/templates/choose-list",
  "/templates/cards?by=import",
  "/main/user/setting?action=add",
  "/mailing/create-mailing",
];

export const FirstStepCreateMailingPage = () => {
  const createMailingData = useAppSelector(selectCreateMailingData);
  const history = useHistory();
  const { isShowConfirmRedirect } = useInnerPromptRedirect(acceptRoutes);

  useEffect(() => {
    if (!createMailingData.data?.templateStep) {
      history.push("/templates/cards?by=import");
    }
  }, [createMailingData]);
  useEffect(() => {
    onExitConfirm();
    return () => {
      offExitConfirm();
    };
  }, []);

  return (
    <Content>
      <Prompt
        message={(location) => {
          return isShowConfirmRedirect(location as unknown as Location)
            ? true
            : `Вы покидаете форму импорта. Внесенные изменения не сохранятся`;
        }}
      />
      <Top>
        <Heading>Введите тему и адрес</Heading>
        <SubText>
          Пользователь сразу увидит от кого письмо, напишите оригинально для
          привлечения внимания.
        </SubText>
      </Top>
      <Row>
        <FirstCreateMailing />
      </Row>
    </Content>
  );
};
